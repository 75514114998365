import { MCAZS080MessageConfirmRequest } from 'types/MCAZS080/MCAZS080MessageConfirmRequest'
import { MCAZS080MessageDraftRequest } from 'types/MCAZS080/MCAZS080MessageDraftRequest'

export interface InitResult {
  [key: string]: any
  draftMessageTemplateSettingId: number
  destinationDivision: string
  // [phase2] start
  sendToNonMember:number //マイナビ退会者を送信対象者に含む
  // [phase2] end
  destinationInfo: MCAZS080DestinationResultDto[]
  interviewDurationId: number
  interviewDetail: string
  interviewScheduleAdjustmentStatus: string
  interviewSchedule: MCAZS080InterviewScheduleResultDto[]
  senderCompanyName: string
  senderMailAddress: string
  replyPermitFlag: string
  replyNecessaryFlag: string,
  replyLimitTime: Date | null,
  subject: string
  replyFromSubject: string
  body: string
  replyFromBody: string
  attachment: MCAZS080DraftAttachmentResultDto[]
  checkedEntryBoxId: number[]
  checkedSeminarId: number[]
  homePage: MCAZS080DraftHomepageResultDto[]
  barcodeDisplayFlag: string
  sendTimeFlag: string
  sendPlanTime: Date
  notificationMailAddress: string
  messageTemplate: MCAZS080MessageTemplateResultDto[]
  messageTemplateHomepage: MCAZS080MessageTemplateHomepageResultDto[]
  messageTemplateAttachment: MCAZS080TemplateAttachmentResultDto[]
  messageTemplateEntrybox: MCAZS080TemplateMynaviAttachedOptionEntryBoxResultDto[]
  messageTemplateSeminar: MCAZS080TemplateMynaviAttachedOptionSeminarResultDto[]
  mynaviAttachedOptionEntryBox: MCAZS080MynaviAttachedOptionEntryBoxResultDto[]
  mynaviAttachedOptionSeminar: MCAZS080MynaviAttachedOptionSeminarResultDto[]
  recruitmentManagementFlag: MCAZS080RecruitmentManagementFlagResultDto[]
  seminarSchedule: MCAZS080SeminarScheduleResultDto[]
  seminarScreen: MCAZS080SeminarResultDto[]
  signature: MCAZS080SignResultDto[]
  previewEntryId: number
  previewJobSeekerFamilyName: string
  previewJobSeekerName: string
  previewJobSeekerId: number
  previewCompanyName: string
  previewJobCategoryName: string
  previewSelectionStepName: string
  previewDecisionDivision: string
  previewInterview: Date
  previewInterviewLocation: string
  previewRecruitmentManagementFlagEntryId: number
  previewRecruitmentManagementFlagId: number
  previewSeminarScheduleEntryId: number
  previewSeminarScheduleId: number
  previewSeminarEntryId: number
  previewSeminarId: number
  companyId: number
  contractMedia: MCAZS080ContractMediaResultDto[]
  messageType: MCAZS080MessageResultDto[]
  interviewScheduleAdjustmentAction: string
  jobSeekerInterviewDesiredSchedule: MCAZS080JobSeekerInterviewDesiredScheduleResultDto[]
  jobSeekerInterviewNotPossibleSchedule: MCAZS080JobSeekerInterviewNotPossibleScheduleResultDto[]
  companyInterviewSchedule: MCAZS080CompanyInterviewScheduleDto[]
  forbiddenWord: string[]
  exampleLinkUrl: string
  attachmentFileRegistrationMax: number
  mynaviAttachedOptionRegistrationMax: number
  publicHoliday: MCAZS080PublicHolidayResultDto[]
  interviewDurationSelect: MCAZS080InterviewDurationResultDto[]
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaJson: string
  mySearchCriteriaNameJson: string[]
  mySearchCriteriaJson: string[]
  filterCondition: string
  interviewLocation: MCAZS080InterviewLocationResultDto[]
  sendReplyImpossibleTime: MCAZS080sendReplyImpossibleTimeDto[]
  adjustmentInterviewScheduleAdjustmentId: number[]
  mapShowURL: string
  companyAccountMailAdress: string
  interviewScheduleAdjustmentEntry: MCAZS080InterviewScheduleAdjustmentEntryDto[]
  interviewScheduleAdjustmentId: number
  interviewScheduleId: number[]
  action: string
  interviewScheduleAdjustmentMax: MCAZS080InterviewScheduleAdjustmentMaxResultDto[]
  readableFlag: string
  replyFormerMessageId: number | null
  selectionManagementSysVersionNumber: number | null
  progressManagementSysVersionNumber: number | null
  notAdoptedNotificationType: string | null
  interviewScheduleIdWeb: number // WEB面接用面接日程ID  WEB面接対応
  interviewWebEnableFlag: string // WEB面接フラグ  WEB面接対応
  interviewWebUrlSendFlag: string // WEB面接URL送付フラグ  WEB面接対応
  sysVersionNumberWeb: string // WEB面接用Sysバージョン番号  WEB面接対応
}

export interface MCAZS080MessageResultDto {
  [key: string]: any
  messageSendId: number
  messageType: string
  status: string
}

export interface MCAZS080DestinationResultDto {
  [key: string]: any
  entryId: string
  selectionManagementId: string
  jobSeekerIdForDisplay: string
  familyName: string
  name: string
  birthdate: Date
  mailAddress: string
  manualRegistrationFlag: number
  applicantScreeningImmunityFlag: number
  memberType: string
  unsubscribedJobSeekerId: number
  schoolName: string
  facultyName: string
  subjectName: string
  entryJobCategoryName: string
  entryType: string
  entryReceptionTime: Date
  viewStartTime: Date
  viewEndTime: Date
  progressStatusSettingId: number
  decisionDivision: string
  selectionFlowSettingId: number
  selectionClassification: string
  selectionFlowName: string
  progressName: string
  progressType: string
  sentMessageCount: number
  readableFlag: string
  senderMessageId: number | null
}

export interface MCAZS080DraftMessageResultDto {
  [key: string]: any
  messageId: number
  mediaFlag: number
  companyContractMediaId: number
  entryId: number
  selectionManagementId: number
  messageType: string
  destinationDivision: string
  senderCompanyName: string
  replyPermitFlag: string
  replyNecessaryFlag: string
  replyLimitTime: Date | null
  subject: string
  body: string
  homePage1Title: string
  homePage1Url: string
  homePage2Title: string
  homePage2Url: string
  homePage3Title: string
  homePage3Url: string
  barcodeDisplayFlag: string
  sendTimeFlag: string
  sendPlanTime: Date|null
  bulkSendFlag: string
  messageSendId: number
  replyFormerMessageId: number
  senderMailAddress: string
  notificationMailAddress: string
  messageTemplateSettingId: number
  sysVersionNumber: number
}

interface MCAZS080InterviewScheduleResultDto {
  [key: string]: any
  interviewScheduleId: number
  interviewScheduleAdjustmentId: number
  interviewStartTime: string
  interviewLocationSettingId: number
  interviewStaffName: string
  interviewLocationName: string | null
  interviewLocationAddress: string | null
  mapUrl: string
  interviewScheduleStatus: string
  interviewScheduleDraftFlag: boolean
  interviewWebEnableFlag: string
}

interface MCAZS080DraftAttachmentResultDto {
  [key: string]: any
  sequence: number
  attachmentName: string
  fileManagementId: string
}

interface MCAZS080DraftHomepageResultDto {
  [key: string]: any
  sequence: number
  homePageTitle: string
  homePageUrl: string
}

interface MCAZS080MessageTemplateResultDto {
  [key: string]: any
  messageTemplateSettingId: number
  templateName: string
  templateType: string
  destinationDivision: string
  senderCompanyName: string
  senderMailAddress: string
  replayPermitFlag: string
  subject: string
  body: string
  reservedScheduleSendFlag: string
  barcordDisplayFlag: string
  // [phase2] start
  sendToNonMember: number
  // [phase2] end
  sequence: number
  replyNecessaryFlag: string // 要返信フラグ
  replyLimitTime: string // 返信期限
  sysVersionNumber: string // sysバージョン番号
}

interface MCAZS080MessageTemplateHomepageResultDto {
  [key: string]: any
  messageTemplateSettingId: number
  sequence: number
  homePageTitle: string
  homePageUrl: string
}

interface MCAZS080TemplateAttachmentResultDto {
  [key: string]: any
  messageTemplateSettingId: number
  sequence: number
  attachmentName: string
  fileManagementId: string
}

interface MCAZS080TemplateMynaviAttachedOptionEntryBoxResultDto {
  [key: string]: any
  messageTemplateSettingId: number
  entryBoxId: number
}

interface MCAZS080TemplateMynaviAttachedOptionSeminarResultDto {
  [key: string]: any
  messageTemplateSettingId: number
  seminarId: number
}

interface MCAZS080MynaviAttachedOptionEntryBoxResultDto {
  [key: string]: any
  entryBoxId: number
  mynaviAttachedOptionName: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
  previewUrl: string
}

interface MCAZS080MynaviAttachedOptionSeminarResultDto {
  [key: string]: any
  seminarId: number
  mynaviAttachedOptionName: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
  previewUrl: string
}

interface MCAZS080RecruitmentManagementFlagResultDto {
  [key: string]: any
  recruitmentManagementFlagSettingId: number
  recruitmentManagementFlagName: string
}

interface MCAZS080SeminarScheduleResultDto {
  [key: string]: any
  seminarScheduleId: number
  seminarScheduleTitle: string	//セミナー日程タイトル
  eventDate: Date
  //[phase2] 開催日テキスト start
  eventDateText: string
  //[phase2] 開催日テキスト end
  venueLocationName: string
  startTime: Date
  endTime: Date
  coordinationFormerSeminarScheduleIdentificationKey: string
  invisibleFlag: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
  // 備考
  remarks: string
  // 開催曜日
  eventWeek: string
  displayFlag: boolean
}
//[phase2] start
interface MCAZS080SeminarResultDto {
  [key: string]: any
  seminarId: number
  coordinationFormerSeminarId: number
  seminarName: string
  publicFlag: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
  seminarScheduleDisplay: string
  displayFlag: boolean
}
//[phase2] end
interface MCAZS080SignResultDto {
  [key: string]: any
  registrationName: string
  signSettingId: string
  signature: string
}

interface MCAZS080ContractMediaResultDto {
  [key: string]: any
  contractMediaId: number
  companyServiceStartDate: Date
  companyServiceEndDate: Date
  jobSeekerServiceStartDate: Date
  jobSeekerServiceEndDate: Date
}

interface MCAZS080JobSeekerInterviewDesiredScheduleResultDto {
  [key: string]: any
  interviewPreferredDate: Date
  timePeriodCode: number
  startTime: Date
  endTime: Date
}

interface MCAZS080JobSeekerInterviewNotPossibleScheduleResultDto {
  [key: string]: any
  interviewNotPossibleDate: Date
  timePeriodCode: number
  startTime: Date
  endTime: Date
}

interface MCAZS080CompanyInterviewScheduleDto {
  [key: string]: any
  familyName: string,
  name: string,
  interviewStartTime: Date,
  interviewEndTime: Date,
  interviewLocationName: string,
  interviewStaffName: string,
}

interface MCAZS080PublicHolidayResultDto {
  [key: string]: any
  publicHoliday: Date
}

interface MCAZS080InterviewDurationResultDto {
  [key: string]: any
  interviewDurationId: number
  displayTime: string
  actualTime: number|null
}

interface MCAZS080InterviewLocationResultDto {
  [key: string]: any
  interviewLocationSettingId: number
  interviewLocationType: number
  interviewLocationName: string
  interviewLocationAddress: string
  mapUrl: string
  displayOrder: number
}

interface MCAZS080sendReplyImpossibleTimeDto {
  [key: string]: any
  contractMediaIdStr: string
  startDateStr: string
  endDateStr: string
}

interface MCAZS080InterviewScheduleAdjustmentEntryDto {
  [key: string]: any
  selectionManagementId: number
  interviewScheduleAdjustmentId: number
  interviewScheduleAdjustmentStatus: string
  interviewScheduleStatus: string
}

interface MCAZS080InterviewScheduleAdjustmentMaxResultDto {
  [key: string]: any
  interviewScheduleAdjustmentId: number
  selectionManagementId: number
}

const destinationResultDto: MCAZS080DestinationResultDto = {
  entryId: '',
  selectionManagementId: '',
  jobSeekerIdForDisplay: '',
  familyName: '',
  name: '',
  birthdate: new Date(),
  mailAddress: '',
  manualRegistrationFlag: 0,
  applicantScreeningImmunityFlag: 0,
  memberType: '',
  unsubscribedJobSeekerId: 0,
  schoolName: '',
  facultyName: '',
  subjectName: '',
  entryJobCategoryName: '',
  entryType: '',
  entryReceptionTime: new Date(),
  viewStartTime: new Date(),
  viewEndTime: new Date(),
  progressStatusSettingId: 0,
  decisionDivision: '',
  selectionFlowSettingId: 0,
  selectionClassification: '',
  selectionFlowName: '',
  progressName: '',
  progressType: '',
  sentMessageCount: 0,
  readableFlag: '',
  senderMessageId: null,
}

const draftMessageResultDto: MCAZS080DraftMessageResultDto = {
  messageId: 0,
  mediaFlag: 0,
  companyContractMediaId: 0,
  entryId: 0,
  selectionManagementId: 0,
  messageType: '',
  destinationDivision: '',
  senderCompanyName: '',
  replyPermitFlag: '',
  replyNecessaryFlag: '',
  replyLimitTime: new Date(),
  subject: '',
  body: '',
  homePage1Title: '',
  homePage1Url: '',
  homePage2Title: '',
  homePage2Url: '',
  homePage3Title: '',
  homePage3Url: '',
  barcodeDisplayFlag: '',
  sendTimeFlag: '',
  sendPlanTime: new Date(),
  bulkSendFlag: '',
  messageSendId: 0,
  replyFormerMessageId: 0,
  senderMailAddress: '',
  notificationMailAddress: '',
  messageTemplateSettingId: 0,
  sysVersionNumber: 0,
    // [phase2] start
  sendToNonMember: 0,
    // [phase2] end
}

/** 送信対象確認エリア */
export interface SendTargetConfirmation {
  [key: string]: any
  destinationSelection: number
  sendTarget: SendTarget[]
  searchedCondition: string
  // phase2 start
  sendToNonMember: number
  // phase2 end
}

/** 送信対象 */
export interface SendTarget {
  [key: string]: any
  fullName: string
  mailAddress: string
  age: number
  jobSeekerId: string
  mynaviMemberInfo: string
  applicationType: string
  jobTitle: string
  schoolName: string
  facultyName: string
  subjectName: string
  applicationDate: string
  progress: string
  selectionFlow: string
  invisibleDestination: string
  deleteDestination: string
}

/** メッセージ入力エリア  */
export interface MessageInput {
  [key: string]: any
  headingMessageInput: string
  headingMessageInputDescription: string
  exampleLink: string
  replyAllow: number
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  targetInput: string
  attachment: File[]
  attachmentId: string[]
  templateAttachmentId: string[]
  entryBoxId: string[]
  seminarId: string[]
  homePageTitle1: string
  homePageTitle2: string
  homePageTitle3: string
  homePageUrl1: string
  homePageUrl2: string
  homePageUrl3: string
  barcodeDisplay: number
  previewSubject: string
  previewBody: string
}

export interface PreviewResult {
  entryId: number
  familyName: string
  name: string
  jobSeekerIdForDisplay: string
  companyName: string
  entryJobCategoryName: string
  progressName: string
  decisionDivision: string
  interviewStartTime: Date
  interviewLocationName: string
}

const messageInput: MessageInput = {
  headingMessageInput: '',
  headingMessageInputDescription: '',
  exampleLink: '',
  replyAllow: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  targetInput: '',
  attachment: [],
  attachmentId: [],
  templateAttachmentId: [],
  entryBoxId: [],
  seminarId: [],
  homePageTitle1: '',
  homePageTitle2: '',
  homePageTitle3: '',
  homePageUrl1: '',
  homePageUrl2: '',
  homePageUrl3: '',
  barcodeDisplay: 0,
  previewSubject: '',
  previewBody: '',
}

const initResult: InitResult = {
  draftMessageTemplateSettingId: 0,
  destinationDivision: '',
  // [phase2] start
  sendToNonMember: 0,
  // [phase2] end
  destinationInfo: [],
  interviewDurationId: 0,
  interviewDetail: '',
  interviewScheduleAdjustmentStatus: '',
  interviewSchedule: [],
  senderCompanyName: '',
  senderMailAddress: '',
  replyPermitFlag: '',
  replyNecessaryFlag: '',
  replyLimitTime: null,
  subject: '',
  replyFromSubject: '',
  body: '',
  replyFromBody: '',
  attachment: [],
  checkedEntryBoxId: [],
  checkedSeminarId: [],
  homePage: [],
  barcodeDisplayFlag: '',
  sendTimeFlag: '',
  sendPlanTime: new Date(),
  notificationMailAddress: '',
  messageTemplate: [],
  messageTemplateHomepage: [],
  messageTemplateAttachment: [],
  messageTemplateEntrybox: [],
  messageTemplateSeminar: [],
  mynaviAttachedOptionEntryBox: [],
  mynaviAttachedOptionSeminar: [],
  recruitmentManagementFlag: [],
  seminarSchedule: [],
  seminarScreen: [],
  signature: [],
  previewEntryId: 0,
  previewJobSeekerFamilyName: '',
  previewJobSeekerName: '',
  previewJobSeekerId: 0,
  previewCompanyName: '',
  previewJobCategoryName: '',
  previewSelectionStepName: '',
  previewDecisionDivision: '',
  previewInterview: new Date(),
  previewInterviewLocation: '',
  previewRecruitmentManagementFlagEntryId: 0,
  previewRecruitmentManagementFlagId: 0,
  previewSeminarScheduleEntryId: 0,
  previewSeminarScheduleId: 0,
  previewSeminarEntryId: 0,
  previewSeminarId: 0,
  companyId: 0,
  contractMedia: [],
  messageType: [],
  interviewScheduleAdjustmentAction: '',
  jobSeekerInterviewDesiredSchedule: [],
  jobSeekerInterviewNotPossibleSchedule: [],
  companyInterviewSchedule: [],
  forbiddenWord: [],
  exampleLinkUrl: '',
  attachmentFileRegistrationMax: 0,
  mynaviAttachedOptionRegistrationMax: 0,
  publicHoliday: [],
  interviewDurationSelect: [],
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaJson: '',
  mySearchCriteriaNameJson: [],
  mySearchCriteriaJson: [],
  filterCondition: '',
  interviewLocation: [],
  sendReplyImpossibleTime: [],
  adjustmentInterviewScheduleAdjustmentId: [],
  mapShowURL: '',
  companyAccountMailAdress: '',
  interviewScheduleAdjustmentEntry: [],
  interviewScheduleAdjustmentId: 0,
  interviewScheduleId: [],
  action: '',
  interviewScheduleAdjustmentMax: [],
  readableFlag: '',
  replyFormerMessageId: null,
  selectionManagementSysVersionNumber: null,
  progressManagementSysVersionNumber: null,
  notAdoptedNotificationType: null,
  interviewScheduleIdWeb: 0,
  interviewWebEnableFlag: '',
  interviewWebUrlSendFlag: '',
  sysVersionNumberWeb: '',
}

/** タイトルエリア */
export interface TitleCon {
  [key: string]: any
  messageTemplate: MessageTemplateCon[]
}

export interface MessageTemplateCon {
  [key: string]: any
  messageTemplateSettingId: number
  templateName: string
  templateType: string
  destinationDivision: string
  senderCompanyName: string
  senderMailAddress: string
  replayPermitFlag: string
  subject: string
  body: string
  reservedScheduleSendFlag: string
  barcordDisplayFlag: string
  // phase2 start
  sendToNonMember: number
  // phase2 end
}

/** 送信対象確認エリア */
export interface SendTargetConfirmationCon {
  [key: string]: any
  destinationSelection: string
  sendTarget: SendTargetCon[]
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaInfo: SearchCriteriaInfo
  mySearchCondition: string| null
  conversionMySearch: MySearch
  // phase2 start
  sendToNonMember: number
}

export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  sysVersionNumber: number             // sysバージョン番号
}

export interface MySearch {
    mySearchName: string //MY検索条件名
    mySearchCriteria: string //MY検索条件
}

/** 送信対象 */
export interface SendTargetCon {
  [key: string]: any
  entryId: string
  selectionManagementId: string
  progressStatusSettingId: string
  decisionDivision: string
  manualRegistrationFlag: string
  fullName: string
  mailAddress: string
  age: number
  jobSeekerId: string
  mynaviMemberInfo: string
  applicationType: string
  jobTitle: string
  schoolName: string
  facultyName: string
  subjectName: string
  applicationDate: string
  progress: string
  selectionFlow: string
  invisibleDestination: string
  deleteDestination: string
  notSend: boolean
  
}

/** 面接日程調整エリア */
export interface InterviewScheduleCon {
  [key: string]: any
  manipulationKubun: number
  interviewDetail: string
  requiredTimeId: string
  interviewDurationSelect: InterviewDurationSelectCon[]
  candidateDate: CandidateDateCon[]
}

export interface InterviewDurationSelectCon {
  [key: string]: any
  interviewDurationId: string
  actualTime: string
  displayTime: string
}

export interface CandidateDateCon {
  [key: string]: any
  interviewStartTime: string
  interviewLocationId: string
  interviewStaffName: string
}

/** メッセージ入力エリア */
export interface MessageInputCon {
  [key: string]: any
  headingMessageInput: string
  headingMessageInputDescription: string
  exampleLink: string
  replyAllow: string
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  targetInput: string
  attachment: AttachmentCon[]
  entryBox: MynaviAttachedOptionEntryBoxCon[]
  seminar: MynaviAttachedOptionSeminarCon[]
  entryBoxId: string[]
  seminarId: string[]
  homePage: HomePageCon[]
  barcodeDisplay: string
  previewSubject: string
  previewBody: string
  // phase2 start
  sendToNonMember: number
  // phase2 end
}

/** 送信日時入力エリア */
export interface SendTimeInputCon {
  [key: string]: any
  transmission: string
  sendPlanTime: Date | null
}

export interface MynaviAttachedOptionEntryBoxCon {
  entryBoxId: number
  entryBoxName: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
}

export interface MynaviAttachedOptionSeminarCon {
  seminarId: number
  seminarName: string
  contractMediaName: string
  displayStartDate: Date
  displayEndDate: Date
}

export interface AttachmentCon {
  attachmentFileName: string
  attachmentId: string
  attachmentName: string
}

export interface HomePageCon {
  homePageTitle: string
  homePageUrl: string
}

export interface RecruitmentManagementFlagCon {
  [key: string]: any
  recruitmentManagementFlagSettingId: number
  recruitmentManagementFlagName: string
}

export interface SeminarScheduleCon {
  [key: string]: any
  seminarScheduleId: number
  eventDate: Date
  //[phase2] 開催日テキスト start
  eventDateText: string
  //[phase2] 開催日テキスト end
  venueLocationName: string
  startTime: Date
  endTime: Date
  coordinationFormerSeminarScheduleIdentificationKey: string
  invisibleFlag: string
  contractMediaName: string
  // 備考
  remarks: string
  // 開催曜日
  eventWeek: string
}

export interface SeminarCon {
  [key: string]: any
  seminarId: number
  coordinationFormerSeminarId: number
  seminarName: string
  publicFlag: string
  contractMediaName: string
}

export interface SignatureCon {
  [key: string]: any
  registrationName: string
  signSettingId: string
  signature: string
}

const titleCon: TitleCon = {
  messageTemplate: [],
}

const interviewScheduleCon: InterviewScheduleCon = {
  manipulationKubun: 0,
  interviewDetail: '',
  requiredTimeId: '',
  interviewDurationSelect: [],
  candidateDate: [],
}

const interviewDurationSelectCon: InterviewDurationSelectCon = {
  interviewDurationId: '',
  actualTime: '',
  displayTime: '',
}

const messageInputCon: MessageInputCon = {
  headingMessageInput: '',
  headingMessageInputDescription: '',
  exampleLink: '',
  replyAllow: '0',
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  targetInput: '',
  attachment: [],
  templateAttachmentId: [],
  entryBox: [],
  seminar: [],
  entryBoxId: [],
  seminarId: [],
  homePage: [],
  homePageTitle: [],
  homePageUrl: [],
  barcodeDisplay: '0',
  previewSubject: '',
  previewBody: '',
  //phase2 start
  sendToNonMember: 0,
  //phase2 end
}

const sendTimeInputCon: SendTimeInputCon = {
  transmission: '0',
  sendPlanTime: null,
}

const sendTargetCon: SendTargetCon = {
  entryId: '',
  selectionManagementId: '',
  progressStatusSettingId: '',
  decisionDivision: '',
  manualRegistrationFlag: '',
  fullName: '',
  mailAddress: '',
  age: 0,
  jobSeekerId: '',
  mynaviMemberInfo: '',
  applicationType: '',
  jobTitle: '',
  schoolName: '',
  facultyName: '',
  subjectName: '',
  applicationDate: '',
  progress: '',
  selectionFlow: '',
  invisibleDestination: '',
  deleteDestination: '',
  notSend: false,
}

const searchCriteriaInfo: SearchCriteriaInfo = {
  entrySearchCriteriaSettingId: '',
  searchCriteriaName: '',
  searchCriteria: '',
  sysVersionNumber: 0,
}

const mySearch: MySearch = {
  mySearchName: '',
  mySearchCriteria: '',
}

const sendTargetConfirmationCon: SendTargetConfirmationCon = {
  destinationSelection: '0',
  sendTarget: [],
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaInfo: searchCriteriaInfo,
  mySearchCondition: null,
  conversionMySearch: mySearch,
  // phase2 start
  sendToNonMember: 0
  // phase2 end
}

const recruitmentManagementFlagCon: RecruitmentManagementFlagCon[] = []

const seminarScheduleCon: SeminarScheduleCon[] = []

const seminarCon: SeminarCon[] = []

const signatureCon: SignatureCon[] = []


const messageConfirmRequest: MCAZS080MessageConfirmRequest = {
  draftMessageSendId: null,
  messageTemplateSettingId: '0',
  destinationId: [],
  selectionManagementId: [],
  progressStatusSettingId: [],
  selectionFlowSettingId: [],
  decisionDivision: [],
  manualRegistrationFlag: [],
  destination: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  allowReply: 0,
  repliesNecessary: 0,
  repliesLimit: '',
  subject: '',
  text: '',
  attachment: [],
  attachmentId: [],
  attachmentName: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  entryBoxId: [],
  seminarId: [],
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  barcodeDisplay: 0,
  sendTimer: 0,
  sendPlanTime: '',
  notifyNotification: 0,
  notifyMailAddress: '',
  manipulationSelection: 0,
  interviewScheduleAdjustmentId: '0',
  interviewScheduleAdjustmentStatus: '0',
  interviewDurationId: '0',
  interviewDurationTime: 0,
  interviewDetail: '',
  interviewScheduleIdN: '',
  interviewStartTimeN: [],
  interviewLocationSettingId: [],
  interviewStaffName: [],
  interviewScheduleStatus: [],
  manipulationKubun: '',
  contractMediaId: [],
  title: titleCon,
  interviewSchedule: interviewScheduleCon,
  messageInput: messageInputCon,
  sendTargetConfirmation: sendTargetConfirmationCon,
  sendTimeInput: sendTimeInputCon,
  recruitmentManagementFlag: recruitmentManagementFlagCon,
  seminarSchedule: seminarScheduleCon,
  seminar: seminarCon,
  signature: signatureCon,
  jobSeekerInterviewDesiredSchedule: [],
  jobSeekerInterviewNotPossibleSchedule: [],
  sendReplyImpossibleTime: [],
  //phase2 start
  sendToNonMember: 0,
  // phase2 end 
  interviewStartTime: '',
  interviewScheduleAdjustmentMax: [],
  searchCriteriaJson: '',
  selectionManagementSysVersionNumber: null,
  progressManagementSysVersionNumber: null,
  notAdoptedNotificationType: null,
  interviewScheduleIdWeb: 0,
  interviewWebUrlSendFlag: '', 
  mySearchCondition: null,
  mySearchCriteriaNameJson: [],
  mySearchCriteriaJson: [],
  senderMessageId: [],
}

const messageDraftRequest: MCAZS080MessageDraftRequest = {
  messageTemplateSettingId: '0',
  destinationId: [],
  selectionManagementId: [],
  progressStatusSettingId: [],
  decisionDivision: [],
  manualRegistrationFlag: [],
  destination: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  allowReply: 0,
  repliesNecessary: 0,
  repliesLimit: '',
  subject: '',
  text: '',
  attachment: [],
  attachmentId: [],
  attachmentName: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  entryBoxId: [],
  seminarId: [],
  homepageTitle1: null,
  homepageTitle2: null,
  homepageTitle3: null,
  homepageUrl1: null,
  homepageUrl2: null,
  homepageUrl3: null,
  barcodeDisplay: 0,
  sendTimer: 0,
  sendPlanTime: '',
  notifyMailAddress: '',
  interviewScheduleAdjustmentId: '0',
  interviewScheduleAdjustmentStatus: '0',
  interviewDurationId: '0',
  interviewDetail: '',
  interviewScheduleId1: '0',
  interviewScheduleId2: '0',
  interviewScheduleId3: '0',
  interviewScheduleId4: '0',
  interviewScheduleId5: '0',
  interviewScheduleId6: '0',
  interviewScheduleId7: '0',
  interviewScheduleId8: '0',
  interviewScheduleId9: '0',
  interviewScheduleId10: '0',
  interviewScheduleId11: '0',
  interviewScheduleId12: '0',
  interviewScheduleId13: '0',
  interviewScheduleId14: '0',
  interviewScheduleId15: '0',
  interviewScheduleId16: '0',
  interviewScheduleId17: '0',
  interviewScheduleId18: '0',
  interviewScheduleId19: '0',
  interviewScheduleId20: '0',
  interviewScheduleId21: '0',
  interviewScheduleId22: '0',
  interviewScheduleId23: '0',
  interviewScheduleId24: '0',
  interviewScheduleId25: '0',
  interviewScheduleId26: '0',
  interviewScheduleId27: '0',
  interviewScheduleId28: '0',
  interviewScheduleId29: '0',
  interviewScheduleId30: '0',
  interviewStartTime1: null,
  interviewStartTime2: null,
  interviewStartTime3: null,
  interviewStartTime4: null,
  interviewStartTime5: null,
  interviewStartTime6: null,
  interviewStartTime7: null,
  interviewStartTime8: null,
  interviewStartTime9: null,
  interviewStartTime10: null,
  interviewStartTime11: null,
  interviewStartTime12: null,
  interviewStartTime13: null,
  interviewStartTime14: null,
  interviewStartTime15: null,
  interviewStartTime16: null,
  interviewStartTime17: null,
  interviewStartTime18: null,
  interviewStartTime19: null,
  interviewStartTime20: null,
  interviewStartTime21: null,
  interviewStartTime22: null,
  interviewStartTime23: null,
  interviewStartTime24: null,
  interviewStartTime25: null,
  interviewStartTime26: null,
  interviewStartTime27: null,
  interviewStartTime28: null,
  interviewStartTime29: null,
  interviewStartTime30: null,
  interviewLocationSettingId1: '0',
  interviewLocationSettingId2: '0',
  interviewLocationSettingId3: '0',
  interviewLocationSettingId4: '0',
  interviewLocationSettingId5: '0',
  interviewLocationSettingId6: '0',
  interviewLocationSettingId7: '0',
  interviewLocationSettingId8: '0',
  interviewLocationSettingId9: '0',
  interviewLocationSettingId10: '0',
  interviewLocationSettingId11: '0',
  interviewLocationSettingId12: '0',
  interviewLocationSettingId13: '0',
  interviewLocationSettingId14: '0',
  interviewLocationSettingId15: '0',
  interviewLocationSettingId16: '0',
  interviewLocationSettingId17: '0',
  interviewLocationSettingId18: '0',
  interviewLocationSettingId19: '0',
  interviewLocationSettingId20: '0',
  interviewLocationSettingId21: '0',
  interviewLocationSettingId22: '0',
  interviewLocationSettingId23: '0',
  interviewLocationSettingId24: '0',
  interviewLocationSettingId25: '0',
  interviewLocationSettingId26: '0',
  interviewLocationSettingId27: '0',
  interviewLocationSettingId28: '0',
  interviewLocationSettingId29: '0',
  interviewLocationSettingId30: '0',
  interviewStaffName1: '',
  interviewStaffName2: '',
  interviewStaffName3: '',
  interviewStaffName4: '',
  interviewStaffName5: '',
  interviewStaffName6: '',
  interviewStaffName7: '',
  interviewStaffName8: '',
  interviewStaffName9: '',
  interviewStaffName10: '',
  interviewStaffName11: '',
  interviewStaffName12: '',
  interviewStaffName13: '',
  interviewStaffName14: '',
  interviewStaffName15: '',
  interviewStaffName16: '',
  interviewStaffName17: '',
  interviewStaffName18: '',
  interviewStaffName19: '',
  interviewStaffName20: '',
  interviewStaffName21: '',
  interviewStaffName22: '',
  interviewStaffName23: '',
  interviewStaffName24: '',
  interviewStaffName25: '',
  interviewStaffName26: '',
  interviewStaffName27: '',
  interviewStaffName28: '',
  interviewStaffName29: '',
  interviewStaffName30: '',
  interviewScheduleStatus1: '0',
  interviewScheduleStatus2: '0',
  interviewScheduleStatus3: '0',
  interviewScheduleStatus4: '0',
  interviewScheduleStatus5: '0',
  interviewScheduleStatus6: '0',
  interviewScheduleStatus7: '0',
  interviewScheduleStatus8: '0',
  interviewScheduleStatus9: '0',
  interviewScheduleStatus10: '0',
  interviewScheduleStatus11: '0',
  interviewScheduleStatus12: '0',
  interviewScheduleStatus13: '0',
  interviewScheduleStatus14: '0',
  interviewScheduleStatus15: '0',
  interviewScheduleStatus16: '0',
  interviewScheduleStatus17: '0',
  interviewScheduleStatus18: '0',
  interviewScheduleStatus19: '0',
  interviewScheduleStatus20: '0',
  interviewScheduleStatus21: '0',
  interviewScheduleStatus22: '0',
  interviewScheduleStatus23: '0',
  interviewScheduleStatus24: '0',
  interviewScheduleStatus25: '0',
  interviewScheduleStatus26: '0',
  interviewScheduleStatus27: '0',
  interviewScheduleStatus28: '0',
  interviewScheduleStatus29: '0',
  interviewScheduleStatus30: '0',
  manipulationKubun: '0',
  interviewScheduleAdjustmentSelection: '',
  sourceDraftMessageSendId: '',
  // phase2 start
  sendToNonMember: 0,
  // phase2 end
  interviewScheduleAdjustmentIdDraft: 0,
  interviewScheduleIdDraft: [],
  actionDraft: '',
  sysVersionNumber: 0,
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaJson: '',
  mySearchCondition: null,
  mySearchCriteriaNameJson: [],
  mySearchCriteriaJson: [],
  replyFormerMessageId: null,
  interviewScheduleIdWeb: 0,
  interviewWebUrlSendFlag:'',
  senderMessageId: [],
}

export {
  initResult,
  destinationResultDto,
  draftMessageResultDto,
  messageInput,
  messageConfirmRequest,
  messageDraftRequest,
  sendTargetConfirmationCon,
}
