import { EventType } from 'broadcast-channel'
import { DurationInputArg2 } from 'moment'

export const validationMessageSet = {
  required: () => '必須です',
  maxLength: ({ max }: any) => `${max}文字以下を入力してください`,
  minLength: ({ min }: any) => `${min}文字以上を入力してください`,
  maxValue: ({ max }: any) => `${max}以下を入力してください`,
  minValue: ({ min }: any) => `${min}以上を入力してください`,
  equalValue: ({ value }: any) => `値は${value}に等しい必要です`,
  email: () => 'メールアドレスのフォーマットが正しくありません',
  url: () => 'URLが正しくありません',
  phoneNumber: () => '電話番号は半角数字、半角ハイフン(-)、半角プラス(+)以外は入力できません',
  postalCode: () => '郵便番号は半角数字のみで入力してください',
  sizeLimt: () => 'ファイルサイズが上限(10MB)を超えています',
  formatLimit: () => '拡張子が不正です(アップロード可能なファイル形式：{doc,gif,jpg,jpeg,pdf,png,pps,ppt,pptx,txt,xls,docx,xlsx})',
  date: () => '日付が正しくありません',
  datetime: () => '時刻が正しくありません',
  integer: () => '整数ではありません',
  halfWidthNumber: () => '半角数字以外は入力できません',
  halfWidthAlphanumericSymbol: () => '半角英数記号以外は入力できません',
  halfWidth: () => '全半角以外は入力できません',
  fullWidth: () => '全角以外は入力できません',
  fullWidthKana: () => '全角カナ以外は入力できません',
  password: () => '半角英数字記号を3種類以上含む 8文字以上22文字以下で入力してください',
  currentPassword: () => '現パスワードと新パスワードが同じです',
  newPassword: () => '新パスワードと新パスワード（確認用）が一致しておりません',
  equalPassword: () => 'パスワードとパスワード（確認用）が一致しておりません',
  flag: () => 'フラグではありません',
  concatMaxLength: ({ max }: any) =>
    `「/」で連結後の長さは${max}文字以下必要です`,
  timeUnit: (input: number) => `${input}分単位で設定してください。`,
  postalCodeNumber: ({ value }: any) => `郵便番号は${value}文字を入力してください`
}

export const formatTemplate = {
  date: 'YYYY-MM-DD',
  dateWithHourMinute: 'YYYY-MM-DDTHH:mm',
  dateWithHourMinuteSpace: 'YYYY-MM-DD HH:mm',
  datePicker: 'yyyy/MM/dd HH:mm',
  time: 'HH:mm',
  title: 'YYYY/MM/DD',
}

export const unitSet: {
  [key: string]: DurationInputArg2
} = {
  days: 'days',
  minute: 'minute',
  minutes: 'minutes',
}

export const magiContants = {
  TRANSITIONSOURCEFLAG_0: '0', //遷移元フラグ:0
  TRANSITIONSOURCEFLAG_1: '1', //遷移元フラグ:1
  // #91157 就職転職分離 start
  LOGIN_INDUCTION_MESSAGE_1: 'Login Induction Message 1',
  LOGIN_INDUCTION_MESSAGE_2: 'Login Induction Message 2',
  // #91157 就職転職分離 end
  DESC: 'desc', //降順
  ASC: 'asc', //昇順
  MESSAGECODE_INSERT_SUCCESS: 'MAACOMMON-001', //登録しました
  MESSAGECODE_DELETE_SUCCESS: 'MAACOMMON-002', //削除しました
  MESSAGECODE_UPDATE_SUCCESS: 'MAACOMMON-003', //更新しました
  MESSAGECODE_RESULT_NULL: 'MAACOMMON-004', //検索結果が存在しません
  MESSAGECODE_TEMPPW_CREATE_SUCCESS: 'MAACOMMON-005', //仮パスワード発行しました
  MESSAGECODE_UPDATE_FAILED: 'MAACOMMON-006', //他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください
  MESSAGECODE_UPDATEALL_FAILED: 'MAACOMMON-007', //更新をすべて完了できませんでした。一部のデータが他のユーザにより変更された可能性がありますので、再度検索してやり直してください
  MESSAGECODE_CSV_SUCCESS: 'MAACOMMON-008', //CSV出力しました
  MESSAGECODE_MAACOMMON_009: 'MAACOMMON-009', //情報が存在しません。再度検索してください
  MESSAGECODE_MAACOMMON_010: 'MAACOMMON-010', //他の利用者により変更されているため、選考ステップは更新できません。

  MESSAGECODE_MCADS010_002: 'MCADS010-002', // 該当条件のアカウントが存在しません。
  MESSAGECODE_MCADS010_003: 'MCADS010-003', // 登録可能上限数（100件）存在するため、新規に追加することはできません。
  MESSAGECODE_MCADS010_004: 'MCADS010-004', // 対象が選択されていません。
  MESSAGECODE_MCADS010_006: 'MCADS010-006', // アカウント種別：統括のアカウントは有効／無効を無効に出来ません。
  MESSAGECODE_MCADS010_010: 'MCADS010-010', // パスワードの再発行が完了しました。
  MESSAGECODE_MCADS010_011: 'MCADS010-011', // 有効／無効の無効化が完了しました。
  MESSAGECODE_MCADS010_012: 'MCADS010-012', // 有効／無効の有効化が完了しました。
  MESSAGECODE_MCADS010_013: 'MCADS010-013', // 条件に一致する検索結果がありませんでした。
  MESSAGE_MCADS010_DiSABLE_LOGIN: 'MIWSで作成したアカウントが、登録可能上限数100件存在するため、新規に追加することはできません。',
  EXTRAACCOUNTLIST_LENGTH: 100,
  ACCOUNT_TYPE_SUPERVISING: '10', // '統括'
  ACCOUNT_TYPE_ADMINISTRATOR: '20', // '管理者'
  ACCOUNT_TYPE_SEMIMANAGER: '30', // '準管理者'
  ACCOUNT_TYPE_GENERALUSER: '40', // '一般ユーザ'
  ACCOUNT_TYPE_LIMITUSER: '50', // '制限ユーザ'
  ROWSPER_PAGE: 50, //最大件数

  VALID: '1', // '有効'
  INVALID: '0', // '無効'
  //MCAHS010
  MESSAGECODE_MCAHS010_003: 'MCAHS010-003',
  MESSAGECODE_MCAHS010_005: 'MCAHS010-005',
  MESSAGECODE_MCAHS010_006: 'MCAHS010-006',
  MESSAGECODE_MCAHS010_ORDER: 'ドラッグ＆ドロップで表示順を入れ替えます',
  MESSAGE_MCAHS010_DISABLE_LOGIN: '管理項目が、登録可能上限数100件存在するため、新規に追加することはできません。',
  SUCCESED: 'Successed',
  DISABLE: 'disable',
  ENABLE: 'enable',
  RECRUITMENTMANAGEMENTFLAGLIST_LENGTH: 100,
  INVISIBLEFLAG: '非表示',
  // #72427 次期開発2022年12月 start
  MESSAGE_MCAHS010:'この管理項目をコピーして新規の情報を登録します',
  // #72427 次期開発2022年12月 end
  //MCAHS020
  // eslint-disable-next-line @typescript-eslint/camelcase
  CHECK_MCAHS020_MaxCount_20: 20,
  // eslint-disable-next-line @typescript-eslint/camelcase
  CHECK_MCAHS020_MaxCount_100: 100,
  CHECK_MCAHS020_SELECTEDTYPE_30: 30,
  MESSAGECODE_MCAHS020_003: 'MCAHS020-003',
  MESSAGECODE_MCAHS020_004: 'MCAHS020-004',
  MESSAGECODE_MCAHS020_005: 'MCAHS020-005',
  MESSAGECODE_MCAHS020_006: 'MCAHS020-006',
  MESSAGECODE_MCAHS020_007: 'MCAHS020-007',
  MESSAGECODE_MCAHS020_008: 'MCAHS020-008',
  MESSAGECODE_MCAHS020_009: 'MCAHS020-009',
  MESSAGECODE_MCAHS020_010: 'MCAHS020-010',
  // 管理項目を登録しました
  MESSAGECODE_MCAHS020_011: 'MCAHS020-011',
  // #72427 次期開発2022年12月 start
  MESSAGECODE_MCAHS020_013: 'MCAHS020-013',
  MESSAGECODE_MCAHS020_014: 'MCAHS020-014',
  MESSAGECODE_MCAHS020_015: 'MCAHS020-015',
  // #72427 次期開発2022年12月 end
  
  //MCAHS030
  STR_MCAHS030_UPDATE: 'update',
  STR_MCAHS030_DELETE: 'delete',
  STR_MCAHS030_CANCEL: 'cancel',
  STR_MCAHS030_CONFIRMCHANGE: 'confirmChange',
  MESSAGECODE_MCAHS030_003: 'MCAHS030-003',
  MESSAGECODE_MCAHS030_005: 'MCAHS030-005',
  MESSAGECODE_MCAHS030_006: 'MCAHS030-006',
  MESSAGECODE_MCAHS030_007: 'MCAHS030-007',
  MESSAGECODE_MCAHS030_008: 'MCAHS030-008',
  MESSAGECODE_MCAHS030_009: 'MCAHS030-009',
  MESSAGECODE_MCAHS030_010: 'MCAHS030-010',
  MESSAGECODE_MCAHS030_014: 'MCAHS030-014',
  MESSAGECODE_MCAHS030_015: 'MCAHS030-015',
  // 管理項目を更新しました
  MESSAGECODE_MCAHS030_016: 'MCAHS030-016',
  // 理項目を削除しました
  MESSAGECODE_MCAHS030_017: 'MCAHS030-017',
  MESSAGECODE_MCAHS030_018: 'MCAHS030-018',

  //MCACS010
  MESSAGECODE_MCACS010_006: 'MCACS010-006',

  MESSAGECODE_MCACS020_UPDATE_SUCCESS: 'MCACS020-003', // 入力されたパスワードを登録しました。
  MESSAGECODE_MCACS020_005: 'MCACS020-005',

  RECRUITMENT_MANAGEMENT_DIVISION_0: '0', // 採用管理区分「0：統括」
  RECRUITMENT_MANAGEMENT_DIVISION_1: '1', // 採用管理区分「1：新卒」
  RECRUITMENT_MANAGEMENT_DIVISION_2: '2', // 採用管理区分「2：中途」
  // MCB ロット３　start
  MCB_YEAR_2025: '10002025',
  // MCB ロット３　end

  CHECK_MACES010_MAXCOUNT: 50,
  CHECK_MACIS010_MAXCOUNT: 50,
  CHECK_MCAZS130_MAXCOUNT: 30,
  CHECK_MACIS010_MAXCOUNT_MSG:
    'MIWSで作成した応募経路が、登録可能上限数50件存在するため、新規に追加することはできません。',
  CHECK_MCAZS130_MAXCOUNT_MSG:
    '登録可能上限数（30件）存在するため、<br>新規に追加することはできません。',
  CHECK_MACNS010_MAXCOUNT: 200,
  CHECK_MACNS010_MAXCOUNT_MSG:
    '登録可能上限数（200件）存在するため、新規に追加することはできません。',
  MAX_MCAOS010_MAXCOUNT: 30,
  MAX_MCAOS010_MAXCOUNT_MSG:
    '登録可能上限数（30件）存在するため、新規に追加することはできません。',

  MESSAGECODE_MCAXS521_00:
    'キャンセルすると指定した条件がクリアされますが、よろしいでしょうか。',

  MESSAGECODE_MCAXS381_01:
    '複数指定する場合は改行で入力してください。一度に設定できるのは10,000件です。',
  MESSAGECODE_MCAXS381_02:
    '複数指定する場合は改行で入力してください。一度に設定できるのは100件です。',
  MESSAGECODE_MCAXS381_03:
    '複数指定はできません。',
  MESSAGECODE_MCAXS381_04: '前方一致で検索されます。複数指定はできません。',
  MESSAGECODE_MCAXS381_05:
    '会員はマイページでメッセージのやりとりができる応募者です。退会者やアップロードした応募者は「会員以外」で検索されます',
  MESSAGECODE_MCAXS571_001: '学校グループを選択してください。',
  MESSAGECODE_MCAXS571_002:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS571_003:
    '学校詳細、学部・学科詳細で選択した検索条件は反映されません。よろしいですか？',
  MESSAGECODE_MCAXS601_001: '学校グループが選択されていません。',
  MESSAGECODE_MCAXS601_002:
    '学校グループ指定画面に戻り「OK」ボタンをクリックした場合は、当画面で設定した内容は検索条件に反映されません。よろしいですか？',
  MESSAGECODE_MCAXS601_003: '学校詳細を選択してください。',
  MESSAGECODE_MCAXS601_004:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS601_005:
    '学部・学科詳細で選択した検索条件は反映されません。よろしいですか？',

  MESSAGECODE_MCAXS611_001: '学校詳細が選択されていません。',
  MESSAGECODE_MCAXS611_002:
    '学校詳細指定画面に戻り「OK」ボタンをクリックした場合は、当画面で設定した内容は検索条件に反映されません。よろしいですか？',
  MESSAGECODE_MCAXS611_003:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',

  MESSAGECODE_MCAXS551_001: '学生分類を選択してください。',
  MESSAGECODE_MCAXS551_002:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS551_003:
    '学科詳細で選択した検索条件は反映されません。よろしいですか？',
  MESSAGECODE_MCAXS591_001: '学生分類が選択されていません。',
  MESSAGECODE_MCAXS591_002:
    '学生分類指定画面に戻り「OK」ボタンをクリックした場合は、当画面で設定した内容は検索条件に反映されません。よろしいですか？',
  MESSAGECODE_MCAXS591_003:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  // AWS
  S3_IMAGES_URL: '', // S3から画像を取得する際のURL
  S3_HEAD_SHOT: '', // 顔写真
  // Phase2 Start
  MCAXS040_S3_IMAGES_URL: '/api/MCAXS040/accessPhotoFromS3',
  MCAXS050_S3_IMAGES_URL: '/api/MCAXS050/accessPhotoFromS3',

  // イメジス：PDF帳票表示
  PDF_IMAGES_PATH: 'PDF_IMAGES_PATH',
  PDF_IMAGES_PATH_ALL: 'PDF_IMAGES_PATH_ALL',
  // CAB：PDF帳票表示
  PDF_CAB_PATH: 'PDF_CAB_PATH',
  PDF_CAB_PATH_ALL: 'PDF_CAB_PATH_ALL',
  // GAB：PDF帳票表示
  PDF_GAB_PATH: 'PDF_GAB_PATH',
  PDF_GAB_PATH_ALL: 'PDF_GAB_PATH_ALL',
  // OPQ：PDF帳票表示
  PDF_OPQ_PATH: 'PDF_OPQ_PATH',
  PDF_OPQ_PATH_ALL: 'PDF_OPQ_PATH_ALL',
  // Phase2 End

  // eslint-disable-next-line @typescript-eslint/camelcase
  // 面接場所登録、面接場所編集Map Windows Open
  MCAO_MAP_SERCH_URL_OPEN: 'MCAO_MAP_SERCH_URL_OPEN',
  // "地図URL"フォーカスロストの地図Url
  MCAO_MAP_IMAGE_URL: 'MCAO_MAP_IMAGE_URL',
  // iframeで固定地図の画像を取得するための画像サイズ幅(px)
  MCAO_MAP_IMAGE_WIDTH: '640',
  // iframeで固定地図の画像を取得するための画像サイズ高さ(px)
  MCAO_MAP_IMAGE_HEIGHT: '480',

  INTEGER_Negative_1: -1,
  INTEGER_0: 0,
  INTEGER_1: 1,
  //MCAZS130
  SCREENID_MCAZS130: 'MCAZS130',
  //MCAES010
  TYPE_LINK: 'link',
  TYPE_BUTTON: 'button',
  TYPE_MINUTE: 'minute',
  MESSAGECODE_MCAES010_000: 'MCAES010-000',
  COUNT_MAX: 50,
  MAX_MSG:
    '採用管理グループが、登録可能上限数50個存在するため、新規に追加することはできません。',
  //MCAES020
  MESSAGECODE_MCAES020_001: 'MCAES020-001',
  MESSAGECODE_MCAES020_002: 'MCAES020-002',
  MESSAGECODE_MCAES020_003: 'MCAES020-003',
  MESSAGECODE_MCAES020_004: 'MCAES020-004',
  MESSAGECODE_MCAES020_005: 'MCAES020-005',
  MESSAGECODE_MCAES020_006: 'MCAES020-006',
  MESSAGECODE_MCAES020_007: 'MCAES020-007',
  MESSAGECODE_MCAES020_008: 'MCAES020-008',
  MESSAGECODE_MCAES020_009: 'MCAES020-009',
  MESSAGECODE_MCAES020_010: 'MCAES020-010',
  MESSAGECODE_MCAES020_011: 'MCAES020-011',
  MESSAGECODE_MCAES020_012: 'MCAES020-012',
  MESSAGECODE_MCAES020_013: 'MCAES020-013',
  MESSAGECODE_MCAES020_014: 'MCAES020-014',
  MESSAGECODE_MCAES020_015: 'MCAES020-015',
  MESSAGECODE_MCAES020_016: 'MCAES020-016',
  MESSAGECODE_MCAES020_017: 'MCAES020-017',
  BUTTON_TYPE_UPDATE: 'update',
  BUTTON_TYPE_REGISTER: 'register',
  BUTTON_TYPE_CANCEL: 'cancel',
  BUTTON_TYPE_DELETE: 'delete',
  RECRUITMENT_TYPE_20: '20',
  BUTTON_REGISTER: '登録する',
  BUTTON_UPDATE: '更新する',
  BUTTON_DELETE: '削除する',
  BUTTON_CANCEL: 'キャンセルする',
  VALIDFLAG: '0',
  NAME: 'name',
  DETACH: 'detach',
  LIGHT: 'light',
  SELECTION_TYPE: 'selectionType',
  department: 'department',
  departmentName: 'departmentName',
  ACCOUNT_TYPE: 'accountType',
  ACCOUNT_TYPE_NAME: 'accountTypeName',
  STATUS: 'status',
  FULL_NAME: 'fullName',
  VALID_FLAG_NAME: 'validFlagName',
  AFFILIATIONTIP: 'affiliationTip',
  NULL: '',
  //MCAYS020
  // eslint-disable-next-line @typescript-eslint/camelcase
  MCAXS040_ShareURL: 'MCAXS040_ShareURL',
  // eslint-disable-next-line @typescript-eslint/camelcase
  MCAXS050_ShareURL: 'MCAXS050_ShareURL',
  MESSAGECODE_MCAYS020_000: 'MCAYS020-000',
  MESSAGECODE_MCAYS020_004: 'MCAYS020-004',
  MESSAGECODE_MCAYS020_005: 'MCAYS020-005',
  MESSAGECODE_MCAYS020_006: 'MCAYS020-006',
  MESSAGECODE_MCAYS020_007: 'MCAYS020-007',
  // 次期9月対応 #58576 start
  MESSAGECODE_MCAYS020_018: 'MCAYS020-018',
  MESSAGECODE_MCAYS020_019: 'MCAYS020-019',
  // 次期9月対応 #58576 end

  //MCADS050
  MCADS050_011: '	アカウントの仮発行が完了しました',
  MCADS050_012: 'アカウントの更新が完了しました',
  MCADS050_013: 'アカウントの削除が完了しました',

  //loginURL
  LOGIN_URL: 'LOGIN_URL',
  // MCACS010
  MESSAGECODE_MCACS010_000: 'MCACS010-000',
  MESSAGECODE_MCACS010_100: 'MCACS010-100',

  // MCAAS010
  MCAAS010_SCREENID: 'MCAAS010',

  // MCADS010
  MESSAGECODE_MCADS010_000: 'MCADS010_000',

  // MCAYS040
  MESSAGECODE_MCAYS040_000: 'MCAYS040-000',

  // MCAOS030
  MESSAGECODE_MCAOS030_000: 'MCAOS030-000',

  // MCAOS020
  MESSAGECODE_MCAOS020_000: 'MCAOS020-000',

  // MCAOS010
  MESSAGECODE_MCAOS010_000: 'MCAOS010-000',
  SCREENID_MCAOS010: 'MCAOS010',

  // MCADS020
  MESSAGECODE_MCADS020_000: 'MCADS020-000',

  // MCAAS020
  MESSAGECODE_MCAAS020_000: 'MCAAS020-000',
  MESSAGECODE_MCAAS020_00A: 'MCAAS020-00A',
  MESSAGECODE_MCAAS020_00B: 'MCAAS020-00B',
  // 転職March #75621 START
  MESSAGECODE_MCAAS020_00C: 'MCAAS020-00C',
  // 転職March #75621 END

  // MCADS030
  MESSAGECODE_MCADS030_000: 'MCADS030-000',
  MESSAGECODE_MCADS030_005: 'MCADS030-005',
  MESSAGECODE_MCADS030_006: 'MCADS030-006',

  // MCADS040
  MESSAGECODE_MCADS040_000: 'MCADS040-000',

  // MCADS050
  MESSAGECODE_MCADS050_000: 'MCADS050-000',

  // MCAXS090
  MESSAGECODE_MCAXS090_000: 'MCAXS090-000',
  MESSAGECODE_MCAXS090_007: 'MCAXS090-007',
  MESSAGECODE_MCAXS090_008: 'MCAXS090-008',
  MESSAGECODE_FILE_SIZE: 10485760,
  // MCAIS030
  MESSAGECODE_MCAIS030_000: 'MCAIS030-000',

  // MCAES020
  MESSAGECODE_MCAES020_000: 'MCAES020_000',

  // MCAIS020
  MESSAGECODE_MCAIS020_000: 'MCAIS020-000',

  // MCAHS010
  MESSAGECODE_MCAHS010_000: 'MCAHS010-000',

  // MCAHS020
  MESSAGECODE_MCAHS020_000: 'MCAHS020-000',

  // MCAHS030
  MESSAGECODE_MCAHS030_000: 'MCAHS030-000',

  // MCAIS010
  MESSAGECODE_MCAIS010_000: 'MCAIS010-000',

  // MCANS030
  MESSAGECODE_MCANS030_000: 'MCANS030-000',

  // MCANS020
  MESSAGECODE_MCANS020_000: 'MCANS020-000',

  // MCANS010
  MESSAGECODE_MCANS010_000: 'MCANS010-000',

  // MCACS020
  MESSAGECODE_MCACS020_000: 'MCACS020-000',

  // MCACS030
  MESSAGECODE_MCACS030_000: 'MCACS030-000',
  MESSAGECODE_MCACS030_002: 'MCACS030-002',

  // MCAYS010
  MESSAGECODE_MCAYS010_000: 'MCAYS010-000',

  // MCARS050
  MESSAGECODE_MCARS050_000: 'MCARS050-000',

  // MCARS060
  MESSAGECODE_MCARS060_000: 'MCARS060-000',

  // MCACS040
  MESSAGECODE_MCACS040_000: 'MCACS040-000',

  // MCAUS010
  MESSAGECODE_MCAUS010_000: 'MCAUS010-000',
  MESSAGECODE_MCAUS010_003: 'MCAUS010-003',

  // MCAUS020
  MESSAGECODE_MCAUS020_000: 'MCAUS020-000',

  // MCAYS030
  MESSAGECODE_MCAYS030_000: 'MCAYS030-000',
  MESSAGECODE_MCAYS030_002: 'MCAYS030-002',
  MESSAGECODE_MCAYS030_005: 'MCAYS030-005',
  MESSAGECODE_MCAYS030_006: 'MCAYS030-006',
  MESSAGECODE_MCAYS030_007: 'MCAYS030-007',
  MESSAGECODE_MCAYS030_008: 'MCAYS030-008',
  MESSAGECODE_MCAYS030_020: 'MCAYS030-020',
  MESSAGECODE_MCAYS030_021: 'MCAYS030-021',
  MESSAGECODE_MCAYS030_022: 'MCAYS030-022',
  // 次期9月対応 #58576 start
  MESSAGECODE_MCAYS030_023: 'MCAYS030-023',
  MESSAGECODE_MCAYS030_024: 'MCAYS030-024',
  MESSAGECODE_MCAYS030_025: 'MCAYS030-025',
  // 次期9月対応 #58576 end

  // MCAGS010-000
  MESSAGECODE_MCAGS010_000: 'MCAGS010-000',

  // MCAGS010-00A
  MESSAGECODE_MCAGS010_00A: 'MCAGS010-00A',

  // MCAGS010-002
  MESSAGECODE_MCAGS010_002: 'MCAGS010-002',

  // MCAGS010-008
  MESSAGECODE_MCAGS010_008: 'MCAGS010-008',

  // MCAGS010-009
  MESSAGECODE_MCAGS010_009: 'MCAGS010-009',

  // MCAGS010-010
  MESSAGECODE_MCAGS010_010: 'MCAGS010-010',

  // MCAXS111-000
  MESSAGECODE_MCAXS111_000: 'MCAXS111-000',

  // MCAXS111-001
  MESSAGECODE_MCAXS111_001: 'MCAXS111-001',

  MCAXS111_TEMPLATENAME: 'アクセスオンライン形式（応募者単位）',

  //MCAJS010
  MESSAGECODE_MCAJS010_000: 'MCAJS010-000',
  MESSAGECODE_MCAJS010_001: 'MCAJS010-001',
  MESSAGECODE_MCAJS010_002: 'MCAJS010-002',
  GRAYFLAG_0: '0',
  GRAYFLAG_1: '1',
  TRANSITION_FLAG_0: '0',
  TRANSITION_FLAG_1: '1',

  //MCAJS020
  MESSAGECODE_MCAJS020_C001: 'MCAJS020-C001',
  MESSAGECODE_MCAJS020_000: 'MCAJS020-000',
  MESSAGECODE_MCAJS020_004: 'MCAJS020-004',
  //"登録する"押下時
  MESSAGECODE_MCAJS020_003: 'MCAJS020-003',
  MESSAGECODE_MCAJS020_010: 'MCAJS020-010',
  MESSAGECODE_MCAJS020_011: 'MCAJS020-011',
  MESSAGECODE_MCAJS020_012: 'MCAJS020-012',

  //"キャンセルする"押下時
  MESSAGECODE_MCAJS020_015: 'MCAJS020-015',
  MESSAGECODE_MCAJS020_005: 'MCAJS020-005',

  //選考ステップダイアログ"OK"押下時
  MESSAGECODE_MCAJS020_016: 'MCAJS020-016',

  // 年度対応 start
  // "登録する"押下時
  MESSAGECODE_MCAJS020_017: 'MCAJS020-017',
  // 年度対応 end
  // 次期9月対応 #62671 start
  MESSAGECODE_MCAJS020_018: 'MCAJS020-018',
  MESSAGECODE_MCAJS020_019: 'MCAJS020-019',
  //【標準選考】文言表示
  MCAJS030_SELECTION_TYPE: '【標準選考】',
  // 次期9月対応 #62671 end

  //MCAJS030
  MESSAGECODE_MCAJS030_C001: 'MCAJS030-C001',
  //初期表示時
  MESSAGECODE_MCAJS030_000: 'MCAJS030-000',
  MESSAGECODE_MCAJS030_001: 'MCAJS030-001',
  //"更新する"押下時
  MESSAGECODE_MCAJS030_005: 'MCAJS030-005',
  MESSAGECODE_MCAJS030_002: 'MCAJS030-002',
  MESSAGECODE_MCAJS030_011: 'MCAJS030-011',
  MESSAGECODE_MCAJS030_023: 'MCAJS030-023',
  MESSAGECODE_MCAJS030_020: 'MCAJS030-020',
  MESSAGECODE_MCAJS030_022: 'MCAJS030-022',
  MESSAGECODE_MCAJS030_021: 'MCAJS030-021',
  //選考ステップダイアログ"OK"押下時
  MESSAGECODE_MCAJS030_008: 'MCAJS030-008',
  MESSAGECODE_MCAJS030_026: 'MCAJS030-026',
  MESSAGECODE_MCAJS030_010: 'MCAJS030-010',
  MESSAGECODE_MCAJS030_009: 'MCAJS030-009',
  //#72716 start
  MESSAGECODE_MCAJS030_029: 'MCAJS030-029',
  //#72716 end
  MESSAGECODE_MCAJS030_030: 'MCAJS030-030',
  //カスタマイズ進捗"×"ボタン押下時
  MESSAGECODE_MCAJS030_012: 'MCAJS030-012',
  MESSAGECODE_MCAJS030_027: 'MCAJS030-027',
  //"選考を終了する"押下時
  MESSAGECODE_MCAJS030_017: 'MCAJS030-017',
  MESSAGECODE_MCAJS030_014: 'MCAJS030-014',
  MESSAGECODE_MCAJS030_024: 'MCAJS030-024',
  //"削除する"押下時
  MESSAGECODE_MCAJS030_018: 'MCAJS030-018',
  MESSAGECODE_MCAJS030_015: 'MCAJS030-015',
  MESSAGECODE_MCAJS030_016: 'MCAJS030-016',
  MESSAGECODE_MCAJS030_025: 'MCAJS030-025',
  MESSAGECODE_MCAJS030_019: 'MCAJS030-019',
  //"キャンセルする"押下時
  MESSAGECODE_MCAJS030_007: 'MCAJS030-007',
  MESSAGECODE_MCAJS030_028: 'MCAJS030-028',
  //進捗を"並び替え可能位置に移動"した時
  MESSAGECODE_MCAJS030_006: 'MCAJS030-006',
  MESSAGECODE_MCAJS030_013: 'MCAJS030-013',

  //MCAKS010
  DELET_FLAG_0: '0', //削除フラグ:0(チェック：正常、削除：成功)
  DELET_FLAG_1: '1', //削除フラグ:1("タグ"に紐づくデータの存在チェック：エラー)
  OUT_FLG_OK: true, //ダイアログ:出力
  OUT_FLG_NG: false, //ダイアログ:出力しない
  MESSAGECODE_MCAKS010_000: 'MCAKS010-000',
  MESSAGECODE_MCAKS010_004: 'MCAKS010-004',
  MESSAGECODE_MCAKS010_005: 'MCAKS010-005',
  MESSAGECODE_MCAKS010_006: 'MCAKS010-006',
  MESSAGECODE_MCAKS010_007: 'MCAKS010-007',
  MESSAGECODE_MCAKS010_009: 'MCAKS010-009',

  //MCAKS020
  MESSAGECODE_MCAKS020_000: 'MCAKS020-000',
  MESSAGECODE_MCAKS020_003: 'MCAKS020-003',
  MESSAGECODE_MCAKS020_004: 'MCAKS020-004',
  MESSAGECODE_MCAKS020_005: 'MCAKS020-005',

  // MCALS010
  MESSAGECODE_MCALS010_000: 'MCALS010-000',
  MESSAGECODE_MCALS010_004: 'MCALS010-004',
  MESSAGECODE_MCALS010_005: 'MCALS010-005',
  MESSAGECODE_MCALS010_006: 'MCALS010-006',
  MESSAGE_MCALS010_1: 'このCSVテンプレートをコピーして新規の情報を登録します',
  MESSAGE_MCALS010_2: 'ドラッグ＆ドロップで表示順を入れ替えます',
  MESSAGE_MCALS010_3: 'このCSVテンプレートをアップロードするテンプレートをダウンロードします',
  CHECK_MCALS010_MAXCOUNT: 50,
  // 就職の場合
  CHECK_MACLS010_MAXCOUNT_MSG1:
    'CSVテンプレートが、登録可能上限数50件(ダウンロード、アップロード各50件)存在するため、新規に追加することはできません。',
  // 転職の場合
  CHECK_MACLS010_MAXCOUNT_MSG2:
    'CSVテンプレートが、登録可能上限数50件存在するため、新規に追加することはできません。',

  // MCAFS010
  MESSAGECODE_MCAFS010_000: 'MCAFS010-000',
  // 次期9月対応 #63073 start
  MESSAGECODE_MCAFS010_001: 'MCAFS010-001',
  MESSAGECODE_MCAFS020_001: 'MCAFS020-001',
  MESSAGECODE_MCAFS020_002: 'MCAFS020-002',
  MESSAGECODE_MCAFS020_003: 'MCAFS020-003',
  MESSAGECODE_MCAFS020_004: 'MCAFS020-004',
  MESSAGECODE_MCAFS020_005: 'MCAFS020-005',
  MESSAGECODE_MCAFS020_006: 'MCAFS020-006',
  // 転職March #75621 START
  MESSAGECODE_MCAFS020_007: 'MCAFS020-007',
  MESSAGECODE_MCAFS020_008: 'MCAFS020-008',
  MESSAGECODE_MCAFS020_009: 'MCAFS020-009',
  // 転職March #75621 END
  // 次期9月対応 #63073 end
  // MCASS010
  MESSAGECODE_MCASS010_000: 'MCASS010-000',
  // MCASS020
  MESSAGECODE_MCASS020_000: 'MCASS020-000',
  // MCASS030
  MESSAGECODE_MCASS030_000: 'MCASS030-000',
  MESSAGECODE_MCASS010_002: 'MCASS010-002',
  MESSAGECODE_MCASS020_002: 'MCASS020-002',
  REACT_SELECT_NOOPTIONSMESSAGE: '一致する候補が見つかりません',

  // MCARS010
  MESSAGECODE_MCARS010_000: 'MCARS010-000',
  MESSAGECODE_MCARS010_00A: 'MCARS010-00A',
  MESSAGECODE_MCARS010_002: 'MCARS010-002',
  MESSAGECODE_MCARS010_003: 'MCARS010-003',
  MESSAGECODE_MCARS010_004_1: 'MCARS010-004-1',
  MESSAGECODE_MCARS010_004_2: 'MCARS010-004-2',
  MESSAGECODE_MCARS010_005: 'MCARS010-005',
  MESSAGECODE_MCARS010_007: 'MCARS010-007',
  MESSAGECODE_MCARS010_008: 'MCARS010-008',
  MESSAGECODE_MCARS010_009: 'MCARS010-009',
  MESSAGECODE_MCARS010_010: 'MCARS010-010',

  // MCBリプレース MCBIS010 START
  MESSAGECODE_MCBIS010_000: 'MCBIS010-000',
  MESSAGECODE_MCBIS010_001: 'MCBIS010-001',
  MESSAGECODE_MCBIS010_002: 'MCBIS010-002',
  MESSAGECODE_MCBIS010_003: 'MCBIS010-003',
  MESSAGECODE_MCBIS010_004: 'MCBIS010-004',
  MESSAGECODE_MCBIS010_005: 'MCBIS010-005',
  MESSAGECODE_MCBIS010_006: 'MCBIS010-006',
  MESSAGECODE_MCBIS010_007: 'MCBIS010-007',
  MESSAGECODE_MCBIS010_00A: 'MCBIS010-00A',
  // MCBリプレース MCBIS010 END

  // MCBリプレース MCBIS030 START
  MESSAGECODE_MCBIS030_000: 'MCBIS030-000',
  MESSAGECODE_MCBIS030_002: 'MCBIS030-002',
  MESSAGECODE_MCBIS030_003: 'MCBIS030-003',
  MESSAGECODE_MCBIS030_005: 'MCBIS030-005', 
  MESSAGECODE_MCBIS030_006: 'MCBIS030-006', 
  MESSAGECODE_MCBIS030_007: 'MCBIS030-007',
  MESSAGECODE_MCBIS030_008: 'MCBIS030-008',
  MESSAGECODE_MCBIS030_009: 'MCBIS030-009', 
  MAX_LENGTH_MCBIS030: 100,
  // MCBリプレース MCBIS030 END

  //MCAZS010
  MESSAGECODE_MCAZS010_002: 'MCAZS010-002',
  MESSAGECODE_MCAZS010_003: 'MCAZS010-003',
  MESSAGECODE_MCAZS010_004: 'MCAZS010-004',
  MESSAGECODE_MCAZS010_005: 'MCAZS010-005',
  // phase2 start jxp
  MESSAGECODE_MCAZS010_006: 'MCAZS010-006',
  MESSAGECODE_MCAZS010_007: 'MCAZS010-007',
  // phase2 start jxp
  // 次期開発5月向#58931 start
  MESSAGECODE_MCAZS010_008: 'MCAZS010-008',
  // 次期開発5月向#58931 end

  // MCAZS020
  MESSAGECODE_MCAZS020_003: 'MCAZS020-003',
  MESSAGECODE_MCAZS020_004: 'MCAZS020-004',
  MESSAGECODE_MCAZS020_005: 'MCAZS020-005',
  MESSAGECODE_MCAZS020_006: 'MCAZS020-006',
  MESSAGECODE_MCAZS020_007: 'MCAZS020-007',
  MESSAGECODE_MCAZS020_008: 'MCAZS020-008',
  // #5654 #59450 start
  MESSAGECODE_MCAZS020_009: 'MCAZS020-009',
  // #5654 #59450 end

  // MCAZS030
  MESSAGECODE_MCAZS030_003: 'MCAZS030-003', //メッセージの送信をキャンセルしてもよろしいですか？キャンセルしたメッセージは下書きに移動します。
  MESSAGECODE_MCAZS030_004: 'MCAZS030-004', //メッセージを送信してもよろしいですか？
  MESSAGECODE_MCAZS030_005: 'MCAZS030-005', //下書きに移動しました。
  MESSAGECODE_MCAZS030_006: 'MCAZS030-006', //送信しました
  MESSAGECODE_MCAZS030_007: 'MCAZS030-007', //メッセージの状態が送信予約ではないメッセージが含まれるため、送信をキャンセルできませんでした
  MESSAGECODE_MCAZS030_008: 'MCAZS030-008', //メッセージの状態が送信予約ではないメッセージが含まれるため、すぐに送信できませんでした。
  MESSAGECODE_MCAZS030_009: 'MCAZS030-009', //条件に一致する検索結果はありませんでした。
  // [phase2] start by zhangxp
  MESSAGECODE_MCAZS030_010: 'MCAZS030-010', //メッセージ送信に失敗した応募者
  // [phase2] end by zhangxp
  // #5654 #59450 start
  MESSAGECODE_MCAZS030_011: 'MCAZS030-011', //一括メッセージ送信対象の応募者
  // #5654 #59450 end

  //MCAZS040
  MESSAGECODE_MCAZS040_002: 'MCAZS040-002', //一括操作したいメッセージを選択してください
  MESSAGECODE_MCAZS040_003: 'MCAZS040-003', //選択されているメッセージをゴミ箱に移動してもよろしいですか？
  MESSAGECODE_MCAZS040_004: 'MCAZS040-004', //ゴミ箱に移動しました。
  MESSAGECODE_MCAZS040_005: 'MCAZS040-005', //条件に一致する検索結果はありませんでした。
  // #5654 #59450 start
  MESSAGECODE_MCAZS040_006: 'MCAZS040-006', //一括メッセージ送信対象の応募者
  // #5654 #59450 end

  //MCAZS050
  MESSAGECODE_MCAZS050_002: 'MCAZS050-002',
  MESSAGECODE_MCAZS050_003: 'MCAZS050-003',
  MESSAGECODE_MCAZS050_004: 'MCAZS050-004',
  MESSAGECODE_MCAZS050_005: 'MCAZS050-005',
  MESSAGECODE_MCAZS050_006: 'MCAZS050-006',
  MESSAGECODE_MCAZS050_007: 'MCAZS050-007',
  MESSAGECODE_MCAZS050_008: 'MCAZS050-008',
  // #5654 #59450 start
  MESSAGECODE_MCAZS050_009: 'MCAZS050-009',
  // #5654 #59450 end

  //MCARS030
  MESSAGECODE_MCARS030_000: 'MCARS030-000',
  MESSAGECODE_MCARS030_003: 'MCARS030-003', //検索条件名を選択してください。
  MESSAGECODE_MCARS030_004: 'MCARS030-004', //検索条件名を入力してください。
  MESSAGECODE_MCARS030_007: 'MCARS030-007',
  MESSAGECODE_MCARS030_008: 'MCARS030-008',
  MESSAGECODE_MCARS030_009: 'MCARS030-009', //検索条件を保存しました。
  MAX_LENGTH_MCARS030: 100,
  // 転職March #75621 START
  MESSAGECODE_MCARS030_000_1: 'MCARS030-000-1',
  // 転職March #75621 END
  // 次期開発9月#51248 start
  MCARS030_HELP_URL_1: 'MCARS030_HELP_URL_1',
  MCARS030_HELP_URL_2: 'MCARS030_HELP_URL_2',
  // 次期開発9月#51248 end
  //MCARS020
  MESSAGECODE_MCARS020_000: 'MCARS020-000',
  MESSAGECODE_MCARS020_001: 'MCARS020-001',
  // MCBリプレース MCBIS020 START
  MESSAGECODE_MCBIS020_001: 'MCBIS020-001',
  // MCBリプレース MCBIS020 END
  // MCALS020
  MESSAGECODE_MCALS020_000: 'MCALS020-000',
  MESSAGECODE_MCALS020_006: 'MCALS020-006',
  MESSAGECODE_MCALS020_009: 'MCALS020-009',
  MESSAGECODE_MCALS020_010: 'MCALS020-010',
  MESSAGECODE_MCALS020_011: 'MCALS020-011',
  MESSAGECODE_MCALS020_012: 'MCALS020-012',
  MESSAGECODE_MCALS020_013: 'MCALS020-013',
  MESSAGECODE_MCALS020_014: 'MCALS020-014',
  MESSAGECODE_MCALS020_015: 'MCALS020-015',
  MESSAGECODE_MCALS020_016: 'MCALS020-016',
  MESSAGECODE_MCALS020_017: 'MCALS020-017',
  MCALS020_ENTRYDATAITEMID: 601,
  // 年度対応 start
  MESSAGECODE_MCALS020_018: 'MCALS020-018',
  // 年度対応 end
  // #63075 次期開発2022年9月 start
  MESSAGECODE_MCALS020_019: 'MCALS020-019',
  // #63075 次期開発2022年9月 end

  //MCALS030
  MESSAGECODE_MCALS030_000: 'MCALS030-000',
  MESSAGECODE_MCALS030_003: 'MCALS030-003',
  MESSAGECODE_MCALS030_004: 'MCALS030-004',
  MESSAGECODE_MCALS030_005: 'MCALS030-005',
  MESSAGECODE_MCALS030_006: 'MCALS030-006',
  MESSAGECODE_MCALS030_007: 'MCALS030-007',
  MESSAGECODE_MCALS030_008: 'MCALS030-008',
  MESSAGECODE_MCALS030_009: 'MCALS030-009',
  MESSAGECODE_MCALS030_010: 'MCALS030-010',
  MESSAGECODE_MCALS030_011: 'MCALS030-011',
  MESSAGECODE_MCALS030_012: 'MCALS030-012',
  MESSAGECODE_MCALS030_013: 'MCALS030-013',

  //MCALS040
  MESSAGECODE_MCALS040_000: 'MCALS040-000',
  MESSAGECODE_MCALS040_003: 'MCALS040-003',
  MESSAGECODE_MCALS040_004: 'MCALS040-004',
  MESSAGECODE_MCALS040_005: 'MCALS040-005',
  MESSAGECODE_MCALS040_006: 'MCALS040-006',
  MESSAGECODE_MCALS040_007: 'MCALS040-007',
  MESSAGECODE_MCALS040_008: 'MCALS040-008',
  MESSAGECODE_MCALS040_009: 'MCALS040-009',
  MESSAGECODE_MCALS040_010: 'MCALS040-010',
  MESSAGECODE_MCALS040_011: 'MCALS040-011',
  MESSAGECODE_MCALS040_012: 'MCALS040-012',
  MESSAGECODE_MCALS040_013: 'MCALS040-013',
  MESSAGECODE_MCALS040_015: 'MCALS040-015',
  MESSAGECODE_MCALS040_016: 'MCALS040-016',
  MESSAGECODE_MCALS040_017: 'MCALS040-017',
  MESSAGECODE_MCALS040_018: 'MCALS040-018',
  MESSAGECODE_MCALS040_019: 'MCALS040-019',
  MESSAGECODE_MCALS040_020: 'MCALS040-020',
  MESSAGECODE_MCALS040_021: 'MCALS040-021',
  // 年度対応 start
  MESSAGECODE_MCALS040_022: 'MCALS040-022',
  // 年度対応 end
  INTEGER_5: 5,
  INTEGER_9: 9,
  TEMPLATE_TYPE_1: '1',
  INPUT_FLAG_1: '1',
  OUTPUT_HISTORY_0: '0',
  LAYOUT_TYPE_0: '0',
  LAYOUT_TYPE_1: '1',
  LAYOUT_TYPE_2: '2',
  LAYOUT_TYPE_3: '3',
  LAYOUT_TYPE_4: '4',
  LAYOUT_TYPE_5: '5',
  // #62022 次期開発2022年9月 start
  LAYOUT_TYPE_8: '8',
  // #62022 次期開発2022年9月 end
  // MCBリプレース Lot3 start
  LAYOUT_TYPE_9: '9',
  // MCBリプレース Lot3 end
  READ_FLAG_UNREAD: '未読',
  MESSAGE_TYPE_ANONYMOUS: '匿名',
  MESSAGE_TYPE_USUALLY: '01', // 通常
  MESSAGE_TYPE_CONTACTS: '30', // お問い合わせ
  BULK_SEND_FLAG_1: '1', // 一括送信
  MEMBER_TYPE_5: '5', // 会員種別
  SENDING_RESULT: '1', // 送信失敗
  MESSAGE_SEND_TYPE_20: '20', // 電子メール

  OUTPUT_UNIT_0: '0',
  OUTPUT_UNIT_1: '1',
  OUTPUT_UNIT_2: '2',
  OUTPUT_UNIT_3: '3',
  OUTPUT_UNIT_4: '4',
  OUTPUT_UNIT_5: '5',
  TEMPLATE_TYPE_0: '0',

  //MCAVS020
  MCAVS020_001: 'MCAVS020-001',

  //MCAZS070
  MCAZS070_003: 'MCAZS070-003',
  MCAZS070_004: 'MCAZS070-004',
  MCAZS070_005: 'MCAZS070-005',
  // 次期開発12月 #72025 start
  MCAZS070_007: 'MCAZS070-007',
  MCAZS070_008: 'MCAZS070-008',
  MCAZS070_009: 'MCAZS070-009',
  // 次期開発12月 #72025 end
  SEARCH_CRITERIA_DESCRIPTION: '※選考フィルターで絞り込んだ条件は表示されません。',

  //MCAZS150
  FIX_INTERVIEW_DATE: 'fix_interview_date',

  // MCAWS010
  MESSAGECODE_MCAWS010_001: 'MCAWS010-001',
  SCREEN_ID_MCAWS010: 'MCAWS010',
  PARAM_CHECKED_MCAWS010: '1',
  PARAM_SEARCH_TYPE_MCAWS010: '1',
  PARAM_SPECIFIC_CRITERIA_NAME_CONTACT:
    '合否連絡をしていない可能性のあるエントリー',
  PARAM_SPECIFIC_CRITERIA_NAME_PROGRESS1:
    '30日間進捗が更新されていないエントリー',
  PARAM_SPECIFIC_CRITERIA_NAME_PROGRESS2:
    '14日間進捗が更新されていないエントリー',

  // ロット3 contants Start

  // MCAXS100 画面説明文
  MESSAGECODE_MCAXS100_000: 'MCAXS100-000',
  // MCAXS290 画面説明文
  MESSAGECODE_MCAXS290_000: 'MCAXS290-000',

  MANIPULATIONTIME: 'manipulationTime',
  SELECTIONNAME: 'selectionName',
  MANIPULATIONTYPE: 'manipulationType',
  PROGRESSNAME: 'progressName',
  DECISIONDIVISION: 'decisionDivision',
  // #75311 25KH start
  HISTRYDELETE: 'histryDelete',
  HISTRYDELETEFLAG:'histryDeleteflag',
  // #75311 25KH end
  // 転職March #75621 START
  INTERVIEWTIME:'interviewTime',
  FAIRUEOTHER:'fairueOther',
  PROGRESSTYPE:'progressType',
  DETAILMANIPULATIONTYPE: 'detailManipulationType',
  DECISIONDIVISIONTYPE:'decisionDivisionType',
  // 転職March #75621 END
  // MCAXS320
  MESSAGECODE_MCAXS320_000: 'MCAXS320-000',
  // MCAXS340
  MESSAGECODE_MCAXS340_000: 'MCAXS340-000',
  // 次期開発12月 #51829 start
  MESSAGECODE_MCAXS340_0000:'MCAXS340-0000',
  // 次期開発12月 #51829 end
  MESSAGECODE_MCAXS340_003: 'MCAXS340-003',
  MCAXS340_MAX_STRING_LENGTH: 400,
  MCAXS340_MAXLENGTH: 11,
  MCAXS340_MAXLENGTH_NEGATIVE: 12,
  MCAXS340_MAXVALUE: 99999999.99,
  MCAXS340_MINVALUE: -99999999.99,

  MCAXS320_WAVY_LINE: '～',
  MCAXS320_TIME: '無期限',
  MESSAGECODE_MCAXS320_001: 'MCAXS320-001',
  MESSAGECODE_MCAXS320_002: 'MCAXS320-002',

  // MCAXS310
  MESSAGECODE_MCAXS310_000: 'MCAXS310-000',
  MESSAGECODE_MCAXS310_002: 'MCAXS310-002',
  MESSAGECODE_MCAXS310_003: 'MCAXS310-003',
  MCAXS310_CONTRACT_MEDIA_NAME: '応募経路　　　　　　：',
  MCAXS310_SEMINAR_INFO: '説明会・セミナー日程：',
  MCAXS310_REGISTEREDDATE: '登録日時　　　　　　：',
  MCAXS310_WAVY_LINE: '～',
  MCAXS310_TIME: '無期限',
  MCAXS310_TIME_NULL: '-',

  // MCAXS271
  MESSAGECODE_MCAXS271_000: 'MCAXS271-000',
  MESSAGECODE_MCAXS271_003: 'MCAXS271-003',
  MESSAGECODE_MCAXS271_010: 'MCAXS271-010',
  MCAXS271_WAVY_LINE: '～',
  MCAXS271_TIME: '―',
  MCAXS271_ENTERTIME: '\n',

  // MCAXS280
  MESSAGECODE_MCAXS280_000: 'MCAXS280-000',
  MESSAGECODE_MCAXS280_003: 'MCAXS280-003',
  MESSAGECODE_MCAXS280_004: 'MCAXS280-004',
  MESSAGECODE_MCAXS280_011: 'MCAXS280-011',
  MCAXS280_WAVY_LINE: '～',
  MCAXS280_TIME: '-',
  MCAXS280_ENTERTIME: '\n',

  // MCAXS670
  MESSAGECODE_MCAXS670_000: 'MCAXS670-000',
  MESSAGECODE_MCAXS670_008: 'MCAXS670-008',
  MESSAGECODE_MCAXS670_015: 'MCAXS670-015',

  // MCAMS010
  MESSAGECODE_MCAMS010_000: 'MCAMS010-000',
  MESSAGECODE_MCAMS010_000_1: 'MCAMS010-000_1',
  MESSAGECODE_MCAMS010_002: 'MCAMS010-002',
  MESSAGECODE_MCAMS010_003: 'MCAMS010-003',
  MESSAGECODE_MCAMS010_004: 'MCAMS010-004',
  MESSAGECODE_MCAMS010_005: 'MCAMS010-005',
  MESSAGECODE_MCAMS010_006: 'MCAMS010-006',
  MESSAGECODE_MCAMS010_007: 'MCAMS010-007',
  MESSAGECODE_MCAMS010_008: 'MCAMS010-008',
  MESSAGECODE_MCAMS010_009: 'MCAMS010-009',
  CHECK_MACMS010_MAXCOUNT: 500,
  CHECK_MACMS010_MAXCOUNT_CODE: 'MACMS010-MAXCOUNT-000',
  CHECK_MACMS010_MAXCOUNT_MSG:
    '登録可能上限数（500件）存在するため、<br/>新規に追加することはできません。',

  // MCBJS010
  MESSAGECODE_MCBJS010_000: 'MCBJS010-000',
  MESSAGECODE_MCBJS010_002: 'MCBJS010-002',
  MESSAGECODE_MCBJS010_003: 'MCBJS010-003',
  MESSAGECODE_MCBJS010_004: 'MCBJS010-004',
  MESSAGECODE_MCBJS010_005: 'MCBJS010-005',
  MESSAGECODE_MCBJS010_006: 'MCBJS010-006',
  MESSAGECODE_MCBJS010_007: 'MCBJS010-007',
  MESSAGECODE_MCBJS010_008: 'MCBJS010-008',
  MESSAGECODE_MCBJS010_009: 'MCBJS010-009',
  CHECK_MCBJS010_MAXCOUNT_CODE: 'MCBJS010-MAXCOUNT-000',
  CHECK_MCBJS010_MAXCOUNT_MSG:
    '登録可能上限数（500件）存在するため、<br/>新規に追加することはできません。',

  // MCAMS020
  MESSAGECODE_MCAMS020_000: 'MCAMS020-000',
  MESSAGECODE_MCAMS020_002: 'MCAMS020-002',
  MESSAGECODE_MCAMS020_003: 'MCAMS020-003',
  MESSAGECODE_MCAMS020_005: 'MCAMS020-005',
  MESSAGECODE_MCAMS020_006: 'MCAMS020-006',
  MESSAGECODE_MCAMS020_007: 'MCAMS020-007',
  MESSAGECODE_MCAMS020_008: 'MCAMS020-008',
  MESSAGECODE_MCAMS020_009: 'MCAMS020-009',
  MESSAGECODE_MCAMS020_010: 'MCAMS020-010',
  MESSAGECODE_MCAMS020_011: 'MCAMS020-011',
  MESSAGECODE_MCAMS020_012: 'MCAMS020-012',
  MESSAGECODE_MCAMS020_013: 'MCAMS020-013',
  MESSAGECODE_MCAMS020_014: 'MCAMS020-014',
  MESSAGECODE_MCAMS020_015: 'MCAMS020-015',
  MESSAGECODE_MCAMS020_016: 'MCAMS020-016',
  MESSAGECODE_MCAMS020_017: 'MCAMS020-017',
  MESSAGECODE_MCAMS020_018: 'MCAMS020-018',
  MESSAGECODE_MCAMS020_019: 'MCAMS020-019',
  MESSAGECODE_MCAMS020_020: 'MCAMS020-020',
  MESSAGECODE_MCAMS020_021: 'MCAMS020-021',
  MESSAGECODE_MCAMS020_022: 'MCAMS020-022',
  MESSAGECODE_MCAMS020_023: 'MCAMS020-023',
  MESSAGECODE_MCAMS020_024: 'MCAMS020-024',
  MESSAGECODE_MCAMS020_026: 'MCAMS020-026',
  // 年度対応 start
  MESSAGECODE_MCAMS020_029: 'MCAMS020-029',
  // 年度対応 end
  // 次期5月対応 #47317 start
  MESSAGECODE_MCAMS020_030: 'MCAMS020-030',
  MESSAGECODE_MCAMS020_031: 'MCAMS020-031',
  MESSAGECODE_MCAMS020_032: 'MCAMS020-032',
  // 次期5月対応 #47317 end
  // 次期開発9月 #62201 start
  MESSAGECODE_MCAMS020_033: 'MCAMS020-033',
  // 次期開発9月 #62201 end
  // MCBリプレース MCAMS020 START
  MESSAGECODE_MCAMS020_034: 'MCAMS020-034',
  // MCBリプレース MCAMS020 END
  // MCBリプレース MCBJS020 START
  // MCBJS020
  MESSAGECODE_MCBJS020_000: 'MCBJS020-000',
  MESSAGECODE_MCBJS020_002: 'MCBJS020-002',
  MESSAGECODE_MCBJS020_003: 'MCBJS020-003',
  MESSAGECODE_MCBJS020_005: 'MCBJS020-005',
  MESSAGECODE_MCBJS020_006: 'MCBJS020-006',
  MESSAGECODE_MCBJS020_007: 'MCBJS020-007',
  MESSAGECODE_MCBJS020_008: 'MCBJS020-008',
  MESSAGECODE_MCBJS020_009: 'MCBJS020-009',
  MESSAGECODE_MCBJS020_010: 'MCBJS020-010',
  MESSAGECODE_MCBJS020_011: 'MCBJS020-011',
  MESSAGECODE_MCBJS020_012: 'MCBJS020-012',
  MESSAGECODE_MCBJS020_013: 'MCBJS020-013',
  MESSAGECODE_MCBJS020_014: 'MCBJS020-014',
  MESSAGECODE_MCBJS020_015: 'MCBJS020-015',
  MESSAGECODE_MCBJS020_016: 'MCBJS020-016',
  MESSAGECODE_MCBJS020_017: 'MCBJS020-017',
  MESSAGECODE_MCBJS020_018: 'MCBJS020-018',
  MESSAGECODE_MCBJS020_019: 'MCBJS020-019',
  MESSAGECODE_MCBJS020_020: 'MCBJS020-020',
  MESSAGECODE_MCBJS020_021: 'MCBJS020-021',
  MESSAGECODE_MCBJS020_022: 'MCBJS020-022',
  MESSAGECODE_MCBJS020_023: 'MCBJS020-023',
  MESSAGECODE_MCBJS020_024: 'MCBJS020-024',
  MESSAGECODE_MCBJS020_025: 'MCBJS020-025',
  MESSAGECODE_MCBJS020_026: 'MCBJS020-026',
  MESSAGECODE_MCBJS020_REQUESTBODY: '添付ファイル：このメッセージには添付ファイルが含まれます。添付ファイルはMy CareerBoxのメッセージから確認できます。<br/>',
  NO_OPTIONS_MESSAGE_SUBMISSIONS: '一致する対象が1件もありませんので、MCB側「提出物内容一覧」画面にて設定ください。',
  NO_OPTIONS_MESSAGE_THANKS: '一致する対象が1件もありませんので、MCB側「サンクスメールテンプレート一覧」画面にて設定ください。',
  NO_OPTIONS_MESSAGE_REFERENCE: '一致する対象が1件もありませんので、MCB側「お問い合わせ先一覧」画面にて設定ください。',
  NO_OPTIONS_MESSAGE_PERSONAL: '一致する対象が1件もありませんので、MCB側「My CareerBoxに関する個人情報の取り扱い一覧」画面にて設定ください。',
  // MCBリプレース MCBJS020 END
  // 26P #80795 start
  MESSAGECODE_MCBJS020_027: 'MCBJS020-027',
  // 26P #80795 start

  // 次期開発12月 #71768 start
  SEND_EMAIL_FIXED_MESSAGE: '\n\n※このメールはマイナビが提供する応募者管理システムを利用して送信されています※',
  // 次期開発12月 #71768 end

  // MCAMS030
  MESSAGECODE_MCAMS030_000: 'MCAMS030-000',
  MESSAGECODE_MCAMS030_003: 'MCAMS030-003',
  MESSAGECODE_MCAMS030_004: 'MCAMS030-004',
  MESSAGECODE_MCAMS030_006: 'MCAMS030-006',
  MESSAGECODE_MCAMS030_007: 'MCAMS030-007',
  MESSAGECODE_MCAMS030_008: 'MCAMS030-008',
  MESSAGECODE_MCAMS030_009: 'MCAMS030-009',
  MESSAGECODE_MCAMS030_010: 'MCAMS030-010',
  MESSAGECODE_MCAMS030_012: 'MCAMS030-012',
  MESSAGECODE_MCAMS030_013: 'MCAMS030-013',
  MESSAGECODE_MCAMS030_014: 'MCAMS030-014',
  MESSAGECODE_MCAMS030_015: 'MCAMS030-015',
  MESSAGECODE_MCAMS030_016: 'MCAMS030-016',
  MESSAGECODE_MCAMS030_017: 'MCAMS030-017',
  MESSAGECODE_MCAMS030_018: 'MCAMS030-018',
  MESSAGECODE_MCAMS030_019: 'MCAMS030-019',
  MESSAGECODE_MCAMS030_020: 'MCAMS030-020',
  MESSAGECODE_MCAMS030_021: 'MCAMS030-021',
  MESSAGECODE_MCAMS030_022: 'MCAMS030-022',
  MESSAGECODE_MCAMS030_023: 'MCAMS030-023',
  MESSAGECODE_MCAMS030_024: 'MCAMS030-024',
  MESSAGECODE_MCAMS030_026: 'MCAMS030-026',
  MESSAGECODE_MCAMS030_027: 'MCAMS030-027',
  MESSAGECODE_MCAMS030_029: 'MCAMS030-029',
  // 次期5月対応 #47317 start
  MESSAGECODE_MCAMS030_033: 'MCAMS030-033',
  MESSAGECODE_MCAMS030_034: 'MCAMS030-034',
  MESSAGECODE_MCAMS030_035: 'MCAMS030-035',
  // 次期5月対応 #47317 end
  // 次期開発9月 #62201 start
  MESSAGECODE_MCAMS030_036: 'MCAMS030-036',
  // 次期開発9月 #62201 end
  // MCBリプレース MCAMS030 START
  MESSAGECODE_MCAMS030_038: 'MCAMS030-038',
  // MCBリプレース MCAMS030 END

  // MCBリプレース MCBJS030 START
  // MCBJS030
  MESSAGECODE_MCBJS030_000: 'MCBJS030-000',
  MESSAGECODE_MCBJS030_002: 'MCBJS030-002',
  MESSAGECODE_MCBJS030_003: 'MCBJS030-003',
  MESSAGECODE_MCBJS030_005: 'MCBJS030-005',
  MESSAGECODE_MCBJS030_006: 'MCBJS030-006',
  MESSAGECODE_MCBJS030_007: 'MCBJS030-007',
  MESSAGECODE_MCBJS030_008: 'MCBJS030-008',
  MESSAGECODE_MCBJS030_009: 'MCBJS030-009',
  MESSAGECODE_MCBJS030_010: 'MCBJS030-010',
  MESSAGECODE_MCBJS030_011: 'MCBJS030-011',
  MESSAGECODE_MCBJS030_012: 'MCBJS030-012',
  MESSAGECODE_MCBJS030_013: 'MCBJS030-013',
  MESSAGECODE_MCBJS030_014: 'MCBJS030-014',
  MESSAGECODE_MCBJS030_015: 'MCBJS030-015',
  MESSAGECODE_MCBJS030_016: 'MCBJS030-016',
  MESSAGECODE_MCBJS030_017: 'MCBJS030-017',
  MESSAGECODE_MCBJS030_018: 'MCBJS030-018',
  MESSAGECODE_MCBJS030_019: 'MCBJS030-019',
  MESSAGECODE_MCBJS030_020: 'MCBJS030-020',
  MESSAGECODE_MCBJS030_021: 'MCBJS030-021',
  MESSAGECODE_MCBJS030_022: 'MCBJS030-022',
  MESSAGECODE_MCBJS030_023: 'MCBJS030-023',
  MESSAGECODE_MCBJS030_024: 'MCBJS030-024',
  MESSAGECODE_MCBJS030_025: 'MCBJS030-025',
  MESSAGECODE_MCBJS030_026: 'MCBJS030-026',
  MESSAGECODE_MCBJS030_027: 'MCBJS030-027',
  MESSAGECODE_MCBJS030_028: 'MCBJS030-028',
  MESSAGECODE_MCBJS030_REQUESTBODY: '添付ファイル：このメッセージには添付ファイルが含まれます。添付ファイルはMy CareerBoxのメッセージから確認できます。<br/>',
  // MCBリプレース MCBJS030 END

  // MCAMS040
  MESSAGECODE_MCAMS040_000: 'MCAMS040-000',
  MESSAGECODE_MCAMS040_003: 'MCAMS040-003',
  MESSAGECODE_MCAMS040_004: 'MCAMS040-004',
  MESSAGECODE_MCAMS040_005: 'MCAMS040-005',
  MESSAGECODE_MCAMS040_007: 'MCAMS040-007',
  MESSAGECODE_MCAMS040_008: 'MCAMS040-008',
  MESSAGECODE_MCAMS040_009: 'MCAMS040-009',
  MESSAGECODE_MCAMS040_010: 'MCAMS040-010',
  MESSAGECODE_MCAMS040_011: 'MCAMS040-011',
  MESSAGECODE_MCAMS040_012: 'MCAMS040-012',
  MESSAGECODE_MCAMS040_013: 'MCAMS040-013',
  MESSAGECODE_MCAMS040_014: 'MCAMS040-014',
  MESSAGECODE_MCAMS040_015: 'MCAMS040-015',
  MESSAGECODE_MCAMS040_016: 'MCAMS040-016',
  MESSAGECODE_MCAMS040_017: 'MCAMS040-017',
  MESSAGECODE_MCAMS040_018: 'MCAMS040-018',
  MESSAGECODE_MCAMS040_019: 'MCAMS040-019',
  MESSAGECODE_MCAMS040_020: 'MCAMS040-020',
  MESSAGECODE_MCAMS040_021: 'MCAMS040-021',
  MESSAGECODE_MCAMS040_022: 'MCAMS040-022',
  MESSAGECODE_MCAMS040_023: 'MCAMS040-023',

  // MCBJS040
  MESSAGECODE_MCBJS040_000: 'MCBJS040-000',
  MESSAGECODE_MCBJS040_003: 'MCBJS040-003',
  MESSAGECODE_MCBJS040_004: 'MCBJS040-004',
  MESSAGECODE_MCBJS040_005: 'MCBJS040-005',
  MESSAGECODE_MCBJS040_006: 'MCBJS040-006',
  MESSAGECODE_MCBJS040_007: 'MCBJS040-007',
  MESSAGECODE_MCBJS040_008: 'MCBJS040-008',
  MESSAGECODE_MCBJS040_009: 'MCBJS040-009',
  MESSAGECODE_MCBJS040_010: 'MCBJS040-010',
  MESSAGECODE_MCBJS040_011: 'MCBJS040-011',
  MESSAGECODE_MCBJS040_012: 'MCBJS040-012',
  MESSAGECODE_MCBJS040_013: 'MCBJS040-013',
  MESSAGECODE_MCBJS040_014: 'MCBJS040-014',
  MESSAGECODE_MCBJS040_015: 'MCBJS040-015',
  MESSAGECODE_MCBJS040_016: 'MCBJS040-016',
  MESSAGECODE_MCBJS040_017: 'MCBJS040-017',
  MESSAGECODE_MCBJS040_018: 'MCBJS040-018',
  MESSAGECODE_MCBJS040_019: 'MCBJS040-019',
  MESSAGECODE_MCBJS040_020: 'MCBJS040-020',
  MESSAGECODE_MCBJS040_021: 'MCBJS040-021',
  MESSAGECODE_MCBJS040_022: 'MCBJS040-022',
  MESSAGECODE_MCBJS040_023: 'MCBJS040-023',
  MESSAGECODE_MCBJS040_024: 'MCBJS040-024',
  
  // MCAQS010
  MESSAGECODE_MCAQS010_000: 'MCAQS010-000',
  MESSAGECODE_MCAQS010_003: 'MCAQS010-003',
  MESSAGECODE_MCAQS010_004: 'MCAQS010-004',
  MESSAGECODE_MCAQS010_005: 'MCAQS010-005',
  MESSAGECODE_MCAQS010_006: 'MCAQS010-006',
  MESSAGECODE_MCAQS010_007: 'MCAQS010-007',

  // MCAXS121
  MESSAGECODE_MCAXS121_000: 'MCAXS121-000',
  MESSAGECODE_MCAXS121_001: 'MCAXS121-001',
  MESSAGECODE_MCAXS121_002: 'MCAXS121-002',
  MESSAGECODE_MCAXS121_003: 'MCAXS121-003',
  MESSAGECODE_MCAXS121_004: 'MCAXS121-004',
  MESSAGECODE_MCAXS121_005: 'MCAXS121-005',
  MESSAGECODE_MCAXS121_006: 'MCAXS121-006',
  STATUS_00: '00',
  STATUS_10: '10',
  STATUS_90: '90',
  STATUS_99: '99',
  // MCAXS130
  MESSAGECODE_MCAXS130_000: 'MCAXS130-000',
  MESSAGECODE_MCAXS130_001: 'MCAXS130-001',
  MESSAGECODE_MCAXS130_002: 'MCAXS130-002',

  // MCAQS020
  MESSAGECODE_MCAQS020_003: 'MCAQS020-003',

  //MCAXS330
  MESSAGECODE_MCAXS330_003: 'MCAXS330-003',
  MESSAGECODE_MCAXS330_000: 'MCAXS330-000',
  MESSAGECODE_MCAXS330_002: 'MCAXS330-002',
  MESSAGECODE_MCAXS330_004: 'MCAXS330-004',
  MESSAGECODE_MCAXS330_005: 'MCAXS330-005',
  MESSAGECODE_MCAXS330_007: 'MCAXS330-007',
  // #58578 次期開発2022年5月 start
  MESSAGECODE_MCAXS330_008: 'MCAXS330-008',
  MESSAGECODE_MCAXS330_009: 'MCAXS330-009',
  MESSAGECODE_MCAXS660_001: "MCAXS660-001",
  // #58578 次期開発2022年5月 end
  //MCAPS010
  MESSAGECODE_MCAPS010_000: 'MCAPS010-000',
  MESSAGECODE_MCAPS010_001: 'MCAPS010-001',
  MESSAGECODE_MCAPS010_002: 'MCAPS010-002',
  MESSAGECODE_MCAPS010_003: 'MCAPS010-003',
  MESSAGECODE_MCAPS010_004: 'MCAPS010-004',
  MESSAGECODE_MCAPS010_005: 'MCAPS010-005',
  MESSAGECODE_MCAPS010_006: 'MCAPS010-006',
  MESSAGECODE_MCAPS010_007: 'MCAPS010-007',
  MESSAGECODE_MCAPS010_008: 'MCAPS010-008',
  MESSAGECODE_MCAPS010_009: 'MCAPS010-009',
  MESSAGECODE_MCAPS010_010: 'MCAPS010-010',
  MESSAGECODE_MCAPS010_011: 'MCAPS010-011',
  MESSAGECODE_MCAPS010_012: 'MCAPS010-012',
  MESSAGECODE_MCAPS010_013: 'MCAPS010-013',
  MESSAGECODE_MCAPS010_014: 'MCAPS010-014',
  //MCAPS020
  MCAPS020_SCREENID: 'MCAPS020',
  MESSAGECODE_MCAPS020_000: 'MCAPS020-000',
  MESSAGECODE_MCAPS020_004: 'MCAPS020-004',
  MCAPS020_UPLOAD_SPECIFICCONDITIONNAME:
    '応募者情報アップロードした応募者を表示する',
  MCAPS020_FILTERAUTO_SPECIFICCONDITIONNAME:
    '応募者情報アップロードで自動適用された応募者を表示する',
  MCAPS020_PROCESSINGRESULT_WAITING: '0',
  MCAPS020_PROCESSINGRESULT_PROCESSING: '1',
  MCAPS020_PROCESSINGRESULT_SUCCESS: '2',
  MCAPS020_PROCESSINGRESULT_FAILURE: '3',
  MCAPS020_PROCESSINGRESULT_PROCESSING_MESSAGE: '処理中',
  MCAPS020_PROCESSINGRESULT_SUCCESS_MESSAGE: '成功',
  MCAPS020_PROCESSINGRESULT_FAILURE_MESSAGE: '失敗',
  // エントリーアップロードエラー管理情報保持期間
  MCAPS020_ENTRY_UPLOAD_ERROR_MANAGEMENT_SAVE_DAY: 30,
  // 名寄せチェック情報保持期間
  MCAQS020_DUPLICATE_IDENTIFICATION_CHECK_SAVE_DAY: 30,
  // ロット3 contants End

  //MCAPS030
  MESSAGECODE_MCAPS030_000: 'MCAPS030-000',
  // 次期開発12月 #73504 start
  //MCAPS040
  MESSAGECODE_MCAPS040_000: 'MCAPS040-000',
  MESSAGECODE_MCAPS040_001: 'MCAPS040-001',
  // 次期開発12月 #73504 end

  //MCAXS030
  MESSAGECODE_MCAXS030_000: 'MCAXS030-000',
  MESSAGECODE_MCAXS030_001: 'MCAXS030-001',
  MESSAGECODE_MCAXS030_002: 'MCAXS030-002',
  MESSAGECODE_MCAXS030_003: 'MCAXS030-003',

  // MCBリプレース MCBHS120 START
  MESSAGECODE_MCBHS120_000: 'MCBHS120-000',
  MESSAGECODE_MCBHS120_001: 'MCBHS120-001',
  MESSAGECODE_MCBHS120_002: 'MCBHS120-002',
  MESSAGECODE_MCBHS120_003: 'MCBHS120-003',
  // MCBリプレース MCBHS120 END

  // MCAXS180 応募者情報の入力
  MESSAGECODE_MCAXS180_000: 'MCAXS180-000',

  // MCAXS191 応募者氏名入力
  MESSAGECODE_MCAXS191_000: 'MCAXS191-000',
  MESSAGECODE_MCAXS191_001: 'MCAXS191-001',
  MESSAGECODE_MCAXS191_002: 'MCAXS191-002',
  // #MCB1.5次開発 #9236 START
  MESSAGECODE_MCAXS191_003: 'MCAXS191-003',
  MESSAGECODE_MCAXS191_004: 'MCAXS191-004',
  // #MCB1.5次開発 #9236 END

  //MCAXS1G
  MESSAGECODE_MCAXS151_000: 'MCAXS151-000',
  //データダウンロード権限チェック 異常エラーコード
  MESSAGECODE_MCAXS151_001: 'MCAXS151-001',
  //閲覧権限チェック 異常エラーコード
  MESSAGECODE_MCAXS151_002: 'MCAXS151-002',
  //ステータスチェック 異常エラーコード
  MESSAGECODE_MCAXS151_003: 'MCAXS151-003',
  // ジョブ管理ステータス
  JOB_MANAGEMENT_STATUS_00: '00',
  JOB_MANAGEMENT_STATUS_10: '10',
  JOB_MANAGEMENT_STATUS_90: '90',
  JOB_MANAGEMENT_STATUS_99: '99',

  //MCAXS220
  MESSAGECODE_MCAXS220_000: 'MCAXS220-000',
  MESSAGECODE_MCAXS220_001: 'MCAXS220-001',
  MESSAGECODE_MCAXS220_002: 'MCAXS220-002',
  MESSAGECODE_MCAXS220_006: 'MCAXS220-006',
  MESSAGECODE_MCAXS220_007: 'MCAXS220-007',
  MESSAGECODE_MCAXS220_010: 'MCAXS220-010',
  MESSAGECODE_MCAXS220_011: 'MCAXS220-011',
  // MCBAS020
  MESSAGECODE_MCBAS020_001: 'MCBAS020-001',
  MESSAGECODE_MCBAS020_002: 'MCBAS020-002',
  MESSAGECODE_MCBAS020_003: 'MCBAS020-003',
  MESSAGECODE_MCBAS020_008: 'MCBAS020-008',
  MESSAGECODE_MCBAS020_009: 'MCBAS020-009',
  // WEB面接対応 start
  MESSAGECODE_MCBAS020_010: 'MCBAS020-010',
  MESSAGECODE_MCBAS020_011: 'MCBAS020-011',
  MESSAGECODE_MCBAS020_012: 'MCBAS020-012',
  MESSAGECODE_MCBAS020_013: 'MCBAS020-013',
  MESSAGECODE_MCBAS020_014: 'MCBAS020-014',
  MESSAGECODE_MCBAS020_015: 'MCBAS020-015',
  MESSAGECODE_MCBAS020_016: 'MCBAS020-016',
  MESSAGECODE_MCBAS020_017: 'MCBAS020-017',
  MESSAGECODE_MCBAS020_018: 'MCBAS020-018',
  MESSAGECODE_MCBAS020_019: 'MCBAS020-019',
  MESSAGECODE_MCBAS020_020: 'MCBAS020-020',
  MESSAGECODE_MCBAS020_021: 'MCBAS020-021',
  // 維持保守 #78462 start
  // 180分 
  ADDMINUTES: 180 ,
  // 維持保守 #78462 end
  // WEB面接対応 end

  // ボタンの情報-OK
  BUTTONMESSAGE_OK: 'OK',
  // ボタンの情報-キャンセル
  BUTTONMESSAGE_CANCEL: 'キャンセル',

  // 応募者情報編集
  MESSAGECODE_MCAXS230_000: 'MCAXS230-000',
  MESSAGECODE_MCAXS230_019: 'MCAXS230-019',
  // #MCB1.5次開発 #9236 START
  MESSAGECODE_MCAXS230_023: 'MCAXS230-023',
  MESSAGECODE_MCAXS230_024: 'MCAXS230-024',
  // #MCB1.5次開発 #9236 END
  //エントリー情報編集
  MESSAGECODE_MCAXS240_000: 'MCAXS240-000',
  MESSAGECODE_MCAXS240_001: 'MCAXS240-001',
  MESSAGECODE_MCAXS240_002: 'MCAXS240-002',
  MESSAGECODE_MCAXS240_003: 'MCAXS240-003',
  MESSAGECODE_MCAXS240_004: 'MCAXS240-004',
  MESSAGECODE_MCAXS240_005: 'MCAXS240-005',
  MESSAGECODE_MCAXS240_006: 'MCAXS240-006',
  MESSAGECODE_MCAXS240_007: 'MCAXS240-007',
  MESSAGECODE_MCAXS240_008: 'MCAXS240-008',

  // 応募者情報登録 内容確認
  MESSAGECODE_MCAXS211_000: 'MCAXS211-000',
  MESSAGECODE_MCAXS211_001: 'MCAXS211-001',
  MESSAGECODE_MCAXS211_002: 'MCAXS211-002',
  MESSAGECODE_MCAXS211_003: 'MCAXS211-003',
  MESSAGECODE_MCAXS211_004: 'MCAXS211-004',
  MESSAGECODE_MCAXS211_005: 'MCAXS211-005',
  MESSAGECODE_MCAXS211_006: 'MCAXS211-006',
  // 年度対応　start
  MESSAGECODE_MCAXS211_007: 'MCAXS211_007',
  // 年度対応　end
  EMPTY: '',
  BLANK: ' ',
  BLANK_ZENKAKU: '　',
  STUDENT_DIVISION: 'studentDivision',
  SCHOOL_COUNTRY_REGION_110: '110',
  SEX: 'sex',
  HAIFUN: '-',
  //次期9月対応 #63073 start
  FULLUN: '－',
  //次期9月対応 #63073 end
  FORWARD_SLASH: '/',
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  // 現住所と同じにチェック
  SAMEASCURRENTADDRESS_1: '1',
  LEFT_BKET: '(',
  RIGHT_BKET: ')',
  LEFT_BKE_ZENKAKUT: '（',
  RIGHT_BKET_ZENKAKU: '）',
  // 取得学位
  OVER_SEAS_SCHOOL_DEGREE: 'overseasSchoolDegree',
  // 国・地域
  OVER_SEAS_SCHOOL_COUNTRY_REGION: 'overseasSchoolCountryRegion',
  // 州
  OVER_SEAS_SCHOOL_STATE: 'overseasSchoolState',
  // 手動登録フラグ
  MANUAL_REGISTRATION_FLAG_1: '1',
  MANUAL_REGISTRATION_FLAG_2: '2',
  // 会員種別
  MEMBER_TYPE: 'memberType',

  // 応募者情報更新 内容確認
  MESSAGECODE_MCAXS650_000: 'MCAXS650-000',
  MESSAGECODE_MCAXS650_001: 'MCAXS650-001',
  MESSAGECODE_MCAXS650_002: 'MCAXS650-002',
  MESSAGECODE_MCAXS650_003: 'MCAXS650-003',
  MESSAGECODE_MCAXS650_004: 'MCAXS650-004',
  MESSAGECODE_MCAXS650_005: 'MCAXS650-005',
  MESSAGECODE_MCAXS650_006: 'MCAXS650-006',

  // MCAXS640
  MESSAGECODE_MCAXS640_000: 'MCAXS640-000',
  MESSAGECODE_MCAXS640_001: 'MCAXS640-001',
  MESSAGECODE_MCAXS640_002: 'MCAXS640-002',
  // 更新完了メッセージ
  EXCEPTIONCODE_MCAXS640_003: 'MCAXS640-003',
  MESSAGECODE_MCAXS640_004: 'MCAXS640-004',

  // MCAXS630
  MESSAGECODE_MCAXS630_000: 'MCAXS630-000',
  MESSAGECODE_MCAXS630_001: 'MCAXS630-001',
  MESSAGECODE_MCAXS630_005: 'MCAXS630-005',

  // 応募者情報登録
  MESSAGECODE_MCAXS201_000: 'MCAXS201-000',
  MESSAGECODE_MCAXS201_002: 'MCAXS201-002',
  MESSAGECODE_MCAXS201_003: 'MCAXS201-003',
  MESSAGECODE_MCAXS201_020: 'MCAXS201-020',
  MESSAGECODE_MCAXS201_022: 'MCAXS201-022',
  MESSAGECODE_MCAXS201_024: 'MCAXS201-024',
  OVERSEAS_MESSAGE_0:
    '※海外の住所を入力される場合、都道府県に【海外】を選択してください。',
  OVERSEAS_MESSAGE_1: '　郵便番号の入力は不要です。',
  OVERSEAS_MESSAGE_2:
    '※住所の先頭に括弧書きで、居住国名を日本語表記で明記してください。',
  OVERSEAS_MESSAGE_3:
    '　例）（アメリカ）1600 Pennsylvania Avenue NW Washington, DC 20500 U.S.A',

  SPRING: '年春',
  AUTUMN: '年秋',
  MCAXS201_NOTICETEXT_0: '■',
  MCAXS201_NOTICETEXT_1: 'ご注意：',
  MCAXS201_NOTICETEXT_2:
    '姓名が同⼀な応募者情報がすでに登録されており、重複登録の可能性があります。',
  MCAXS201_NOTICETEXT_3: 'ご確認下さい。',
  MCAXS201_NOTICETEXT_4:
    'なお、統括権限、管理者権限および準管理者権限のない採用担当者は、所属する選考フロー以外の',
  MCAXS201_NOTICETEXT_5: '応募者情報の参照および更新ができません。',
  MCAXS201_NOTICETEXT_6: '該当する応募者管理ID（クリックで参照できます）：',
  HALF_SPACE: ' ',
  MCAXS201: 'MCAXS201', // MCAXS201画面ID
  MCAXS191: 'MCAXS191', // MCAXS191画面ID
  // MCAQS040
  MESSAGECODE_MCAQS040_000: 'MCAQS040-000',
  // アカウント権限チェック 異常エラーコード
  MESSAGECODE_MCAQS040_001: 'MCAQS040-001',
  // アカウント権限チェック
  MESSAGECODE_MCAQS040_002: 'MCAQS040-002',
  // 実行確認チェック
  MESSAGECODE_MCAQS040_003: 'MCAQS040-003',
  // 実行確認チェック
  MESSAGECODE_MCAQS040_004: 'MCAQS040-004',
  // 応募者情報名寄せ完了メッセージ
  MESSAGECODE_MCAQS040_005: 'MCAQS040-005',
  // 25KH #74726 start
  MESSAGECODE_MCAQS040_007: 'MCAQS040-007',
  // 25KH #74726 end
  // 郵便番号必須チェック
  MESSAGECODE_MCAXS230_002: 'MCAXS230-002',
  MESSAGECODE_MCAXS230_003: 'MCAXS230-003',

  // MCAQS030
  MESSAGECODE_MCAQS030_000: 'MCAQS030-000',
  MESSAGECODE_MCAQS030_001: 'MCAQS030-001',
  MESSAGECODE_MCAQS030_002: 'MCAQS030-002',
  MESSAGECODE_MCAQS030_003: 'MCAQS030-003',
  MESSAGECODE_MCAQS030_004: 'MCAQS030-004',
  MESSAGECODE_MCAQS030_005: 'MCAQS030-005',
  MESSAGECODE_MCAQS030_006: 'MCAQS030-006',
  MESSAGECODE_MCAQS030_007: 'MCAQS030-007',
  MESSAGECODE_MCAQS030_007_1: 'MCAQS030-007-1',
  MESSAGECODE_MCAQS030_007_2: 'MCAQS030-007-2',
  MESSAGECODE_MCAQS030_007_3: 'MCAQS030-007-3',
  MESSAGECODE_MCAQS030_007_4: 'MCAQS030-007-4',

  // 学生区分
  //   [2]大学院
  STUDENT_DIVISION_2: '2',
  // [3]大学
  STUDENT_DIVISION_3: '3',
  // [4]短大
  STUDENT_DIVISION_4: '4',
  // [5]高専
  STUDENT_DIVISION_5: '5',
  // [6]専門学校
  STUDENT_DIVISION_6: '6',
  // [8]海外の学校
  STUDENT_DIVISION_8: '8',
  // [9]その他
  STUDENT_DIVISION_9: '9',

  STRING_0: '0',
  STRING_1: '1',
  STRING_2: '2',
  STRING_3: '3',
  STRING_4: '4',
  STRING_5: '5',
  STRING_6: '6',
  STRING_7: '7',
  STRING_8: '8',
  STRING_9: '9',
  STRING_10: '10',
  STRING_20: '20',
  STRING_30: '30',
  STRING_40: '40',
  STRING_90: '90',

  // NONE
  NONE: '該当なし',

  // 郵便番号_海外
  PREFECTURES_OFFLINE: '99',
  PREFECTURES_DEFAULT: '9999999',
  MESSAGECODE_HUMANITIES_AND_SCIENCES_DIVISION: 'humanitiesAndSciencesDivision',
  MESSAGECODE_SUBJECT_CLASSIFICATION: 'vocationalSchoolMajor',
  MESSAGECODE_MCAXS230_018: 'MCAXS230-018',
  MESSAGECODE_FULL_SPACE: '　',
  MESSAGECODE_MCAXS230_020: 'MCAXS230-020',
  SUGGEST_NO_OPTIONS_MESSAGE: '一致する候補が見つかりません',
  SUGGEST_ALREADY_INPUT: 'は既に入力されています',
  //MCAXS260
  MESSAGECODE_MCAXS260_003: 'MCAXS260-003',
  MESSAGECODE_MCAXS260_004: 'MCAXS260-004',
  MESSAGECODE_MCAXS260_005: 'MCAXS260-005',
  MESSAGECODE_MCAXS260_006: 'MCAXS260-006',
  MESSAGECODE_MCAXS260_007: 'MCAXS260-007',
  MESSAGECODE_MCAXS260_035: 'MCAXS260-035',
  MESSAGECODE_MCAXS260_012: 'MCAXS260-012',
  MESSAGECODE_MCAXS260_014_1: 'MCAXS260-014-1',
  MESSAGECODE_MCAXS260_014_2: 'MCAXS260-014-2',
  MESSAGECODE_MCAXS260_026: 'MCAXS260-026',
  MESSAGECODE_MCAXS260_030: 'MCAXS260-030',
  MESSAGECODE_MCAXS260_031: 'MCAXS260-031',
  MESSAGECODE_MCAXS260_032: 'MCAXS260-032',
  MESSAGECODE_MCAXS260_033: 'MCAXS260-033',
  // 年度対応　MCAXS260 start
  MESSAGECODE_MCAXS260_036: 'MCAXS260-036',
  // 年度対応　MCAXS260 end
  MESSAGECODE_MCAXS260_046: 'MCAXS260-046',
  // MCAXS141
  // その他を一括更新、「内定者一覧」「応募者検索一覧」「エントリー一覧」
  PREVSCREENID_MCAXS260: 'MCAXS260',
  //　応募者情報詳細（転職）
  PREVSCREENID_MCAXS620: 'MCAXS620',
  //　応募者情報詳細（就職）
  PREVSCREENID_MCAXS040: 'MCAXS040',
  //　面接日程調整一覧
  PREVSCREENID_MCBAS020: 'MCBAS020',
  //　エントリー情報詳細
  PREVSCREENID_MCAXS050: 'MCAXS050',
  //　GET_METHOD_URL
  MCAXS141_GET_METHOD_URL: 'MCAXS141_GET_METHOD_URL',

  /** 選択した応募者データは面接日程調整中のものがあります。応募者を選択しなおしてください。*/
  MESSAGECODE_MCAXS050_002: 'MCAXS050-002',
  /** 対象外にしてもよろしいですか？\n対象外にすると選考中一覧から対象外一覧へと移動されます。メッセージ送信予約をされている場合は、そのまま配信されます。 */
  MESSAGECODE_MCAXS050_003: 'MCAXS050-003',
  /** 対象外から戻してもよろしいですか？ */
  MESSAGECODE_MCAXS050_004: 'MCAXS050-004',
  /** 表示可能なデータがありません */
  MESSAGECODE_MCAXS050_005: 'MCAXS050-005',
  /** 質問は選考変更できません。 */
  MESSAGECODE_MCAXS050_006: 'MCAXS050-006',
  MESSAGECODE_MCAXS050_007: 'MCAXS050-007',
  MESSAGECODE_MCAXS050_008: 'MCAXS050-008',
  MESSAGECODE_MCAXS050_009: 'MCAXS050-009',
  MESSAGECODE_MCAXS050_010: 'MCAXS050-010',
  /** ワンクリック不採用通知送信不可エラー */
  MESSAGECODE_MCAXS050_016: 'MCAXS050-016',
  MESSAGECODE_MCAXS050_017: 'MCAXS050-017',
  MESSAGECODE_MCAXS050_018: 'MCAXS050-018',
  /** 選択した応募者データは日程調整中のものがあります。応募者を選択しなおして下さい。 */
  MESSAGECODE_MCAXS620_002: 'MCAXS620-002',
  /** 対象外にしてもよろしいですか？\n対象外にすると選考中一覧から対象外一覧へと移動されます。メッセージ送信予約をされている場合は、そのまま配信されます。 */
  MESSAGECODE_MCAXS620_003: 'MCAXS620-003',
  /** 対象外から戻してもよろしいですか？ */
  MESSAGECODE_MCAXS620_004: 'MCAXS620-004',
  /** 表示可能なデータがありません */
  MESSAGECODE_MCAXS620_005: 'MCAXS620-005',
  /** 質問は選考変更できません。 */
  MESSAGECODE_MCAXS620_006: 'MCAXS620-006',

  // MCAXS010
  MCAXS010_SCREENID: 'MCAXS010',
  MESSAGECODE_MCAXS010_002: 'MCAXS010-002',
  MESSAGECODE_MCAXS010_003: 'MCAXS010-003',
  MESSAGECODE_MCAXS010_004: 'MCAXS010-004',
  MESSAGECODE_MCAXS010_005: 'MCAXS010-005',

  MESSAGECODE_MCAXS020_002: 'MCAXS020-002',
  MESSAGECODE_MCAXS020_003: 'MCAXS020-003',
  MESSAGECODE_MCAXS020_004: 'MCAXS020-004',
  MESSAGECODE_MCAXS020_005: 'MCAXS020-005',
  MESSAGECODE_MCAXS020_008: 'MCAXS020-008',
  MESSAGECODE_MCAXS020_009: 'MCAXS020-009',

  // 検索条件詳細 (就職)
  // MCAXS351　本体
  MCAXS351_SCREENID: 'MCAXS351',
  MESSAGECODE_MCAXS351_001: 'MCAXS351-001',
  MESSAGECODE_MCAXS351_002: 'MCAXS351-002',
  MESSAGECODE_MCAXS351_003: 'MCAXS351-003',
  MESSAGECODE_MCAXS351_004: 'MCAXS351-004',
  MESSAGECODE_MCAXS351_005: 'MCAXS351-005',
  // MCBリプレース MCAXS701 START
  MESSAGECODE_MCAXS351_006: 'MCAXS351-006',
  MESSAGECODE_MCAXS351_007: 'MCAXS351-007',
  // MCBリプレース MCAXS701 END
  MESSAGECODE_MCAXS351_002_MAXCOUNT: 10000,
  MESSAGECODE_MCAXS351_003_MAXCOUNT: 100,

  // MCAXS361　応募情報
  MESSAGECODE_MCAXS361_001: 'MCAXS361-001',
  MESSAGECODE_MCAXS361_002: 'MCAXS361-002',

  // MCAXS371　学校情報
  MESSAGECODE_MCAXS371_001: 'MCAXS371-001',
  MESSAGECODE_MCAXS371_002: 'MCAXS371-002',

  // MCAXS371　基本情報
  MESSAGECODE_MCAXS381_001: 'MCAXS381-001',
  MESSAGECODE_MCAXS381_002: 'MCAXS381-002',
  MESSAGECODE_MCAXS381_003: 'MCAXS381-003',
  MESSAGECODE_MCAXS381_004: 'MCAXS381-004',
  MESSAGECODE_MCAXS381_005: 'MCAXS381-005',

  // MCAXS391　採用管理情報
  MESSAGECODE_MCAXS391_001: 'MCAXS391-001',
  MESSAGECODE_MCAXS391_002: 'MCAXS391-002',
  MESSAGECODE_MCAXS391_003: 'MCAXS391-003',

  // 検索条件詳細 (転職)
  // MCAXS401　本体
  MESSAGECODE_MCAXS401_001_MAXCOUNT: 10000,
  MESSAGECODE_MCAXS401_002_MAXCOUNT: 100,
  MESSAGECODE_MCAXS401_001: 'MCAXS401-001',
  MESSAGECODE_MCAXS401_002: 'MCAXS401-002',

  // MCAXS411 応募情報
  // なし

  // MCAXS421 基本情報
  MESSAGECODE_MCAXS421_001: 'MCAXS421-001',
  MESSAGECODE_MCAXS421_002: 'MCAXS421-002',

  // MCAXS431 採用管理情報
  // なし

  // MCAXS521 都道府県モーダル
  MESSAGECODE_MCAXS521_001: 'MCAXS521-001',

  // MCAXS561 アンケート回答内容指定モーダル
  MESSAGECODE_MCAXS561_001: 'キャンセルすると指定した条件がクリアされますが、よろしいでしょうか。',

  // MCAXS501 管理項目指定モーダル
  MESSAGECODE_MCAXS501_001: 'MCAXS501-001',
  MCAXS501_MAXLENGTH_POSITVE_NUMBER: 11,
  MCAXS501_MAXLENGTH_NEGATIVE_NUMBER: 12,
  MCAXS501_NUMBERR_0: 0,
  MCAXS501_MAXVALUE: 99999999.99,
  MCAXS501_MINVALUE: -99999999.99,

  /** 選択した応募者データは面接日程調整中のものがあります。応募者を選択しなおしてください。 */
  MESSAGECODE_MCAXS040_002: 'MCAXS040-002',
  /** 対象外にしてもよろしいですか？\n対象外にすると全件一覧から対象外一覧へと移動されます。メッセージ送信予約をされている場合は、そのまま配信されます。 */
  MESSAGECODE_MCAXS040_003: 'MCAXS040-003',
  /** 対象外から戻してもよろしいですか？ */
  MESSAGECODE_MCAXS040_004: 'MCAXS040-004',
  /** 表示可能なデータがありません */
  MESSAGECODE_MCAXS040_005: 'MCAXS040-005',
  /** 強制停止された提出リクエストは[操作]できません。 */
  MESSAGECODE_MCAXS040_007: 'MCAXS040-007',
  /** 提出状況が「提出済」以外の提出リクエストはPDF出力できません。 */
  MESSAGECODE_MCAXS040_008: 'MCAXS040-008',
  /** 「送信予約」「強制停止」「無効の提出リクエスト」が含まれているため、[操作]できません。 */
  MESSAGECODE_MCAXS040_009: 'MCAXS040-009',
  /** 応募者がMy CareerBoxを退会しているため[操作]できません。 */
  MESSAGECODE_MCAXS040_010: 'MCAXS040-010',
  // 離脱チェックListenパス
  BACK_PATH: '',

  // タグ付け
  /* 権限のないデータが存在するため、タグを設定できません。再度検索し、実施してください。 */
  MESSAGECODE_MCAXS170_002: 'MCAXS170-002',
  /* 入力した内容を設定します。よろしいですか？ */
  MESSAGECODE_MCAXS170_003: 'MCAXS170-003',
  /* キャンセルします。よろしいですか？ */
  MESSAGECODE_MCAXS170_004: 'MCAXS170-004',
  /* キャンセルします。よろしいですか？ */
  MESSAGECODE_MCAXS170_005: 'MCAXS170-005',
  /* 一括で登録できるタグの上限個数は10個のため登録できません。 */
  MESSAGECODE_MCAXS170_006: 'MCAXS170-006',
  /* タグ名は30文字以内で入力してください */
  MESSAGECODE_MCAXS170_007: 'MCAXS170-007',
  /* 年度対応 start */
  /* タグ名は30文字以内で入力してください */
  MESSAGECODE_MCAXS170_007_1: 'MCAXS170-007-1',
  /* 年度対応 end */

  // MCAZS080
  DESTINATION_DIVISION_MYNAVI_ONLY: '0', //送信先：マイナビのみ
  DESTINATION_DIVISION_MYNAVI_PREFERRED: '1', //送信先：マイナビ優先
  DESTINATION_DIVISION_EMAIL_ONLY: '2', //送信先：e-mailのみ
  // MCBリプレース START
  DESTINATION_DIVISION_MYCAREERBOX: '3', //送信先：My CareerBox
  // MCBリプレース END
  BARCODE_DISPLAY_FLAG_SHOW: '1', //バーコード表示：表示する
  BARCODE_DISPLAY_FLAG_NOT_SHOW: '0', //バーコード表示：表示しない
  NOTIFICATION_NOT_DESIRED: '0', //お知らせを希望しない
  NOTIFICATION_DESIRED: '1', //お知らせを希望する
  SEND_TIME_FLAG_IMMEDIATE: '0', //送信日時フラグ：即時送信
  SEND_TIME_FLAG_RESERVATION: '1', //送信日時フラグ：タイマー送信
  REPLY_PERMIT_FLAG_PERMIT: '1', //返信許可フラグ：許可する
  REPLY_PERMIT_FLAG_DISALLOW: '0', //返信許可フラグ：許可しない
  REPLY_NECESSARY_FLAG_PERMIT: '1', //要返信フラグ：要返信する
  REPLY_NECESSARY_FLAG_DISALLOW: '0', //要返信フラグ：要返信しない
  MESSAGE_TYPE_INTERVIEW_SCHEDULE: '03', //メッセージ種別：[03]日程調整
  MESSAGE_TYPE_NUMBER_ANONYMOUS: '31', //メッセージ種別：[31]匿名
  MEMBERS_TYPE_MEMBER: '1', //会員種別：会員
  MEMBERS_TYPE_NON_MEMBER: '5', //会員種別：非会員
  MEMBERS_TYPE_DEACTIVATED: '9', //会員種別：退会済み
  // MCBリプレース START
  MEMBERS_TYPE_MCB: '7', //会員種別：MCB会員
  // MCBリプレース END
  ENTRY_TYPE_NORMAL: '11', //エントリー種別：[11]通常応募
  ENTRY_TYPE_SCOUT: '12', //エントリー種別：[12]スカウト応募
  ENTRY_TYPE_SEMINAR_ENTRY: '13', //エントリー種別：[13]セミナー・面談参加申込み
  ENTRY_TYPE_QUESTION: '14', //エントリー種別：[14]質問（お問い合わせ）
  ENTRY_TYPE_ANONYMOUS_QUESTION: '15', //エントリー種別：[15]匿名質問
  ENTRY_TYPE_OLD_EVENT: '16', //エントリー種別：[16]旧イベント応募
  ENTRY_TYPE_EVENT_SIT_DOWN: '17', //エントリー種別：[17]イベント着席
  ENTRY_TYPE_EVENT: '18', //エントリー種別：[18]イベント応募
  ENTRY_TYPE_OTHER_ROUTE: '19', //エントリー種別：[19]他ルート応募
  SELECTION_CLASSIFICATION_SEMINAR_APPLICATION: '40', //選考分類：[40]セミナー申込
  SELECTION_CLASSIFICATION_QUESTION: '50', //選考分類：[50]質問
  SELECTION_CLASSIFICATION_EVENT_SIT_DOWN: '60', //選考分類：[60]イベント着席
  ACTION_CHANGE_POSSIBLE: 'change_possible_date', //候補日を変更する
  ACTION_FIX_INTERVIEW: 'fix_interview_date', //面接日を確定する
  ACTION_CHANGE_INTERVIEW: 'change_interview_date', //面接日を変更する
  ACTION_WITHDRAW_INTERVIEW: 'withdraw_interview_date', //面接日を取り下げる
  ACTION_READJUST_INTERVIEW: 'readjust_interview_date', //面接日を再調整する
  SELECTION_NUMBER_NOT_PRESENT: 0, //面接候補日を提示しない
  SELECTION_NUMBER_PRESENT: 1, //面接候補日を提示する
  SELECTION_NUMBER_FIX: 2, //応募者の希望日から確定する
  SELECTION_NUMBER_OTHER: 3, //他の面接候補日を提示する
  SELECTION_NUMBER_SEND: 4, //確定した面接日を送る
  INTERVIEW_LOCATION_TYPE_SYSTEM_FIXED: '0', //面接場所種別：システム固定
  INTERVIEW_LOCATION_AND_DURATION_OTHER_ANNOTATION: 'その他　応募者側には「メッセージ本文をご確認ください。」と表示されます。', //面接場所、面接所要時間「その他」の説明文
  INTERVIEW_DURATION_ID_OTHER: '999', //面接所要時間：その他
  INTERVIEW_FIX_SUBJECT: '[面接内容]確定 yyyy/MM/dd (曜日) hh:mm～（所要時間：約mm分）',
  REPLY_PREFIX_SUBJECT: 'Re: ',
  REPLY_PREFIX_BODY: '> ',
  MESSAGE_ATTACHMENT_MAX_SIZE: 1024 * 1024 * 10,
  RESERVATION_MAX_DATE_LENGTH: 30,
  RESERVATION_MIN_MINUTES_LENGTH: 15,
  // MCBリプレース START
  SUBMISSION_REQUEST_RESERVATION_MAX_DATE_LENGTH: 30,
  SUBMISSION_REQUEST_RESERVATION_MIN_MINUTES_LENGTH: 60,
  // MCBリプレース END
  DURATION_ACTUAL_TIME_MINUTE_OTHER: 60, //所要時間その他選択時の値（分）
  ZS080_TIMEINTERVALS: 5,　//面接日時の分単位
  SEND_TO_NON_MEMBER: 0, //マイナビ退会者を発信者に含む
  SEND_TO_NON_MEMBER_ONE: 1,//マイナビ退会者を発信者に含む
  CONVERT_TO_STARTTIME: '【開催日時】', //開催日時
  CONVERT_TO_REMARKS: '【備　　考】',  //備考
  INTERVIEWDATE_MAX_DATE_LENGTH: 90,
  INTERVIEW_WEB_URL_SEND_FLAG: '1', // WEB面接URL送付フラグ  WEB面接対応

  MESSAGECODE_MCAZS080_063: 'MCAZS080-063',
  MESSAGECODE_MCAZS080_064: 'MCAZS080-064',
  MESSAGECODE_MCAZS080_065: 'MCAZS080-065',
  MESSAGECODE_MCAZS080_071: 'MCAZS080-071',

  // MCAZS091　メッセージ送信
  MESSAGECODE_MCAZS091_065: 'MCAZS091-065',
  MESSAGECODE_MCAZS161_002: 'MCAZS161-002',
  PROGRESS_TYPE_NOT_ADOPTED: '7', //進捗種別：不採用
  IMMUNITY_FLAG_NOT_IMMUNITY: '0', //書類選考免除フラグ：該当しない
  IMMUNITY_FLAG_IMMUNITY: '1', //書類選考免除フラグ：該当する
  DECISION_DIVISION_PASSING: '3', //判定区分：[3]合格
  DECISION_DIVISION_FAILURE: '5', //判定区分：[5]不合格
  TEMPLATE_TYPE_GENERAL: '0', //メッセージテンプレート種別：[0]汎用
  TEMPLATE_TYPE_PASSING_NOTIFICATION: '1', //メッセージテンプレート種別：[1]合格通知
  TEMPLATE_TYPE_NOT_ADOPTED_NOTIFICATION: '2', //メッセージテンプレート種別：[2]不採用通知

  MESSAGECODE_MCAXS471_001:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS471_002:
    '「いずれかのイベント／各イベントごと」のどちらかを選択してください。',
  MESSAGECODE_MCAXS481_001:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS481_002:
    '「いずれかの日程／各受付ごと」のどちらかを選択してください。',
  // 26P #80786 start
  MESSAGECODE_MCAXS481_003:
    '「開催後の日程を非表示にする」をチェックした場合でも、事前に選択した過去日程の選択状態は保持されます。',
  // 26P #80786 end
  MESSAGECODE_MCAXS491_001:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS511_001:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS531_001:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS541_001:
    '特定のメッセージで検索にて選択した検索条件がクリアされます。よろしいですか？',
  MESSAGECODE_MCAXS541_002:
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',

  MCAXS541_LEADER_LENGTH: 21,

  MESSAGEINFO_LOADING: 'ただいま読込中',

  MESSAGEINFO_NEW_REGULATIONS_ADD: 'を新規追加',

  PROGRESS_TYPE_EXCLUDE: '9', // 選考ステップタイプ：対象外

  MESSAGECODE_MCAXS090_009: 'MCAXS090-009',

  // MCAMS020
  MESSAGECODE_MCAMS020_025: 'MCAMS020-025',

  // MCAMS030
  MESSAGECODE_MCAMS030_028: 'MCAMS030-028',

  // MCAMS040
  MESSAGECODE_MCAMS040_024: 'MCAMS040-024',

  // [phase2] start
  // MCBCS030
  MESSAGECODE_MCBCS030_000: 'MCBCS030-000',
  MESSAGECODE_MCBCS030_003: 'MCBCS030-003',
  MCBCS030_HETO: '-',
  // MCBCS040
  MESSAGECODE_MCBCS040_000: 'MCBCS040-000',
  MESSAGECODE_MCBCS040_001: 'MCBCS040-001',
  MESSAGECODE_MCBCS040_002: 'MCBCS040-002',

  // MCAXS300
  MESSAGECODE_MCAXS300_000: 'MCAXS300-000',
  MESSAGECODE_MCAXS300_001: 'MCAXS300-001',
  MESSAGECODE_MCAXS300_002: 'MCAXS300-002',
  MESSAGECODE_MCAXS300_003: 'MCAXS300-003',
  MESSAGECODE_MCAXS300_004: 'MCAXS300-004',
  MESSAGECODE_MCAXS300_005: 'MCAXS300-005',
  MESSAGECODE_MCAXS300_006: 'MCAXS300-006',
  MESSAGECODE_MCAXS300_007: 'MCAXS300-007',
  MESSAGECODE_MCAXS300_008: 'MCAXS300-008',
  MESSAGECODE_MCAXS300_009: 'MCAXS300-009',
  MESSAGECODE_MCAXS300_100: 'MCAXS300-100',
  MESSAGECODE_MCAXS300_101: 'MCAXS300-101',
  MESSAGECODE_MCAXS300_102: 'MCAXS300-102',
  MESSAGECODE_PHOTO_SIZE: 10485760,
  // 年度対応 start
  MESSAGECODE_MCAXS300_0012: 'MCAXS300-0012',
  // 年度対応 end

  // MCBCS010
  MESSAGECODE_MCBCS010_001: 'MCBCS010-001',
  MESSAGECODE_MCBCS010_002: 'MCBCS010-002',
  MESSAGECODE_MCBCS010_003: 'MCBCS010-003',
  MESSAGECODE_MCBCS010_004: 'MCBCS010-004',
  MESSAGECODE_MCBCS010_005: 'MCBCS010-005',
  MESSAGECODE_MCBCS010_006: 'MCBCS010-006',
  MESSAGECODE_MCBCS010_007: 'MCBCS010-007',
  MESSAGECODE_MCBCS010_008: 'MCBCS010-008',
  MESSAGECODE_MCBCS010_010: 'MCBCS010-009',
  MESSAGECODE_MCBCS010_011: 'MCBCS010-010',
  MESSAGECODE_MCBCS010_012: 'MCBCS010-011',
  MESSAGECODE_MCBCS010_100: 'MCBCS010-100',
  MESSAGECODE_MCBCS010_101: 'MCBCS010-101',
  MESSAGECODE_MCBCS010_102: 'MCBCS010-102',
  MESSAGECODE_MCBCS010_103: 'MCBCS010-103',

  // MCAXS660
  MESSAGECODE_MCAXS660_000: "MCAXS660-000",

  // MCBCS020
  MESSAGECODE_MCBCS020_100: 'MCBCS020-100',

  // MCAXS260
  MESSAGECODE_MCAXS260_028: 'MCAXS260-028',
  // MCAVS010
  MESSAGE_MCAVS010_005: '大きな数字は選考フローが付与された件数、（）内の数は選考フローが付与された人数です。前回ログアウトからの数が表示されます。（アカウントごとに異なります）',
  MESSAGE_MCAVS010_006: '未既読は企業単位のため、本アカウントで既読になると他のアカウントでも既読となります。（未読件数より減算されます）',
  // 年度対応 start
  MESSAGECODE_MCAVS010_002: 'MCAVS010_002',
  MESSAGECODE_MCAVS010_003: 'MCAVS010_003',
  MESSAGECODE_MCAVS010_004: 'MCAVS010_004',
  MESSAGECODE_MCAVS010_0099: 'MCAVS010_0099',
  // 年度対応 end
  // 次期9月対応 #58811 start
  MESSAGE_MCAVS010_009: 'MCAVS010_009',
  // 次期9月対応 #58811 end
  // 次期5月対応 #69958 start
  CHECK_MCAVS010_MAXROWS: 3,
  // 次期5月対応 #69958 end
  // 26P #74725 start
  MESSAGECODE_MCAVS010_011: 'MCAVS010-011',
  MESSAGECODE_MCAVS010_012: 'MCAVS010-012',
  // 26P #74725 end

  // MCBリプレース START
  USE_OF_MESSAGE_TYPE_NORMAL: '0',
  USE_OF_MESSAGE_TYPE_MCB: '1',
  // MCBリプレース END
  // [phase2] end

  // WEB面接対応 START
  MESSAGE_MCAVS010_007: 'WEB面接残枠を取得することができませんでした。',
  MESSAGE_MCAVS010_008: '※担当者用のWEB面接URLは同一URLで最大5名まで同時にご利用が可能です。\n※応募者用WEB面接URLは1名のみ利用可能です。',
  // WEB面接対応 END
  MESSAGECODE_401: '401',

  Correlation_Item_Check_Error_Message: 'Correlation Item Check Error Message',

  //new-graduate url
  NEW_GRADUATE_URL: 'NEW_GRADUATE_URL',
  //career url
  CAREER_URL: 'CAREER_URL',
  //w3 url
  W3_URL: 'W3_URL',
  //adobe url
  ADOBE_URL: 'ADOBE_URL',
  // MIWS Web操作マニュアル rul
  OPER_MANUAL_RUL: 'OPER_MANUAL_RUL',
  // MIWS Web操作マニュアル rul(不採用通知)
  OPER_MANUAL_RUL_385: 'OPER_MANUAL_RUL_385',
  // MCBリプレース MCBHS161 START
  // オリジナル設問モーダルURL(MCB側)
  ORIGIN_MODAL_URL: 'ORIGIN_MODAL_URL',
  // ドメイン
  MCB_COMMON_URL: 'MCB_COMMON_URL',
  // MCBリプレース MCBHS161 END
  // MCBリプレース MCBLS020 START
  MCBLS020_GET_METHOD_URL: 'MCBLS020_GET_METHOD_URL',
  // MCBリプレース MCBLS020 END
  // MCBリプレース MCBHS190 START
  // MCBPDF出力マニュアルURL
  MCB_PDF_OUTPUT_MANUAL_URL: 'MCB_PDF_OUTPUT_MANUAL_URL',
  // MCBリプレース MCBHS190 END
  // MCAVS010
  SCREEN_ID_MCAVS010: 'MCAVS010',
  //メッセージテンプレート
  MESSAGETEMPLATE: 'メッセージテンプレート',
  // マイナビ添付オプション
  Mynavi_Attached_Option: 'マイナビ添付オプション',

  // 進捗種別
  PROGRESS_TYPE_INTERVIEW: "0", // 応募
  PROGRESS_TYPE_CUSTOM: "1", // カスタム
  PROGRESS_TYPE_INFIX: "2", // 内定
  PROGRESS_TYPE_INTERNSHIP: "3", // インターンシップ参加
  PROGRESS_TYPE_CONSENT: "4", // 内定承諾
  PROGRESS_TYPE_ENTRY: "5", // 入社
  PROGRESS_TYPE_NOPASS: "6", // 不合格
  PROGRESS_TYPE_NOADOPTION: "7", // 不採用
  PROGRESS_TYPE_DISMISS: "8", // 辞退
  PROGRESS_TYPE_OUTSIGHT: "9", // 対象外

  SELECTION_FLOW_SELECT_ALL_NAME: '選考ステップ×合否を全て選択',
  EXCEPT_NAME: '、を除く',
  MESSAGECODE_MCAXS691_000: 'MCAXS691-000',
  MESSAGECODE_MCAXS691_001: 'MCAXS691-001',
  MESSAGECODE_MCAXS691_002: 'MCAXS691-002',
  MCAXS691_LABEL_LENGTH: 40,
  // 25KH #75311 start
  SELECTION_INCLUDE_BEFORE_CHANGE: '過去の履歴を含む選考段階 選考変更前を含む',
  SELECTION_NOT_INCLUDE_BEFORE_CHANGE: '過去の履歴を含む選考段階 選考変更前を含まない',
  // 25KH #75311 end
  MESSAGECODE_MCABS010_001: "※マイナビ関連サイト（就職情報サイトマイナビ、マイナビ転職）で作成されたアカウントのため、MIWSでパスワード変更はできません。\n　ご希望の場合は、作成元のサイトにてパスワード変更をお願いします。",

  // 年度対応 start
  MESSAGECODE_MCAAS010_COMMON_001: 'MCAAS010-COMMON-001',
  MESSAGECODE_MCAAS010_COMMON_002: 'MCAAS010-COMMON-002',
  MESSAGECODE_MCAAS010_COMMON_003: 'MCAAS010-COMMON-003',
  MESSAGECODE_MCAAS010_COMMON_004: 'MCAAS010-COMMON-004',
  MESSAGECODE_MCAAS010_COMMON_005: 'MCAAS010-COMMON-005',
  MESSAGECODE_MCAAS010_COMMON_006: 'MCAAS010-COMMON-006',
  MESSAGECODE_MCAAS010_COMMON_007: 'MCAAS010-COMMON-007',
  MESSAGECODE_MCAAS010_COMMON_008: 'MCAAS010-COMMON-008',
  MESSAGECODE_MCAAS010_002: 'MCAAS010-002',
  // 年度対応 end

  // MCBリプレース MCBHS040 START
  MESSAGECODE_MCBHS040_001: 'MCBHS040-001',
  MESSAGECODE_MCBHS040_002: 'MCBHS040-002',
  MESSAGECODE_MCBHS040_003: 'MCBHS040-003',
  MESSAGECODE_MCBHS040_004: 'MCBHS040-004',
  // MCBリプレース MCBHS040 END

  // MCBリプレース MCBHS010 START
  MESSAGECODE_MCBHS010_029: 'MCBHS010-029',
  MESSAGECODE_MCBHS010_030: 'MCBHS010-030',
  MESSAGECODE_MCBHS010_033: 'MCBHS010-033',
  MESSAGECODE_MCBHS010_034: 'MCBHS010-034',
  MESSAGECODE_MCBHS010_036: 'MCBHS010-036',
  MESSAGECODE_MCBHS010_037: 'MCBHS010-037',
  MESSAGECODE_MCBHS010_038: 'MCBHS010-038',
  MESSAGECODE_MCBHS010_039: 'MCBHS010-039',
  MESSAGECODE_MCBHS010_040: 'MCBHS010-040',
  MESSAGECODE_MCBHS010_041: 'MCBHS010-041',
  MESSAGECODE_MCBHS010_042: 'MCBHS010-042',
  MESSAGECODE_MCBHS010_043: 'MCBHS010-043',
  MESSAGECODE_MCBHS010_044: 'MCBHS010-044',
  MESSAGECODE_MCBHS010_045: 'MCBHS010-045',
  MESSAGECODE_MCBHS010_046: 'MCBHS010-046',
  MESSAGECODE_MCBHS010_047: 'MCBHS010-047',
  MESSAGECODE_MCBHS010_048: 'MCBHS010-048',
  MESSAGECODE_MCBHS010_049: 'MCBHS010-049',
  MESSAGECODE_MCBHS010_050: 'MCBHS010-050',
  MESSAGECODE_MCBHS010_052: 'MCBHS010-052',
  MESSAGECODE_MCBHS010_053: 'MCBHS010-053',
  MESSAGECODE_MCBHS010_054: 'MCBHS010-054',
  MESSAGECODE_MCBHS010_060: 'MCBHS010-060',
  MESSAGECODE_MCBHS010_061: 'MCBHS010-061',
  MESSAGECODE_MCBHS010_062: 'MCBHS010-062',
  MESSAGECODE_MCBHS010_063: 'MCBHS010-063',
  MESSAGECODE_MCBHS010_064: 'MCBHS010-064',
  // MCBリプレース #9200 START
  MESSAGECODE_MCBHS010_082: 'MCBHS010-082',
  // MCBリプレース #9200 END
  // MCBリプレース MCBHS010 END
  // MCBリプレース #9200 START
  MESSAGECODE_MCBHS020_043: 'MCBHS020-043',
  // MCBリプレース #9200 END
  
  // MCBリプレース MCAZS220 START
  MCBUSE_STATUS_1: '1',
  MCBUSE_STATUS_2: '2',
  MCBUSE_STATUS_NAME_1: 'My CareerBox利用中',
  // MCBリプレース MCAZS220 END

  // MCBリプレース MCBHS151 START
  MESSAGECODE_MCBHS151_001: 'MCBHS151-001',
  MESSAGECODE_MCBHS151_002: 'MCBHS151-002',
  // MCBリプレース MCBHS151 END

  // MCBリプレース MCBHS131 START
  MESSAGECODE_MCBHS131_001: 'MCBHS131-001',
  MESSAGECODE_MCBHS131_002: 'MCBHS131-002',
  MESSAGECODE_MCBHS131_004: 'MCBHS131-004',
  MESSAGECODE_MCBHS131_005: 'MCBHS131-005',
  MESSAGECODE_MCBHS131_006: 'MCBHS131-006',
  MESSAGECODE_MCBHS131_007_MAXCOUNT: 10000,
  MESSAGECODE_MCBHS131_008_MAXCOUNT: 100,
  // MCBリプレース MCBHS131 END

   // MCBリプレース MCBHS141 START
   MESSAGECODE_MCBHS141_002: 'MCBHS141-002',
   MESSAGECODE_MCBHS141_003: 'MCBHS141-003',
   MESSAGECODE_MCBHS141_004: 'MCBHS141-004',
   MESSAGECODE_MCBHS141_005: 'MCBHS141-005',
   MESSAGECODE_MCBHS141_006: 'MCBHS141-006',
   MESSAGECODE_MCBHS141_007: 'MCBHS141-007',
   // MCBリプレース MCBHS141 END
   
  // MCBリプレース MCAXS701 START
  MESSAGECODE_MCAXS701_003: 'MCAXS701-003',
  MESSAGECODE_MCAXS701_004: 'MCAXS701-004',
  MESSAGECODE_MCAXS701_005: 'MCAXS701-005',
  MESSAGECODE_MCAXS701_006: 'MCAXS701-006',
  MESSAGECODE_MCAXS701_007: 'MCAXS701-007',
  // MCBリプレース MCAXS701 END

  // MCBリプレース MCBHS171 START
  MESSAGECODE_MCBHS171_001:'MCBHS171-001',
  MESSAGECODE_MCBHS171_002:'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  // MCBリプレース MCBHS171 END

  MCBHS180_SCREENID: 'MCBHS180',
  // MCBリプレース MCBHS180 START
  MESSAGECODE_MCBHS180_000: 'MCBHS180-000',
  MESSAGECODE_MCBHS180_003: 'MCBHS180-003',
  MESSAGECODE_MCBHS180_004: 'MCBHS180-004',
  MESSAGECODE_MCBHS180_006: 'MCBHS180-006',
  MESSAGECODE_MCBHS180_007: 'MCBHS180-007',
  MESSAGECODE_MCBHS180_008: 'MCBHS180-008',
  MESSAGECODE_MCBHS180_009: 'MCBHS180-009',
  MESSAGECODE_MCBHS180_010: 'MCBHS180-010',
  MESSAGECODE_MCBHS180_011: 'MCBHS180-011',
  MESSAGECODE_MCBHS180_012: 'MCBHS180-012',
  MESSAGECODE_MCBHS180_013: 'MCBHS180-013',
  MESSAGECODE_MCBHS180_014: 'MCBHS180-014',
  // #MCB1.5次開発 #9260 START
  MESSAGECODE_MCBHS180_015: 'MCBHS180-015',
  MESSAGECODE_MCBHS180_016: 'MCBHS180-016',
  // #MCB1.5次開発 #9260 END
  MESSAGECODE_MCBHS180_030: 'MCBHS180-030',
  MESSAGECODE_MCBHS180_032: 'MCBHS180-032',
  MESSAGECODE_MCBHS180_033: 'MCBHS180-033',

  // MCBリプレース MCBHS180 END

  MESSAGECODE_MCBHS211_001: 'MCBHS211-001',
  MESSAGECODE_MCBHS211_002: 'MCBHS211-002',
  MESSAGECODE_MCBHS211_003: 'MCBHS211-003',
  MESSAGECODE_MCBHS211_004: 'MCBHS211-004',

  // MCBリプレース MCAXS260 start
  MESSAGECODE_MCAXS260_040: 'MCAXS260-040',
  MESSAGECODE_MCAXS260_041: 'MCAXS260-041',
  // MCBリプレース MCAXS260 end

  // MCBリプレース MCBLS010 start
  SOURCEFUNCTIONID_MCBLS010: 'MCBLS010',
  MESSAGECODE_MCBLS010_002: 'MCBLS010-002',
  MESSAGECODE_MCBLS010_003: 'MCBLS010-003',
  MESSAGECODE_MCBLS010_004: 'MCBLS010-004',
  MESSAGECODE_MCBLS010_005: 'MCBLS010-005',
  MESSAGECODE_MCBLS010_006: 'MCBLS010-006',
  // MCBリプレース MCBLS010 end

  // MCBリプレース MCBHS190 start
  MESSAGECODE_MCBHS190_002: 'MCBHS190-002',
  MESSAGECODE_MCBHS190_003: 'MCBHS190-003',
  MESSAGECODE_MCBHS190_004: 'MCBHS190-004',
  MESSAGECODE_MCBHS190_005: 'MCBHS190-005',
  // MCBリプレース #9222 START
  MESSAGECODE_MCBHS190_999: 'MCBHS190-999',
  MESSAGECODE_MCBHS190_007: 'MCBHS190-007',
  // MCBリプレース #9222 END
  // MCBリプレース MCBHS190 end
  // MCBリプレース MCBHS020 start
  LIMIT_DATE_DETERMINE_FLAG_0: '0', //提出締切指定フラグ：締切日を指定
  LIMIT_DATE_DETERMINE_FLAG_1: '1', //提出締切指定フラグ：期間を指定
  LIMIT_DATE_DETERMINE_FLAG_2: '2', //提出締切指定フラグ：随時受付終了
  // MCBリプレース MCBHS020 end
  // MCBリプレース MCAZS170 start
  MESSAGECODE_MCAZS170_002: 'MCAZS170-002',//条件に一致する検索結果がありませんでした。
  MESSAGECODE_MCAZS170_003: 'MCAZS170-003',//選択されたメッセージをゴミ箱に移動します。よろしいですか？
  MESSAGECODE_MCAZS170_004: 'MCAZS170-004',//送信中のメッセージが選択されているためゴミ箱に移動できません。
  MESSAGECODE_MCAZS170_005: 'MCAZS170-005',//メッセージ送信に伴う処理が完了していないメッセージが含まれています。恐れ入りますが、時間をおいて再度実施してください。
  MESSAGECODE_MCAZS170_006: 'MCAZS170-006',//ゴミ箱に移動しました。
  // MCBリプレイス #9041 START
  MESSAGECODE_MCAZS170_007: 'MCAZS170-007',//メッセージ送信に失敗した応募者
  // MCBリプレイス #9041 END  
  MESSAGECODE_MCAZS170_008: 'MCAZS170-008',//一括メッセージ送信対象の応募者
  MESSAGECODE_MCAZS170_009: 'MCAZS170-009',//閲覧権限のない応募者へのメッセージが含まれるため、操作できません。
  // MCBリプレース MCAZS170 end
  // MCBリプレース MCAZS180 start
  MESSAGECODE_MCAZS180_002: 'MCAZS180-002', //条件に一致する検索結果がありませんでした。
  MESSAGECODE_MCAZS180_003: 'MCAZS180-003', //メッセージの送信をキャンセルしてもよろしいですか？/nキャンセルしたメッセージは下書きに移動します。
  MESSAGECODE_MCAZS180_004: 'MCAZS180-004', //送信予約ではないメッセージが含まれるため、送信をキャンセルできませんでした。
  MESSAGECODE_MCAZS180_005: 'MCAZS180-005', //メッセージを送信してもよろしいですか？
  MESSAGECODE_MCAZS180_006: 'MCAZS180-006', //送信予約ではないメッセージが含まれるため、すぐに送信できませんでした。
  MESSAGECODE_MCAZS180_007: 'MCAZS180-007', //下書きに移動しました。
  MESSAGECODE_MCAZS180_008: 'MCAZS180-008', //送信しました。
  MESSAGECODE_MCAZS180_009: 'MCAZS180-009', //メッセージ送信に失敗した応募者
  MESSAGECODE_MCAZS180_010: 'MCAZS180-010', //一括メッセージ送信対象の応募者
  MESSAGECODE_MCAZS180_011: 'MCAZS180-011', //閲覧権限のない応募者へのメッセージが含まれるため、操作できません。
  MESSAGECODE_MCAZS180_012: 'MCAZS180-012', //閲覧権限のない応募者へのメッセージが含まれるため、操作できません。
  MESSAGECODE_MCAZS180_013: 'MCAZS180-013', //閲覧権限のない応募者へのメッセージが含まれるため、操作できません。
  MESSAGECODE_MCAZS180_014: 'MCAZS180-014', //提出リクエスト取消しAPI実行で問題が発生しました。
  MESSAGECODE_MCAZS180_015: 'MCAZS180-015', //メンテナンス中のため、My CareerBox関連機能はご利用いただけません。ご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。メンテナンス時間についてはお知らせの掲示をご確認ください。
  // #MCB1.5次開発 #8986 START
  MESSAGECODE_MCAZS180_016: 'MCAZS180-016', //送信日時がMy CareerBoxのメンテナンス日時の範囲内です。
  // #MCB1.5次開発 #8986 END
  // MCBリプレース MCAZS180 End
  // MCBリプレース MCAZS190 start
  MESSAGECODE_MCAZS190_002: 'MCAZS190-002',//条件に一致する検索結果がありませんでした。
  MESSAGECODE_MCAZS190_003: 'MCAZS190-003',//選択されたメッセージをゴミ箱に移動します。よろしいですか？
  MESSAGECODE_MCAZS190_004: 'MCAZS190-004',//ゴミ箱に移動しました。
  MESSAGECODE_MCAZS190_005: 'MCAZS190-005',//一括メッセージ送信対象の応募者
  MESSAGECODE_MCAZS190_006: 'MCAZS190-006',//閲覧権限のない応募者へのメッセージが含まれるため、操作できません。
  MESSAGECODE_MCAZS190_007: 'MCAZS190-007',//閲覧権限のない応募者へのメッセージが含まれるため、操作できません。
  // MCBリプレース MCAZS190 end
  // MCBリプレース MCAZS200 start
  MESSAGECODE_MCAZS200_002: 'MCAZS200-002',//条件に一致する検索結果がありませんでした。
  MESSAGECODE_MCAZS200_003: 'MCAZS200-003',//選択したメッセージをゴミ箱から元に戻してもよろしいですか？送信予約のメッセージは下書きに移動します。
  MESSAGECODE_MCAZS200_004: 'MCAZS200-004',//削除してもよろしいですか？削除したメッセージは元に戻せません。
  MESSAGECODE_MCAZS200_005: 'MCAZS200-005',//ゴミ箱から戻しました。
  MESSAGECODE_MCAZS200_006: 'MCAZS200-006',//メッセージを削除しました。
  MESSAGECODE_MCAZS200_007: 'MCAZS200-007',//一括メッセージ送信対象の応募者
  MESSAGECODE_MCAZS200_008: 'MCAZS200-008',//閲覧権限のない応募者へのメッセージが含まれるため、操作できません。
  MESSAGECODE_MCAZS200_009: 'MCAZS200-009',//閲覧権限のない応募者へのメッセージが含まれるため、操作できません。
  // MCBリプレース MCAZS200 end
  // MCBリプレース MCAZS210 start
  MESSAGECODE_MCAZS210_001: 'MCAZS210-001',//こちらのファイルがシステム上から削除されているため、添付ファイルがダウンロードできませんでした。
  MESSAGECODE_MCAZS210_002: 'MCAZS210-002',//権限がないため、操作を実行できません。
  // MCBリプレース MCAZS210 end
  // MCBリプレース MCBHS050 start
  MESSAGECODE_MCBHS050_003: 'MCBHS050-003',//こちらのファイルがシステム上から削除されているため、添付ファイルがダウンロードできませんでした。
  MESSAGECODE_MCBHS050_004: 'MCBHS050-004',//権限がないため、操作を実行できません。
  // MCBリプレース MCBHS050 end
  MESSAGECODE_MCBKS010_000: 'MCBKS010-000',
  MESSAGECODE_MCBKS010_001: 'MCBKS010-001',
  MESSAGECODE_MCBKS010_002: 'MCBKS010-002',
  MESSAGECODE_MCBKS010_003: 'MCBKS010-003',
  MESSAGECODE_MCBKS010_004: 'MCBKS010-004',
  MESSAGECODE_MCBKS010_005: 'MCBKS010-005',
  MESSAGECODE_MCBKS010_006: 'MCBKS010-006',
  MESSAGECODE_MCBKS010_007: 'MCBKS010-007',

  MESSAGECODE_MCBKS020_001: 'MCBKS020-001',
  MESSAGECODE_MCBKS020_002: 'MCBKS020-002',
  MESSAGECODE_MCBKS020_003: 'MCBKS020-003',
  MESSAGECODE_MCBKS020_004: 'MCBKS020-004',

  MESSAGECODE_MCBKS030_001: 'MCBKS030-001',
  MESSAGECODE_MCBKS030_002: 'MCBKS030-002',
  MESSAGECODE_MCBKS030_003: 'MCBKS030-003',
  MESSAGECODE_MCBKS030_004: 'MCBKS030-004',
  MESSAGECODE_MCBKS030_005: 'MCBKS030-005',
  MESSAGECODE_MCBKS030_006: 'MCBKS030-006',
  MESSAGECODE_MCBKS030_007: 'MCBKS030-007',
  MESSAGECODE_MCBKS030_008: 'MCBKS030-008',
  MESSAGECODE_MCBKS030_009: 'MCBKS030-009',
  MESSAGECODE_MCBKS030_010: 'MCBKS030-010',

  RECRUITMENT_MANAGEMENT_FLAG_TYPE_10: "10",
  RECRUITMENT_MANAGEMENT_FLAG_TYPE_20: "20",
  RECRUITMENT_MANAGEMENT_FLAG_TYPE_30: "30",

  DUPLICATE_UPDDATE_FLAG_FALSE: "0",
  DUPLICATE_UPDDATE_FLAG_TRUE: "1",
  // #75311 25KH start
  MESSAGECODE_MCAXS080_001: 'MCAXS080_001',
  MESSAGECODE_MCAXS080_002: 'MCAXS080_002',
  MESSAGECODE_MCAXS080_003: 'MCAXS080_003',
  MESSAGECODE_MCAXS080_004: 'MCAXS080_004',
  // #75311 25KH end

  // 転職March #75621 START
  // Marchサービスフラグ
  MARCHSERVICEFLAG_VALID: '1',
  MARCHSERVICEFLAG_INVALID: '0',
  MARCHRESPONSIBLECOMPANYFLAG_VALID: '1',
  MARCHRESPONSIBLECOMPANYFLAG_INVALID: '0',
  SERVICEMODE_MARCH: '1',
  SERVICEMODE_NO_MARCH: '0',
  MESSAGECODE_MCAAS010_COMMON_009: 'MCAAS010-COMMON-009',
  MESSAGECODE_MCAAS010_COMMON_010: 'MCAAS010-COMMON-010',
  MESSAGECODE_MCAAS010_COMMON_011: 'MCAAS010-COMMON-011',
  MESSAGECODE_MCAAS010_COMMON_012: 'MCAAS010-COMMON-012',
  MESSAGECODE_MCAAS010_004: 'MCAAS010-004',
  MESSAGECODE_MCAAS010_005: 'MCAAS010-005',
  // 転職March #75621 END
}

export const BROADCAST_CHANNEL_CONFIG = {
  NAME: 'MCAZS101',
  EVENT_TYPE: 'message' as EventType,
  MESSAGE: 'FROM_MCAZS101'
}

export const TABLE_CONTAINER_ID = "TABLE_CONTAINER"
export const SEARCH_BUTTON_ID = "SEARCH_BUTTON_ID"




export const pdfConstants = {
    // PDF作成設定件数
    PDF_MAKING_NUMBER_SETTING: 1,
}

export const CHUNK_FAILED_KEY = "chunk_failed"
// #51347 次期開発2022年9月 start
export const PARENT_WINDOW_NAME = "Parent"
// #51347 次期開発2022年9月 end
export const ALERT_FLAG = "alerted"
