/**
 * SearchRequestValidation
 */
import * as yup from 'yup'
import { miscRegexSet } from 'utils/regex'
import { validationMessageSet } from 'utils/contants'

export default yup.object().shape({
  /**
   * 基本情報
   */
  // 応募者管理ID
  // -
  applicantId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumberIndention.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 氏名／氏名カナ
  // -
  name: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 性別
  // -
  sexObj: yup.lazy(value => (value ? yup.string() : yup.mixed().notRequired())),

  // 性別コード
  // -
  sexCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 性別名
  // -
  sexName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 電話番号
  // -
  phoneNumber: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.phoneNumber,
          (value: any) => miscRegexSet.phoneNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // メールアドレス
  // -
  mailAddress: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 居住地（都道府県）
  // -
  residenceObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 現住所条件
  // -
  residenceCurrentObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 居住地条件（現住所：都道府県）コード
  // -
  residenceCurrentPrefecturesCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 現住所
  // -
  residenceCurrentPrefecturesName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 居住地条件（結合子）名
  // -
  residenceConnectorName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 休暇中住所条件
  // -
  residenceVacationObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 居住地条件（休暇中住所：都道府県）コード
  // -
  residenceVacationPrefecturesCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 休暇中住所
  // -
  residenceVacationPrefecturesName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 語学スキル
  // -
  languageSkills: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidth',
          validationMessageSet.halfWidth,
          (value: any) => miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 保有資格
  // -
  eligibility: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidth',
          validationMessageSet.halfWidth,
          (value: any) => miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 会員種別
  // -
  memberTypeObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 会員種別コード
  // -
  memberTypeCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 会員種別名
  // -
  memberTypeName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  /**
   * 学校情報
   */
  // 卒業時期
  // -
  graduationPeriodObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 卒業時期コード
  // -
  graduationPeriodCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 卒業時期名
  // -
  graduationPeriodName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 文理区分
  // -
  philosophyHumanitiesObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 文理区分コード
  // -
  philosophyHumanitiesCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 文理区分名
  // -
  philosophyHumanitiesName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校種別
  // -
  schoolTypeObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 学校種別ID
  // -
  schoolTypeId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校種別名
  // -
  schoolTypeName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校名
  // -
  schoolName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学部名・研究科名
  // -
  facultyGraduateName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidth',
          validationMessageSet.halfWidth,
          (value: any) => miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学科名・専攻名
  // -
  departmentDepartmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校グループ
  // -
  schoolGroupObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 学校グループID
  // -
  schoolGroupId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校グループ名
  // -
  schoolGroupName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校グループ（学校詳細）
  // -
  schoolDetailsObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 学校詳細ID
  // -
  schoolDetailsId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校詳細名
  // -
  schoolDetailsName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校グループ（学部・学科詳細）
  // -
  undergraduateDepartmentObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 学校種別ID
  // -
  undergraduateDepartmentSchoolTypeId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学校種別名
  // -
  undergraduateDepartmentSchoolTypeName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 大学ID
  // -
  undergraduateDepartmentUniversityId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 大学名
  // -
  undergraduateDepartmentUniversityName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学部・研究科ID
  // -
  undergraduateDepartmentFacultyId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学部・研究科名
  // -
  undergraduateDepartmentFacultyName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学科・専攻ID
  // -
  undergraduateDepartmentSubjectId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学科・専攻名
  // -
  undergraduateDepartmentSubjectName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学生分類
  // -
  studentClassificationObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 学科区分ID
  // -
  studentClassificationId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学科区分名
  // -
  studentClassificationName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学生分類（学科詳細）
  // -
  departmentDetailsObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 学科ID
  // -
  departmentId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 学科名
  // -
  departmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 国・地域
  // -
  countryRegionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 国・地域コード
  // -
  countryRegionCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 国・地域名
  // -
  countryRegionName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  /**
   * 応募情報
   */
  // 応募経路
  // -
  applicationRouteObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募経路ID
  // -
  applicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路名
  // -
  applicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 初回エントリー日
  // -
  firstEntryDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 初回エントリー日
  // -
  firstEntryDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 対象応募経路
  // -
  targetApplicationRouteObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 対象応募経路ID
  // -
  targetApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 対象応募経路名
  // -
  targetApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリー日
  // -
  entryDateObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // エントリー日条件判定名
  // -
  entryDateConditionJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリー日条件
  // -
  entryDateConditionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 分類FLG
  // -
  entryDateClassificationFlag: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 分類名
  // -
  entryDateClassificationName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路ID
  // -
  entryDateApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路名
  // -
  entryDateApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 連携元エントリーボックスID
  // -
  entryDatecoordinationFormerEntryBoxId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリーボックスID
  // -
  entryDateEntryBoxId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリーボックス名
  // -
  entryDateEntryBoxName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリー日（From）
  // -
  entryDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリー日（To）
  // -
  entryDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリー有無
  // -
  entryPresenceObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // エントリー有無条件判定名
  // -
  entryPresenceConditionJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリー有無条件
  // -
  entryPresenceConditionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 分類FLG
  // -
  entryPresenceClassificationFlag: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 分類名
  // -
  entryPresenceClassificationName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路ID
  // -
  entryPresenceApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路名
  // -
  entryPresenceApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 連携元エントリーボックスID
  // -
  entryPresencecoordinationFormerEntryBoxId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリーボックスID
  // -
  entryPresenceEntryBoxId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリーボックス名
  // -
  entryPresenceEntryBoxName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリー有無名
  // -
  entryPresenceName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // アンケート回答内容
  // -
  questionnaireResponseObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // アンケート条件判定名
  // -
  questionnaireResponseConditionJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // アンケート回答条件
  // -
  questionnaireResponseConditionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募経路ID
  // -
  questionnaireResponseApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路名
  // -
  questionnaireResponseApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 連携元エントリーボックスID
  // -
  questionnaireResponsecoordinationFormerEntryBoxId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリーボックスID
  // -
  questionnaireResponseEntryBoxId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // エントリーボックス名
  // -
  questionnaireResponseEntryBoxName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 設問番号
  // -
  questionnaireResponseQuestionNumber: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 質問文
  // -
  questionnaireResponseQuestionTitle: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 回答有無名
  // -
  questionnaireResponseQuestionAnswerConditionName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 質問種別コード
  // -
  questionnaireResponseQuestionTypeCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 質問種別名
  // -
  questionnaireResponseQuestionTypeName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 回答テキスト
  // -
  questionnaireResponseAnswerText: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 回答選択肢
  // -
  questionnaireResponseAnswerCheckBoxObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 回答選択肢ID
  // -
  questionnaireResponseAnswerCheckBoxId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 回答選択肢名
  // -
  questionnaireResponseAnswerCheckBoxName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 受付日程・予約状況
  // -
  seminarReceptionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // セミナー受付日程・予約状況条件判定名
  // -
  seminarReceptionConditionJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナー受付予約状況（いずれかの日程）条件
  // -
  seminarReceptionWhichOneObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // セミナー受付予約状況（いずれかの日程）名
  // -
  seminarReceptionWhichOneName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナー受付日程・予約状況（各受付ごと）
  // -
  seminarReceptionIndividualObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // セミナー受付日程・予約状況（各受付ごと）条件判定名
  // -
  seminarReceptionIndividualJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナー受付日程・予約状況（各受付ごと）条件
  // -
  seminarReceptionIndividualObj2: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募経路ID
  // -
  seminarReceptionIndividualApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路名
  // -
  seminarReceptionIndividualApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 連携元エントリーボックスID
  // -
  seminarReceptionIndividualCoordinationFormerSeminarId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナーID
  // -
  seminarReceptionIndividualSeminarId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナー名
  // -
  seminarReceptionIndividualSeminarName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 公開フラグ
  // -
  seminarReceptionIndividualPublicFlag: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 公開フラグ名
  // -
  seminarReceptionIndividualPublicName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナー日程ID
  // -
  seminarReceptionIndividualSeminarScheduleId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナー日程タイトル
  // -
  seminarReceptionIndividualSeminarScheduleTitle: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 非表示フラグ
  // -
  seminarReceptionIndividualInvisibleFlag: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 非表示フラグ名
  // -
  seminarReceptionIndividualInvisibleName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 開催日
  // -
  applicationFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 開催時間
  // -
  seminarReceptionIndividualStartTime: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDatetime', validationMessageSet.date, (value: any) =>
          miscRegexSet.datetime.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 終了時間
  // -
  seminarReceptionIndividualEndTime: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDatetime', validationMessageSet.date, (value: any) =>
          miscRegexSet.datetime.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 開催地場所コード
  // -
  seminarReceptionIndividualVenueLocationCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 開催地場所名
  // -
  seminarReceptionIndividualVenueLocationName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナー受付予約状況（各受付ごと）名
  // -
  seminarReceptionIndividualName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 予約した受付日程
  // -
  reservationDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 予約した受付日程
  // -
  reservationDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // Webセミナー視聴状況
  // -
  webSeminarObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // Webセミナー視聴状況条件判定名
  // -
  webSeminarConditionJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // Webセミナー視聴状況条件
  // -
  webSeminarConditionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募経路ID
  // -
  webSeminarApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路名
  // -
  webSeminarApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // セミナー日程ID
  // -
  webSeminarSeminarScheduleId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 連携元WebセミナーID
  // -
  webSeminarCoordinationFormerWebSeminarId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // Webセミナータイトル
  // -
  webSeminarTitle: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // Webセミナー視聴状況条件名
  // -
  webSeminarWatchingStatusConditionName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント参加状況の条件
  // -
  eventsObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // イベント参加状況条件判定名
  // -
  eventsConditionJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント参加状況（いずれかのイベント）条件
  // -
  eventsWhichOneObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // イベント参加状況（いずれかのイベント）名
  // -
  eventsWhichOneName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント参加状況（各イベントごと）
  // -
  eventsIndividualObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // イベント参加状況（各イベントごと）条件判定名
  // -
  eventsIndividualJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント参加状況（各イベントごと）条件
  // -
  eventsIndividualObj2: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募経路ID
  // -
  eventsIndivIdualApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募経路名
  // -
  eventsIndividualApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント種別ID
  // -
  eventsIndivIdualEventTypeId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント種別名
  // -
  eventsIndividualEventTypeName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 連携元イベントID
  // -
  eventsIndividualCoordinationFormerEventId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント日程
  // -
  eventsIndividualEventSchedule: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベントID
  // -
  eventsIndivIdualEventId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント名
  // -
  eventsIndividualEventName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 都道府県コード
  // -
  eventsIndividualPrefecturesCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 都道府県名
  // -
  eventsIndividualPrefecturesName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント参加状況（各イベントごと）名
  // -
  eventsIndividualName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // Started By DiaoJiaHao
  // 連携元イベント区分ID
  // -
  eventsIndividualAreaId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  eventsIndividualAreaName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),
  // End By DiaoJiaHao

  // イベント開催日From
  // -
  eventDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イベント開催日To
  // -
  eventDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // メッセージ送付状況
  // -
  sendMessegeObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // メッセージ送付状況条件名
  // -
  sendMessegeConditionJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // メッセージ送付状況条件
  // -
  sendMessegeConditionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // メッセージ種別FLG
  // -
  sendMessegeMessageTypeFlag: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // メッセージ種別名
  // -
  sendMessegeMessageTypeName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 件名
  // -
  sendMessegeSubject: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 送付状態名
  // -
  sendMessegeSendConditionName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 送付From
  // -
  sendMessegeSendDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 送付To
  // -
  sendMessegeSendDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // スカウト応募経路
  // -
  scoutApplicationRouteObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // スカウト（応募経路）ID
  // -
  scoutApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // スカウト（応募経路）名
  // -
  scoutApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // スカウト条件
  // -
  scoutObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // スカウト（条件）名
  // -
  scoutConditionsName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募歓迎応募経路
  // -
  welcomeApplicationRouteObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募歓迎（応募経路）ID
  // -
  welcomeApplicationRouteId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募歓迎（応募経路）名
  // -
  welcomeApplicationRouteName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 応募歓迎条件
  // -
  welcomeObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 応募歓迎（条件）名
  // -
  welcomeConditionsName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  /**
   * 採用管理情報
   */
  // 選考フロー
  // -
  selectionFlowObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 選考フローID
  // -
  selectionFlowId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 選考フロー名
  // -
  selectionFlowName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 選考ステップ
  // -
  selectionStepObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 選考ステップID
  // -
  selectionStepId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 選考ステップ名
  // -
  selectionStepName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 合否判定
  // -
  judgmentUndeterminedObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 合否判定コード
  // -
  judgmentUndeterminedCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 合否判定名
  // -
  judgmentUndeterminedName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 管理項目
  // -
  managementItemObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 管理項目条件判定名
  // -
  managementItemConditionJudgmentName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 管理項目条件
  // -
  managementItemConditionObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 採用管理フラグ設定ID
  // -
  recruitmentManagementFlagSettingId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 採用管理フラグ名
  // -
  recruitmentManagementFlagName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 管理項目値有無条件名
  // -
  managementItemPresenceName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 種別コード
  // -
  managementItemTypeCode: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 種別名
  // -
  managementItemTypeName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 詳細テキスト
  // -
  managementItemFlagText: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 詳細範囲From
  // -
  managementItemFlagFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 詳細範囲To
  // -
  managementItemFlagTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 詳細選択肢
  // -
  managementItemFlagCheckBoxObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 詳細選択肢ID
  // -
  managementItemFlagCheckBoxId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 詳細選択肢名
  // -
  managementItemFlagCheckBoxName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // タグ
  // -
  tagTxtObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // タグ設定ID
  // -
  tagSettingId: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // タグ名
  // -
  tagName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // イメジス情報
  // -
  imagesObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 項目論理名
  // -
  imagesLogicalName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 項目物理名
  // -
  imagesPhysicalName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 値From
  // -
  imagesValueFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 値To
  // -
  imagesValueTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // GAB情報
  // -
  gABObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 項目論理名
  // -
  gABLogicalName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 項目物理名
  // -
  gABPhysicalName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // GABタイプ名
  // -
  gABTypName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 条件リスト名
  // -
  gABListName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 値From
  // -
  gABValueFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 値To
  // -
  gABValueTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // OPQ情報
  // -
  oPQObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 項目論理名
  // -
  oPQLogicalName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 項目物理名
  // -
  oPQPhysicalName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // OPQタイプ名
  // -
  oPQTypName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 値From
  // -
  oPQValueFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 値To
  // -
  oPQValueTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // CAB情報
  // -
  cABObj: yup.lazy(value =>
    value ? yup.string() : yup.mixed().notRequired()
  ),

  // 項目論理名
  // -
  cABLogicalName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 項目物理名
  // -
  cABPhysicalName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthAlphanumericSymbol',
          validationMessageSet.halfWidthAlphanumericSymbol,
          (value: any) => miscRegexSet.halfWidthAlphanumericSymbol.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // CABタイプ名
  // -
  cABTypName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 条件リスト名
  // -
  cABListName: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isHalfWidth', validationMessageSet.halfWidth, (value: any) =>
          miscRegexSet.halfWidth.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 値From
  // -
  cABValueFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 値To
  // -
  cABValueTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test(
          'isHalfWidthNumber',
          validationMessageSet.halfWidthNumber,
          (value: any) => miscRegexSet.halfWidthNumber.test(value)
        )
      : yup.mixed().notRequired()
  ),
  // [phase2] start
  // 連携日
  // -
  linkDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 連携日
  // -
  linkDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),
  // [phase2] end

  // MCBリプレース MCAXS701 START
  //提出リクエスト日
  // -
  sendDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 提出リクエスト日
  // -
  sendDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  //提出締切日
  // -
  deadlineDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 提出締切日
  // -
  deadlineDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 最終提出日
  // -
  submitDateFrom: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),

  // 最終提出日
  // -
  submitDateTo: yup.lazy(value =>
    value
      ? yup
        .string()
        .test('isDate', validationMessageSet.date, (value: any) =>
          miscRegexSet.date.test(value)
        )
      : yup.mixed().notRequired()
  ),
  // MCBリプレース MCAXS701 END 
})
