import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MCACS010LoginRequest } from 'types/MCACS010/MCACS010LoginRequest'
import { getToken, setToken } from 'utils/auth'
import { ALERT_FLAG } from 'utils/contants'
export interface InitInfo {
  [key: string]: string
  coordinationSystemKubun: string,// 連携システム区分
  contractMediaName: string,// 契約応募経路名
  serviceUrl: string,// サービスURL
}

const initialState = () => ({
  //cookies
  token: getToken(),
  alerted: localStorage.getItem(ALERT_FLAG) || "",
  initInfo: [] as InitInfo[],
  initFlag: false,
  first: true,
  siteKey: "",
  gtmId: "",
  // #91157 就職転職分離 start
  miwsEnvFlag: "",
  // #91157 就職転職分離 end
})

const companyLoginSlice = createSlice({
  name: 'companyLogin',
  initialState: initialState(),
  reducers: {
    //init 
    init: state => state,
    // #91157 就職転職分離 start
    initSuccess(state, action: PayloadAction<{ list: InitInfo[], miwsEnvFlag: string }>) {
      state.initInfo = action.payload.list
      state.miwsEnvFlag = action.payload.miwsEnvFlag
    },
    // #91157 就職転職分離 end
    //Magiログイン
    loginMagi(state, _: PayloadAction<MCACS010LoginRequest>) {
      return state
    },
    //Magiログイン成功
    loginSuccess(state, action: PayloadAction<{
      token: string
    }>) {
      state.token = action.payload.token
      setToken(action.payload.token)
    },
    //
    setInitFlag(state, action: PayloadAction<boolean>) {
      state.initFlag = action.payload
      return state
    },
    setFirst(state, action: PayloadAction<boolean>) {
      state.first = action.payload
      return state
    },
    initCaptcha(state, action: PayloadAction<{
      siteKey: string
    }>) {
      state.siteKey = action.payload.siteKey
    },
    getGtmId: state => state,
    setGtmId(state, action: PayloadAction<{ gtmId: string}>) {
      return {
        ...state,
        gtmId: action.payload.gtmId
      }
    },
    setAlerted(state, action: PayloadAction<string>) {
      state.alerted = action.payload
      return state
    },
  },
})

const { actions, reducer } = companyLoginSlice

export const {
  init,
  initSuccess,
  loginMagi,
  loginSuccess,
  setInitFlag,
  setFirst,
  initCaptcha,
  getGtmId,
  setGtmId,
  setAlerted
} = actions
export default reducer
