const messageMap = new Map([
  ['1', '登録しました'],
  ['2', '削除しました'],
  ['3', 'パスワードを更新しました'],
  ['200', 'Successed'],
  ['500', 'Failed'],
  ['402', 'Validation Failed'],
  ['401', '以下いずれかの事象が発生したため、ログイン状態が無効になりました。<br />・ご利用中のアカウントで別端末からのログインがあったため（MIWSでは同一アカウントでの複数同時ログインができません）<br />・一定時間操作をされなかったため<br /><br />作業中のデータがあった場合、未保存のデータは破棄されており復元できません。ご了承ください。'],
  ['403', 'Forbidden'],
  ['404', 'Not Found'],
  ['504', 'アクセス集中によりサイトに繋がりにくくなっております。\nしばらく時間をおいてアクセスいただきますようお願いいたします。'],
  ['Network Error', '通信に失敗しました。少しお待ちいただき、再度操作を行ってください。'],
  ['Correlation Item Check Error Message', '詳細範囲Toが詳細範囲Fromより小さい値です'],
  // #91157 就職転職分離 start
  ['Login Induction Message 1', 'ご利用のURLは中途採用MIWSのURLです。\n新卒採用MIWSをご利用の方は\n以下のURLから再度ログインしてください。'],
  ['Login Induction Message 2', 'ご利用のURLは新卒採用MIWSのURLです。\n中途採用MIWSをご利用の方は\n以下のURLから再度ログインしてください。'],
  // #91157 就職転職分離 end

  ['MAACOMMON-001', '登録しました'],
  ['MAACOMMON-002', '削除しました'],
  ['MAACOMMON-003', '更新しました'],
  ['MAACOMMON-004', '検索結果が存在しません'],
  ['MAACOMMON-005', '仮パスワード発行しました'],
  [
    'MAACOMMON-006',
    '他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください',
  ],
  [
    'MAACOMMON-007',
    '更新をすべて完了できませんでした。一部のデータが他のユーザにより変更された可能性がありますので、再度検索してやり直してください',
  ],
  ['MAACOMMON-008', 'CSV出力しました'],
  ['MAACOMMON-009', '情報が存在しません。再度検索してください'],
  ['MAACOMMON-010', '他の利用者により変更されているため、選考ステップは更新できません。'],

  ['MCADS010_000', '最大100件までアカウントを登録できます。\n各マイナビサービスで作成したアカウントについてはこちらから編集できません。こちらで作成したアカウントでは各ナビへログインができませんのでご注意ください。'],
  ['MCADS010-002', 'お探しのページが見つかりません。\nお探しのページは一時的にアクセスできない状態にあるか、移動もしくは削除された可能性があります。また、URL、ファイル名にタイプミスがないか再度ご確認ください。'],
  [
    'MCADS010-003',
    '登録可能上限数（100件）存在するため、新規に追加することはできません。',
  ],
  [
    'MCADS010-006',
    'アカウント種別：統括のアカウントは有効／無効を無効に出来ません。',
  ],
  ['MCADS010-010', 'パスワードの再発行が完了しました。'],
  ['MCADS010-011', '有効／無効の無効化が完了しました。'],
  ['MCADS010-012', '有効／無効の有効化が完了しました。'],
  ['MCADS010-013', '条件に一致する検索結果がありませんでした。'],
  ['MCADS010-005', '有効／無効：無効のアカウントが選択されています。よろしいですか？'],
  ['MCADS010-007', '選択されたすべてのアカウントに対して、パスワードを再発行します。よろしいですか？'],
  ['MCADS010-008', '選択されたすべてのアカウントに対して、無効にします。よろしいですか？'],
  ['MCADS010-009', '選択されたすべてのアカウントに対して、有効にします。よろしいですか？'],
  // #9567 就職転職分離(リダイレクト対応) start
  ['MCADS010-015', 'この機能は現在ご利用いただけません。\nしばらく時間をおいてアクセスいただきますようお願いいたします。'],
  // #9567 就職転職分離(リダイレクト対応) end

  ['MCAIS010-000', '最大50件まで応募経路を登録できます。\n応募経路を設定することで、各選考フローの応募経路ごとの数字を確認できます。\nシステム登録となっている応募経路は応募経路の編集はできません。'],
  [
    'MCAIS010-001',
    '登録上限数は50件です。',
  ],
  // 転職March #75621 START
  ['MCAAS020-000', '新着のお知らせがあります。'],
  ['MCAAS020-00A', '現在、新着のお知らせはありません。'],
  ['MCAAS020-00B', 'こちら'],
  ['MCAAS020-00C', '　※過去のお知らせは'],
  // 転職March #75621 END
  ['MCAIS020-000', ''],
  [
    'MCAIS020-001',
    '入力された応募経路は、既に登録されています。',
  ],
  [
    'MCAIS020-002',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAIS020-003', '入力された内容で登録します。よろしいですか？'],
  [
    'MCAIS020-004',
    '登録上限数は50件です。',
  ],
  ['MCAIS020-005', '応募経路を登録しました。'],

  ['MCAIS030-000', ''],
  [
    'MCAIS030-001',
    '入力された応募経路は、既に登録されています。',
  ],
  [
    'MCAIS030-002',
    'この応募経路に紐づくデータが存在するため、削除できません。',
  ],
  [
    'MCAIS030-003',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAIS030-004', '入力された内容で更新します。よろしいですか？'],
  ['MCAIS030-005', '表示している応募経路を削除します。よろしいですか？'],
  ['MCAIS030-006', '応募経路名を更新しました。'],
  ['MCAIS030-007', '応募経路を削除しました。'],

  [
    'MCANS010-000',
    '最大200件まで署名を登録できます。\nメッセージ入力時に選択することで、登録された署名を差し込むことができます。',
  ],
  [
    'MCANS010-001',
    '登録上限数は200件です。',
  ],

  [
    'MCANS020-000',
    '',
  ],
  [
    'MCANS020-001',
    '登録名に入力された値は、既に登録されています。',
  ],
  [
    'MCANS020-002',
    '登録上限数は200件です。',
  ],
  ['MCANS020-003', '入力された内容で登録します。よろしいですか？'],
  [
    'MCANS020-004',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCANS020-005', '署名を登録しました。'],

  [
    'MCANS030-000',
    '',
  ],
  [
    'MCANS030-001',
    '登録名に入力された値は、既に登録されています。',
  ],
  ['MCANS030-002', '表示している署名を削除します。よろしいですか？'],
  ['MCANS030-003', '入力された内容で更新します。よろしいですか？'],
  [
    'MCANS030-004',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCANS030-005', '署名を更新しました。'],
  ['MCANS030-006', '署名を削除しました。'],

  ['MCAOS010-000', '最大30件まで面接場所を登録できます。\n「その他」はシステム登録となっているため、並び替えや編集ができません。'],
  [
    'MCAOS010-001',
    '登録上限数は30件です。',
  ],

  ['MCAOS020-000', '面接場所名も応募者に表示されます。'],
  ['MCAOS020-001', '入力された内容で登録します。よろしいですか？'],
  [
    'MCAOS020-002',
    '登録上限数は30件です。',
  ],
  ['MCAOS020-003', '入力された面接場所名は、既に登録されています。'],
  [
    'MCAOS020-004',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAOS020-005', '面接場所を登録しました。'],

  ['MCAOS030-000', '面接場所名も応募者に表示されます。'],
  ['MCAOS030-001', '入力された内容で更新します。よろしいですか？'],
  ['MCAOS030-002', '入力された面接場所名は、既に登録されています。'],
  [
    'MCAOS030-003',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAOS030-004', '表示している面接場所を削除します。よろしいですか？'],
  ['MCAOS030-005', '面接場所を更新しました。'],
  ['MCAOS030-006', '面接場所を削除しました。'],

  ['MCAHS020-000', ''],

  ['MCAHS020-001', '指定されたページは存在しません。'],
  ['MCAHS020-002', 'システムエラーが発生しました。'],
  ['MCAHS020-003', '入力された内容で登録します。よろしいですか？'],
  [
    'MCAHS020-004',
    '登録上限数は100件です。',
  ],
  ['MCAHS020-005', '種別「選択」の場合、選択肢は1つ以上入力してください。'],
  ['MCAHS020-006', '操作をキャンセルしますか？入力した内容が保存されない可能性があります。'],
  [
    'MCAHS020-007',
    '登録上限数は100件です。',
  ],
  ['MCAHS020-008', '選択肢を入力してください。'],
  [
    'MCAHS020-009',
    '選択肢に入力された値は、既に登録されています。',
  ],
  [
    'MCAHS020-010',
    '管理項目に入力された値は、既に登録されています。',
  ],
  ['MCAHS020-011', '管理項目を登録しました。'],
  // #72427 次期開発2022年12月 start
  ['MCAHS020-013', '各対象年度の管理項目登録上限数は100件のため、登録できません。'],
  ['MCAHS020-014', '入力された管理項目名は、既に登録されています。'],
  ['MCAHS020-015', '管理項目の登録が完了しました。入力された内容を確認する場合は、指定した対象年度に切り替えてご確認ください。'],
  // #72427 次期開発2022年12月 end

  ['MCAHS030-000', ''],
  ['MCAHS030-001', '指定されたページは存在しません。'],
  ['MCAHS030-002', 'システムエラーが発生しました。'],
  ['MCAHS030-003', '入力された内容で更新します。よろしいですか？'],
  [
    'MCAHS030-004',
    '登録上限数は100件です。',
  ],
  ['MCAHS030-005', '種別「選択」の場合、選択肢は1つ以上入力してください。'],
  ['MCAHS030-006', '操作をキャンセルしますか？入力した内容が保存されない可能性があります。'],
  [
    'MCAHS030-007',
    '登録上限数は100件です。',
  ],
  ['MCAHS030-008', '表示している管理項目を削除します。よろしいですか？'],
  [
    'MCAHS030-009',
    'この管理項目に紐づく応募者データが存在するため、削除できません。',
  ],
  [
    'MCAHS030-010',
    'こちらの管理項目はアクションとして使用されているため、削除できません。\n削除したい場合は、アクションから設定解除した後に実施してください。',
  ],
  ['MCAHS030-011', '選択肢を入力してください。'],
  [
    'MCAHS030-012',
    '選択肢に入力された値は、既に登録されています。',
  ],
  [
    'MCAHS030-013',
    '管理項目名に入力された値は、既に登録されています。',
  ],
  ['MCAHS030-014', '既に登録されてる名称も同時に変更されますがよろしいですか？'],
  ['MCAHS030-015', '既に登録されてる名称も同時に変更されますがよろしいですか？'],
  ['MCAHS030-016', '管理項目を更新しました。'],
  ['MCAHS030-017', '管理項目を削除しました。'],
  [
    'MCAHS030-018',
    'こちらの管理項目はCSVテンプレートの項目として使用されているため、削除できません。\n削除したい場合はCSVテンプレートから設定解除した後に実施してください。',
  ],

  ['MCACS020-001', '現在のパスワードの入力値が正しくありません。'],
  ['MCACS020-002', '入力されたパスワードは利用できません。パスワードのルールをご確認の上、再度入力してください。'],
  ['MCACS020-003', '入力されたパスワードを登録しました。'],
  ['MCACS020-004', '利用可能なユーザではありません。'],
  ['MCACS020-005', '入力した内容が全て破棄されます。'],
  ['MCACS020-006', '入力されたパスワードは利用できません。パスワードのルールをご確認の上、再度入力してください。'],
  ['MCACS020-000', ''],
  [
    'MCACS030-000',
    'MIWSをご利用いただくにあたり、利用規約に同意いただく必要があります。\nご利用予定のMIWSの規約に同意いただける場合は、「同意してサービスを利用する」をクリックしてください。'
  ],

  [
    'MCACS040-000',
    '\n\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;只今、応募受付のための準備中です。\n\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;準備が完了いたしましたら、ご連絡差し上げます。\n\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;もうしばらくお待ちください。',
  ],

  ['MCACS010-000', 'マイナビの関連サイトに求人を掲載されているお客様は、下記ボタンをクリックすることでログインができます。'],
  ['MCACS010-100', 'MIWSで作成したアカウントでログインする場合は下記よりログインができます。'],
  ['MCACS010-002', 'アカウントロック中です。'],
  [
    'MCACS010-003',
    'ログインIDおよびパスワードをご確認の上、再度入力してください。',
  ],
  [
    'MCACS010-004',
    'ログインの失敗回数が規定回数に達しました。アカウントをロックします。',
  ],
  [
    'MCACS010-005',
    '仮パスワードの有効期限が切れています。再度、仮パスワードを発行してください。',
  ],
  ['MCACS010-006', '既にログイン済みです。'],
  ['MCACS010-007', 'ログイン認証に失敗しました。'],
  ['MCAHS010-000', '最大100件まで管理項目を登録できます。'],
  ['MCAHS010-003', '選択されたすべての管理項目を[表示／非表示]に変更します。よろしいですか？'],
  [
    'MCAHS010-004',
    '登録上限数は100件です。',
  ],
  // #72427 次期開発2022年12月 start
  [
    'MCAHS010-007',
    '登録上限数は100件です。',
  ],
  // #72427 次期開発2022年12月 end
  ['MCAHS010-005', '管理項目を「非表示」に更新しました。'],
  ['MCAHS010-006', '管理項目を「表示」に更新しました。'],

  // MCAUS010
  [
    'MCAUS010-000',
    'ご迷惑をおかけして申し訳ありません。\nシステム上で、エラーが発生しました。\n作業途中であった場合、その操作は正しく動作していない可能性がございます。\n\nよろしければこの問題をMIWSシステム担当者にお知らせください。\nこの報告から原因を調査し、サービスの改善を目指します。',
  ],
  ['MCAUS010-001', 'エラー内容の報告に失敗しました。'],
  ['MCAUS010-003', '入力した内容が全て破棄されます。'],

  // MCAUS020
  [
    'MCAUS020-000',
    'エラー内容の送信が完了しました。\nご協力ありがとうございます。\n',
  ],

  //MCAYS010

  ['MCAYS010-000', '応募者に対するコメントを入力します。'],
  ['MCAYS010-001', 'コメントを登録しました。'],
  [
    'MCAYS010-002',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],

  [
    'MCAYS010-003',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],

  // MCAYS020
  ['MCAYS020-000', '選択した応募者データを更新します。'],
  [
    'MCAYS020-001',
    '応募者データが他の利用者により変更されています。画面を開きなおして下さい。',
  ],
  [
    'MCAYS020-002',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  ['MCAYS020-004', '書類選考免除の応募者が配信対象です。'],
  ['MCAYS020-005', '質問者が配信対象です。'],
  ['MCAYS020-006', 'セミナー情報への応募者が配信対象です。'],
  ['MCAYS020-007', 'イベント着席をした求職者が配信対象です。'],
  ['MCAYS020-008', '合否を更新しました。'],
  ['MCAYS020-009', '選考ステップを更新しました。'],
  ['MCAYS020-010', '優先度を更新しました。'],
  // 次期9月対応 #58576 start
  ['MCAYS020-018', '不採用ステータス変更が設定されているため毎日4:00に「選考ステップ：不採用」へ自動で変更します。<br />※20:00～翌日4:00の間で「合否：不合格」とした応募者は、翌々日の4:00に不採用へ変更されます。<br />不採用ステータス変更を自動で実施したくない場合は、「キャンセル」を押下し、不採用通知設定を修正してください。'],
  ['MCAYS020-019', '不採用通知の送信予約設定がされているため、<br />毎日5:00に不合格通知を自動で送信予約します。<br />送信予約日時は「選考ステップ：不採用」へ更新した日から<br />3日後の15:00に設定されます。<br /><br />不採用通知の送信予約を自動で実施したくない場合は<br />「キャンセル」を押下し、不採用通知設定を修正してください。'],
  // 次期9月対応 #58576 end
  // MCAYS030
  ['MCAYS030-000', '選択した応募者データを更新します。'],
  [
    'MCAYS030-001',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  [
    'MCAYS030-002',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  ['MCAYS030-005', '書類選考免除の応募者が配信対象に含まれています。<br />'],
  ['MCAYS030-006', '質問者が配信対象に含まれています。<br />'],
  ['MCAYS030-007', 'セミナー情報への応募者が配信対象に含まれています。<br />'],
  ['MCAYS030-008', 'イベント着席をした求職者が配信対象に含まれています。<br />'],
  ['MCAYS030-009', '合否を更新しました。'],
  ['MCAYS030-0010', '選考ステップを更新しました。'],
  ['MCAYS030-0011', '優先度を更新しました。'],
  ['MCAYS030-020', '一度に更新できるのは{0}件です。'],
  ['MCAYS030-021', '  優先度を変更しますか？'],
  ['MCAYS030-022', '  合否を変更しますか？'],
  // 次期9月対応 #58576 start
  ['MCAYS030-023', '不採用ステータス変更が設定されているため毎日4:00に「選考ステップ：不採用」へ自動で変更します。<br />※20:00～翌日4:00の間で「合否：不合格」とした応募者は、翌々日の4:00に不採用へ変更されます。<br />不採用ステータス変更を自動で実施したくない場合は、「キャンセル」を押下し、不採用通知設定を修正してください。'],
  ['MCAYS030-024', '不採用通知の送信予約設定がされているため、<br />毎日5:00に不合格通知を自動で送信予約します。<br />送信予約日時は「選考ステップ：不採用」へ更新した日から<br />3日後の15:00に設定されます。<br /><br />不採用通知の送信予約を自動で実施したくない場合は<br />「キャンセル」を押下し、不採用通知設定を修正してください。'],
  ['MCAYS030-025', '不採用通知の送信予約設定されているため毎日5：00に不合格通知を自動で送信予約します。<br />送信予約日時は「選考ステップ：不採用」へ更新した日から3日後の15：00に設定されます。<br />※退会済み、または他ルート応募には不採用通知の送信予約設定は適用されません。<br /><br />'
  + '不採用通知の送信予約を自動で実施したくない場合は「キャンセル」を押下し、共通設定の「不採用通知設定管理」より設定を変更してください。'],
  // 次期9月対応 #58576 end

  ['MCAYS040-000', ''],
  ['MCAYS040-001', '権限のない応募者データが含まれています。再度検索してください。'],
  [
    'MCAYS040-002',
    '選択されたすべての優先度を0に更新します。既に値が登録されている場合は上書きされます。\nよろしいですか？',
  ],
  [
    'MCAYS040-003',
    '選択されたすべての優先度を更新します。既に値が登録されている場合は上書きされます。\nよろしいですか？',
  ],
  ['MCAYS040-004', '優先度を更新しました。'],
  [
    'MCAYS040-005',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],

  //MCARS050
  ['MCARS050-000',
    'アクション設定にあらかじめ検索条件とアクションを設定します。<br />' +
    '応募時点で検索条件に合致するエントリーに対して設定したアクションを適用します。<br />' +
    '※アクション設定に利用できない検索条件の詳細についてはマニュアルをご覧ください。<br />' +
    '※選考フロー設定をするアクションが複数の条件に合致する場合は、最終的に合致した最下行のアクションが有効となります。<br />' +
    '※対象外設定をするアクションを指定した条件に1つでも合致した場合は、上記の適用順を問わず「対象外設定」が適用されます。'],

  //MCARS060
  ['MCARS060-000',
    '表示されている検索条件に合致する応募を受け付けた際に適用するアクションを設定できます。<br />' +
    '※複数合致する検索条件のアクション設定の中で1つでも対象外設定をしている場合は、順番を問わず「対象外設定」が適用されます。'],
  [
    'MCARS060-003',
    'アクションを設定してください。',
  ],
  [
    'MCARS060-004',
    '選択した選考フローは有効ではありません。有効な選考フローを選択してください。',
  ],
  [
    'MCARS060-005',
    '選択した選考ステップは有効ではありません。有効な選考ステップを選択してください。',
  ],
  [
    'MCARS060-006',
    '選択したタグは有効ではありません。有効なタグを選択してください。',
  ],
  [
    'MCARS060-007',
    '選択した管理項目は有効ではありません。有効な管理項目を選択してください。',
  ],
  [
    'MCARS060-008',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  [
    'MCARS060-009',
    'フィルターを使わないに設定されています。保存するとフィルターのアクションが削除され、フィルター処理が実行されませんがよろしいですか？',
  ],
  [
    'MCARS060-010',
    '設定したアクションを保存します。よろしいですか？',
  ],
  ['MCARS060-011', 'アクションを保存しました。'],
  ['MCARS060-012', 'アクションを削除しました。'],

  ['MCAUS010-001', 'エラー内容の報告に失敗しました。'],
  ['MCAUS010-002', 'エラー発生時とアカウントが異なっているため、エラー報告できません。'],

  ['MCASS020-001', '入力された内容で送信します。よろしいですか？'],

  // MCAES020 start
  ['MCAES020-007', '入力された採用グループ名は既に登録されています。'],
  [
    'MCAES020-008',
    '追加対象のユーザの中に統括または、管理者のユーザが含まれているため、追加することはできません。',
  ],
  [
    'MCAES020-009',
    '独自グループに追加する選考の中に標準選考が含まれているため、追加することはできません。',
  ],
  // MCAES020 end
  ['MCAFS010-002', '[{0}]をONにしました。'],
  ['MCAFS010-003', '[{0}]をOFFにしました。'],
  // 次期9月対応 #63073 start
  ['MCAFS010-004', '[{0}]をOFFにしました。'],
  ['MCAFS010-005', '[{0}]をONにしました。'],
  ['MCAFS020-001', '指定されたページは存在しません。'],
  ['MCAFS020-002', 'メールアドレスが入力されていません。'],
  ['MCAFS020-003', '入力された内容で更新します。よろしいですか？'],
  [
    'MCAFS020-004',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAFS010-001', '各種アラートの管理ができます。任意のアラートに対して設定をしてください。'],
  ['MCAFS020-005','通知タイミングや通知するメールアドレスを設定いただくことが可能です。<br />'+
  '複数のメールアドレスを記載する場合は、カンマ区切りで記載ください。<br />'+
  '通知タイミング「毎日」を選択すると毎朝8：15に指定のメールアドレス宛に通知メールが届きます。<br />'+
  '「随時」を選択すると、1日数回指定のメールアドレス宛に通知メールが届きます。リアルタイム配信ではありませんのでご注意ください。<br />'+
  '※通知タイミング「随時」のメール配信スケジュールは1日7回（8：15／10：15／12：15／14：15／16：15／18：15／20：15）です。<br />'+
  '　各回配信完了まで30分程度時間を要します。<br />'+
  '※前回送信分から更新がある場合のみ、配信されます。<br />'+
  '※メール配信のスケジュールの15分前に前回配信からの更新有無と内容を確認いたします。そのため、メール配信内容と実態が異なる場合がございます。<br />'+
  '※誤ったメールアドレスを設定した場合は、通知メールは届きません。通知メールが届かない場合はメールアドレスに誤りがないかご確認ください。'
  ],
  ['MCAFS020-006','MIWSより送信したe-mail宛のメッセージの内、何らかの理由で配信先サーバからエラーメッセージが到着した場合は、送信メール未着通知メールを受け取ることが可能です。<br />'+
  '受け取りたい場合は、メールアドレスをご設定ください。<br />'+
  '複数のメールアドレスを記載する場合は、カンマ区切りで記載ください。<br />'+
  '※通知メッセージが届くまで、15分程度タイムラグが発生する場合がございます。<br />'+
  '※誤ったメールアドレスを設定した場合は、通知メールは届きません。通知メールが届かない場合はメールアドレスに誤りがないかご確認ください。'
  ],
  // 転職March #75621 START
  ['MCAFS020-007','キャリアパートナーが推薦を完了した際にメールでお知らせします。<br />'+
  '※複数のメールアドレスを設定したい場合は「,」で区切ってご入力ください。<br />'+
  '※最大200文字まで設定可能です。'
  ],
  ['MCAFS020-008','採用企業が合否を変更した際または、キャリアパートナーにて合否変更が完了した際にメールでお知らせします。<br />'+
  '※複数のメールアドレスを設定したい場合は「,」で区切ってご入力ください。<br />'+
  '※最大200文字まで設定可能です。'
  ],
  ['MCAFS020-009','キャリアパートナーが合否を変更した際または、採用企業にて合否変更が完了した際にメールでお知らせします。<br />'+
  '※複数のメールアドレスを設定したい場合は「,」で区切ってご入力ください。<br />'+
  '※最大200文字まで設定可能です。'
  ],
  // 転職March #75621 END
  // 次期9月対応 #63073 end
  //MCADS020
  ['MCADS020-000', ''],
  ['MCADS020-001', '指定されたページは存在しません。'],
  ['MCADS020-002', 'システムエラーが発生しました。'],
  [
    'MCADS020-004',
    '登録可能上限数（100件）存在するため、新規に追加することはできません。',
  ],
  ['MCADS020-005', '入力された採用グループは存在しません。'],
  ['MCADS020-003', '操作をキャンセルしますか？入力した内容が保存されない可能性があります。'],
  //MCADS030
  [
    'MCADS030-000',
    'ログインIDとパスワードを入力してアカウントを登録してください。',
  ],
  [
    'MCADS030-002',
    'ログインIDに入力された値は、既に登録されています。',
  ],
  [
    'MCADS030-003',
    '入力されたパスワードは利用できません。パスワードのルールをご確認の上、再度入力してください。',
  ],
  ['MCADS030-004', 'アカウントの仮発行が完了しました。'],
  [
    'MCADS030-005',
    'URL有効期限が切れています。再度、仮アカウントを発行してください。',
  ],
  ['MCADS030-006', 'こちらのアカウントはすでに発行が完了しています。'],
  ['MCADS030-001', '入力された内容で登録します。よろしいですか？'],
  ['MCADS030-004', 'アカウントの仮発行が完了しました。'],
  ['MCADS030-007', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
  ['MCADS030-008', 'このアカウントは削除されたため登録ができません。アカウント管理者へ再度アカウント発行を依頼してください。'],
  ['MCADS030-009', 'アクセスのURLが不正です。\n' +
    'OKボタンを押した後に表示される画面から再度ログインしてください。'],
  ['MCADS030-010', '入力されたパスワードは利用できません。パスワードのルールをご確認の上、再度入力してください。'],
  //MCADS040
  ['MCADS040-000', ''],
  ['MCADS040-001', '指定されたページは存在しません。'],
  ['MCADS040-002', 'システムエラーが発生しました。'],
  ['MCADS040-004', '入力された採用グループは存在しません。'],
  ['MCADS040-003', '操作をキャンセルしますか？入力した内容が保存されない可能性があります。'],
  ['MCADS040-005', '当該アカウントは標準グループのみの所属になります。よろしいですか？'],
  ['MCADS040-006', '採用グループが設定されていないため、エントリーデータが閲覧できなくなります。よろしいですか？'],
  ['MCADS040-007', '選択したアカウントは存在しません。'],
  //MCADS050
  ['MCADS050-000', '以下の内容で情報を更新します。内容をご確認ください。'],
  ['MCADS050-001', '指定されたページは存在しません。'],
  ['MCADS050-002', 'システムエラーが発生しました。'],
  ['MCADS050-006', '選択されたログインIDは存在しません。'],
  [
    'MCADS050-007',
    '登録可能上限数（100件）存在するため、新規に追加することはできません。',
  ],
  ['MCADS050-008', '入力された採用グループは存在しません。'],
  ['MCADS050-011', 'アカウントの仮発行が完了しました。'],
  ['MCADS050-012', 'アカウントの更新が完了しました。'],
  ['MCADS050-013', 'アカウントの削除が完了しました。'],
  ['MCADS050-003', '入力された内容で登録します。よろしいですか？'],
  ['MCADS050-004', '入力された内容で更新します。よろしいですか？'],
  ['MCADS050-005', '表示しているアカウントを削除します。よろしいですか？'],
  // 次期開発12月 #75981 start
  ['MCADS050-015', '選択中の初期表示年度は有効な期間を過ぎています。初期表示年度を変更ください。'],
  // 次期開発12月 #75981 end

  //MCAGS010
  ['MCAGS010-001', '指定されたページは存在しません。'],
  ['MCAGS010-002', '入力された内容で更新します。よろしいですか？'],
  ['MCAGS010-007', '選択したテンプレートは存在しません。'],
  ['MCAGS010-008', '不採用通知の設定が完了しました。'],
  ['MCAGS010-009', '操作をキャンセルしますか？入力した内容が保存されない可能性があります。'],
  [
    'MCAGS010-000',
    '不採用通知設定を行います。\n不採用ステータス変更では、「合否：不合格」を対象として「選考ステップ：不採用」へ自動に更新する設定をします。'
  ],
  // 次期9月対応 #58576 start
  [
    'MCAGS010-00A',
    'ワンクリック不採用通知のメッセージテンプレートを設定し、更新ボタンを押下してください。\nエントリー詳細にある「ワンクリック書類選考／面接選考不採用通知の送信ボタン」より、翌日15:00を配信予約日とした不採用通知メッセージを送信できるようになります。\nその他、選考ステップを起点とした不採用通知の自動送信予約機能もご利用いただけます。',
  ],
  // 次期9月対応 #58576 end
  ['MCAGS010-010', '入力した内容が全て破棄されます。'],
  ['MCAGS010-012', '有効なメッセージテンプレートが存在しません。'],
  ['MCAGS010-013', '件名または本文に値が登録されていないテンプレートは指定できません。'],

  //MCAES010
  [
    'MCAES010-000',
    '採用グループではアカウントをグループ化し、閲覧できる応募データを制限できます。\n最大50件まで採用グループを登録できます。',
  ],
  [
    'MCAES010-002',
    '登録上限数は50件です。',
  ],
  [
    'MCAES010-003',
    '登録上限数は50件です。',
  ],
  //MCAES020
  ['MCAES020-001', '指定されたページは存在しません。'],
  ['MCAES020-002', 'システムエラーが発生しました。'],
  ['MCAES020-003', 'システムエラーが発生しました。'],
  ['MCAES020-004', 'システムエラーが発生しました。'],
  [
    'MCAES020-005',
    '登録上限数は50件です。',
  ],
  ['MCAES020-006', '入力された採用グループ名は、既に登録されています。'],
  ['MCAES020-007', '入力された採用グループ名は、既に登録されています。'],
  [
    'MCAES020-008',
    '追加対象のアカウントの中に統括または、管理者のアカウントが含まれているため、追加することはできません。',
  ],
  [
    'MCAES020-009',
    'こちらの採用グループに追加する選考フローの中に、システム標準の選考フローが含まれているため、追加することはできません。',
  ],
  ['MCAES020-010', '標準グループから標準選考は、はずすことはできません。'],
  ['MCAES020-011', '入力された内容で登録します。よろしいですか？'],
  ['MCAES020-012', '入力された内容で更新します。よろしいですか？'],
  ['MCAES020-013', '操作をキャンセルしますか？入力した内容が保存されない可能性があります。'],
  ['MCAES020-014', '表示している採用グループを削除します。よろしいですか？\n採用グループを削除すると紐づくアカウントが応募者情報を参照できなくなりますのでご注意ください。'],
  ['MCAES020-015', '採用グループの登録が完了しました。'],
  ['MCAES020-016', '採用グループの更新が完了しました。'],
  ['MCAES020-017', '採用グループの削除が完了しました。'],
  //MCAXS090
  ['MCAXS090-000', '最大50件まで添付ファイルを登録できます。<br/>ファイルサイズの上限は10MByteまでになります。<br/><span style="color:' + '#e53935' + '">パスワード付きzipファイルは添付できません。</span>'],

  // MCAES020
  ['MCAES020_000', ''],
  [
    'MCAXS090-001',
    '登録上限数は50件です。',
  ],
  ['MCAXS090-002', '選択されたファイルを登録します。よろしいですか？'],
  ['MCAXS090-003', '選択された添付ファイルを削除します。よろしいですか？'],
  ['MCAXS090-004', '添付ファイルを登録しました。'],
  ['MCAXS090-005', '添付ファイルを削除しました。'],
  ['MCAXS090-007', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAXS090-008', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx'],
  ['MCAXS090-100', '添付ファイルを入力してください。'],
  ['MCAXS090-010', 'パスワード付きzipファイルは添付できません。'],
  ['MCAXS090-011', 'パスワード付きzipファイルは添付できません。'],
  // 年度対応　start
  ['MCAXS090-012', '選択された添付ファイルを削除します。\n削除すると他の年度でも参照できなくなります。よろしいですか？'],
  // 年度対応　end
  //MCACS030
  ['MCACS030-001', '利用可能なアカウントではありません。'],
  // MCAFS010
  ['MCAFS010-000', ''],
  // MCASS010
  [
    'MCASS010-000',
    'ご報告いただいた内容は、規約の改訂を含めた今後の対応方針策定の参考にさせていただきますが、以下フォームからお問い合わせやご質問をいただいても回答はいたしかねます。\nまた、個別の会員に対し、何か対処・対応することを目的としたものではなく、「いつ」「なにを」「どうする」といった約束や対応方法のアドバイスはいたしかねます。',
  ],
  // MCASS010
  [
    'MCASS020-000',
    '送信内容を確認します。<br><br><div style="color:black;font-weight:bold;float:left;">ご利用方法：</div><div style="color:black;float:left;margin-left:8px;">以下の内容で送信します。よろしければ<label style="font-weight:bold">「送信する」</label>ボタンをクリックしてください。<br>内容に変更がある場合は<label style="font-weight:bold">「キャンセルする」</label>ボタンをクリックして、入力画面に戻り変更してください。</div>',
  ],
  // MCASS010
  [
    'MCASS030-000',
    '会員による迷惑行為のご報告を受け付けました。\n貴重なご報告をいただき、ありがとうございます。\n今後の「採用活動における会員の迷惑行為」への対応案および規約改定の参考にさせていただきます。\n\nなお、ご報告いただきました会員が他のご参画企業様にも同様に迷惑行為や違反行為を繰り返し行っている事が確認できた場合、\n内容や頻度を鑑みマイナビ転職より当該会員に対し、警告や会員登録の失効手続きなどを行わせていただく場合もございます。',
  ],
  [
    'MCASS010-002',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  [
    'MCASS020-002',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  //MCAJS010
  //初期表示時
  ['MCAJS010-000', '選考フローごとに、選考ステップを管理できます。'],
  ['MCAJS010-001', '指定されたページは存在しません。'],
  // #75311 25KH start
  // 26P #82433 start
  ['MCAJS010-002', 'プレサイトサービス開始時に「プレサイト期間選考フロー（サンプル）」をコピーして「20XXプレサイト期間」が自動的に作成されます。\n本サイトサービス開始時に「採用選考フロー（サンプル）」をコピーして「20XX本サイト採用」が自動的に作成されます。'],
  // 26P #82433 end
  // #75311 25KH end
  //MCAJS020
  //"登録する"押下時
  ['MCAJS020-C001', '終了日が開始日よりも前です'],
  [
    'MCAJS020-000',
    '選考ステップは並び替え可能範囲以外は固定です。1つのフローで設定可能な選考ステップは最大10個です。（選考ステップは最大20個まで作成できます。）',
  ],
  ['MCAJS020-003', '入力された内容で登録します。よろしいですか？'],
  [
    'MCAJS020-004',
    '表示可能な選考ステップ個数は10個までのため、登録できません。',
  ],

  ['MCAXS351-001', '応募者管理IDの桁数が間違っています。'],
  ['MCAXS351-002', '応募者管理IDに設定できる上限（10,000件）を超えています。'],
  ['MCAXS351-003', '氏名・氏名カナは100件以内で入力してください。'],
  ['MCAXS351-004', '応募者情報\n・エントリー日時\n・エントリー有無\n・受付日程・予約状況\n・イベント参加状況\nいずれかの条件を入力してください。'],
  [
    'MCAXS351-005',
    '複数のMY検索が検索条件に使用されているため、MY検索の保存が出来ません。'
  ],
  // MCBリプレース MCAXS701 START
  ['MCAXS351-006', 'My CareerBox情報\n' +
  '締切日指定の場合\n' +
  '・提出締切日\n' +
  '開始または終了いずれかの条件を入力してください。',],
  ['MCAXS351-007', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\n ご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。'],
  // MCBリプレース MCAXS701 END
  ['MCAXS361-001', '応募経路を選択しない場合は、対象年度内の応募者初回エントリーが検索対象となります。'],
  ['MCAXS361-002', '予約者のみが表示されます。キャンセル者は含まれません。'],
  ['MCAXS371-001', '院／大学が対象です。'],
  ['MCAXS371-002', '海外の学校の場合に指定してください。'],
  ['MCAXS381-001', '複数指定する場合は改行で入力してください。一度に設定できるのは10,000件です。'],
  ['MCAXS381-002', '複数指定する場合は改行で入力してください。一度に設定できるのは100件です。'],
  ['MCAXS381-003', '複数指定はできません。'],
  ['MCAXS381-004', '前方一致で検索されます。複数指定はできません。'],
  ['MCAXS381-005', '会員はマイページでメッセージのやりとりができる応募者です。退会者やアップロードした応募者は「会員以外」で検索されます。'],
  ['MCAXS391-001', '選考ステップや合否の一括更新をする場合は必ず1つに絞ってください。\nまた、MY検索登録後、アクション設定をする場合には選考フロー・選考ステップは全てチェックを外してください。'],
  ['MCAXS391-002', '現在の合否を検索します。\n参加／不参加／その他は「出欠管理型」の選考ステップでのみ使用される合否です。'],
  ['MCAXS391-003', '複数指定した場合はANDで検索されます。'],
  ['MCAXS391-004', '現在の選考ステップを検索します。\n' +
    '選考ステップや合否の一括更新をする場合は必ず1つに絞ってください。\n' +
    'また、MY検索登録後、アクション設定をする場合には選考フロー・選考ステップは全てチェックを外してください。'],
  // 25KH #75311 start
  ['MCAXS691-000', '選考ステップ／合否の検索ができます。<br />' +
    '現在の選考段階、過去の履歴を含む選考段階を指定し検索が可能です。'],
  ['MCAXS691-001', 'キャンセルすると選択した条件がクリアされます。よろしいですか？'],
  ['MCAXS691-002', '選考ステップ／合否の検索が可能です。\n' +
    '条件追加後、更に条件を追加してAND検索、OR検索が可能です。'],
  ['MCAVS010-010', '選考ステップの履歴情報をもとに進捗が確認できます。内定には、内定・内定承諾・入社の履歴がある人数が表示されます。承諾には、内定承諾・入社の履歴がある人数が表示されます。'],
  // 25KH 75311 end
  ['MCAXS401-001', '利用者番号は10,000件以内で入力してください。'],
  ['MCAXS401-002', '氏名・氏名カナは100件以内で入力してください。'],
  [
    'MCAXS421-001',
    '複数指定する場合は改行で入力してください。一度に設定できるのは10,000件です。',
  ],
  [
    'MCAXS421-002',
    '複数指定する場合は改行で入力してください。一度に設定できるのは100件です。',
  ],
  ['MCAXS431-001', '参加／不参加／その他は「出欠管理型」の選考ステップでのみ使用される合否です。'],
  ['MCAXS431-002',
    '合否・選考ステップを一括変更する場合は、選考フローを選択し、選考ステップを1つに絞ってください。\n' +
    '選考ステップはアクション設定に使用できません。アクション設定を利用する場合は、全てのチェックを外して下さい。'],
  // 転職March start
  ['MCAXS431-003', '合否を一括変更する場合は、選考フローを選択し、選考ステップを1つに絞ってください。'],
  // 転職March end
  [
    'MCAXS521-001',
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  ],
  [
    'MCAXS561-001',
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  ],
  [
    'MCAXS501-001',
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  ],
  [
    'MCAJS020-005',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  [
    'MCAJS020-007',
    '選考ステップに紐づく「応募者データが存在している」または「他の選考フローで使用されている」ため、削除できません。',
  ],
  [
    'MCAJS020-008',
    '他の選考フローで使用している場合、そちらの選考ステップ名も変更されます。',
  ],
  [
    'MCAJS020-009',
    '選考ステップ名に入力された値は、既に登録されています。',
  ],
  [
    'MCAJS020-010',
    '選考フロー名に入力された値は、既に登録されています。',
  ],
  ['MCAJS020-011', '選考フローを登録しました。'],
  [
    'MCAJS020-012',
    '選考期間の終了日に過去の日付が指定されています。よろしいですか？',
  ],
  [
    'MCAJS020-013',
    '選考ステップが既に20個登録されているため、新規に追加することができません。',
  ],
  [
    'MCAJS020-014',
    'こちらの選考ステップは、アクションとして使用されているため、削除できません。\n削除したい場合は、アクションから設定解除した後に実施してください。',
  ],
  [
    'MCAJS020-015',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  [
    'MCAJS020-016',
    '「{0}」は「{1}」でのみ利用できる選考ステップ名です。',
  ],
  // 年度対応 start
  [
    'MCAJS020-017',
    '選考フローを登録しました。入力された内容を確認する場合は、指定した対象年度に切り替えてご確認ください。',
  ],
  // 次期9月対応 #62671 start
  [
    'MCAJS020-018',
    '選考フロー名に 【標準選考】 の文字は使用できません。',
  ],
  [
    'MCAJS020-019',
    '※選考フロー名の先頭に【標準選考】が必ず表示されます',
  ],
  // 次期9月対応 #62671 end
  // 年度対応 end
  //MCAJS030
  ['MCAJS030-C001', '終了日が開始日よりも前です'],
  //初期表示時
  ['MCAJS030-000', ''],
  ['MCAJS030-001', '指定されたページは存在しません。'],
  //"更新する"押下時
  ['MCAJS030-005', '入力された内容で更新します。よろしいですか？'],
  ['MCAJS030-002', 'システムエラーが発生しました。'],
  [
    'MCAJS030-011',
    '選考フロー名に入力された値は、既に登録されています。',
  ],
  [
    'MCAJS030-023',
    '選考ステップ一覧に存在する選考ステップに紐づく応募者データが存在するため、更新できません。\n以下の選考ステップは、選考フローに戻してください。\n\n該当の選考ステップ\n{0}',
  ],
  ['MCAJS030-020', '既に紐づけされている選考フローの名称もすべて変更されます。'],
  [
    'MCAJS030-022',
    '選考期間の終了日に過去の日付が指定されています。よろしいですか？',
  ],
  ['MCAJS030-021', '選考フローを更新しました。'],
  //選考ステップダイアログ"OK"押下時
  ['MCAJS030-008', '選考ステップ名を入力してください。'],
  [
    'MCAJS030-026',
    '選考ステップが既に20個登録されているため、新規に追加することができません。',
  ],
  [
    'MCAJS030-010',
    '選考ステップ名に入力された値は、既に登録されています。',
  ],
  [
    'MCAJS030-009',
    '他の選考フローで使用している場合、そちらの選考ステップ名も変更されます。',
  ],
  [
    'MCAJS030-030',
    '「{0}」は「{1}」でのみ利用できる選考ステップ名です。',
  ],
  //#72716 start
  [
    'MCAJS030-029',
    '選考フローを削除した場合、選考フローに紐づくこれまでの送受信メッセージも削除されます。\n削除された送信メッセージに対して、応募者から返信メッセージがあった場合も確認することができなくなります。\nまた、一度選考フローを削除すると復活することはできません。\n\n削除してもよろしいですか？',
  ],
  //#72716 end
  //カスタマイズ進捗"×"ボタン押下時
  [
    'MCAJS030-012',
    '選考ステップに紐づく「応募者データが存在している」または「他の選考フローで使用されている」ため、削除できません。',
  ],
  [
    'MCAJS030-027',
    `こちらの選考ステップは、アクションとして使用されているため、削除できません。\n削除したい場合は、アクションから設定解除した後に実施してください。`,
  ],
  //"選考を終了する"押下時
  ['MCAJS030-017', '選考フローを終了します。よろしいですか？'],
  [
    'MCAJS030-014',
    'こちらの選考フローはシステム標準の選考フローのため、選考を終了することはできません。',
  ],
  ['MCAJS030-024', '選考フローの期間を終了しました。'],
  //"削除する"押下時
  ['MCAJS030-018', '選考フローを削除します。よろしいですか？'],
  [
    'MCAJS030-015',
    'こちらの選考フローはシステム標準の選考フローのため、削除することはできません。',
  ],
  [
    'MCAJS030-016',
    '選考フローに紐づく応募者データが存在するため、削除できません。',
  ],
  ['MCAJS030-025', '選考フローを削除しました。'],
  [
    'MCAJS030-019',
    `こちらの選考フローはアクションとして使用されているため、削除できません。\n削除したい場合は、アクションから設定解除した後に実施してください。`,
  ],
  //"キャンセルする"押下時
  [
    'MCAJS030-007',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  [
    'MCAJS030-028',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  //進捗を"並び替え可能位置に移動"した時
  ['MCAJS030-006', '表示可能な選考ステップ個数は10個までのため、登録できません。'],
  [
    'MCAJS030-013',
    '選考ステップに紐づくエントリーデータが存在するため、選考ステップ一覧に戻すことはできません。',
  ],

  // MCAKS010
  ['MCAKS010-000', 'こちらの一覧はタグ名順で表示されています。\nアクション設定に使用しているタグを削除することはできません。'],
  ['MCAKS010-003', 'こちらのタグはアクションとして使用されているため、削除できません。\n削除したい場合は、アクションから設定解除した後に実施してください。'],
  ['MCAKS010-004', 'タグを削除しました。'],
  [
    'MCAKS010-005',
    'タグを削除します。よろしいですか？\nこちらのタグが紐づいている応募者データからも、タグが削除されますのでご注意ください。',
  ],
  [
    'MCAKS010-006',
    '削除対象のタグと紐づく応募者データからも、こちらのタグが削除されます。\nよろしいですか？',
  ],
  ['MCAKS010-007', '条件に一致する検索結果がありませんでした。'],
  [
    'MCAKS010-009',
    '終了日が開始日よりも前です',
  ],

  // MCAKS020
  ['MCAKS020-000', ''],
  [
    'MCAKS020-003',
    '現在付与されているタグ名をすべて変更します。よろしいですか？',
  ],
  [
    'MCAKS020-004',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAKS020-005', 'タグ名を変更しました。'],

  //MCALS010
  ['MCALS010-000', '最大50件までテンプレートを登録できます。'],
  [
    'MCALS010-004',
    '登録上限数は50件です。',
  ],
  ['MCALS010-005', 'テンプレートを「非表示」に更新しました。'],
  ['MCALS010-006', 'テンプレートを「表示」に更新しました。'],

  // MCALS020
  ['MCALS020-000', ''],
  [
    'MCALS020-003',
    '登録可能上限数（50件）存在するため、新規に追加することはできません。',
  ],
  ['MCALS020-004', '入力されたテンプレート名は、既に登録されています。'],
  [
    'MCALS020-005',
    '「選考フロー・評価」または「管理項目」の項目が選択されていません。',
  ],
  [
    'MCALS020-006',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCALS020-007', '選択された項目が削除されているため項目追加できません。{0}'],
  [
    'MCALS020-008',
    '選択された項目のエントリーボックスが表示期間外 または 紐づく有効なエントリーデータが存在しないため項目追加できません。{0}',
  ],
  ['MCALS020-009', '出力単位を取得できませんでした。'],
  ['MCALS020-010', '履歴種別を取得できませんでした。'],
  ['MCALS020-011', 'レイアウト分類を取得できませんでした。'],
  ['MCALS020-012', 'エントリー履歴を取得できませんでした。'],
  ['MCALS020-013', '説明会・面接履歴を取得できませんでした。'],
  ['MCALS020-014', '面接日程履歴を取得できませんでした。'],
  ['MCALS020-015', 'イベント参加履歴を取得できませんでした。'],
  ['MCALS020-016', '出力する選考フローを取得できませんでした。'],
  ['MCALS020-017', 'キャンセルされた履歴を取得できませんでした。'],
  // 年度対応 start
  ['MCALS020-018', 'テンプレート対象年度を変更したため、選択済項目の一部を選択から外しました。'],
  // 年度対応 end
  // #63075 次期開発2022年9月 start
  ['MCALS020-019', '必須項目は移動できません。'],
  // #63075 次期開発2022年9月 end

  // MCALS030
  ['MCALS030-000', ''],
  ['MCALS030-003', '入力されたテンプレート名は、既に登録されています。'],
  [
    'MCALS030-004',
    '「選考フロー・評価」または「管理項目」の項目が選択されていません。',
  ],
  [
    'MCALS030-005',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCALS030-006', '選択された項目が削除されているため項目追加できません。{0}'],
  [
    'MCALS030-007',
    '選択された項目のエントリーボックスが表示期間外 または 紐づく有効なエントリーデータが存在しないため項目追加できません。{0}',
  ],
  ['MCALS030-008', 'エントリー履歴を取得できませんでした。'],
  ['MCALS030-009', '説明会・面接履歴を取得できませんでした。'],
  ['MCALS030-010', '面接日程履歴を取得できませんでした。'],
  ['MCALS030-011', 'イベント参加履歴を取得できませんでした。'],
  ['MCALS030-012', '出力する選考フローを取得できませんでした。'],
  ['MCALS030-013', 'キャンセルされた履歴を取得できませんでした。'],

  // MCARS010
  [
    'MCARS010-000',
    '最大100件までMY検索を登録できます。',
  ],
  ['MCARS010-00A', ''],
  ['MCARS010-002', '一括操作を実施するドロップダウンリストを選択して下さい。'],
  ['MCARS010-003', '選択した一括操作を実行します。よろしいですか？'],
  // MCB ロット３ MY検索（応募管理）一覧　start
  [
    'MCARS010-004-1',
    'アクション設定ありのMY検索（応募管理）を削除します。よろしいですか？',
  ],
  [
    'MCARS010-004-2',
    'アクション設定ありのMY検索（応募管理）を削除することはできません。',
  ],
  ['MCARS010-005', 'MY検索（応募管理）名を入力してください。'],
  [
    'MCARS010-006',
    '同じ名称でMY検索（応募管理）を保存できません。',
  ],
  // MCB ロット３ MY検索（応募管理）一覧　end
  [
    'MCARS010-007',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCARS010-008', '入力された内容で更新します。よろしいですか？'],
  // MCB ロット３ MY検索（応募管理）一覧　start
  ['MCARS010-009', 'MY検索（応募管理）を削除しました。'],
  ['MCARS010-010', 'MY検索（応募管理）を更新しました。'],
  // MCB ロット３ MY検索（応募管理）一覧　end
  ['MCARS010-011', 'アクションで利用できない検索条件が含まれているためアクションの設定はできません。利用できない検索条件の詳細についてはマニュアル「付録ー検索詳細で使用できる検索項目」をご覧ください。'],

  // MCAZS030
  [
    'MCAZS030-003',
    'メッセージの送信をキャンセルしてもよろしいですか？\nキャンセルしたメッセージは下書きに移動します。',
  ],
  ['MCAZS030-004', 'メッセージを送信してもよろしいですか？'],
  ['MCAZS030-005', '下書きに移動しました。'],
  ['MCAZS030-006', '送信しました。'],
  [
    'MCAZS030-007',
    '送信予約ではないメッセージが含まれるため、送信をキャンセルできませんでした。',
  ],
  [
    'MCAZS030-008',
    '送信予約ではないメッセージが含まれるため、すぐに送信できませんでした。',
  ],
  ['MCAZS030-009', '条件に一致する検索結果がありませんでした。'],
  // [phase2] start by zhangxp
  ['MCAZS030-010', 'メッセージ送信に失敗した応募者'],
  // [phase2] end by zhangxp
  // #5654 #59450 start
  ['MCAZS030-011', '一括メッセージ送信対象の応募者'],
  // #5654 #59450 end
  // 次期開発5月向#58931 start
  ['MCAZS030-012', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // 次期開発5月向#58931 end
  ['MCAZS040-002', '一括操作したいメッセージを選択してください'],
  [
    'MCAZS040-003',
    '選択されたメッセージをゴミ箱に移動します。よろしいですか？',
  ],
  ['MCAZS040-004', 'ゴミ箱に移動しました。'],
  ['MCAZS040-005', '条件に一致する検索結果がありませんでした。'],
  // #5654 #59450 start
  ['MCAZS040-006', '一括メッセージ送信対象の応募者'],
  // #5654 #59450 end
  // 次期開発5月向#58931 start
  ['MCAZS040-007', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // 次期開発5月向#58931 end

  // MCAZS050
  ['MCAZS050-002', '一括操作したいメッセージを選択してください'],
  [
    'MCAZS050-003',
    '選択したメッセージをゴミ箱から元に戻してもよろしいですか？\n送信予約のメッセージは下書きに移動します。',
  ],
  ['MCAZS050-004', '削除してもよろしいですか？\n削除したメッセージは元に戻せません。'],
  ['MCAZS050-005', 'ゴミ箱から戻しました。'],
  ['MCAZS050-006', 'メッセージを削除しました。'],
  ['MCAZS050-007', '条件に一致する検索結果がありませんでした。'],
  ['MCAZS050-008', 'ゴミ箱移動後1年以上経過している場合は、自動的にメッセージが削除されますのでご注意ください。'],
  // #5654 #59450 start
  ['MCAZS050-009', '一括メッセージ送信対象の応募者'],
  // #5654 #59450 end
  // 次期開発5月向#58931 start
  ['MCAZS050-010', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // 次期開発5月向#58931 end

  //MCARS030
  // 次期開発9月#51248 start
  [
    'MCARS030-000',
    '現在の検索条件を保存します。\n保存方法を上書き保存、新規保存、またはアクション設定条件保存から選択し「OK」ボタンをクリックしてください。'
  ],
   // 次期開発9月#51248 end
  // MCB ロット３ MY検索（応募管理）保存 start
  ['MCARS030-003', 'MY検索（応募管理）を選択してください。'],
  ['MCARS030-004', 'MY検索（応募管理）名称を入力してください。'],
  ['MCARS030-005', '同じ名称でMY検索（応募管理）を保存できません。'],
  // MCB ロット３ MY検索（応募管理）保存 end
  [
    'MCARS030-006',
    '登録上限数は100件です。',
  ],
  [
    'MCARS030-007',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  // MCB ロット３ MY検索（応募管理）保存 start
  ['MCARS030-008', 'MY検索（応募管理）を保存します。よろしいですか？'],
  ['MCARS030-009', 'MY検索（応募管理）を保存しました。'],
  ['MCARS030-010', 'アクションで利用できない検索条件が含まれているためアクションが設定されているMY検索（応募管理）への上書き保存はできません。利用できない検索条件の詳細についてはマニュアルをご覧ください。'],
  ['MCARS030-011', 'アクションが設定されているMY検索（応募管理）に上書きしようとしています。よろしいですか？'],
  ['MCARS030-012', '利用したMY検索（応募管理）に設定されているアクションは新規保存したMY検索（応募管理）へ引き継がれません。MY検索（応募管理）を保存してよろしいですか？'],
  // MCB ロット３ MY検索（応募管理）保存 end
  // 転職March #75621 START
  ['MCARS030-000-1', '現在の検索条件を保存します。\n保存方法を上書き保存、または新規保存から選択し「OK」ボタンをクリックしてください。'],
  // 転職March #75621 END
  //MCAZS010
  ['MCAZS010-002', '一括操作したいメッセージを選択してください。'],
  ['MCAZS010-003', '選択されたメッセージをゴミ箱に移動します。よろしいですか？'],
  ['MCAZS010-004', 'ゴミ箱に移動しました。'],
  ['MCAZS010-005', '条件に一致する検索結果がありませんでした。'],
  // phase2 start jxp
  ['MCAZS010-007', '選考フローが複数ある応募者が存在します。<br/>更新の新しい選考フローでメッセージを送りますが、よろしいでしょうか。<br/>必要に応じて応募管理から検索条件やフィルタで選考フローをしぼってから、<br/>メッセージ送信をして下さい。'],
  ['MCAZS010-006', '応募経路が混ざっている状態です。\n応募管理から応募経路をしぼって、メッセージ送信をしてください。'],
  // phase2 start end
  // 次期開発5月向#58931 start
  ['MCAZS010-008', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // 次期開発5月向#58931 end
  //MCAZS020
  ['MCAZS020-003', '選択されたメッセージをゴミ箱に移動します。よろしいですか？'],
  ['MCAZS020-004', 'ゴミ箱に移動しました。'],
  ['MCAZS020-005', 'メッセージ送信に失敗した応募者'],
  ['MCAZS020-006', '条件に一致する検索結果がありませんでした。'],
  ['MCAZS020-007', '送信中のメッセージが選択されているためゴミ箱に移動できません。'],
  ['MCAZS020-008', 'メッセージ送信に伴う処理が完了していないメッセージが含まれています。\n恐れ入りますが、時間をおいて再度実施してください。'],
  // #5654 #59450 start
  ['MCAZS020-009', '一括メッセージ送信対象の応募者'],
  // #5654 #59450 end
  // 次期開発5月向#58931 start
  ['MCAZS020-010', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // 次期開発5月向#58931 end
  //MCAZS080
  ['MCAZS080-001', '指定されたページは存在しません。'],
  [
    'MCAZS080-002',
    'こちらのメッセージは変更されました。画面を更新してもう一度ご確認ください。',
  ],
  [
    'MCAZS080-003',
    'マイナビ退会者には送信できません。\n送信対象をご確認ください。',
  ],
  [
    'MCAZS080-004',
    '送信対象に匿名質問が含まれています。\n匿名宛に送信する場合、対象を匿名質問のみにしてください。',
  ],
  ['MCAZS080-005', '{0}は使用できない項目のため置換できません。\n'],
  ['MCAZS080-006', '{0}は値が取得できないため置換できません。\n'],
  ['MCAZS080-007', '{0}は値が取得でないため、該当の応募者宛には空白に置換されます。\n'],
  //#74734 維持保守 start
  ['MCAZS080-008', '表示期間外の予約したセミナー日程が挿入されています。\n'],
  
  [
    'MCAZS080-009',
    '挿入された「セミナー日程」「予約したセミナー日程」の情報は既に削除されています。\n',
  ],
  ['MCAZS080-010', '存在しない予約したセミナー日程が挿入されています。\n'],
  ['MCAZS080-011', '「予約したセミナー日程」は1つしか挿入できません。\n'],
  //#74734 維持保守 end
  [
    'MCAZS080-012',
    '文字の装飾「{0}」の挿入が不正です。\n',
  ],
  ['MCAZS080-013', '件名にご利用いただけない文字が含まれています。\n'],
  ['MCAZS080-014', '本文にご利用いただけない文字が含まれています。\n'],
  ['MCAZS080-015', '下書きに保存してもよろしいですか？'],
  ['MCAZS080-016', '下書きに保存しました。'],
  [
    'MCAZS080-017',
    '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx',
  ],
  ['MCAZS080-018', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAZS080-019', '添付ファイルの登録上限数は5件です。'],
  ['MCAZS080-020', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  [
    'MCAZS080-021',
    '送信元メールアドレスは半角英数字で"ユーザ名@ドメイン名"形式で入力して下さい。',
  ],
  ['MCAZS080-022', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAZS080-023', '添付ファイルの登録上限数は5件です。'],
  ['MCAZS080-024', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  [
    'MCAZS080-025',
    '送信先に「マイナビのみ」が選択されていない場合は、添付ファイルを送信できません。',
  ],
  ['MCAZS080-026', '同じ名称の添付ファイルが複数選択されています。'],
  [
    'MCAZS080-027',
    '送信先に「マイナビのみ」が選択されていない場合は、バーコードの「表示する」を選択できません。',
  ],
  ['MCAZS080-028', '件名にご利用いただけない文字が含まれています。'],
  ['MCAZS080-029', '本文にご利用いただけない文字が含まれています。'],
  [
    'MCAZS080-030',
    '{番号}番目のホームページタイトルにご利用いただけない文字が含まれています。',
  ],
  [
    'MCAZS080-031',
    '{番号}番目のホームページURLにご利用いただけない文字が含まれています。',
  ],
  ['MCAZS080-032', 'URLが入力されていません。'],
  [
    'MCAZS080-033',
    '件名または本文にテンプレートで利用される文字列「●●」が含まれています。',
  ],
  [
    'MCAZS080-034',
    'システムメンテナンス期間の予約はできません。\n（{0}）',
  ],
  ['MCAZS080-035', '面接確定時は送信予約できません。'],
  ['MCAZS080-036', '送信予約日が未入力です。'],
  ['MCAZS080-037', '送信予約日時に過去は指定できません。'],
  [
    'MCAZS080-038',
    'ご指定の予約日時は応募経路が契約期間外となるため、送信予約できません。予約日時を変更するか、送信先をご確認ください。',
  ],
  [
    'MCAZS080-039',
    'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。',
  ],
  [
    'MCAZS080-040',
    'ご指定の予約日時はサービス期間が終了になる応募経路の応募者が送信先に含まれているため、送信予約できません。予約日時を変更するか、送信先をご確認ください。',
  ],
  [
    'MCAZS080-041',
    'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。',
  ],
  [
    'MCAZS080-042',
    'ご指定の予約日時はサービス期間が終了になる応募経路の応募者が送信先に含まれているため、送信予約できません。予約日時を変更するか、送信先をご確認ください。',
  ],
  [
    'MCAZS080-043',
    '送信予約日時については本日より30日後まで設定できます。設定可能期間内に変更をお願いします。',
  ],
  ['MCAZS080-044', '送信予約日時が添付オプションの表示期間外です。'],
  [
    'MCAZS080-045',
    '送信予約日時までの残り時間が15分を切ったため予約できません。送信予約日時を設定しなおしてください。',
  ],
  ['MCAZS080-046', '添付オプションの指定可能数は{0}件です。'],
  [
    'MCAZS080-047',
    'マイナビの応募経路が混在しているため、マイページへメッセージ送信できません。すべてe-mailへの送信となります。送信先を「e-mailのみ」に設定してください。\nマイページへのメッセージとして送信する場合には、対象者の応募経路を絞ってから再度設定してください。',
  ],
  [
    'MCAZS080-048',
    '送信対象に非会員が含まれているため、送信先が「マイナビのみ」で送信できません。送信対象をご確認ください。',
  ],
  [
    'MCAZS080-049',
    '送信対象に匿名質問が含まれているため、送信先が「e-mailのみ」で送信できません。\n送信対象をご確認ください。',
  ],
  [
    'MCAZS080-050',
    '送信元メールアドレスが未入力のため、送信先が「e-mailのみ」「マイナビ優先」で送信できません。\n送信元メールアドレスをご確認ください。',
  ],
  [
    'MCAZS080-051',
    '送信可能な対象者が存在しません。\n送信対象をご確認ください。',
  ],
  [
    'MCAZS080-052',
    '送信可能な対象者が存在しません。\n送信対象をご確認ください。',
  ],
  [
    'MCAZS080-053',
    '送信可能な対象者が存在しません。\n送信対象をご確認ください。',
  ],
  [
    'MCAZS080-054',
    'マイナビ退会者には送信できません。\n送信対象をご確認ください。',
  ],
  [
    'MCAZS080-055',
    '送信対象に匿名質問が含まれています。\n匿名質問宛に送信する場合、対象を匿名質問のみにしてください。',
  ],
  ['MCAZS080-056', '閲覧期限を過ぎている応募者が含まれるため送信できません。'],
  [
    'MCAZS080-057',
    '閲覧期限を過ぎている応募者が含まれるため送信予約できません。',
  ],
  [
    'MCAZS080-058',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAZS080-059', 'メッセージの送信権限がありません。'],
  [
    'MCAZS080-060',
    '送信元メールアドレスが未入力のため、送信先が「e-mailのみ」で送信できません。送信元メールアドレスをご確認ください。',
  ],
  //#74734 維持保守 start
  ['MCAZS080-062', '「予約したセミナー日程」は1つしか挿入できません。\n'],
  //#74734 維持保守 end
  ['MCAZS080-063', 'ご注意ください！\n送信先をマイナビ宛に変更します。メッセージはマイナビのマイページに送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  ['MCAZS080-064', 'ご注意ください！\n送信先をマイナビ優先に変更します。\nマイナビ会員にはマイナビのマイページへ、マイナビ非会員には登録されているメールアドレス宛に送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  ['MCAZS080-065', 'ご注意ください！\n送信先をe-mail宛に変更します。メールは登録されているメールアドレス宛に送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  ['MCAZS080-066', '応募者からの返信期限に過去は指定できません。'],
  ['MCAZS080-067', '応募者からの返信期限に送信予約日時より過去は指定できません。'],
  ['MCAZS080-068', 'ご指定の応募者からの返信期限は応募経路が契約期間外となるため、設定できません。応募者からの返信期限を変更するか、送信先をご確認ください。'],
  ['MCAZS080-069', '送信可能なメールアドレスがありません。'],
  ['MCAZS080-070', 'パスワード付きzipファイルは添付できません。'],
  ['MCAZS080-071', '権限がないため、操作を実行できません。'],
  ['MCAZS080-072', 'パスワード付きzipファイルは添付できません。'],
  ['MCAZS080-073', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCAZS080-074', '本文には、差し込み文字以外の文字も入力してください。'],
  ['MCAZS080-075', '本文に面接日時またはWEB面接URLが含まれていません。'],
  ['MCAZS080-076', 'WEB面接URLがすでにキャンセルされています。\nWEB面接URL発行を再度実行してください。'],
  ['MCAZS080-077', '選択したテンプレート種別は、「My CareerBox」であるため、一部利用できない入力項目が削除されます。'],
  ['MCAZS080-078', '件名、本文には差し込み文字以外の文字も入力してください。'],
  ['MCAZS080-079', '送信メッセージからの返信ではないため{@reply_title}{@reply_body}は使用できません。'],

  //MCBAS010
  ['MCBAS010-003', '候補日が設定されていません。「カレンダー」もしくは「候補日を追加する」から候補日の設定をお願いします。'],
  ['MCBAS010-004', '面接候補日が送信予約日時より過去日になっています。'],
  ['MCBAS010-005', '面接候補日に本日または過去日が含まれています。'],
  ['MCBAS010-006', '送信対象に面接日程調整中の応募者が存在します。本メッセージを送信すると、調整中の面接日程調整は、取り下げとなります。よろしいですか？'],
  ['MCBAS010-008', '送信対象に既に面接調整メッセージが送信予約されている人がいるため、面接調整メッセージを送信できません。'],
  ['MCBAS010-009', '入力の確定日は応募者の希望する時間帯に収まりません。応募者の希望する時間帯と所要時間を確認の上、確定日を入力してください。'],
  ['MCBAS010-010', '面接日時は{0}分単位で設定してください。'],
  ['MCBAS010-011', 'タイマーは10分単位で設定してください。'],
  ['MCBAS010-012', '面接候補日については本日より90日後まで設定できます。設定可能期間内に変更をお願いします。'],
  //MCAZS091
  ['MCAZS091-001', '件名または本文にテンプレートで利用される文字列「●●」が含まれています。\n'],
  ['MCAZS091-002', '{0}は使用できない項目のため置換できません。\n'],
  ['MCAZS091-003', '{0}は値が取得できないため置換できません。\n'],
  ['MCAZS091-004', '{0}は値が取得でないため、該当の応募者宛には空白に置換されます。\n'],
  //#74734 維持保守 start
  ['MCAZS091-005', '表示期間外の予約したセミナー日程が挿入されています。\n'],
  ['MCAZS091-006', '挿入された「セミナー日程」「予約したセミナー日程」の情報は既に削除されています。\n'],
  ['MCAZS091-007', '存在しない予約したセミナー日程が挿入されています。\n'],
  ['MCAZS091-008', '「予約したセミナー日程」は1つしか挿入できません。\n'],
  //#74734 維持保守 end
  ['MCAZS091-009', '文字の装飾「{0}」の挿入が不正です。\n'],
  ['MCAZS091-010', 'マイナビ非会員/退会済みの送信対象者が含まれています。\nマイナビ非会員/退会済みの送信対象者にはメッセージを送信できません。\n対象から外さずに送信した場合は送信失敗となります。\n'],
  ['MCAZS091-011', 'マイナビ退会済みの送信対象者が含まれています。\nマイナビ退会済みの送信対象者にはメッセージを送信できません。\n対象から外さずに送信した場合は送信失敗となります。\n'],
  ['MCAZS091-012', 'マイナビ退会済みの送信対象者が含まれています。\nマイナビ退会済みの送信対象者にはメッセージを送信できません。\n対象から外さずに送信した場合は送信失敗となります。\n'],
  ['MCAZS091-013', 'ご注意ください！\n'],
  ['MCAZS091-014', '書類選考免除の応募者が送信対象です。\n'],
  ['MCAZS091-015', '書類選考免除の応募者が送信対象に含まれています。\n'],
  ['MCAZS091-016', 'イベント着席をした求職者が配信対象です。\n'],
  ['MCAZS091-017', 'イベント着席をした求職者が配信対象に含まれています。\n'],
  ['MCAZS091-018', 'セミナー情報への応募者が送信対象です。\n'],
  ['MCAZS091-019', 'セミナー情報への応募者が送信対象に含まれています。\n'],
  ['MCAZS091-020', '質問者が送信対象です。\n'],
  ['MCAZS091-021', '質問者が送信対象に含まれています。\n'],
  ['MCAZS091-022', '意図せず「書類選考不合格」メッセージを送信してしまう事象が多発しています。\n'],
  ['MCAZS091-023', '「合格通知」用のテンプレートを利用していますが、判定が不採用の送信対象者が含まれています。\n'],
  ['MCAZS091-024', '「不採用通知」用のテンプレートを利用していますが、判定が合格の送信対象者が含まれています。\n'],
  ['MCAZS091-025', '「合格通知」用のテンプレートを利用していますが、判定が不採用の送信対象者が含まれています。\n'],
  ['MCAZS091-026', '\nメッセージの内容をよくご確認の上、送信してください。'],
  ['MCAZS091-030', '拡張子が非対応のファイルが含まれています。対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx'],
  ['MCAZS091-031', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAZS091-033', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  ['MCAZS091-045', 'ご指定の予約日時は応募経路の契約期間外となるため、送信予約できません。予約日時を変更するか、送信先をご確認ください。'],
  ['MCAZS091-046', 'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。'],
  ['MCAZS091-047', 'ご指定の予約日時はサービス期間が終了になる応募経路の応募者が送信先に含まれているため、送信予約できません。予約日時を変更するか、送信先をご確認ください。'],
  ['MCAZS091-048', 'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。'],
  ['MCAZS091-049', 'ご指定の予約日時はサービス期間が終了になる応募経路の応募者が送信先に含まれているため、送信予約できません。予約日時を変更するか、送信先をご確認ください。'],
  ['MCAZS091-050', '送信予約日時については本日より30日後まで設定できます。設定可能期間内に変更をお願いします。'],
  ['MCAZS091-052', '送信予約日時までの残り時間が15分を切ったため予約できません。送信予約日時を設定しなおしてください。'],
  ['MCAZS091-053', '利用したメッセージテンプレートに添付ファイルが添付されておりましたが、送信先を「e-mailのみ」に変更したため添付ファイルは削除されました。'],
  ['MCAZS091-060', '本文に管理項目の差し込み文字が含まれています。\n送信日時までに管理項目を変更した場合、変更後の内容が差し込まれます。\n送信日時までに管理項目は変更しないようにご注意ください。\n'],
  ['MCAZS091-061', '送信可能なメールアドレスがありません。'],
  ['MCAZS091-065', '送信対象者に複数の選考フローが含まれます。管理項目・選考ステップ・合否は送信対象者一覧に表示されている選考フローに紐づく内容が差し込まれますので、必ずご確認の上送信ください。'],
  ['MCAZS091-064', 'パスワード付きzipファイルは添付できません。'],
  ['MCAZS091-068', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCAZS091-069', 'WEB面接URLがすでにキャンセルされています。\nWEB面接URL発行を再度実行してください。'],
  ['MCAZS091-070', '※応募者に送信されたWEB面接URLは面接調整一覧に表示されます。\n※担当者用のWEB面接URLもあわせて面接調整一覧から確認できます。\n'],
  ['MCAZS091-071', '発行済みのWEB面接URLがある場合は、メッセージ送信後にWEB面接URLもキャンセルとなります。\n'],
  ['MCAZS091-072', '他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください'],

  //MCAZS101
  ['MCAZS101-001', 'テンプレート名を入力してください。'],
  ['MCAZS101-002', '新規テンプレートとして保存してもよろしいですか？'],
  ['MCAZS101-003', '指定されたテンプレート名は既に登録されています。'],
  ['MCAZS101-004', '登録上限数は500件です。'],
  ['MCAZS101-005', '保存先のテンプレートを選択してください。'],
  ['MCAZS101-006', 'テンプレートを上書き保存してもよろしいですか？'],
  ['MCAZS101-007', '指定されたテンプレート名は既に登録されています。'],
  ['MCAZS101-011', '選考ステップを「不採用」に更新しました。'],
  ['MCAZS101-012', 'My CareerBoxのテンプレートを上書き保存してもよろしいですか？'],
  ['MCAZS101-013', '件名、本文に使用できない差し込み文字が使用されているためメッセージテンプレートに登録できません。'],
  ['MCAZS101-014', '件名、本文に使用できない差し込み文字が使用されているためメッセージテンプレートに登録できません。'],

  //MCAZS161
  ['MCAZS161-001', '他のアカウントを有する担当者様が面接日程調整をしている可能性があります。面接日程調整をやりなおしてください。'],
  ['MCAZS161-002', '送信対象者の中に現在、面接日程調整中の応募者がいます。\n※面接日程調整：送信予約を含む提示済み日程～面接実施前の確定日程\n再提示をすることで、調整中の日程は企業キャンセル（取り下げ）となります。\nよろしいですか？'],

  //MCARS020
  ['MCARS020-000', ''],
  ['MCARS020-001', '登録されているMY検索はありません。'],
  // MCBリプレース MCBIS020 START
  ['MCBIS020-001', '登録されているMY検索(提出リクエスト管理)はありません。'],
  // MCBリプレース MCBIS020 END
  //MCALS040
  ['MCALS040-000', '以下の内容で情報を更新します。内容をご確認ください。'],
  ['MCALS040-003', '入力された内容で登録します。よろしいですか？'],
  ['MCALS040-004', '入力された内容で更新します。よろしいですか？'],
  ['MCALS040-005', '表示しているテンプレートを削除します。よろしいですか？'],
  [
    'MCALS040-006',
    '登録上限数は50件です。',
  ],
  ['MCALS040-007', '入力されたテンプレート名は、既に登録されています。'],
  ['MCALS040-008', '選択したテンプレートは存在しません。'],
  ['MCALS040-009', 'テンプレートの登録が完了しました。'],
  ['MCALS040-010', 'テンプレートの更新が完了しました。'],
  ['MCALS040-011', 'テンプレートの削除が完了しました。'],
  ['MCALS040-012', '選択された項目が削除されています。{0}'],
  ['MCALS040-013', '出力単位を取得できませんでした。'],
  ['MCALS040-015', 'レイアウト分類を取得できませんでした。'],
  ['MCALS040-016', 'エントリー履歴を取得できませんでした。'],
  ['MCALS040-017', '説明会・面接履歴を取得できませんでした。'],
  ['MCALS040-018', '面接日程履歴を取得できませんでした。'],
  ['MCALS040-019', 'イベント参加履歴を取得できませんでした。'],
  ['MCALS040-020', '出力する選考フローを取得できませんでした。'],
  ['MCALS040-021', 'キャンセルされた履歴を取得できませんでした。'],
  // 年度対応 start
  ['MCALS040-022', 'テンプレートの登録が完了しました。登録結果は登録時の対象年度に切り替えてご確認ください。'],
  // 年度対応 end
  ['MCAVS020-001', '指定されたページは存在しません。'],
  //MCAZS070
  ['MCAZS070-003', '退会済みの会員のため、返信できません。'],
  ['MCAZS070-004', 'こちらのファイルがシステム上から削除されているため、添付ファイルがダウンロードできませんでした。'],
  ['MCAZS070-005', '権限がないため、操作を実行できません。'],
  // 次期開発5月向#58931 start
  ['MCAZS070-006', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // 次期開発5月向#58931 end
  // 次期開発12月 #72025 start
  ['MCAZS070-007',
    '送信先対象に閲覧権限のない応募者または削除された応募者が含まれています。\n' +
    '上記応募者を除いてメッセージ送信されます。'],
  ['MCAZS070-008',
    '一括送信したメッセージへ返信を行います。\n' +
    '対象者一覧より、送信対象者をご確認ください。'],
  ['MCAZS070-009',
    '一括送信したメッセージへ返信を行います。\n' +
    '一括送信した全ての送信対象者へメッセージが配信されます。'],
  // 次期開発12月 #72025 end
  // MCAZS150
  [
    'MCAZS150-001',
    '他のアカウントを有する担当者様が面接日程調整をしている可能性があります。画面を更新してください。',
  ],
  // MCAWS010
  ['MCAWS010-001', '面接日を取得できませんでした。'],
  // ロット３　START  //
  // MCAXS320
  ['MCAXS320-000', ''],
  ['MCAXS320-001', '選択されたセミナーをキャンセルします。よろしいですか？'],
  [
    'MCAXS320-002',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAXS320-003', 'セミナーをキャンセルしました。'],
  // 年度対応　MCAXS320 start
  // 送信予約期間チェック
  ['MCAXS320-004', 'サービス期間が終了したセミナーはキャンセルできません。'],
  // 年度対応　MCAXS320 end
  ['MCAXS320-005', '対象のセミナーは企業専用画面で削除されているため、キャンセルできません。'],

  //MCAXS260
  [
    'MCAXS260-003',
    'この処理を行う場合は、選考フローを１つに絞り込みを行ってから応募者を選択しなおして下さい',
  ],
  [
    'MCAXS260-005',
    '選択した応募者データを対象外にしてもよろしいですか？\nメッセージ送信予約をされている場合は、そのまま送信されます。',
  ],
  [
    'MCAXS260-001',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  [
    'MCAXS260-013',
    '閲覧権限のない応募者データが含まれています。再度検索を行ってください。',
  ],
  [
    'MCAXS260-002',
    '選択した応募者データは面接日程調整中のものがあります。応募者を選択しなおしてください。',
  ],
  // 年度対応　MCAXS260 start
  [
    'MCAXS260-036',
    'メッセージ送信に伴う処理が完了していない応募者データが含まれています。\n恐れ入りますが、時間をおいて再度実施してください。',
  ],
  // 年度対応　MCAXS260 end
  // 維持保守　#74253 start
   [
    'MCAXS260-037',
    '送信中、送信予約、下書きのメッセージがある応募者が含まれるため削除できません。\n送信中のメッセージの場合、時間をおいて再度実行してください。\n送信予約、下書きのメッセージは、ゴミ箱に移し再度実行してください。',
  ],
  // 維持保守　#74253 end
  ['MCAXS260-016', '選択した応募者データを対象外に更新しました。'],
  ['MCAXS260-006', '選択した応募者データを選考中に戻してもよろしいですか？'],
  [
    'MCAXS260-001',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  ['MCAXS260-017', '選択した応募者データを選考中に戻しました。'],
  [
    'MCAXS260-003',
    'この処理は選考フローを1つに絞り込む必要があります。',
  ],
  [
    'MCAXS260-012',
    'マイナビサービスの応募経路は変更できません。マイナビサービス以外の応募経路がある応募者を選択しなおしてください。',
  ],
  [
    'MCAXS260-004',
    'この処理は応募経路を1つに絞り込む必要があります。',
  ],
  // 維持保守 #72689 start 
  [
    'MCAXS260-007',
    '応募者データを削除した場合は、それ以降は削除した方とメッセージの送受信ができなくなってしまいます。\n送信予約されたメッセージがある場合は、送信は実施されずにメッセージが削除されます。\n\nまた、削除したデータは復活できません。\n削除してもよろしいですか？',
  ],
  // 維持保守 #72689 end 
  [
    'MCAXS260-035',
    '応募者データを削除してしまうと、それ以降は削除した方とメッセージの送受信ができなくなってしまいます。\nこれまでの送受信メッセージも削除され、送信予約されたメッセージがある場合、送信は実施されずにメッセージが削除されます。\n\nまた、削除したデータは復活できません。\n削除してもよろしいですか？',
  ],
  ['MCAXS260-018', '選択した応募者データを削除しました。'],
  [
    'MCAXS260-015',
    '権限がないため、操作を実行できません。',
  ],
  [
    'MCAXS260-014-1',
    '1回あたりにPDF出力できる上限は2000件です。対象を2000件以下に絞ってからPDF出力を実行してください。',
  ],
  [
    'MCAXS260-014-2',
    '1回あたりにPDF出力できる上限は100件です。対象を100件以下に絞ってからPDF出力を実行してください。',
  ],
  [
    'MCAXS260-011',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  ['MCAXS260-008', '書類選考免除の応募者が配信対象です。'],
  ['MCAXS260-009', 'イベント着席をした求職者が配信対象です。'],
  ['MCAXS260-010', 'セミナー情報への応募者が配信対象です。'],
  ['MCAXS260-019', '書類選考免除の応募者が配信対象に含まれています。'],
  ['MCAXS260-020', 'イベント着席をした求職者が配信対象に含まれています。'],
  [
    'MCAXS260-021',
    '書類選考免除の応募者が配信対象に含まれています。\nイベント着席をした求職者が配信対象に含まれています。',
  ],
  ['MCAXS260-022', 'セミナー情報への応募者が配信対象に含まれています。'],
  [
    'MCAXS260-023',
    '書類選考免除の応募者が配信対象に含まれています。\nセミナー情報への応募者が配信対象に含まれています。',
  ],
  [
    'MCAXS260-024',
    'イベント着席をした求職者が配信対象に含まれています。\nセミナー情報への応募者が配信対象に含まれています。',
  ],
  [
    'MCAXS260-025',
    '書類選考免除の応募者が配信対象に含まれています。\nイベント着席をした求職者が配信対象に含まれています。\nセミナー情報への応募者が配信対象に含まれています。',
  ],
  ['MCAXS260-026', '最大15件のエントリーデータをシェアすることができます。対象を15件以下に絞ってお試しください。'],
  ['MCAXS260-027', '1回あたりにCSV出力できる上限は{0}件です。対象を{1}件以下に絞ってからCSV出力を実行してください。'],
  // [phase2] Start
  ['MCAXS260-029', '対象外ではない応募者データが含まれています。応募者を選択しなおしてください。'],
  // [phase2] End
  ['MCAXS260-030', '一度に更新できるのは{0}件です。'],
  ['MCAXS260-031', 'My CareerBoxに連携可能な応募者数を超えています。1000件以下で再実行してください。'],
  ['MCAXS260-032', '応募経路が混ざっている状態です。\n検索条件で応募経路をしぼるか、以下より条件追加して下さい。'],
  ['MCAXS260-033', '選考フローが複数ある応募者が存在します。\n更新の新しい選考フローでメッセージを送りますが、よろしいでしょうか。\n必要に応じて検索条件やフィルタで選考フローをしぼってから、メッセージ送信して下さい。'],
  ['MCAXS260-046', '1回あたりにCSV出力できる上限は{0}人です。対象を{1}人以下に絞ってからCSV出力を実行してください。'],
  // MCAXS100 start 
  ['MCAXS100-000', ''],
  // MCAXS100 end 
  // MCAXS290 start 
  ['MCAXS290-000', ''],
  ['MCAXS290-001', '他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください。'],
  //　MCAXS290 end 
  //MCAZS130
  [
    'MCAZS130-001',
    '登録上限数は30件です。',
  ],
  // MCAXS3400 start 
  ['MCAXS340-000', '共通設定から項目の新規登録ができます。新しい項目を追加する場合はそちらから追加をお願いします。'],
  // 次期開発12月 #51829 start
  ['MCAXS340-0000', '共通設定から項目の新規登録ができます。新しい項目を追加する場合はそちらから追加をお願いします。</br>管理項目の値を一括削除する場合は各管理項目の横にある「データ削除」にチェックを入れ、OKへ進んでください。</br>更新対象は、背景が青色の項目です。'],
  // 次期開発12月 #51829 end
  ['MCAXS340-001', '管理項目を更新しました。'],
  [
    'MCAXS340-002',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  [
    'MCAXS340-003',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAXS340-004', '小数点以下は２桁以内で入力して下さい'],
  //　MCAXS340 end 

  // MCAXS310 start by renbiao
  ['MCAXS310-000', '企業専用画面で設定している定員の制御はこちらには反映されません。（定員を超えても予約できます。） こちらで予約が増加した場合でも、企業専用画面の予約人数には反映されません。（定員設定を減らしておく必要があります。）'],
  ['MCAXS310-001', '指定されたページは存在しません。'],
  ['MCAXS310-002', '入力された内容で予約します。よろしいですか？'],
  [
    'MCAXS310-003',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAXS310-004', 'セミナーを予約しました。'],
  // MCAXS310 start by renbiao
  // MCBリプレース MCAMS010 start
  [
    'MCAMS010-000',
    '最大{0}件までテンプレート（マイナビ・e-mail・My CareerBox宛）を登録できます。',
  ],
  [
    'MCAMS010-000_1',
    '最大{0}件までテンプレート（マイナビ・e-mail）を登録できます。',
  ],
  // MCBリプレース MCAMS010 END
  ['MCAMS010-002', '一括操作を選択してください。'],
  [
    'MCAMS010-003',
    '選択されたすべてのテンプレートを[表示／非表示]に変更します。よろしいですか？',
  ],
  [
    'MCAMS010-004',
    '[書類選考/面接]の不採用通知に設定されているテンプレートが含まれているため非表示にできません。\n不採用通知のテンプレート設定を解除してから変更してください。',
  ],
  [
    'MCAMS010-005',
    '登録上限数は500件です。',
  ],
  [
    'MCAMS010-006',
    '登録上限数は500件です。',
  ],
  ['MCAMS010-007', '選択されたすべてのテンプレートを「表示」状態にしました。'],
  ['MCAMS010-008', '選択されたすべてのテンプレートを非表示にしました。'],
  ['MCAMS010-009', '条件に一致する検索結果がありませんでした。'],
  [
    'MACMS010-MAXCOUNT-000',
    'メッセージテンプレートが、登録可能上限数{0}件存在するため、新規に追加することはできません。'
  ],

  [
    'MCBJS010-000',
    '最大500件まで提出リクエストテンプレートを登録できます。',
  ],
  [
    'MCBJS010-001',
    '指定されたページは存在しません。',
  ],
  ['MCBJS010-002', '一括操作を選択してください。'],
  [
    'MCBJS010-003',
    '選択されたすべての提出リクエストテンプレートを[表示／非表示]に変更します。よろしいですか？',
  ],
  [
    'MCBJS010-004',
    '登録上限数は500件です。',
  ],
  [
    'MCBJS010-005',
    '登録上限数は500件です。',
  ],
  ['MCBJS010-006', '選択されたすべての提出リクエストテンプレートを「表示」状態にしました。'],
  ['MCBJS010-007', '選択されたすべての提出リクエストテンプレートを「非表示」状態にしました。'],
  ['MCBJS010-008', '条件に一致する検索結果がありませんでした。'],
  [
    'MCBJS010-MAXCOUNT-000',
    '提出リクエストテンプレートが、登録可能上限数（500件）存在するため、新規に追加することはできません。'
  ],

  // MCAMS020 start by renbiao
  ['MCAMS020-000', ''],
  ['MCAMS020-002', '{0}は使用できない項目のため置換できません。'],
  //#74734 維持保守 start
  ['MCAMS020-003', '表示期間外の予約したセミナー日程が挿入されています。'],
  ['MCAMS020-005', '存在しない予約したセミナー日程が挿入されています。'],
  ['MCAMS020-006', '「予約したセミナー日程」は1つしか挿入できません。'],
  //#74734 維持保守 end
  ['MCAMS020-007', '文字の装飾{0}の挿入が不正です。'], 
  ['MCAMS020-008', '件名にご利用いただけない文字が含まれています。'],
  ['MCAMS020-009', '本文にご利用いただけない文字が含まれています。'],
  ['MCAMS020-010', '入力されたテンプレート名は、既に登録されています。'],
  [
    'MCAMS020-011',
    '件名または本文にテンプレートに利用できない文字列「●●」が含まれています。',
  ],
  [
    'MCAMS020-012',
    '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx',
  ],
  ['MCAMS020-013', '添付ファイルの登録上限数は5件です。'],
  ['MCAMS020-014', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAMS020-015', '同じ名称の添付ファイルが複数選択されています。'],
  [
    'MCAMS020-016',
    '送信先に「マイナビのみ」が選択されていない場合は、バーコードの「表示する」を選択できません。',
  ],
  ['MCAMS020-017', '件名にご利用いただけない文字が含まれています。'],
  ['MCAMS020-018', '本文にご利用いただけない文字が含まれています。'],
  [
    'MCAMS020-019',
    '送信元会社名にマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  [
    'MCAMS020-020',
    '{0}番目のホームページタイトルにマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  [
    'MCAMS020-021',
    '{0}番目のホームページURLにマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  ['MCAMS020-022', 'URLが入力されていません。'],
  ['MCAMS020-023', '添付オプションの指定可能数は20件です。'],
  [
    'MCAMS020-024',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  //#74734 維持保守 start
  ['MCAMS020-026', '「予約したセミナー日程」は1つしか挿入できません。'],
  //#74734 維持保守 end
  ['MCAMS020-028', 'パスワード付きzipファイルは添付できません。'],
  // 年度対応 start
  ['MCAMS020-029', '対象年度を変更したため、マイナビ添付オプションをクリアしました。'],
  // 年度対応 end
  // 次期5月対応 #47317 start
  ['MCAMS020-030',
    'ご注意ください！\n送信先をマイナビ宛に変更します。メッセージはマイナビのマイページに送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  ['MCAMS020-031',
    'ご注意ください！\n送信先をマイナビ優先に変更します。\nマイナビ会員にはマイナビのマイページへ、マイナビ非会員には登録されているメールアドレス宛に送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  ['MCAMS020-032',
    'ご注意ください！\n送信先をe-mail宛に変更します。メールは登録されているメールアドレス宛に送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  // 次期5月対応 #47317 end
  // 次期開発9月 #62201 start
  ['MCAMS020-033', '本文には、差し込み文字以外の文字も入力してください。'],
  // 次期開発9月 #62201 end
  // MCAMS020 end by renbiao
  // MCBリプレース MCAMS020 START
  ['MCAMS020-034',
  'ご注意ください！\n送信先をMy CareerBox宛に変更します。My CareerBoxメッセージ箱と登録のメールアドレス宛に送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  // MCBリプレース MCAMS020 END
  // MCBリプレース MCBJS020 START
  // MCBJS020
  // #MCB1.5次開発 8777 START
  ['MCBJS020-001', '提出物内容一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBJS020-002', 'サンクスメールテンプレート一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBJS020-003', 'お問い合わせ先一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBJS020-004', 'My CareerBoxに関する個人情報の取り扱い一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  // #MCB1.5次開発 8777 END
  ['MCBJS020-005', '選択した提出リクエストテンプレートは存在しません。'],
  ['MCBJS020-006', '{0}は使用できない項目のため置換できません。'],
  ['MCBJS020-007', '{0}は値が取得できないため置換できません。'], 
  ['MCBJS020-008', '件名にご利用いただけない文字が含まれています。'], 
  ['MCBJS020-009', '本文にご利用いただけない文字が含まれています。'],
  ['MCBJS020-010', 'サンクスメールの件名にご利用いただけない文字が含まれています。'],
  ['MCBJS020-011', 'サンクスメールの本文にご利用いただけない文字が含まれています。'],
  ['MCBJS020-012', '入力された提出リクエストテンプレート名は、既に登録されています。'],
  ['MCBJS020-013', '提出締切の日時に過去は指定できません。'],
  ['MCBJS020-014', '提出締切の日数と時刻をともに入力ください。'],
  ['MCBJS020-015', '随時受付終了の日時に過去は指定できません。'],
  // #MCB1.5次開発 8777 START
  ['MCBJS020-016', '随時受付終了の場合、再提出の受付設定はできません。'],
  // #MCB1.5次開発 8777 END
  [
    'MCBJS020-017',
    '件名または本文に提出リクエストテンプレートに利用できない文字列「●●」が含まれています。',
  ],
  ['MCBJS020-018', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx'],
  ['MCBJS020-019', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCBJS020-020', '同じ名称の添付ファイルが複数選択されています。'],
  ['MCBJS020-021', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  ['MCBJS020-022', 'パスワード付きzipファイルは添付できません。'],
  ['MCBJS020-023', 'URLが入力されていません。'],
  ['MCBJS020-024', 'サンクスメールの件名または本文に提出リクエストテンプレートに利用できない文字列「●●」が含まれています。'],
  [
    'MCBJS020-025',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCBJS020-026', '現在、My CareerBox関連の機能は利用できません、しばらく経ってからもう一度操作してください。'],
  // MCBリプレース MCBJS020 END
  // 26P #80795 start
  ['MCBJS020-027', '「提出物内容の設定」の選択内容をクリアします。よろしいですか。'],
  // 26P #80795 end

  // MCAMS030 start
  ['MCAMS030-000', ''],
  ['MCAMS030-002', '指定されたページは存在しません。'],
  ['MCAMS030-003', '{0}は使用できない項目のため置換できません。'],
  //#74734 維持保守 start
  ['MCAMS030-004', '表示期間外の予約したセミナー日程が挿入されています。'],
  ['MCAMS030-006', '存在しない予約したセミナー日程が挿入されています。'],
  ['MCAMS030-007', '「予約したセミナー日程」は1つしか挿入できません。'],
  //#74734 維持保守 end
  ['MCAMS030-008', '文字の装飾{0}の挿入が不正です。'],
  ['MCAMS030-009', '件名にご利用いただけない文字が含まれています。'],
  ['MCAMS030-010', '本文にご利用いただけない文字が含まれています。'],
  ['MCAMS030-011', '入力されたテンプレート名は、既に登録されています。'],
  [
    'MCAMS030-012',
    '件名または本文にテンプレートで利用される文字列「●●」が含まれています。',
  ],
  [
    'MCAMS030-013',
    '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx',
  ],
  ['MCAMS030-014', '添付ファイルの登録上限数は5件です。'],
  ['MCAMS030-015', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAMS030-016', '同じ名称の添付ファイルが複数選択されています。'],
  [
    'MCAMS030-017',
    '送信先に「マイナビのみ」が選択されていない場合は、バーコードの「表示する」を選択できません。',
  ],
  ['MCAMS030-018', '件名にご利用いただけない文字が含まれています。'],
  ['MCAMS030-019', '本文にご利用いただけない文字が含まれています。'],
  [
    'MCAMS030-020',
    '送信元会社名にマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  [
    'MCAMS030-021',
    '{0}番目のホームページタイトルにマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  [
    'MCAMS030-022',
    '{0}番目のホームページURLにマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  ['MCAMS030-023', 'URLが入力されていません。'],
  ['MCAMS030-024', '添付オプションの指定可能数は20件です。'],
  [
    'MCAMS030-025',
    'このテンプレートは[書類選考/面接]の不採用通知に設定されているため削除できません。\n不採用通知のテンプレート設定を解除してから削除してください。',
  ],
  [
    'MCAMS030-026',
    'このテンプレートを削除します。よろしいですか？\n添付ファイルがある場合は、添付ファイルも削除されます。',
  ],
  [
    'MCAMS030-027',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  //#74734 維持保守 start
  ['MCAMS030-029', '「予約したセミナー日程」は1つしか挿入できません。'],
  //#74734 維持保守 end
  ['MCAMS030-030', 'このテンプレートは不採用通知に設定されているため、件名と本文の入力は必須です。'],
  ['MCAMS030-032', 'パスワード付きzipファイルは添付できません。'],
  // 次期5月対応 #47317 start
  ['MCAMS030-033',
    'ご注意ください！\n送信先をマイナビ宛に変更します。メッセージはマイナビのマイページに送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  ['MCAMS030-034',
    'ご注意ください！\n送信先をマイナビ優先に変更します。\nマイナビ会員にはマイナビのマイページへ、マイナビ非会員には登録されているメールアドレス宛に送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  ['MCAMS030-035',
    'ご注意ください！\n送信先をe-mail宛に変更します。メールは登録されているメールアドレス宛に送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  // 次期5月対応 #47317 end
  // 次期開発9月 #62201 start
  ['MCAMS030-036', '本文には、差し込み文字以外の文字も入力してください。'],
  // 次期開発9月 #62201 end
  ['MCAMS030-037', 'このテンプレートは[書類選考/面接]の不採用通知に設定されているため、\n「種別：合格通知」への変更、または「送信先：e-mailのみ」への変更はできません。\n不採用通知のテンプレート設定を解除してから変更してください。'],
  // MCBリプレース MCAMS030 START
  ['MCAMS030-038',
    'ご注意ください！\n送信先をMy CareerBox宛に変更します。My CareerBoxメッセージ箱と登録のメールアドレス宛に送られます。\n選択した送信先により、使用できる入力項目や差し込み文字・文字装飾が異なります。\nよくご確認の上、配信設定を行ってください。'],
  // MCBリプレース MCAMS030 END
  // MCAMS030 end

  // MCBリプレース MCBJS030 START
  // #MCB1.5次開発 8777 START
  ['MCBJS030-001', '提出物内容一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBJS030-002', 'サンクスメールテンプレート一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBJS030-003', 'お問い合わせ先一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBJS030-004', 'My CareerBoxに関する個人情報の取り扱い一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  // #MCB1.5次開発 8777 END
  ['MCBJS030-005', '選択した提出リクエストテンプレートは存在しません。'],
  ['MCBJS030-006', '{0}は使用できない項目のため置換できません。'],
  ['MCBJS030-007', '{0}は値が取得できないため置換できません。'], 
  ['MCBJS030-008', '件名にご利用いただけない文字が含まれています。'], 
  ['MCBJS030-009', '本文にご利用いただけない文字が含まれています。'],
  ['MCBJS030-010', 'サンクスメールの件名にご利用いただけない文字が含まれています。'],
  ['MCBJS030-011', 'サンクスメールの本文にご利用いただけない文字が含まれています。'],
  ['MCBJS030-012', '入力された提出リクエストテンプレート名は、既に登録されています。'],
  ['MCBJS030-013', '提出締切の日時に過去は指定できません。'],
  ['MCBJS030-014', '提出締切の日数と時刻をともに入力ください。'],
  ['MCBJS030-015', '随時受付終了の日時に過去は指定できません。'],
  // #MCB1.5次開発 8777 START
  ['MCBJS030-016', '随時受付終了の場合、再提出の受付設定はできません。'],
  // #MCB1.5次開発 8777 END
  [
    'MCBJS030-017',
    '件名または本文に提出リクエストテンプレートに利用できない文字列「●●」が含まれています。',
  ],
  ['MCBJS030-018', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx'],
  ['MCBJS030-019', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCBJS030-020', '同じ名称の添付ファイルが複数選択されています。'],
  ['MCBJS030-021', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  ['MCBJS030-022', 'パスワード付きzipファイルは添付できません。'],
  ['MCBJS030-023', 'URLが入力されていません。'],
  ['MCBJS030-024', 'サンクスメールの件名または本文に提出リクエストテンプレートに利用できない文字列「●●」が含まれています。'],
  [
    'MCBJS030-025',
    '他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください。',
  ],
  ['MCBJS030-026', 'この提出リクエストテンプレートを削除します。よろしいですか？\n添付ファイルがある場合は、添付ファイルも削除されます。'],
  [
    'MCBJS030-027',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCBJS030-028', '現在、My CareerBox関連の機能は利用できません、しばらく経ってからもう一度操作してください。'],
  // MCBリプレース MCBJS030 END

  // MCAMS040 start by taoxiaojia
  [
    'MCAMS040-000',
    '以下の内容で情報を更新します。内容をご確認ください。',
  ],
  [
    'MCAMS040-003',
    '件名または本文にテンプレートで利用される文字列「●●」が含まれています。',
  ],
  ['MCAMS040-004', '{0}は使用できない項目のため置換できません。'],
  //#74734 維持保守 start
  ['MCAMS040-005', '表示期間外の予約したセミナー日程が挿入されています。'],
  ['MCAMS040-007', '存在しない予約したセミナー日程が挿入されています。'],
  ['MCAMS040-008', '「予約したセミナー日程」は1つしか挿入できません。'],
  //#74734 維持保守 end
  ['MCAMS040-009', '文字の装飾{0}の挿入が不正です。'],
  ['MCAMS040-010', '入力されたテンプレート名は、既に登録されています。'],
  [
    'MCAMS040-011',
    '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx',
  ],
  ['MCAMS040-012', '添付ファイルの登録上限数は5件です。'],
  ['MCAMS040-013', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAMS040-014', '同じ名称の添付ファイルが複数選択されています。'],
  [
    'MCAMS040-015',
    '送信先に「マイナビのみ」が選択されていない場合は、バーコードの「表示する」を選択できません。',
  ],
  ['MCAMS040-016', '件名にご利用いただけない文字が含まれています。'],
  ['MCAMS040-017', '本文にご利用いただけない文字が含まれています。'],
  [
    'MCAMS040-018',
    '送信元会社名にマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  [
    'MCAMS040-019',
    '{0}番目のホームページタイトルにマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  [
    'MCAMS040-020',
    '{0}番目のホームページURLにマイナビでご利用いただけない文字が含まれています。\n・差込文字列の間違い。例：[応募者姓]　など\n上記のご利用はご遠慮いただいております。ご了承ください。',
  ],
  ['MCAMS040-029', 'パスワード付きzipファイルは添付できません。'],
  ['MCAMS040-021', 'URLが入力されていません。'],
  ['MCAMS040-022', '添付オプションの指定可能数は20件です。'],
  ['MCAMS040-023', '登録上限数は500件です。'],
  ['MCAMS040-025', 'メッセージテンプレートの登録が完了しました。'],
  ['MCAMS040-026', 'メッセージテンプレートの更新が完了しました。'],
  ['MCAMS040-027', 'このテンプレートは不採用通知に設定されているため、件名と本文の入力は必須です。'],
  // 年度対応　MCAMS040 start
  ['MCAMS040-030', 'メッセージテンプレートの登録が完了しました。入力された内容を確認する場合は、指定した対象年度に切り替えてご確認ください。'],
  // 年度対応　MCAMS040 end
  ['MCAMS040-031', 'このテンプレートは[書類選考/面接]の不採用通知に設定されているため、\n「種別：合格通知」への変更、または「送信先：e-mailのみ」への変更はできません。\n不採用通知のテンプレート設定を解除してから変更してください。'],
  //　MCAXS290 end by taoxiaojia

  // #80743 start
  ['MCAMS020-035', '件名、本文に使用できない差し込み文字が使用されているためメッセージテンプレートに登録できません。'],
  ['MCAMS030-039', '件名、本文に使用できない差し込み文字が使用されているためメッセージテンプレートに登録できません。'],
  ['MCAMS040-032', '件名、本文に使用できない差し込み文字が使用されているためメッセージテンプレートに登録できません。'],
  // #80743 end

  // MCAXS271 start by 劉旭東
  ['MCAXS271-000', '追加する選考を選択してください。\n選考を追加することで、別々の選考フローで合否や選考ステップを管理できます。\n共通設定画面から選考フローの登録ができます。新しい選考を追加する場合はそちらから追加をお願いします。'],
  ['MCAXS271-001', '指定されたページは存在しません。'],
  // 年度対応　MCAXS271 start
  ['MCAXS271-002', '権限がないため、操作を実行できません。'],
  // 年度対応　MCAXS271 end
  ['MCAXS271-003', '入力された内容で選考フローを追加します。よろしいですか？ '],
  [
    'MCAXS271-004',
    '追加する選考が選考期間外のため、選考を追加できませんでした。',
  ],
  [
    'MCAXS271-005',
    '検索条件で絞り込んだ選考と一致しない選考が含まれるため、選考を追加できませんでした。',
  ],
  ['MCAXS271-006', '内定した選考が含まれるため、選考を追加できませんでした。'],
  ['MCAXS271-008', '対象外の選考が含まれるため、選考を追加できませんでした。'],
  ['MCAXS271-009', '選考フローを追加しました。'],
  [
    'MCAXS271-010',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  [
    'MCAXS271-011',
    '応募者に同じ選考フロー（{0}）が存在するため追加できません。',
  ],
  // MCAXS271 end by 劉旭東

  // MCAXS280 start by 劉旭東
  ['MCAXS280-000', '「変更後の選考」より選考を選び変更してください。\n選考フローを変更すると、変更前の選考ステップ・合否はリセットされます。'],
  ['MCAXS280-001', '指定されたページは存在しません。'],
  ['MCAXS280-002', '選考の利用権限がありません。'],
  ['MCAXS280-003', 'へ選考を変更します。よろしいですか？'],
  [
    'MCAXS280-004',
    '変更前と変更後の選考が同じ選考です。同じ選考でも、選考を変更すると進捗はリセットされます。よろしいですか？',
  ],
  [
    'MCAXS280-005',
    '変更後の選考が選考期間外のため、選考を変更できませんでした。',
  ],
  [
    'MCAXS280-006',
    '検索条件で絞り込んだ選考と一致しない選考が含まれるため、選考を変更できませんでした。',
  ],
  ['MCAXS280-007', '内定済の選考が含まれるため、選考を変更できませんでした。'],
  ['MCAXS280-008', '対象外の選考が含まれるため、選考を変更できませんでした。'],
  [
    'MCAXS280-009',
    '調整中の面接日程が存在する選考が含まれるため、選考を変更できませんでした。',
  ],
  ['MCAXS280-010', '選考を変更しました。'],
  [
    'MCAXS280-011',
    '操作をキャンセルしますか？入力した内容が保存されない可能性があります。',
  ],
  [
    'MCAXS280-012',
    '応募者に同じ選考フロー（{0}）が存在するため変更できません。',
  ],
  // MCAXS280 end by 劉旭東
  // MCAXS330 start by 趙博
  [
    'MCAXS330-000',
    '{0}名の応募者に対して受検者のID登録を行います。',
  ],
  ['MCAXS330-001', '指定されたページは存在しません。'],
  ['MCAXS330-002', '入力された内容で登録します。よろしいですか？'],
  [
    'MCAXS330-003',
    '受検終了日が受検開始日より前に設定されています。もう一度ご確認ください。',
  ],
  [
    'MCAXS330-004',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  [
    'MCAXS330-005',
    'SHL社に連携可能な応募者数を超えています。1000件以下で再実行してください。',
  ],
  [
    'MCAXS330-006',
    'SHL社への連携で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。',
  ],
  // #58578 次期開発2022年5月 start
  ['MCAXS330-007','受検開始日が過去日に設定されています。もう一度ご確認ください。'],
  ['MCAXS330-008','受検開始日が適性テストの契約開始日より前に設定されています。もう一度ご確認ください。'],
  ['MCAXS330-009','受検終了日が適性テストの契約終了日より後に設定されています。もう一度ご確認ください。'],
  ['MCAXS660-001', "受検者として、{0}名の登録に成功しました。</p>"],
  // #58578 次期開発2022年5月 end
  // MCAXS330 start by 趙博
  // MCAXS670 start by 付小強
  ['MCAXS670-000', ''],
  ['MCAXS670-001', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
  ['MCAXS670-002', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
  ['MCAXS670-006', '入力された内容で応募経路を追加します。よろしいですか？'],
  ['MCAXS670-007', '入力された内容で応募経路を変更します。よろしいですか？'],
  ['MCAXS670-008', '変更前の応募経路と変更後の応募経路が同じ応募経路のため、応募経路を変更できませんでした。'],
  ['MCAXS670-009', '応募経路が存在しない選考が対象に含まれるため、応募経路を変更できませんでした。'],
  ['MCAXS670-010', '追加する応募経路が存在しないため、応募経路を追加できませんでした。'],
  ['MCAXS670-011', '変更後の応募経路が存在しないため、応募経路を変更できませんでした。'],
  ['MCAXS670-013', '応募経路の追加が完了しました。（追加する応募経路が既に存在した対象へは応募経路は追加されませんでした。）'],
  ['MCAXS670-014', '応募経路の変更が完了しました。（変更後の応募経路が既に存在した対象については応募経路情報が統合されました。）'],
  ['MCAXS670-015', '応募日を入力してください。'],
  ['MCAXS670-016', '応募経路の追加が完了しました。'],
  ['MCAXS670-017', '応募経路の変更が完了しました。'],
  ['MCAXS670-018', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
  ['MCAXS670-019', 'マイナビサービスの応募経路は変更できません。マイナビサービス以外の応募経路がある応募者を選択しなおしてください。'],
  // MCAXS670 end by 付小強

  // MCAQS010
  ['MCAQS010-000', '応募情報を名寄せする条件を入力します。'],
  ['MCAQS010-003', '対象項目を選択してください。'],
  [
    'MCAQS010-004',
    '「学校」「学校＋学部」「学校＋学部＋学科」のみの指定では名寄せチェックはできません。 他の項目も指定してください。',
  ],
  [
    'MCAQS010-005',
    '入力した内容で名寄せチェックを実行します。よろしいですか？',
  ],
  ['MCAQS010-006', '名寄せ対象はありません。'],
  ['MCAQS010-007', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],

  // MCAXS111 Start
  [
    'MCAXS111-000',
    'データダウンロード項目のテンプレートを選択して、「ファイルを作成する」ボタンをクリックしてください。',
  ],
  ['MCAXS111-001', '出力する選考フローを選択してください。'],
  [
    'MCAXS111-002',
    '権限がないため、操作を実行できません。',
  ],
  // MCAXS111 End

  // MCAXS121
  [
    'MCAXS121-000',
    'ダウンロードデータを作成中です。\nファイルの作成が完了しましたら、画面が切り替わります。\nしばらくお待ちください。',
  ],
  [
    'MCAXS121-001',
    '権限がないため、操作を実行できません。',
  ],
  [
    'MCAXS121-002',
    '選択したテンプレートが他のアカウントを有する担当者様により削除されているため利用できません。テンプレートの選択からやりなおしてください。',
  ],
  [
    'MCAXS121-003',
    '選択したテンプレートが他のアカウントを有する担当者様により変更されているため利用できません。テンプレートの選択からやりなおしてください。',
  ],
  [
    'MCAXS121-004',
    '選択した選考フローが利用できません。テンプレートの選択からやりなおしてください。',
  ],
  [
    'MCAXS121-005',
    '閲覧できない応募者データが含まれています。再度検索してください。',
  ],
  [
    'MCAXS121-006',
    'CSVファイルの作成に失敗しました。恐れ入りますが、一覧の検索から処理をやりなおしてください。',
  ],
  // MCAXS121
  //  MCAXS130
  [
    'MCAXS130-000',
    'ダウンロードデータの作成が完了しました。\n「ダウンロードする」ボタンをクリックしてください。',
  ],
  [
    'MCAXS130-001',
    '権限がないため、操作を実行できません。',
  ],
  [
    'MCAXS130-002',
    'ファイルのダウンロード期限を過ぎています。テンプレートの選択からやりなおしてください。',
  ],

  //MCAQS020
  ['MCAQS020-003', '名寄せ対象はありません。'],
  ['MCAQS020-004', '名寄せチェック結果はありません。'],

  // MCAPS020
  // 次期開発12月 #73504 start
  // 25P #72790 start
  ['MCAPS020-000', '自動名寄せ件数には、 CSVアップロード時、応募者の「姓、名、メールアドレス（メイン）、選考フロー」が重複し、自動で名寄せされた件数が表示されます。\n重複件数には、CSVアップロードを行った時点で応募者の「姓、名」が重複した件数が表示されます。CSVアップロード後に名寄せを行った場合は遷移先の件数と一致しない場合があります。'],
  // 25P #72790 end
  // 次期開発12月 #73504 end
  [
    'MCAPS020-001',
    '権限がないため、操作を実行できません。',
  ],
  [
    'MCAPS020-002',
    '重複結果を確認できる期間は{0}までです。確認期間が過ぎているため、確認することはできません。',
  ],
  [
    'MCAPS020-003',
    'エラー内容を確認できる期間は{0}までです。確認期間が過ぎているため、確認することはできません。',
  ],
  ['MCAPS020-004', '条件に一致する検索結果がありませんでした。'],

  // MCAPS030
  ['MCAPS030-000', 'エラー内容を確認の上、修正し、アップロードを再実行してください。'],
  ['MCAPS030-001', 'システムエラーが発生しました。'],
  ['MCAPS030-002', '選考フローの利用権限がない為、再アップロードできません。'],
  ['MCAPS030-003', 'アップロード対象のCSVテンプレートが削除されたため、再アップロードできません。再度CSVアップロードしてください。'],

  // 次期開発12月 #73504 start
  // MCAPS040
  // 25P #72790 start
  ['MCAPS040-000', 'MIWS内に存在する応募者と、CSV内の応募者の「姓、名、メールアドレス(メイン)、選考フロー」が一致した以下のデータは、自動名寄せを行いました。'],
  // 25P #72790 end
  ['MCAPS040-001', '指定されたページは存在しません。'],
  // 次期開発12月 #73504 end

  // MCAXS030
  ['MCAXS030-000','矢印の上でドラッグ＆ドロップすることで表示する項目の追加・削除、順番の変更を行えます。\n氏名/応募者管理IDは表示非表示及び順番の変更はできません。 \n本設定はアカウントごとに設定できます。'],
  ['MCAXS030-001','入力した内容で設定します。よろしいですか？'],
  ['MCAXS030-002','入力された内容を破棄し、画面を移動します。よろしいですか？'],
  ['MCAXS030-003','表示上限数は10件です。'],
  // ロット３　END  //

  // MCBリプレース MCBHS120 Start
  // MCBリプレース #8716 Start
  ['MCBHS120-000', '矢印の上でドラッグ＆ドロップすることで表示する項目の追加・削除、順番の変更を行えます。\n氏名／応募者管理ID、提出物内容／提出リクエスト確認状況は表示非表示及び順番の変更はできません。 \n本設定はアカウントごとに設定できます。'],
  // MCBリプレース #8716 End
  ['MCBHS120-001', '入力した内容で設定します。よろしいですか？'],
  ['MCBHS120-002', '入力された内容を破棄し、画面を移動します。よろしいですか？'],
  ['MCBHS120-003', '表示上限数は10件です。'],
  // MCBリプレース MCBHS120 End

   //MCAPS010 START BY ZHAOBO
   ['MCAPS010-000', ''],
   ['MCAPS010-001', '入力された内容でアップロードを開始します。よろしいですか？'],
   ['MCAPS010-002', '選択したテンプレートは存在しません。'],
   ['MCAPS010-003', '選択した選考フローは存在しません。'],
   ['MCAPS010-004', '選択した応募経路は存在しません。'],
   ['MCAPS010-005', 'ファイルの拡張子がCSVではないため、アップロードできません。'],
   ['MCAPS010-006', 'ファイルのサイズが50MBを超えているため、アップロードできません。'],
   ['MCAPS010-007', 'アップロードされたファイルはテンプレートの項目と一致しないため、アップロードできません。'],
   ['MCAPS010-008', 'アップロードされたCSVファイルのヘッダ項目と{0}行目：実項目の数が一致しません。'],
   ['MCAPS010-009', 'アップロードされたCSVファイルに登録対象が入力されていません。'],
   ['MCAPS010-010', 'CSVファイルアップロードに失敗しました。'],
   ['MCAPS010-011', 'CSVファイルアップロードが完了しました。'],
   ['MCAPS010-012', 'こちらのテンプレートを選択した場合は、選考フローを選択する必要があります。'],
   ['MCAPS010-013', 'こちらのテンプレートを選択した場合は、応募経路を選択する必要があります。'],
   ['MCAPS010-014', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
   ['MCAPS010-015', '{0}行目：{1}の入力値が設定されていません。'],
   ['MCAPS010-016', '{0}行目：{1}に入力された値（{2}）は使用できません。規定の値を入力ください。'],
   ['MCAPS010-017', '{0}行目：{1}で入力された値（{2}）は、半角数字で入力してください。'],
   ['MCAPS010-018', '{0}行目：{1}で入力された値（{2}）は、選択できない項目です。規定の値を入力ください。'],
   ['MCAPS010-019', '{0}行目：{1}で入力された選考：{2}はアップロード者の権限では操作できません。'],
   ['MCAPS010-020', '{0}行目：{1}の入力値（{2}）が不正です。'],
   ['MCAPS010-021', '{0}行目：{1}の入力値は改行できません。'],
   ['MCAPS010-022', '{0}行目：{1}にMIWSで使用できない文字（{2}）が含まれている可能性があります。'],
   ['MCAPS010-023', '{0}行目：{1}（{2}）は書式が正しくありません。'],
   ['MCAPS010-024', '{0}行目：{1}（{2}）は半角英数字で"XXX-XXXX"形式で入力して下さい。'],
   ['MCAPS010-025', '{0}行目：{1}（{2}）は書式が正しくありません。'],
   ['MCAPS010-026', '{0}行目：{1}（{2}）は書式が正しくありません。'],
   ['MCAPS010-027', '{0}行目：{1}（{2}）は0～5の半角数字で入力してください。'],
   ['MCAPS010-028', '{0}行目：「学部」または「学科」が入力されていません。'],
   ['MCAPS010-029', '{0}行目：{1}で入力された値（{2}）は選考フロー：{3}に存在しません。'],
   ['MCAPS010-030', '{0}行目：{1}に入力された値（{2}）は使用できません。規定の値を入力ください。'],
   ['MCAPS010-031', '{0}行目：{1}で入力された値（{2}）は選考ステップ：{3}に使用できません。規定の値を入力してください。'],
   ['MCAPS010-032', '{0}行目：「GAB：言語DL回数（日本語）」または「GAB：計数DL回数（日本語）」項目が存在しません。'],
   ['MCAPS010-033', '{0}行目：「GAB：言語DL回数（英語）」または「GAB：計数DL回数（英語）」項目が存在しません。'],
   ['MCAPS010-034', '{0}行目：「GAB：受検言語」項目が存在しない。または入力値が正しくありません。'],
   ['MCAPS010-035', 'ファイルの文字コードがShift-JISではないため、アップロードできません。'],
   ['MCAPS010-036', '{0}行目：{1}で入力された値（{2}）は、11文字以下を入力してください。'],
   ['MCAPS010-037', '{0}行目：{1}で入力された値（{2}）は、99999999.99以下を入力してください。'],
   ['MCAPS010-038', '{0}行目：{1}で入力された値（{2}）は、-99999999.99以上を入力してください。'],
   ['MCAPS010-039', '{0}行目：{1}で入力された値（{2}）は、小数点以下は２桁以内で入力して下さい。'],
   ['MCAPS010-040', '{0}行目：年月日はすべて入力してください。'],
   ['MCAPS010-041', '{0}行目：年月日が不正です。'],
   ['MCAPS010-042', 'アップロードの上限件数（10,000件）を超えているため、アップロードできません。'],
   // #MCB1.5次開発 #9236 START
   ['MCAPS010-043', '{0}行目：{1}に使用できない文字（\\,/,:,*,?,",<,>,|のいずれか）が使われています。'],
   // #MCB1.5次開発 #9236 END
   //MCAPS010  END  BY ZHAOBO

    // MCAXS180 応募者情報の入力
    ['MCAXS180-000', '以下の方法で応募者データを新規追加ができます。'],

    // MCAXS191 応募者氏名入力
    ['MCAXS191-000', ''],
    ['MCAXS191-001', '入力された内容を破棄し、画面を移動します。よろしいですか？'],
    ['MCAXS191-002', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
    // #MCB1.5次開発 #9236 START
    ['MCAXS191-003', '姓に使用できない文字（\\,/,:,*,?,",<,>,|のいずれか）が使われています。'],
    ['MCAXS191-004', '名に使用できない文字（\\,/,:,*,?,",<,>,|のいずれか）が使われています。'],
    // #MCB1.5次開発 #9236 END
    // MCAXS230_応募者情報編集
    ['MCAXS230-000', ''],
    ['MCAXS230-001', '対象の応募者は閲覧できません。'],
    ['MCAXS230-002', '郵便番号を入力してください。'],
    ['MCAXS230-003', '郵便番号のフォーマットが正しくありません。'],
    ['MCAXS230-004', '西暦が不正です。'],
    ['MCAXS230-005', '学校名を入力してください。'],
    ['MCAXS230-006', '研究科・専攻名を選択してください。'],
    ['MCAXS230-007', '研究科を入力してください。'],
    ['MCAXS230-008', '専攻名を入力してください。'],
    ['MCAXS230-009', '学部・学科名を選択してください。'],
    ['MCAXS230-010', '学部名を入力してください。'],
    ['MCAXS230-011', '学科名を入力してください。'],
    ['MCAXS230-012', '学科名を選択してください。'],
    ['MCAXS230-014', '専攻分野を選択してください。'],
    ['MCAXS230-015', '文理区分を選択してください。'],
    ['MCAXS230-018', '入力された内容を破棄し、画面を移動します。よろしいですか？'],
    ['MCAXS230-019', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
    ['MCAXS230-020', '学校名（頭文字）を選択してください。'],
    ['MCAXS230-021', '取得学位を指定してください。'],
    ['MCAXS230-022', '専攻分野を選択してください。'],
    // #MCB1.5次開発 #9236 START
    ['MCAXS230-023', '姓に使用できない文字（\\,/,:,*,?,",<,>,|のいずれか）が使われています。'],
    ['MCAXS230-024', '名に使用できない文字（\\,/,:,*,?,",<,>,|のいずれか）が使われています。'],
    // #MCB1.5次開発 #9236 END

    //MCAXS141
    ['MCAXS141-001',`権限がないため、操作を実行できません。`],

    //MCAXS151
    ['MCAXS151-000', ``],
    //データダウンロード権限チェック 異常エラーコード
    ['MCAXS151-001', '権限がないため、操作を実行できません。'],
    //閲覧権限チェック 異常エラーコード
    ['MCAXS151-002', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
    //ステータスチェック 異常エラーコード
    ['MCAXS151-003', 'PDFダウンロードができませんでした。一覧の検索から処理をやりなおしてください。'],

    // MCAXS220
    ['MCAXS220-000', ''],
    ['MCAXS220-001', '郵便番号を入力してください。'],
    ['MCAXS220-002', '郵便番号のフォーマットが正しくありません。'],
    ['MCAXS220-003', '選択した応募経路が存在しないため、登録できません。'],
    ['MCAXS220-004', '選択した選考フローは権限がないため、登録できません。'],
    ['MCAXS220-005', '「郵便番号」、「都道府県」、「市区町村以降の住所」はすべて入力してください。'],
    ['MCAXS220-006', '入力された内容を破棄し、画面を移動します。よろしいですか？'],
    ['MCAXS220-007', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
    ['MCAXS220-008', '選考フローを入力してください。'],
    ['MCAXS220-009', '西暦が不正です。'],
    ['MCAXS220-010', '終了日が開始日よりも前です'],
    ['MCAXS220-011', '必須です'],
   // MCBAS020
   ['MCBAS020-001', '指定されたページは存在しません。'],
   ['MCBAS020-002', '他のアカウントを有する担当者様が面接日程調整をしている可能性があります。画面を更新してください。'],
   ['MCBAS020-003', '応募者に面接キャンセル連絡のメッセージを送信してください。\n応募者にメッセージが送られた時点で、確定していた面接が取り消されます。\n（発行済みのWEB面接URLがある場合は、同時に無効化されます。）'],
   ['MCBAS020-008', '条件に一致する検索結果がありませんでした。'],
   ['MCBAS020-009', '指定されたページは存在しません。'],
   // WEB面接対応 start
   ['MCBAS020-010', 'WEB面接残枠を取得することができませんでした。'],
   ['MCBAS020-011', '担当者のWEB面接URLは最大5名まで同時に利用が可能です。'],
   ['MCBAS020-012', 'WEB面接URLを発行します。\n※実際に面接を行う端末や接続環境で、接続テストの実施をお願いします。'],
   ['MCBAS020-013', 'エラーが発生しました。'],
   ['MCBAS020-014', '既にWEB面接URLが発行されています。'],
   ['MCBAS020-015', 'WEB面接のURLを発行できませんでした。'],
   ['MCBAS020-016', 'WEB面接のURL発行可能枠数がない、または発行期限が切れているため、URLを発行できません。'],
   ['MCBAS020-017', 'WEB面接URLをキャンセルします。よろしいですか？\n本操作により、面接日の取り下げは行われません。\n※面接URL送付メッセージを送信予約している場合はURL差し込み文字部分が空白で送信されます。'],
   ['MCBAS020-018', 'WEB面接URLをキャンセルしました。'],
   ['MCBAS020-019', '既にキャンセルされています。'],
   ['MCBAS020-020', 'WEB面接URLをキャンセルできませんでした。'],
   ['MCBAS020-021', 'WEB面接URLをコピーしました。'],
   // WEB面接対応 end

   // MCAXS650
   ['MCAXS650-000', '以下の内容で情報を更新します。内容をご確認ください。'],
   ['MCAXS650-001', '対象の応募者は閲覧できません。'],
   ['MCAXS650-002', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
   ['MCAXS650-003', '学部名を入力してください。'],
   ['MCAXS650-004', '学科名を入力してください。'],
   ['MCAXS650-005', '応募者情報の更新が完了しました。'],
   ['MCAXS650-006', '入力された内容で更新します。よろしいですか？'],

   // MCAXS240
   ['MCAXS240-000', ''],
   ['MCAXS240-001', '権限がないため対象の応募者を閲覧できません。'],
   ['MCAXS240-002', '郵便番号を入力してください。'],
   ['MCAXS240-003', '郵便番号のフォーマットが正しくありません。'],
   ['MCAXS240-004', '「郵便番号」、「都道府県」、「市区町村以降の住所」はすべて入力してください。'],
   ['MCAXS240-005', '入力された内容を破棄し、画面を移動します。よろしいですか？'],
   ['MCAXS240-006', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
   ['MCAXS240-007', '西暦が不正です。'],
   ['MCAXS240-008', '終了日が開始日よりも前です'],

   // MCAXS640
   ['MCAXS640-000', '以下の内容で情報を更新します。内容をご確認ください。'],
   ['MCAXS640-001', '権限がないため対象の応募者を閲覧できません。'],
   ['MCAXS640-002', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
   ['MCAXS640-003', 'エントリーの更新が完了しました。'],
   ['MCAXS640-004', '入力された内容で更新します。よろしいですか？'],
   // MCAXS630
   ['MCAXS630-000', '以下の内容で情報を更新します。内容をご確認ください。'],
   ['MCAXS630-001', '入力された内容で登録します。よろしいですか？'],
   ['MCAXS630-002', '選択した応募経路が存在しないため、登録できません。'],
   ['MCAXS630-003', '選択した選考フローは権限がないため、登録できません。'],
   ['MCAXS630-004', '「郵便番号」、「都道府県」、「市区町村以降の住所」はすべて入力してください。'],
   ['MCAXS630-005', '応募情報を登録しました。[利用者番号：{0}]'],

   //[' MCAXS201
   ['MCAXS201-000', 'フォームから応募者情報を登録できます。'],
   [
    'MCAXS201-022',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCAXS201-002', '郵便番号を入力してください。'],
  ['MCAXS201-003', '郵便番号のフォーマットが正しくありません。 半角数字（ハイフン無し）で入力してください。'],
  ['MCAXS201-004', '西暦が不正です。'],
  ['MCAXS201-005', '学校名を入力してください。'],
  ['MCAXS201-006', '研究科・専攻名を入力してください。'],
  ['MCAXS201-007', '研究科を入力してください。'],
  ['MCAXS201-008', '専攻名を入力してください。'],
  ['MCAXS201-009', '学部・学科名を選択してください。'],
  ['MCAXS201-010', '学部名を入力してください。'],
  ['MCAXS201-011', '学科名を入力してください。'],
  ['MCAXS201-023', '学科名を選択してください。'],
  ['MCAXS201-014', '専攻分野を選択してください。'],
  ['MCAXS201-015', '文理区分を選択してください。'],
  ['MCAXS201-018', '選択した選考フローは権限がないため、登録できません。'],
  ['MCAXS201-019', '選択した応募経路が存在しないため、登録できません。'],
  ['MCAXS201-020', '入力された内容を破棄し、画面を移動します。よろしいですか？'],
  ['MCAXS201-024', '学校名（頭文字）を選択してください。'],
  ['MCAXS201-025', '取得学位を指定してください。'],
  ['MCAXS201-026', '専攻分野を選択してください。'],
  // MCAQS040
  ['MCAQS040-000', '指定されたマージ対象を確認する。'],
  // アカウント権限チェック 異常エラーコード
  ['MCAQS040-001', '指定されたページは存在しません。'],
  // アカウント権限チェック
  ['MCAQS040-002', 'システムエラーが発生しました。'],
  // 実行確認チェック
  ['MCAQS040-003', '名寄せを実行します。\n名寄せを実行すると元に戻せません。よろしいですか？'],
  // 実行確認チェック
  ['MCAQS040-004', '応募者データが他のアカウントを有する担当者様により変更されています。再度名寄せチェックをしてください。'],
  // 応募者情報名寄せ完了メッセージ
  ['MCAQS040-005', '応募者情報の名寄せが完了しました。'],
  // 送信中メッセージチェック
  ['MCAQS040-006', '消す方の応募者に送信中のメッセージがあるため名寄せできません。メッセージ送信完了後に操作を行ってください。'],
  // 25KH #74726 start
  // 維持保守 #74253 start
  ['MCAQS040-007', '消す方の選考フローに送信中、送信予約、下書きのいずれかのメッセージが存在するため名寄せできません。\n送信中のメッセージの場合、時間をおいて再度実行してください。\n送信予約、下書きのメッセージは、ゴミ箱に移し再度実行してください。'],
  // 維持保守 #74253 end
  // 25KH #74726 end
  // MCBリプレース MCAQS040 START
  ['MCAQS040-008', '消す方の応募者に送信中のMy CareerBoxメッセージがあるため名寄せできません。メッセージ送信完了後に操作を行ってください。'],
  // MCBリプレース MCAQS040 END
  // #MCBリプレイス対応　#79162 MCAQS040 START
  // #MCB1.5次開発 #81606 START
  ['MCAQS040-009', '応募者のMy CareerBox会員IDが異なるため名寄せできません。'],
  ['MCAQS040-010', '「消す」応募者に提出リクエストが紐づいているため、名寄せできません。名寄せする場合は、「消す」応募者に紐づくすべての提出リクエストを強制停止する必要があります。'],
  // #MCB1.5次開発 #81606 END
  ['MCAQS040-011', '応募者の提出リクエストに同じ提出物内容が存在するため、名寄せできません。'],
  // #MCBリプレイス対応　#79162 MCAQS040 END

  // MCAQS030
  ['MCAQS030-000', '名寄せ対象の検索結果一覧より名寄せの対象を指定できます。'],
  ['MCAQS030-001', '指定されたページは存在しません。'],
  ['MCAQS030-002', 'システムエラーが発生しました。'],
  ['MCAQS030-003', '重複が{0}件を超えています。{1}件まで表示しています。'],
  ['MCAQS030-004', '「残す」「消す」をそれぞれ1つずつ選択してください。'],
  ['MCAQS030-005', '「残す」「消す」は別々の応募者情報を選択して下さい。'],
  ['MCAQS030-006', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
  // 25KH #74726 start
  ['MCAQS030-007', '応募者に同じ選考フロー（{0}）が存在します。</br>［消す］応募者の選考フローに紐づく「応募経路」「メッセージ送信履歴」「エントリー履歴」「My Carreer Box連携履歴」は、［残す］応募者の情報に追加します。'],
  ['MCAQS030-007-1', '（２）（１）で選択した選考フローの管理項目が「未設定」且つ、［消す］応募者データの管理項目に'],
  ['MCAQS030-007-2', '「値がある」場合のデータの取り扱いについて選択ください。（どちらかを選択）'],
  ['MCAQS030-007-3', '名寄せを行ってよろしいですか？'],
  ['MCAQS030-007-4', '{0}の「選考ステップ・合否の設定履歴」「優先度」「コメント」「管理項目」はどちらを残しますか？'],
  ['MCAQS030-011', '消す方の応募者に送信中のMy CareerBoxメッセージがあるため名寄せできません。メッセージ送信完了後に操作を行ってください。'],
  // 25KH #74726 end
  // MCBリプレース MCAQS030 START
  // #MCB1.5次開発 #81606 START
  ['MCAQS030-008', '応募者のMy CareerBox会員IDが異なるため名寄せできません。'],
  ['MCAQS030-009', '「消す」応募者に提出リクエストが紐づいているため、名寄せできません。名寄せする場合は、「消す」応募者に紐づくすべての提出リクエストを強制停止する必要があります。'],
  // #MCB1.5次開発 #81606 END
  ['MCAQS030-010', '応募者の提出リクエストに同じ提出物内容が存在するため、名寄せできません。'],
  // MCBリプレース MCAQS030 END

  // MCAXS211
  ['MCAXS211-000', '以下の内容で情報を更新します。内容をご確認ください。'],
  ['MCAXS211-001', '入力された内容で登録します。よろしいですか？'],
  ['MCAXS211-002', '学部名を入力してください。'],
  ['MCAXS211-003', '学科名を入力してください。'],
  ['MCAXS211-004', '選択した選考フローは権限がないため、登録できません。'],
  ['MCAXS211-005', '選択した応募経路が存在しないため、登録できません。'],
  ['MCAXS211-006', '応募者を登録しました。[応募者管理ID：{0}]'],
  // 年度対応　MCAXS211 start
  ['MCAXS211_007', '入力された内容を破棄し、画面を移動します。よろしいですか？'],
  // 年度対応　MCAXS211 end
  // MCAXS650
  ['MCAXS650-000', '以下の内容で情報を更新します。内容をご確認ください。'],
  ['MCAXS650-001', '対象の応募者は閲覧できません。'],
  [
    'MCAXS650-002',
    '選択した応募者データが他の利用者により変更されています。再度検索を行い応募者を選択しなおして下さい。',
  ],
  ['MCAXS650-003', '学部が入力されていません。'],
  ['MCAXS650-004', '学科が入力されていません。'],
  ['MCAXS650-005', '応募者情報の更新が完了しました。'],
  ['MCAXS650-006', '入力された内容で更新します。よろしいですか？'],

  //  MCAXS660
  ['MCAXS660-000', "受検者として、{0}名の登録に成功しました。</p>"],

  // MCAXS240
  ['MCAXS240-000', ''],
  ['MCAXS240-001', '対象のエントリーは閲覧できません。'],
  ['MCAXS240-002', '郵便番号を入力してください。'],
  ['MCAXS240-003', '郵便番号のフォーマットが正しくありません。'],
  ['MCAXS240-004', '住所は全て入力してください。'],
  ['MCAXS240-005', '入力した内容が全て破棄されます。'],
  [
    'MCAXS240-006',
    '操作をキャンセルしますか？入力した内容が保存されない可能性があります。',
  ],
  ['MCAXS240-007', '西暦が不正です。'],
  ['MCAXS240-008', '終了日が開始日よりも前です'],

  // MCAXS640
  ['MCAXS640-000', '以下の内容で情報を更新します。内容をご確認ください。'],
  ['MCAXS640-001', '対象のエントリーは閲覧できません。'],
  [
    'MCAXS640-002',
    '選択した応募者データが他の利用者により変更されています。再度検索を行い応募者を選択しなおして下さい。',
  ],
  ['MCAXS640-003', 'エントリーの更新が完了しました。'],
  ['MCAXS640-004', '入力された内容で更新します。よろしいですか？'],

  // MCAQS040
  ['MCAQS040-000', '以下の通り名寄せをします。名寄せした情報をもとに戻すことはできません。\nご確認の上、「実行する」ボタンをクリックしてください。'],
  // アカウント権限チェック 異常エラーコード
  ['MCAQS040-001', '指定されたページは存在しません。'],
  // アカウント権限チェック
  ['MCAQS040-002', 'システムエラーが発生しました。'],
  // 実行確認チェック
  // [
  //   'MCAQS040-003',
  //   '名寄せを実行します。名寄せを実行すると元に戻せませんが、よろしいですか？',
  // ],
  // 実行確認チェック
  [
    'MCAQS040-004',
    '選択した応募者データが他の利用者により変更されています。再度名寄せチェックを行い応募者を選択し直してください。',
  ],
  // 応募者情報名寄せ完了メッセージ
  ['MCAQS040-005', '応募者情報の名寄せが完了しました。'],

  // MCAQS030
  ['MCAQS030-000', '名寄せ対象の検索結果一覧より名寄せの対象を指定できます。'],
  ['MCAQS030-001', '指定されたページは存在しません。'],
  ['MCAQS030-002', 'システムエラーが発生しました。'],
  ['MCAQS030-003', '重複が{0}件を超えています。{1}件まで表示しています。'],
  ['MCAQS030-004', '「残す」「消す」をそれぞれ1つずつ選択してください。'],
  ['MCAQS030-005', '「残す」「消す」は別々の応募者情報を選択して下さい。'],

  // MCAXS010
  [
    'MCAXS010-001',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  [
    'MCAXS010-002',
    '一括で合否・選考ステップを変更するには選考フロー・選考ステップが同一である必要があります。',
  ],
  ['MCAXS010-003', '条件に一致する検索結果がありませんでした。'],
  [
    'MCAXS010-004',
    '表示上限数を超えた表示対象があります。\n必要に応じて詳細な検索条件で検索条件の追加や表示上限数の変更を行ってから再検索してください。'
  ],
  [
    'MCAXS010-005',
    '応募経路が混ざっている状態です。\n検索条件で応募経路をしぼるか、以下より条件追加して下さい。'
  ],

  // MCAXS020
  [
    'MCAXS020-001',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  [
    'MCAXS020-002',
    '選択した応募者データを対象外にしてもよろしいですか？\n対象外にすると選考中一覧から選考対象外一覧へと移動されます。メッセージ送信予約をされている場合は、そのまま送信されます。',
  ],
  ['MCAXS020-003', '対象外から戻してもよろしいですか？'],
  [
    'MCAXS020-004',
    '一括で選考ステップ・合否を更新するには選考フロー・選考ステップが同一である必要があります。',
  ],
  ['MCAXS020-005', '条件に一致する検索結果がありませんでした。'],
  [
    'MCAXS020-006',
    '選択した応募者データは日程調整中のため対象外にすることができません。',
  ],
  ['MCAXS020-008', '不採用ステータス変更が設定されているため毎日4:00に「選考ステップ：不採用」へ自動で変更します。\\n※20:00～翌日4:00の間で「合否：不合格」とした応募者は、翌々日の4:00に不採用へ変更されます。\\n不採用ステータス変更を自動で実施したくない場合は、「キャンセル」を押下し、不採用通知設定を修正してください。'],
  [
    'MCAXS020-009',
    '一括で合否を更新するには選考フロー・選考ステップが同一である必要があります。',
  ],
  // MCAXS040
  [
    'MCAXS040-002',
    '選択した応募者データは面接日程調整中のものがあります。応募者を選択しなおしてください。',
  ],
  [
    'MCAXS040-003',
    '対象外にしてもよろしいですか？\n対象外にすると全件一覧から対象外一覧へと移動されます。メッセージ送信予約をされている場合は、そのまま配信されます。',
  ],
  ['MCAXS040-004', '対象外から戻してもよろしいですか？'],
  ['MCAXS040-005', '表示可能なデータがありません。'],
  ['MCAXS040-006', '権限がないため、操作を実行できません。'],
  ['MCAXS040-007', '強制停止された提出リクエストは{0}できません。'],
  ['MCAXS040-008', '提出状況が「提出済」以外の提出リクエストはPDF出力できません。'],
  ['MCAXS040-009', '「送信予約」「強制停止」「無効の提出リクエスト」が含まれているため、{0}できません。'],
  ['MCAXS040-010', '応募者がMy CareerBoxを退会しているため{0}できません。'],
  ['MCAXS040-011', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\n ご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。'],
  ['MCAXS040-012', '提出済のファイルがないため、PDFダウンロードできません。'],
  // MCAXS050
  [
    'MCAXS050-001',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  [
    'MCAXS050-002',
    '選択した応募者データは面接日程調整中のものがあります。応募者を選択しなおしてください。',
  ],
  [
    'MCAXS050-003',
    '対象外にしてもよろしいですか？\n対象外にすると選考中一覧から対象外一覧へと移動されます。メッセージ送信予約をされている場合は、そのまま配信されます。'
  ],
  ['MCAXS050-004', '対象外から戻してもよろしいですか？'],
  ['MCAXS050-005', '表示可能なデータがありません。'],
  ['MCAXS050-006', '{0}は選考フローを変更できません。質問や匿名質問以外でエントリーをいただくようにご案内ください。'],
  ['MCAXS050-007', '書類選考免除の応募者が配信対象です。<br />'],
  ['MCAXS050-008', '質問者が配信対象です。<br />'],
  ['MCAXS050-009', 'セミナー情報への応募者が配信対象です。<br />'],
  ['MCAXS050-010', 'イベント着席をした求職者が配信対象です。<br />'],
  ['MCAXS050-016', 'すでに不採用通知メッセージが送信予約または送信されているか、過去に不採用通知メッセージの送信予約をキャンセルしています。\n送信予約をキャンセルした場合は、メッセージを作成し送信してください。'],
  ['MCAXS050-018', 'ワンクリック不採用通知用のメッセージテンプレートを設定する必要があります。\n共通設定の「不採用通知設定管理」より設定ください。'],
  // MCAXS620
  [
    'MCAXS620-001',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  [
    'MCAXS620-002',
    '選択した応募者データは面接日程調整中のものがあります。応募者を選択しなおしてください。',
  ],
  ['MCAXS620-003', '対象外にしてもよろしいですか？\n対象外にすると選考中一覧から対象外一覧へと移動されます。メッセージ送信予約をされている場合は、そのまま配信されます。'],
  ['MCAXS620-004', '対象外から戻してもよろしいですか？'],
  ['MCAXS620-005', '表示可能なデータがありません'],
  ['MCAXS620-006', '{0}は選考フローを変更できません。質問や匿名質問以外でエントリーをいただくようにご案内ください。'],
  // MCAXS401
  ['MCAXS401_001', '応募者管理IDに設定できる上限（10,000件）を超えています。'],
  ['MCAXS401_002', '氏名・氏名カナに設定できる上限（100件）を超えています。'],
  // MCAXS421
  ['MCAXS411_001', '掲載期間を指定すると「応募職種を選択」するプルダウンで選択できる職種が絞り込まれます。'],
  [
    'MCAXS421_001',
    '複数指定する場合は改行で入力してください。一度に設定できるのは10,000件です。',
  ],
  [
    'MCAXS421_002',
    '複数指定する場合は改行で入力してください。一度に設定できるのは100件です。',
  ],
  // MCAXS521
  [
    'MCAXS521_001',
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  ],
  // MCAXS561
  [
    'MCAXS561_001',
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  ],
  // MCAXS501
  [
    'MCAXS501_001',
    'キャンセルすると選択した条件がクリアされます。よろしいですか？',
  ],
  ['MCAXS501-002', '小数点以下は２桁以内で入力して下さい'],
  // MCAXS170
  [
    'MCAXS170-002',
    '権限のないデータが存在するため、タグを設定できません。再度検索し、実施してください。',
  ],
  ['MCAXS170-003', '入力した内容を設定します。よろしいですか？'],
  ['MCAXS170-004', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
  ['MCAXS170-005', 'タグを設定しました。'],
  [
    'MCAXS170-006',
    '一括で登録できるタグの上限数は10件です。',
  ],
  [
    'MCAXS170-007',
    'タグ名は20文字以内で入力してください。',
  ],
  // 年度対応 start
  [
    'MCAXS170-007-1',
    '入力した内容を設定します。設定しますと他の年度でも更新されます。よろしいですか？',
  ],
  // 年度対応 end
  ['MCAXS090-009', 'ファイルサイズが 0Byte のファイルは指定できません 。'],

  ['MCAMS020-025', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],

  ['MCAMS030-028', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],

  ['MCAMS040-024', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],

  ['MCADS010-014', 'アカウント情報の登録が完了していないアカウントが含まれております。'],

  // MCAZS240
  ['MCAZS240-001', 'テンプレート名を入力してください。'],
  ['MCAZS240-002', '新規テンプレートとして保存してもよろしいですか？'],
  ['MCAZS240-003', '指定されたテンプレート名は既に登録されています。'],
  ['MCAZS240-004', '登録上限数は500件です。'],
  ['MCAZS240-005', '保存先のテンプレートを選択してください。'],
  ['MCAZS240-006', 'My CareerBoxのテンプレート以外を上書き保存してもよろしいですか？'],
  ['MCAZS240-007', 'テンプレートを上書き保存してもよろしいですか？'],
  ['MCAZS240-008', '上書き保存対象のメッセージテンプレートが存在しないため、上書き保存できません。'],
  ['MCAZS240-009', 'メッセージテンプレートを新規保存しました。'],
  ['MCAZS240-010', 'メッセージテンプレートを上書き保存しました。'],
  ['MCAZS240-011', 'メッセージを送信しました。メッセージが届くまで、時間がかかる場合があります。<br/>最新のメッセージ一覧をご確認いただく場合は一覧へ遷移後、手動で更新いただく必要があります。'],
  ['MCAZS240-012', 'メッセージを送信予約しました。<br/>最新のメッセージ一覧をご確認いただく場合は一覧へ遷移後、手動で更新いただく必要があります。'],
  
  //  MCBCS040
  [
    'MCBCS040-000',
    'ダウンロードデータの作成が完了しました。\n「ダウンロードする」ボタンをクリックしてください。',
  ],
  [
    'MCBCS040-001',
    '権限がないため、操作を実行できません。',
  ],
  [
    'MCBCS040-002',
    'ファイルのダウンロード期限を過ぎています。応募者一覧画面の検索からやりなおしてください。',
  ],

  // MCAXS300
  ['MCAXS300-000', '表示文言'],
  ['MCAXS300-001', '入力された内容で更新します。よろしいですか？'],
  ['MCAXS300-002', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\njpg,jpeg,png'],
  ['MCAXS300-003', '参照したファイルのサイズが大きすぎます。サイズが10MB以内のファイルを参照してください。'],
  ['MCAXS300-004', '変更対象者が応募していません。もう一度ご確認ください。'],
  ['MCAXS300-005', 'アップロードに失敗しました。'],
  ['MCAXS300-006', 'アップロードが完了しました。'],
  ['MCAXS300-007', '画像を削除しました。'],
  ['MCAXS300-008', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
  ['MCAXS300-009', 'ファイルサイズが 0Byte のファイルは指定できません。'],
  ['MCAXS300-100', '添付ファイルを選択してください。'],
  ['MCAXS300-101', '形式：JPG（JPEG）またはPNG'],
  ['MCAXS300-102', '※ファイルサイズの上限は10MByteまでになります。<br/>※大きさは自由ですが、表示の際に幅162pxX高さ216pxにリサイズされます。'],
  ['MCAXS300-012', 'アップロードされたファイルは画像ファイルではないため、アップロードできません。'],
  // 年度対応 start
  ['MCAXS300-0012', '入力した内容を設定します。設定しますと他の年度でも更新されます。よろしいですか？'],
  // 年度対応 end

  // MCBCS010
  ['MCBCS010-001', '指定されたページは存在しません。'],
  ['MCBCS010-002', 'My CareerBoxに連携可能な応募者数を超えています。{0}件以下で再実行してください。'],
  ['MCBCS010-003', '選択された対象データに紐づく選考フローに対する権限がありません。一覧画面に戻り検索しなおしてください。'],
  ['MCBCS010-004', '権限がないため、操作を実行できません。'],
  ['MCBCS010-005', 'My CareerBoxへデータを連携します。よろしいですか？'],
  ['MCBCS010-006', '現在、My CareerBoxは停止時間となっております。時間をおいて再度実施してください。'],
  ['MCBCS010-007', '連携対象データの中に、My CareerBox連携済のデータが含まれています。\n再度My CareerBoxへ連携をしてもよろしいですか？'],
  ['MCBCS010-008', '連携対象データの中に、My CareerBoxカテゴリーと一致しない応募経路からのエントリーデータが存在しています。\nMy CareerBoxへ連携をしますか？'],
  ['MCBCS010-009', '選択された対象データに紐づく選考フローに対する権限がありません。一覧画面に戻り検索しなおしてください。'],
  ['MCBCS010-010', 'ただ今混み合っております。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBCS010-011', 'My CareerBoxへの連携で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBCS010-100', '連携済の応募者が連携対象に含まれています。<br/>連携対象外とする応募者がいる場合は、応募者一覧に戻り、チェックをはずしてください。<br/>連携済みの応募者が{0}件中{1}件います。<br/>※メールアドレスの登録がない応募者を除外した件数です'],
  // 25P 72790 start
  ['MCBCS010-101', '検索結果一覧の応募者をMy CareerBoxに連携します。<br/>検索条件を設定して生成してください。<br/>メールアドレス（メイン）・メールアドレス（サブ）の両方に設定のない応募者は<br/>連携対象となりませんのでご注意ください。'],
  // 25P 72790 end
  ['MCBCS010-102', '※My CareerBoxにデータが連携される所要時間は、システムの負荷状況によって異なります。<br/>※現在の所要時間の目安は企業向けMy CareerBoxのトップページに掲出しております。'],
  ['MCBCS010-103', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],

  // MCBCS020
  ['MCBCS020-100', '検索結果一覧の学生をMy CareerBoxに連携完了しました。<br/>My CareerBoxへのデータ連携まで少しお時間がかかる場合がございます。<br/>時間をおいてからMy CareerBoxをご確認ください。'],
  // MCBCS030
  ['MCBCS030-000', 'ダウンロードデータを作成中です。\nファイルの作成が完了しましたら、画面が切り替わります。\nしばらくお待ちください。'],
  ['MCBCS030-001', '権限がないため、操作を実行できません。'],
  ['MCBCS030-002', '閲覧できない応募者データが含まれています。応募者一覧画面に戻り、再度検索してください。'],
  ['MCBCS030-003', 'CSVファイルの作成に失敗しました。恐れ入りますが、一覧の検索から処理をやりなおしてください。'],

  // MCAXS260
  ['MCAXS260-028', '現在、My CareerBoxは停止時間となっております。時間をおいて再度実施してください。'],
  // [phase2] end
  ['MCACS010-008', '「私はロボットではありません」にチェックを入れてください。'],

  ['MCAMS020-027', '選択したテンプレートは存在しません。'],
  ['MCAMS030-031', '選択したテンプレートは存在しません。'],
  ['MCAMS040-028', '選択したテンプレートは存在しません。'],
  ['MCAZS101-010', '上書き保存対象のメッセージテンプレートが存在しないため、上書き保存できません。'],

  ['MCAXS491-002', '未視聴：視聴を開始していない、もしくは視聴していない状態\n視聴中：視聴を開始している、もしくは一時中断した状態\n視聴済：視聴が完了した状態'],

  // 年度対応 start
  ['MCAAS010-001', '指定された年度は利用不可のため、もう一度ご選択ください。'],
  ['MCAAS010-002', '選択された年度は表示対象外のため、もう一度ご選択ください。'],
  ['MCAAS010-COMMON-001', '対象年度を切り替えます。'],
  ['MCAAS010-COMMON-002', '対象年度の切り替えが行われたため、入力された内容を破棄し、切り替えた対象年度の応募者一覧画面に遷移します。'],
  ['MCAAS010-COMMON-003', '入力した内容が全て破棄されます。'],
  ['MCAAS010-COMMON-004', '入力された内容を破棄し、画面を移動します。よろしいですか？'],
  ['MCAAS010-COMMON-005', '別ウィンドウで対象年度の切り替え操作が行われたため、処理を中断いたしました。\n切り替えた対象年度の応募者一覧画面に遷移します。'],
  ['MCAAS010-COMMON-006', '別ウィンドウで対象年度の切り替え操作が行われたため、処理を中断いたしました。\n切り替えた対象年度の画面に遷移します。'],
  ['MCAAS010-COMMON-007', '別ウィンドウで対象年度の切り替え操作が行われたため、処理を中断いたしました。\n本ウィンドウは、「×」ボタンでブラウザを閉じてください。'],
  ['MCAAS010-COMMON-008', '別ウィンドウで対象年度の切り替え操作が行われたため、本画面を終了し、切り替えた対象年度の画面に遷移します。'],
  // 転職March #75621 START
  ['MCAAS010-003', '指定されたサービスは利用できません。'],
  ['MCAAS010-004', '選択されたサービスは表示対象外のため、「マイナビ転職」に切り替えます。'],
  ['MCAAS010-005', '選択されたサービスは表示対象外のため、処理を中断いたしました。\n「×」ボタンから本ウィンドウを閉じてください。'],
  ['MCAAS010-COMMON-009', 'サービスを切り替えます。'],
  ['MCAAS010-COMMON-010', '別ウィンドウでサービスの切り替え操作が行われたため、処理を中断いたしました。\n切り替えたサービスの画面に遷移します。'],
  ['MCAAS010-COMMON-011', '別ウィンドウでサービスの切り替え操作が行われたため、処理を中断いたしました。\n本ウィンドウは、「×」ボタンでブラウザを閉じてください。'],
  ['MCAAS010-COMMON-012', '別ウィンドウでサービスの切り替え操作が行われたため、本画面を終了し、切り替えたサービスの画面に遷移します。'],
  // 転職March #75621 END
  ['MCAVS010_002', '他の年度に新規応募者がいます。'],
  ['MCAVS010_003', '他の年度に未読メッセージがあります。'],
  ['MCAVS010_004', '現在の対象年度とは異なる年度の応募者が選択されたため、対象年度の切り替えを行い画面遷移します。'],
  ['MCAVS010_0099', '閲覧できる選考フローがありません。'],
  // 年度対応 end

  // 24KH #61555 start
  ['MCAXS621-001', 'キャンセルすると選択した条件がクリアされます。よろしいですか？'],
  ['MCAXS621-002', '「いずれかの日程／各受付ごと」のどちらかを選択してください。'],
  // 24KH #61555 end

  // 次期9月対応 #58811 start
  ['MCAVS010_009','表示されている応募件数と遷移先の件数に差異が生じる場合があります。'],
  // 次期9月対応 #58811 end

  // 26P #74725 start
  ['MCAVS010-011', '「予約」には、MIWSからの手動予約を含む「セミナー予約人数」が表示されます。「キャンセル」には、キャンセル後に再度予約した人数も含まれます。'],
  ['MCAVS010-012', '「参加人数」にはイベントエントリー人数が表示されます。'],
  // 26P #74725 end

  ['MCBKS010-000', '検索された応募者データに対して、表形式で項目の更新ができます。ページごとの更新が必要です。'],
  ['MCBKS010-001', '一度に更新できる件数は{0}件までです。'],
  ['MCBKS010-002', '権限がないため閲覧できません。'],
  ['MCBKS010-003', '削除されているため閲覧できません。'],
  ['MCBKS010-004', '表示されている応募者データに変更された項目があります。\n変更内容を保存して別のページへ移動しますか？'],
  ['MCBKS010-005', '表更新は1ページずつ更新します。\n変更を保存します。よろしいですか？'],
  ['MCBKS010-006', '「このページを更新する」を押下していない場合、入力した内容は保存されません。\nキャンセルしてよろしいですか？'],
  ['MCBKS010-007', '性別を更新しました。'],

  ['MCBKS020-001', '一度に更新できる件数は{0}件までです。'],
  ['MCBKS020-002', '一度に更新できる件数は{0}件までです。'],
  ['MCBKS020-003', '他のアカウントを有する担当者様により、管理項目設定が変更されました。\nその他一括更新から、表更新（管理項目）をやり直してください。'],
  ['MCBKS020-004', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],

  ['MCBKS030-001', '他のアカウントを有する担当者様により、選択した管理項目の設定が変更されました。\n再度表更新（管理項目）選択画面から、操作を行ってください。'],
  ['MCBKS030-002', '削除されているため閲覧できません。'],
  ['MCBKS030-003', '権限がないため閲覧できません。'],
  ['MCBKS030-004', '選考が変更されたか、削除されたため閲覧できません。'],
  ['MCBKS030-005', '表示されている応募者データに変更された項目があります。\n変更内容を保存して別のページへ移動しますか？'],
  ['MCBKS030-006', '表更新は1ページずつ更新します。\n変更を保存します。よろしいですか？'],
  ['MCBKS030-007', '以下の応募者データが他のユーザにより変更されています。\n更新内容を上書きして保存しますか？'],
  ['MCBKS030-008', '「このページを更新する」を押下していない場合、入力した内容は保存されません。\nキャンセルしてよろしいですか？'],
  ['MCBKS030-009', '管理項目を更新しました。'],
  ['MCBKS030-010', 'コピーしました。'],

  // 24GO対応 #80211 start
  ['MCGOCOMMON-001', 'この機能は現在ご利用いただけません。\nしばらく時間をおいてアクセスいただきますようお願いいたします。'],
  // 24GO対応 #80211 end

  // MCBリプレース MCBHS151 START
  ['MCBHS151-001', 'TOEIC（点数）の左側の枠に低い値を、右側の枠に高い値を入力してください'],
  ['MCBHS151-002', 'TOEFL iBT（点数）の左側の枠に低い値を、右側の枠に高い値を入力してください'],
  ['MCBHS151-003', 'エラー　※最大5件まで入力可能'],
  // MCBリプレース MCBHS151 END

  // MCBリプレース MCBHS131 START
  ['MCBHS131-001', '複数指定する場合は改行で入力してください。一度に設定できるのは10,000件です。'],
  ['MCBHS131-002', '複数指定する場合は改行で入力してください。一度に設定できるのは100件です。'],
  ['MCBHS131-003', '提出リクエストを受領した応募者は、My CareerBox利用状況が利用中になります。\nMy CareerBox利用状況は、年度を跨いで引き継がれます。'],
  ['MCBHS131-004', '応募者管理IDの桁数が間違っています。'],
  ['MCBHS131-005', '応募者管理IDに設定できる上限（10,000件）を超えています。'],
  ['MCBHS131-006', '氏名・氏名カナは100件以内で入力してください。'],
  // MCBリプレース MCBHS131 END

  // MCBリプレース MCBIS010 start
  [
    'MCBIS010-000',
    '最大100件までMY検索を登録できます。',
  ],
  ['MCBIS010-00A', ''],
  ['MCBIS010-001', '選択した一括処理を実行します。よろしいですか？'],
  ['MCBIS010-002', 'MY検索（提出リクエスト管理）を削除しました。'],
  ['MCBIS010-003', '入力された内容で更新します。よろしいですか？'],
  ['MCBIS010-004', 'MY検索（提出リクエスト管理）名を入力してください。'],
  ['MCBIS010-005', '同じ名称でMY検索（提出リクエスト管理）を保存できません。'],
  ['MCBIS010-006', 'MY検索を更新しました。'],
  [
    'MCBIS010-007',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  // MCBリプレース MCBIS010 end
  // MCBリプレース MCBIS030 start
  [
    'MCBIS030-000',
    '現在の検索条件（提出リクエスト管理）を保存します。 保存方法を上書き保存、または新規保存から選択し「OK」ボタンをクリックしてください。',
  ],
  [
    'MCBIS030-002',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCBIS030-003', 'MY検索（提出リクエスト管理）を保存します。よろしいですか？'],
  ['MCBIS030-005', 'MY検索（提出リクエスト管理）を選択してください。'],
  ['MCBIS030-006', 'MY検索（提出リクエスト管理）名称を入力してください。'],
  [
    'MCBIS030-007',
    '登録上限数は100件です。',
  ],
  ['MCBIS030-008', '同じ名称でMY検索（提出リクエスト管理）を保存できません。'],
  ['MCBIS030-009', 'MY検索（提出リクエスト管理）を保存しました。'],
  // MCBリプレース MCBIS030 end
  // MCBリプレース MCBHS040 START
  ['MCBHS040-001', '条件に一致する検索結果がありませんでした。'],
  ['MCBHS040-002', '表示上限数を超えた表示対象があります。\n必要に応じて詳細な検索条件で検索条件の追加や表示上限数の変更を行ってから再検索してください。'],
  // #MCB1.5次開発 8777 START
  ['MCBHS040-003', '応募者絞り込み検索実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  // #MCB1.5次開発 8777 END
  ['MCBHS040-004', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\nご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。'],
  // MCBリプレース MCBHS040 END

 // MCBリプレース MCAXS701 START
  ['MCAXS701-001', '「提出リクエスト状況」条件が「リクエスト済」を選択してください。',],
  ['MCAXS701-002', '「提出リクエスト状況」条件が「リクエスト済」、「提出状況」条件が「提出済」を選択ください。',],
  // #MCB1.5次開発 #81606 START
  ['MCAXS701-003', '提出物内容一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。',],
  // #MCB1.5次開発 #81606 END
  ['MCAXS701-004', '終了日が開始日よりも前です',],
  ['MCAXS701-005', '終了日が開始日よりも前です',],
  ['MCAXS701-006', '終了日が開始日よりも前です',],
  ['MCAXS701-007', '一致する対象が1件もありませんので、MCB側「提出物内容一覧」画面にて設定ください。',],
  // MCBリプレース MCAXS701 END

  // #MCB1.5次開発 #81606 START
  ['MCBHS141-001', '提出物内容一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。',],
  // #MCB1.5次開発 #81606 END
  ['MCBHS141-002', '一致する対象が1件もありませんので、MCB側「提出物内容一覧」画面にて設定ください。',],
  ['MCBHS141-006', '提出締切日はFromかToのどちらか入力必須。',],
  ['MCBHS141-007', '最終提出日はFromかToのどちらか入力必須。',],

  ['MCBHS161-001', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\nご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。\n',],

  // MCBリプレース MCBHS211 START
  ['MCBHS211-006', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\nご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。\n',],
  // MCBリプレース MCBHS211 END

  // MCBリプレース MCAZS220 START
  ['MCAZS220-001', '指定されたページは存在しません。'],
  ['MCAZS220-002', 'こちらのメッセージは変更されました。画面を更新してもう一度ご確認ください。'],
  ['MCAZS220-003', '{0}は使用できない項目のため置換できません。\n'],
  ['MCAZS220-004', '{0}は値が取得できないため置換できません。\n'],
  ['MCAZS220-005', '{0}は値が取得でないため、該当の応募者宛には空白に置換されます。\n'],
  ['MCAZS220-006', '件名にご利用いただけない文字が含まれています。\n'],
  ['MCAZS220-007', '本文にご利用いただけない文字が含まれています。\n'],
  ['MCAZS220-008', '下書きに保存してもよろしいですか？'],
  ['MCAZS220-009', '下書きに保存しました。'],
  ['MCAZS220-010', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx'],
  ['MCAZS220-011', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAZS220-012', '添付ファイルの登録上限数は5件です。'],
  ['MCAZS220-013', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  ['MCAZS220-014', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCAZS220-015', '添付ファイルの登録上限数は5件です。'],
  ['MCAZS220-016', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  ['MCAZS220-017', '同じ名称の添付ファイルが複数選択されています。'],
  ['MCAZS220-018', '件名にご利用いただけない文字が含まれています。'],
  ['MCAZS220-019', '本文にご利用いただけない文字が含まれています。'],
  ['MCAZS220-020', 'URLが入力されていません。'],
  ['MCAZS220-021', '件名または本文にテンプレートで利用される文字列「●●」が含まれています。'],
  // #MCB1.5次開発 #81606 START
  ['MCAZS220-022', '指定された日時がMy CareerBoxのメンテナンス日時の範囲内です。\n（{0}）'],
  // #MCB1.5次開発 #81606 END
  ['MCAZS220-023', '送信予約日が未入力です。'],
  ['MCAZS220-024', '送信予約日時に過去は指定できません。'],
  ['MCAZS220-025', 'ご指定の予約日時は応募経路が契約期間外となるため、送信予約できません。予約日時を変更するか、送信先をご確認ください。'],
  ['MCAZS220-026', 'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。'],
  ['MCAZS220-027', 'ご指定の予約日時はサービス期間が終了になる応募経路の応募者が送信先に含まれているため、送信予約できません。予約日時を変更するか、送信先をご確認ください。'],
  ['MCAZS220-028', '送信予約日時については本日より30日後まで設定できます。設定可能期間内に変更をお願いします。'],
  ['MCAZS220-029', '送信予約日時までの残り時間が15分を切ったため予約できません。送信予約日時を設定しなおしてください。'],
  // #MCB1.5次開発 #72395 START
  ['MCAZS220-030', '返信先メールアドレスが未入力のため、送信できません。\n返信先メールアドレスをご確認ください。'],
  // #MCB1.5次開発 #72395 END
  ['MCAZS220-031', '送信可能な対象者が存在しません。\n送信対象をご確認ください。'],
  ['MCAZS220-032', '閲覧期限を過ぎている応募者が含まれるため送信できません。'],
  ['MCAZS220-033', '閲覧期限を過ぎている応募者が含まれるため送信予約できません。'],
  ['MCAZS220-034', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
  ['MCAZS220-035', 'メッセージの送信権限がありません。'],
  ['MCAZS220-039', '同じ名称の添付ファイルが複数選択されています。'],
  ['MCAZS220-040', 'パスワード付きzipファイルは添付できません。'],
  ['MCAZS220-041', 'パスワード付きzipファイルは添付できません。'],
  ['MCAZS220-042', '権限がないため、操作を実行できません。'],
  ['MCAZS220-043', '送信可能なメールアドレスがありません。'],
  // #MCB1.5次開発 #81606 START
  ['MCAZS220-044', 'メッセージ登録実行で問題が発生しました。\nお手数ですが、採用状況レポート画面下部に記載の弊社サポートデスクまで問い合わせいただくようお願いします。\n\n※メッセージ登録実行エラーにより、問題が発生した可能性があります。'],
  // #MCB1.5次開発 #81606 END
  ['MCAZS220-045', '選択したテンプレート種別は、「My CareerBox」ではないため、一部利用できない入力項目が削除されます。'],
  // #MCB1.5次開発 #8997 START
  ['MCAZS220-046', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCAZS220-047', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // #MCB1.5次開発 #8997 END
  // MCBリプレース MCAZS220 END

  // MCBリプレース MCAZS230 START
  ['MCAZS230-001', '件名または本文にテンプレートで利用される文字列「●●」が含まれています。\n',],
  ['MCAZS230-002', '{0}は使用できない項目のため置換できません。\n',],
  ['MCAZS230-003', '{0}は値が取得できないため置換できません。\n',],
  ['MCAZS230-004', '{0}は値が取得でないため、該当の応募者宛には空白に置換されます。\n',],
  ['MCAZS230-005', '\nメッセージの内容をよくご確認の上、送信してください。',],
  ['MCAZS230-007', '送信可能なメールアドレスがありません。',],
  ['MCAZS230-008', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx',],
  ['MCAZS230-009', '1ファイルあたり10MBを超えるファイルが添付されています。',],
  ['MCAZS230-010', '添付ファイルの登録上限数は5件です。',],
  ['MCAZS230-011', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。',],
  ['MCAZS230-012', '同じ名称の添付ファイルが複数選択されています。',],
  ['MCAZS230-013', '件名または本文にテンプレートで利用される文字列「●●」が含まれています。',],
  ['MCAZS230-014', '件名にご利用いただけない文字が含まれています。',],
  ['MCAZS230-015', '本文にご利用いただけない文字が含まれています。',],
  ['MCAZS230-016', 'URLが入力されていません。',],
  // #MCB1.5次開発 #81606 START
  ['MCAZS230-017', '指定された日時がMy CareerBoxのメンテナンス日時の範囲内です。\n（{0}）',],
  // #MCB1.5次開発 #81606 END
  ['MCAZS230-018', '送信予約日が未入力です。',],
  ['MCAZS230-019', '送信予約日時に過去は指定できません。',],
  ['MCAZS230-020', 'ご指定の予約日時は応募経路の契約期間外となるため、送信予約できません。予約日時を変更するか、送信先をご確認ください。',],
  ['MCAZS230-021', 'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。',],
  ['MCAZS230-022', 'ご指定の予約日時はサービス期間が終了になる応募経路の応募者が送信先に含まれているため、送信予約できません。予約日時を変更するか、送信先をご確認ください。',],
  ['MCAZS230-023', '送信予約日時については本日より30日後まで設定できます。設定可能期間内に変更をお願いします。',],
  ['MCAZS230-024', '送信予約日時までの残り時間が15分を切ったため予約できません。送信予約日時を設定しなおしてください。',],
  // #MCB1.5次開発 #72395 START
  ['MCAZS230-025', '返信先メールアドレスが未入力のため、送信できません。\n返信先メールアドレスをご確認ください。',],
  // #MCB1.5次開発 #72395 END
  ['MCAZS230-026', 'パスワード付きzipファイルは添付できません。',],
  // #MCB1.5次開発 #81606 START
  ['MCAZS230-027', 'メッセージ登録実行で問題が発生しました。\nお手数ですが、採用状況レポート画面下部に記載の弊社サポートデスクまで問い合わせいただくようお願いします。\n\n※メッセージ登録実行エラーにより、問題が発生した可能性があります。',],
  // #MCB1.5次開発 #81606 END
  // #MCB1.5次開発 #8997 START
  ['MCAZS230-028', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。',],
  // #MCB1.5次開発 #8997 END
  // MCBリプレース MCAZS230 END

  // MCBリプレース MCBHS010 START
  ['MCBHS010-002', 'こちらの提出リクエストは変更されました。画面を更新してもう一度ご確認ください。'],
  ['MCBHS010-003', 'メッセージの送信権限がありません。'],
  // #MCB1.5次開発 8777 START
  ['MCBHS010-005', '提出物内容一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBHS010-006', 'サンクスメールテンプレート一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBHS010-007', 'お問い合わせ先一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBHS010-008', 'My CareerBoxに関する個人情報の取り扱い一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  // #MCB1.5次開発 8777 END
  ['MCBHS010-009', '下書きに保存してもよろしいですか？'],
  ['MCBHS010-010', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx'],
  ['MCBHS010-011', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCBHS010-012', '添付ファイルの登録上限数は5件です。'],
  ['MCBHS010-013', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  ['MCBHS010-014', '同じ名称の添付ファイルが複数選択されています。'],
  ['MCBHS010-015', 'パスワード付きzipファイルは添付できません。'],
  // #MCB1.5次開発 8777 START
  ['MCBHS010-016', '提出リクエスト取消実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  // #MCB1.5次開発 8777 END
  ['MCBHS010-017', '提出リクエスト仮登録API実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBHS010-018', '下書きに保存しました。'],
  ['MCBHS010-019', '{0}は使用できない項目のため置換できません。\n'],
  ['MCBHS010-020', '{0}は値が取得できないため置換できません。\n'],
  ['MCBHS010-021', '{0}は値が取得できないため、該当の応募者宛には空白に置換されます。\n'],
  ['MCBHS010-022', '件名にご利用いただけない文字が含まれています。\n'],
  ['MCBHS010-023', '本文にご利用いただけない文字が含まれています。\n'],
  ['MCBHS010-024', '{0}は使用できない項目のため置換できません。\n'],
  ['MCBHS010-025', '{0}は値が取得できないため置換できません。\n'],
  ['MCBHS010-026', '{0}は値が取得できないため、該当の応募者宛には空白に置換されます。\n'],
  ['MCBHS010-027', 'サンクスメッセージの件名にご利用いただけない文字が含まれています。\n'],
  ['MCBHS010-028', 'サンクスメッセージの本文にご利用いただけない文字が含まれています。\n'],
  ['MCBHS010-029', '提出締切の日時に過去は指定できません。'],
  ['MCBHS010-030', 'ご指定の提出締切日時に送信予約日時より過去は指定できません。'],
  ['MCBHS010-033', '随時受付終了の日時に過去は指定できません。'],
  ['MCBHS010-034', 'ご指定の随時受付終了日時に送信予約日時より過去は指定できません。'],
  // #MCB1.5次開発 8777 START
  ['MCBHS010-036', '指定された提出締切日時がMy CareerBoxのメンテナンス日時の範囲内です。\n（{0}）'],
  // #MCB1.5次開発 8777 END
  // #MCB1.5次開発 8777 START
  ['MCBHS010-037', '随時受付終了の場合、再提出の受付設定はできません。'],
  // #MCB1.5次開発 8777 END
  ['MCBHS010-038', '件名にご利用いただけない文字が含まれています。'],
  ['MCBHS010-039', '本文にご利用いただけない文字が含まれています。'],
  ['MCBHS010-040', '件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。'],
  ['MCBHS010-041', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx'],
  ['MCBHS010-042', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCBHS010-043', '添付ファイルの登録上限数は5件です。'],
  ['MCBHS010-044', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  ['MCBHS010-045', '同じ名称の添付ファイルが複数選択されています。'],
  ['MCBHS010-046', 'パスワード付きzipファイルは添付できません。'],
  ['MCBHS010-047', 'URLが入力されていません。'],
  ['MCBHS010-048', 'サンクスメッセージの件名にご利用いただけない文字が含まれています。'],
  ['MCBHS010-049', 'サンクスメッセージの本文にご利用いただけない文字が含まれています。'],
  ['MCBHS010-050', 'サンクスメッセージの件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。'],
  // #MCB1.5次開発 8777 START
  ['MCBHS010-052', '指定された日時がMy CareerBoxのメンテナンス日時の範囲内です。\n（{0}）'],
  // #MCB1.5次開発 8777 END
  ['MCBHS010-053', '送信予約日が未入力です。'],
  ['MCBHS010-054', '送信予約日時に過去は指定できません。'],
  ['MCBHS010-060', '送信予約日時については本日より30日後まで設定できます。設定可能期間内に変更をお願いします。'],
  ['MCBHS010-061', '送信予約日時までの残り時間が15分を切ったため予約できません。送信予約日時を設定しなおしてください。'],
  ['MCBHS010-062', '閲覧期限を過ぎている応募者が含まれるため送信できません。'],
  ['MCBHS010-063', '閲覧期限を過ぎている応募者が含まれるため送信予約できません。'],
  // #MCB1.5次開発 9219 START
  // 維持保守 #9659 start
  ['MCBHS010-064', '送信可能な対象者が存在しません。送信対象に以下の応募者がいないかご確認ください。\n・送信対象者にメールアドレスが存在しない。\n・送信対象者のメールアドレスが無効となっている。\n・同一提出物内容で送信済みの応募者がいる。\n・同一My CareerIDを持つ応募者がいる。'],
  // 維持保守 #9659 end
  // #MCB1.5次開発 9219 END
  ['MCBHS010-065', '送信可能なメールアドレスがありません。'],
  // #MCB1.5次開発 8777 START
  ['MCBHS010-067', '提出リクエスト取消実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBHS010-068', '提出リクエスト実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  // #MCB1.5次開発 8777 END
  ['MCBHS010-069', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
  ['MCBHS010-070', '権限がないため、操作を実行できません。'],
  // #MCB1.5次開発 8777 START
  ['MCBHS010-071', '提出物内容一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBHS010-072', 'サンクスメールテンプレート一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBHS010-073', 'お問い合わせ先一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  ['MCBHS010-074', 'My CareerBoxに関する個人情報の取り扱い一覧取得実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  // #MCB1.5次開発 8777 END
  ['MCBHS010-075', 'ご指定の予約日時は応募経路が契約期間外となるため、送信予約できません。予約日時を変更するか、送信先をご確認ください。'],
  ['MCBHS010-076', 'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。'],
  ['MCBHS010-077', 'ご指定の予約日時はサービス期間が終了になる応募経路の応募者が送信先に含まれているため、送信予約できません。予約日時を変更するか、送信先をご確認ください。'],
  // #MCB1.5次開発 #8997 START
  ['MCBHS010-078', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCBHS010-079', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // #MCB1.5次開発 #8997 END
  // MCBリプレース #9200 START
  ['MCBHS010-082', '提出リクエスト実行で以下のエラーが発生しました。\n{0}'],
  // MCBリプレース #9200 END
  // MCBリプレース MCBHS010 END

  // MCBリプレース MCBHS171 START
  ['MCBHS171-001', '選択できるファイル提出が登録されていません。',],
  // MCBリプレース MCBHS171 END
  // MCBリプレース MCABS010 START
  ['MCABS010-001', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\nご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。',],
  // MCBリプレース MCABS010 END
  
// MCBHS180  START
['MCBHS180-000', '提出リクエスト管理画面で選択した対象学生が、応募管理画面に一覧で表示されます。\n尚、提出リクエスト管理画面で検索した条件は応募管理画面に引き継ぎがされない為、MCBの内容で絞り込みを行いたい場合は、応募管理画面にて検索を再度行ってください。'],
['MCBHS180-001', '権限がないため、操作を実行できません。'],
['MCBHS180-002', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\nご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。',],
// #MCB1.5次開発 8777 START
['MCBHS180-003', 'PDF出力対象者に、My CareerBoxの未提出（強制停止を含む）の応募者が含まれております。\n未提出者（強制停止を含む）のPDFは出力されませんのでご注意ください。'],
// #MCB1.5次開発 8777 END
['MCBHS180-004', '提出済の提出リクエストがないため、PDF出力を実行できません。'],
['MCBHS180-006', '一度に処理できるのは{0}件です。'],
// #MCB1.5次開発 #9260 START
['MCBHS180-007', '締切変更の一括操作が可能な対象は、同一の提出物内容に限ります。詳細な検索条件から「提出物内容」を絞って再度操作を行ってください。'],
// #MCB1.5次開発 #9260 END
// #MCB1.5次開発 8777 START
['MCBHS180-008', '「送信予約」「強制停止」「無効の提出リクエスト」が含まれているため、操作できません。'],
['MCBHS180-009', '「送信予約」「強制停止」「無効の提出リクエスト」が含まれているため、操作できません。'],
// #MCB1.5次開発 8777 END
['MCBHS180-010', 'My CareerBox退会の応募者が存在するため提出締切変更ができません。'],
['MCBHS180-011', 'My CareerBox退会の応募者が存在するため強制停止ができません。'],
['MCBHS180-012', 'My CareerBox退会の応募者が存在するためメッセージ送信ができません。'],
['MCBHS180-013', '提出済の提出リクエストがないため、CSV出力を実行できません。'],
['MCBHS180-014', '一度に処理できるのは10,000件です。'],
// #MCB1.5次開発 #9260 START
['MCBHS180-015', '強制停止の一括操作が可能な対象は、同一の提出物内容に限ります。詳細な検索条件から「提出物内容」を絞って再度操作を行ってください。'],
['MCBHS180-016', '一括操作が可能な対象は、同一の提出物内容に限ります。詳細な検索条件から「提出物内容」を絞って再度操作を行ってください。'],
// #MCB1.5次開発 #9260 END
 // MCBリプレース MCBJS040 START
  // MCBJS040
  ['MCBJS040-001', '指定されたページは存在しません。'],
  ['MCBJS040-002', '選択した提出リクエストテンプレートは存在しません。'],
  ['MCBJS040-003', '件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。'],
  ['MCBJS040-004', '{0}は使用できない項目のため置換できません。'],
  ['MCBJS040-005', '{0}は値が取得できないため置換できません。'],
  ['MCBJS040-006', 'サンクスメールの件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。'],
  ['MCBJS040-007', 'サンクスメールの件名または本文に{0}は使用できない項目のため置換できません。'],
  ['MCBJS040-008', 'サンクスメールの件名または本文に{0}は値が取得できないため置換できません。'],
  ['MCBJS040-009', '入力された提出リクエストテンプレート名は、既に登録されています。'],
  ['MCBJS040-010', '拡張子が非対応のファイルが含まれています。/n 対応している拡張子は以下の通りです。/n ai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx'],
  ['MCBJS040-011', '1ファイルあたり10MBを超えるファイルが添付されています。'],
  ['MCBJS040-012', '同じ名称の添付ファイルが複数選択されています。'],
  ['MCBJS040-013', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。'],
  ['MCBJS040-014', 'パスワード付きzipファイルは添付できません。'],
  ['MCBJS040-015', '件名にご利用いただけない文字が含まれています。'],
  ['MCBJS040-016', '本文にご利用いただけない文字が含まれています。'],
  ['MCBJS040-017', 'URLが入力されていません。'],
  ['MCBJS040-018', 'サンクスメールの件名にご利用いただけない文字が含まれています。'],
  ['MCBJS040-019', 'サンクスメールの本文にご利用いただけない文字が含まれています。'],
  ['MCBJS040-020', '登録上限数は500件です。'],
  ['MCBJS040-021', '提出リクエストテンプレートの登録が完了しました。'],
  ['MCBJS040-022', '提出リクエストテンプレートの登録が完了しました。入力された内容を確認する場合は、指定した対象年度に切り替えてご確認ください。'],
  ['MCBJS040-023', '他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください。'],
  ['MCBJS040-024', '提出リクエストテンプレートの更新が完了しました。'],

  ['MCBHS211-001', '応募者管理IDの桁数が間違っています。'],
  // #MCB1.5次開発 #81606 START
  ['MCBHS211-002', 'My CareerBox情報の締切日指定を選択の場合、締切日の範囲を指定してください。'],
  // #MCB1.5次開発 #81606 END
  ['MCBHS211-003', '応募者管理IDは10,000件以内で入力してください。'],
  ['MCBHS211-004', '氏名・氏名カナは100件以内で入力してください。'],

  // MCBリプレース MCAXS260 start
  ['MCAXS260-038', '提出済の提出リクエストがないため、PDF出力を実行できません。'],
  ['MCAXS260-039', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\n' +
    'ご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\n' +
    'メンテナンス時間についてはお知らせの掲示をご確認ください。'],
  ['MCAXS260-040', '一度に処理できるのは{0}件です。'],
  // #MCB1.5次開発 #81606 START
  ['MCAXS260-041',
    'PDF出力対象者に、My CareerBoxの未提出（強制停止を含む）の応募者が含まれております。\n' +
    '未提出者（強制停止を含む）のPDFは出力されませんのでご注意ください。'],
  // #MCB1.5次開発 #81606 END
  ['MCAXS260-042', '提出済の提出リクエストがないため、CSV出力を実行できません。'],
  ['MCAXS260-043', '他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください'],
  ['MCAXS260-044', '提出リクエスト関連のメッセージ送信に伴う処理が完了していない応募者データが含まれています。\n' +
    '恐れ入りますが、時間をおいて再度実施してください。'],
  ['MCAXS260-045',
    '送信中、送信予約、下書きの提出リクエスト関連メッセージがある応募者が含まれるため削除できません。\n' +
    '送信中のメッセージの場合、時間をおいて再度実行してください。\n' +
    '送信予約、下書きのメッセージは、ゴミ箱に移し再度実行してください。'],
  // MCBリプレース MCAXS260 end
  // MCBリプレース MCBLS020 START
  ['MCBLS020-001', '権限がないため、操作を実行できません。'],
  // MCBリプレース MCBLS020 END
  // MCBリプレース MCBLS030 START
  ['MCBLS030-001', '権限がないため、操作を実行できません。'],
  ['MCBLS030-002', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
  ['MCBLS030-004', '提出物PDFファイルが存在しません。処理をやりなおしてください。'],
  ['MCBLS030-005', 'メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\nご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。'],
  // MCBリプレース MCBLS030 START

  // MCBリプレース MCBLS010 start
  ['MCBLS010-001', '権限がないため、操作を実行できません。'],
  ['MCBLS010-002', '条件に一致する検索結果がありませんでした。'],
  ['MCBLS010-003', 'PDFダウンロード情報を削除します。よろしいですか？'],
  ['MCBLS010-004', '該当のPDFダウンロード情報が存在しません。\n' +
  '再検索を行って最新情報を取得してください。'],
  ['MCBLS010-005', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
  ['MCBLS010-006', 'PDFダウンロード情報を削除しました。'],
  ['MCBLS010-108', 'ダウンロード失敗エラー。'],
  ['MCBLS010-109', '削除失敗エラー。'],
  // MCBリプレース MCBLS010 end
  // MCBリプレース MCBHS090 START
  ['MCBHS090-002', 'こちらの提出リクエストは変更されました。画面を更新してもう一度ご確認ください。'],
  ['MCBHS090-003', 'メッセージの送信権限がありません。'],
  ['MCBHS090-005', '{0}は使用できない項目のため置換できません。\n'],
  ['MCBHS090-006', '{0}は値が取得できないため置換できません。\n'],
  [
    'MCBHS090-007',
    '{0}は値が取得できないため、該当の応募者宛には空白に置換されます。\n',
  ],
  ['MCBHS090-008', '件名にご利用いただけない文字が含まれています。\n'],
  ['MCBHS090-009', '本文にご利用いただけない文字が含まれています。\n'],
  ['MCBHS090-010', '権限がないため、操作を実行できません。'],
  ['MCBHS090-011', '件名にご利用いただけない文字が含まれています。\n'],
  ['MCBHS090-012', '本文にご利用いただけない文字が含まれています。\n'],
  [
    'MCBHS090-013',
    '件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。',
  ],
  [
    'MCBHS090-014',
    // #MCB1.5次開発 #81606 START
    '指定された日時がMy CareerBoxのメンテナンス日時の範囲内です。\n' + '（{0}）',
    // #MCB1.5次開発 #81606 END
  ],
  [
    'MCBHS090-015',
    'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。',
  ],
  ['MCBHS090-016', '閲覧期限を過ぎている応募者が含まれるため送信できません。'],
  [
    'MCBHS090-017',
    '送信可能な対象者が存在しません。\n' + '送信対象をご確認ください。',
  ],
  ['MCBHS090-018', '送信可能なメールアドレスがありません。'],
  [
    'MCBHS090-019',
    '既に強制停止済のリクエストが存在するため強制停止ができません。',
  ],
  // #MCB1.5次開発 8777 START
  [
    'MCBHS090-021',
    '提出リクエストの強制停止実行で問題が発生しました。\nお手数ですが、採用状況レポート画面下部に記載の弊社サポートデスクまで問い合わせいただくようお願いします。\n\n※強制停止実行エラーにより、問題が発生した可能性があります。'],
  // #MCB1.5次開発 8777 END
  [
    'MCBHS090-022',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  ['MCBHS090-023', '下書きに保存してもよろしいですか？'],
  ['MCBHS090-024', '下書きに保存しました。'],
  // #MCB1.5次開発 #8997 START
  ['MCBHS090-025', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCBHS090-026', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // #MCB1.5次開発 #8997 END
  // MCBリプレース MCBHS090 END

  // MCBリプレース MCBHS190 start
  [
    'MCBHS190-002',
    // #MCB1.5次開発 #81606 START
    '応募者ごとのファイル名称「応募者管理IDのみ」または「応募者管理ID + 姓名」いずれかを選択してください。',
    // #MCB1.5次開発 #81606 END
  ],
  [
    'MCBHS190-003',
    '1回あたりにPDF出力できる上限は{0}件です。対象を{1}件以下に絞ってからPDF出力を実行してください。',
  ],
  [
    'MCBHS190-004',
    '権限がないため、操作を実行できません。',
  ],
  [
    'MCBHS190-005',
    '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。',
  ],
  // MCBリプレース #9222 START
  [
    'MCBHS190-999',
    'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？',
  ],
  // MCBリプレース #8974 START
  [
    'MCBHS190-006',
    '提出済のファイルがないため、PDFダウンロードできません。',
  ],
  // MCBリプレース #8974 END
  [
    'MCBHS190-007',
    // #MCB1.5次開発 #9236 START
    '出力タイトルに使用できない文字（\\,/,:,*,?,",<,>,|のいずれか）が使われています。',
    // #MCB1.5次開発 #9236 END
  ],
  // MCBリプレース #9222 END
  // MCBリプレース MCBHS190 end

  // MCBリプレース MCBHS020 START
  ['MCBHS020-001', '件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。\n',],
  ['MCBHS020-002', '{0}は使用できない項目のため置換できません。\n',],
  ['MCBHS020-003', '{0}は値が取得できないため置換できません。\n',],
  ['MCBHS020-004', '{0}は値が取得でないため、該当の応募者宛には空白に置換されます。\n',],
  ['MCBHS020-005', 'サンクスメッセージの件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。\n',],
  ['MCBHS020-006', 'サンクスメッセージの件名または本文に{0}は使用できない項目のため置換できません。\n',],
  ['MCBHS020-007', 'サンクスメッセージの件名または本文に{0}は値が取得できないため置換できません。\n',],
  ['MCBHS020-008', 'サンクスメッセージの件名または本文に{0}は値が取得でないため、該当の応募者宛には空白に置換されます。\n',],
  ['MCBHS020-009', '\nメッセージの内容をよくご確認の上、送信してください。',],
  ['MCBHS020-011', '送信可能なメールアドレスがありません。',],
  ['MCBHS020-012', '拡張子が非対応のファイルが含まれています。\n対応している拡張子は以下の通りです。\nai,art,doc,fla,gif,jpg,jpeg,lzh,mpeg,mpg,pdf,png,pps,ppt,psd,txt,xls,zip,docx,xlsx',],
  ['MCBHS020-013', '1ファイルあたり10MBを超えるファイルが添付されています。',],
  ['MCBHS020-014', '添付ファイルの登録上限数は5件です。',],
  //#MCBリプレース MCBHS020 #78712 START
  ['MCBHS020-015', 'ファイルサイズが 0Byte のファイル、または削除された添付ファイルが選択されています。',],
  //#MCBリプレース MCBHS020 #78712 END
  ['MCBHS020-016', '同じ名称の添付ファイルが複数選択されています。',],
  ['MCBHS020-017', '件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。',],
  ['MCBHS020-018', '件名にご利用いただけない文字が含まれています。',],
  ['MCBHS020-019', '本文にご利用いただけない文字が含まれています。',],
  ['MCBHS020-020', 'URLが入力されていません。',],
  // #MCB1.5次開発 8777 START
  ['MCBHS020-021', '指定された日時がMy CareerBoxのメンテナンス日時の範囲内です。\n（{0}）',],
  // #MCB1.5次開発 8777 END
  ['MCBHS020-022', '送信予約日が未入力です。',],
  ['MCBHS020-023', '送信予約日時に過去は指定できません。',],
  ['MCBHS020-024', 'ご指定の予約日時は応募経路の契約期間外となるため、送信予約できません。予約日時を変更するか、送信先をご確認ください。',],
  ['MCBHS020-025', 'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。',],
  ['MCBHS020-026', 'ご指定の予約日時はサービス期間が終了になる応募経路の応募者が送信先に含まれているため、送信予約できません。予約日時を変更するか、送信先をご確認ください。',],
  ['MCBHS020-027', '送信予約日時については本日より30日後まで設定できます。設定可能期間内に変更をお願いします。',],
  ['MCBHS020-028', '送信予約日時までの残り時間が15分を切ったため予約できません。送信予約日時を設定しなおしてください。',],
  ['MCBHS020-029', '送信可能な対象者が存在しません。\n送信対象をご確認ください。',],
  ['MCBHS020-031', 'パスワード付きzipファイルは添付できません。',],
  ['MCBHS020-032', '提出締切の日時に過去は指定できません。',],
  ['MCBHS020-033', 'ご指定の提出締切日時に送信予約日時より過去は指定できません。',],
  ['MCBHS020-034', '随時受付終了の日時に過去は指定できません。',],
  ['MCBHS020-035', 'ご指定の随時受付終了日時に送信予約日時より過去は指定できません。',],
  // #MCB1.5次開発 8777 START
  ['MCBHS020-036', '指定された提出締切日時がMy CareerBoxのメンテナンス日時の範囲内です。\n（{0}）',],
  // #MCB1.5次開発 8777 START
  ['MCBHS020-037', '随時受付終了の場合、再提出の受付設定はできません。',],
  // #MCB1.5次開発 8777 END
  ['MCBHS020-038', '提出リクエスト取消実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。',],
  // MCBリプレース MCBHS020 #8449 START
  ['MCBHS020-039', '提出リクエスト実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。\n再操作後も本エラーが表示される場合は、お手数ですが提出リクエスト入力画面からやり直してください。',],
  // #MCB1.5次開発 8777 END
  ['MCBHS020-040', '提出リクエストで問題が発生しました。\nお手数ですが、採用状況レポート画面下部に記載の弊社サポートデスクまで問い合わせいただくようお願いします。\n\n※リクエストエラーにより、問題が発生した可能性があります。',],
  // MCBリプレース MCBHS020 #8449 END
  // #MCB1.5次開発 9126 START
  ['MCBHS020-042', '同一My CareerIDを持つ応募者が含まれるため、提出リクエストできません。\nお手数ですが提出リクエスト入力画面からやり直してください。',],
  // #MCB1.5次開発 9126 END
  // #MCB1.5次開発 #8997 START
  ['MCBHS020-041', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。',],
  // #MCB1.5次開発 #8997 END
  // MCBリプレース #9200 START
  ['MCBHS020-043', '提出リクエスト実行で以下のエラーが発生しました。\n{0}'],
  // MCBリプレース #9200 END
  // MCBリプレース MCBHS020 END
  // MCBリプレース MCBHS100 START
  ['MCBHS100-001', '件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。\n',],
  ['MCBHS100-002', '{0}は使用できない項目のため置換できません。\n',],
  ['MCBHS100-003', '{0}は値が取得できないため置換できません。\n',],
  ['MCBHS100-004', '{0}は値が取得でないため、該当の応募者宛には空白に置換されます。\n',],
  ['MCBHS100-005', '\nメッセージの内容をよくご確認の上、送信してください。',],
  ['MCBHS100-006', '送信可能な対象者が存在しません。\n送信対象をご確認ください。',],
  ['MCBHS100-007', '送信可能なメールアドレスがありません。',],
  ['MCBHS100-008', '件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。',],
  ['MCBHS100-009', '件名にご利用いただけない文字が含まれています。',],
  ['MCBHS100-010', '本文にご利用いただけない文字が含まれています。',],
  // #MCB1.5次開発 #81606 START
  ['MCBHS100-011', '指定された日時がMy CareerBoxのメンテナンス日時の範囲内です。\n' + '（{0}）',],
  // #MCB1.5次開発 #81606 END
  ['MCBHS100-012', 'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。',],
  ['MCBHS100-013', '閲覧期限を過ぎている応募者が含まれるため送信できません。',],
  ['MCBHS100-014', '他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください。',],
  // #MCB1.5次開発 #81606 START
  // #MCB1.5次開発 #8777 START
  ['MCBHS100-015', '提出リクエストの強制停止実行で問題が発生しました。\nお手数ですが、採用状況レポート画面下部に記載の弊社サポートデスクまで問い合わせいただくようお願いします。\n\n※強制停止実行エラーにより、問題が発生した可能性があります。'],
  // #MCB1.5次開発 #8777 END
  // #MCB1.5次開発 #81606 END
  // #MCB1.5次開発 #8997 START
  ['MCBHS100-016', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。',],
  // #MCB1.5次開発 #8997 END
  // MCBリプレース MCBHS100 END
  // MCBリプレース MCBHS060 START
  ['MCBHS060-002', 'こちらの提出リクエストは変更されました。画面を更新してもう一度ご確認ください。'],
  ['MCBHS060-003', 'メッセージの送信権限がありません。'],
  ['MCBHS060-005', '{0}は使用できない項目のため置換できません。\n'],
  ['MCBHS060-006', '{0}は値が取得できないため置換できません。\n'],
  ['MCBHS060-007', '{0}は値が取得できないため、該当の応募者宛には空白に置換されます。\n'],
  ['MCBHS060-008', '件名にご利用いただけない文字が含まれています。\n'],
  ['MCBHS060-009', '本文にご利用いただけない文字が含まれています。\n'],
  ['MCBHS060-010', '提出締切の日時に過去は指定できません。'],
  ['MCBHS060-011', '随時受付終了の日時に過去は指定できません。'],
  // #MCB1.5次開発 #81606 START
  ['MCBHS060-012', '指定された提出締切日時がMy CareerBoxのメンテナンス日時の範囲内です。\n（{0}）'],
  // #MCB1.5次開発 #81606 END
  // #MCB1.5次開発 8777 START
  ['MCBHS060-013', '随時受付終了の場合、再提出の受付設定はできません。'],
  // #MCB1.5次開発 8777 END
  ['MCBHS060-014', '件名にご利用いただけない文字が含まれています。'],
  ['MCBHS060-015', '本文にご利用いただけない文字が含まれています。'],
  ['MCBHS060-016', '件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。\n'],
  ['MCBHS060-017', 'サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。'],
  ['MCBHS060-018', '閲覧期限を過ぎている応募者が含まれるため送信できません。'],
  ['MCBHS060-019', '送信可能な対象者が存在しません。\n送信対象をご確認ください。'],
  ['MCBHS060-020', '送信可能なメールアドレスがありません。'],
  ['MCBHS060-021', '権限がないため、操作を実行できません。'],
  // #MCB1.5次開発 #81606 START
  // #MCB1.5次開発 8777 START
  ['MCBHS060-022', '提出リクエストの締切変更実行で問題が発生しました。\nお手数ですが、採用状況レポート画面下部に記載の弊社サポートデスクまで問い合わせいただくようお願いします。\n\n※締切変更実行エラーにより、問題が発生した可能性があります。'],
  // #MCB1.5次開発 8777 END
  // #MCB1.5次開発 #81606 END
  ['MCBHS060-023', 'キャンセルすると入力した内容が保存されない可能性があります。よろしいですか？'],
  ['MCBHS060-024', '下書きに保存してもよろしいですか？'],
  ['MCBHS060-025', '下書きに保存しました。'],
  // #MCB1.5次開発 #81606 START
  ['MCBHS060-026', '指定された日時がMy CareerBoxのメンテナンス日時の範囲内です。\n（{0}）'],
  // #MCB1.5次開発 #81606 END
  // #MCB1.5次開発 #8997 START
  ['MCBHS060-027', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCBHS060-028', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // #MCB1.5次開発 #8997 END
  // MCBリプレース MCBHS060 END
  // MCBリプレース MCBHS070 START
  ['MCBHS070-001','件名または本文に提出リクエストテンプレートで利用される文字列「●●」が含まれています。\n'],
  ['MCBHS070-002','{0}は使用できない項目のため置換できません。\n'],
  ['MCBHS070-003','{0}は値が取得できないため置換できません。\n'],
  ['MCBHS070-004','{0}は値が取得でないため、該当の応募者宛には空白に置換されます。\n'],
  ['MCBHS070-005','\nメッセージの内容をよくご確認の上、送信してください。'],
  ['MCBHS070-006','送信可能な対象者が存在しません。\n送信対象をご確認ください。'],
  ['MCBHS070-007','送信可能なメールアドレスがありません。'],
  ['MCBHS070-008','件名または本文にテンプレートで利用される文字列「●●」が含まれています。'],
  ['MCBHS070-009','件名にご利用いただけない文字が含まれています。'],
  ['MCBHS070-010','本文にご利用いただけない文字が含まれています。'],
  ['MCBHS070-011','サービス期間が終了となった応募経路の応募者が送信先に含まれているため、送信できません。'],
  ['MCBHS070-012','提出締切の日時に過去は指定できません。'],
  ['MCBHS070-013','随時受付終了の日時に過去は指定できません。'],
  // #MCB1.5次開発 #81606 START
  ['MCBHS070-014','指定された提出締切日時がMy CareerBoxのメンテナンス日時の範囲内です。\n' + '（{0}）'],
  // #MCB1.5次開発 #81606 END
  // #MCB1.5次開発 #8777 START
  ['MCBHS070-015','随時受付終了の場合、再提出の受付設定はできません。'],
  // #MCB1.5次開発 #81606 START
  ['MCBHS070-016','提出リクエストの締切変更実行で問題が発生しました。\nお手数ですが、採用状況レポート画面下部に記載の弊社サポートデスクまで問い合わせいただくようお願いします。\n\n※締切変更実行エラーにより、問題が発生した可能性があります。'],
  // #MCB1.5次開発 #8777 END
  ['MCBHS070-017','指定された日時がMy CareerBoxのメンテナンス日時の範囲内です。\n' + '（{0}）'],
  // #MCB1.5次開発 #81606 END
  // #MCB1.5次開発 #8997 START
  ['MCBHS070-018','閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // #MCB1.5次開発 #8997 END
  // MCBリプレース MCBHS070 END
  // MCBHS030 START
  ['MCBHS030-001', '提出リクエストテンプレート名を入力してください。'],
  ['MCBHS030-002', '新規提出リクエストテンプレートとして保存してもよろしいですか？'],
  ['MCBHS030-003', '指定された提出リクエストテンプレート名は既に登録されています。'],
  ['MCBHS030-004', '登録上限数は500件です。'],
  ['MCBHS030-005', '保存先の提出リクエストテンプレートを選択してください。'],
  ['MCBHS030-006', '提出リクエストテンプレートを上書き保存してもよろしいですか？'],
  ['MCBHS030-007', '上書き保存対象の提出リクエストテンプレートが存在しないため、上書き保存できません。'],
  ['MCBHS030-008', '他のユーザにより変更された可能性がありますので、一覧画面から再度検索してやり直してください'],
  ['MCBHS030-009', '提出リクエストテンプレートを新規保存しました。'],
  ['MCBHS030-010', '提出リクエストテンプレートを上書き保存しました。'],
  //  MCBHS030 END
  // MCBリプレース MCAZS170 START
  ['MCAZS170-002', '条件に一致する検索結果がありませんでした。'],
  ['MCAZS170-003', '選択されたメッセージをゴミ箱に移動します。よろしいですか？'],
  ['MCAZS170-004', '送信中のメッセージが選択されているためゴミ箱に移動できません。'],
  ['MCAZS170-005', 'メッセージ送信に伴う処理が完了していないメッセージが含まれています。\n恐れ入りますが、時間をおいて再度実施してください。'],
  ['MCAZS170-006', 'ゴミ箱に移動しました。'],
  ['MCAZS170-007', 'メッセージ送信に失敗した応募者'],
  ['MCAZS170-008', '一括メッセージ送信対象の応募者'],
  ['MCAZS170-009', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // MCBリプレース MCAZS170 END
  // MCBリプレース MCAZS180 START
  ['MCAZS180-002','条件に一致する検索結果がありませんでした。'],
  ['MCAZS180-003','メッセージの送信をキャンセルしてもよろしいですか？\nキャンセルしたメッセージは下書きに移動します。'],
  ['MCAZS180-004','送信予約ではないメッセージが含まれるため、送信をキャンセルできませんでした。'],
  ['MCAZS180-005','メッセージを送信してもよろしいですか？'],
  ['MCAZS180-006','送信予約ではないメッセージが含まれるため、すぐに送信できませんでした。'],
  ['MCAZS180-007','下書きに移動しました。'],
  ['MCAZS180-008','送信しました。'],
  ['MCAZS180-009','メッセージ送信に失敗した応募者'],
  ['MCAZS180-010','一括メッセージ送信対象の応募者'],
  ['MCAZS180-011','閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCAZS180-012','閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCAZS180-015','メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\nご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。',],
  ['MCAZS180-013','閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // #MCB1.5次開発 #81606 START
  ['MCAZS180-014','提出リクエスト取消実行で問題が発生しました。\nしばらく経ってから、再度操作を行ってください。'],
  // #MCB1.5次開発 #81606 END
  // #MCB1.5次開発 #8986 START
  ['MCAZS180-016','送信日時がMy CareerBoxのメンテナンス日時の範囲内です。\n{0}'],
  // #MCB1.5次開発 #8986 END
  // MCBリプレース MCAZS180 END
  // MCBリプレース MCAZS190 START
  ['MCAZS190-002','条件に一致する検索結果がありませんでした。'],
  ['MCAZS190-003','選択されたメッセージをゴミ箱に移動します。よろしいですか？'],
  ['MCAZS190-004','ゴミ箱に移動しました。'],
  ['MCAZS190-005','一括メッセージ送信対象の応募者',],
  ['MCAZS190-006','閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCAZS190-007','閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCAZS190-008','メンテナンス中のため、My CareerBox関連機能はご利用いただけません。\nご不便をおかけいたしますが、サービス再開後に再度アクセスいただけますようお願い申し上げます。\nメンテナンス時間についてはお知らせの掲示をご確認ください。'],
  // MCBリプレース MCAZS190 END
  // MCBリプレース MCAZS200 START
  ['MCAZS200-002', '条件に一致する検索結果がありませんでした。'],
  ['MCAZS200-003','選択したメッセージをゴミ箱から元に戻してもよろしいですか？\n送信予約のメッセージは下書きに移動します。'],
  ['MCAZS200-004', '削除してもよろしいですか？\n削除したメッセージは元に戻せません。'],
  ['MCAZS200-005', 'ゴミ箱から戻しました。'],
  ['MCAZS200-006', 'メッセージを削除しました。'],
  ['MCAZS200-007', '一括メッセージ送信対象の応募者'],
  ['MCAZS200-008', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  ['MCAZS200-009', '閲覧権限のない応募者へのメッセージが含まれるため、操作できません。'],
  // MCBリプレース MCAZS200 END
  // MCBリプレース MCAZS210 START
  ['MCAZS210-001', 'こちらのファイルがシステム上から削除されているため、添付ファイルがダウンロードできませんでした。'],
  ['MCAZS210-002', '権限がないため、操作を実行できません。'],
  // MCBリプレース MCAZS210 END
  // MCBリプレース MCBHS050 START
  ['MCBHS050-003', 'こちらのファイルがシステム上から削除されているため、添付ファイルがダウンロードできませんでした。'],
  ['MCBHS050-004', '権限がないため、操作を実行できません。'],
  // MCBリプレース MCBHS050 END
  // #75311 25KH start
  ['MCAXS080_001', 'キャンセルしますか？'],
  ['MCAXS080_002', '選択した履歴を削除します。<br/>削除すると元に戻すことはできません。<br/>よろしいですか？'],
  ['MCAXS080_003', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
  ['MCAXS080_004', '選考ステップ履歴を更新しました。'],
  // #75311 25KH end

  // 転職March #75621 START
  ['MCAXS110-001', 'この応募者を推薦します。よろしいですか？'],
  // 転職March #95647 START
  ['MCAXS110-002', '更新対象に「推薦済み」のエントリーが含まれています。\n推薦済みのエントリーは下記選考ステップに変更することができません\n・スクリーニング\n・書類回収中'],
  // 転職March #95647 END
  ['MCAXS110-003', '選考ステップを更新しました。'],
  ['MCAXS110-004', '推薦を完了しました。'],
  ['MCAXS110-005', 'キャンセルすると入力した内容が保存されない可能性があります。<br/>よろしいですか？'],
  ['MCAZXS110-006', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
  ['MCAZXS110-007', '応募者データが他のアカウントを有する担当者様により変更されています。再度検索してください。'],
  ['MCAYS020-003', '{0}'],
  // 転職March #75621 END
])

// メッセージ取得
// 与えられたメッセージIDを元に該当するメッセージを取得する。
export const getMessage = (messageID: string, args?: string[]): string => {
  let message = messageMap.get(messageID) || ''
  if (!args) {
    return message
  }

  args.forEach((v, i): void => {
    message = message.replace(new RegExp(`\\{${i}\\}`), v)
  })
  return message
}

export const getDynamicMessage = (info: any): any => {
  const val = messageMap.get(info.messageID) || ''
  return val.replace('{0}', info.title)
}

export const getDynamicMessageId = (info: any): any => {
  return info.messageID
}

export const getMessageEx = (messageID: string, info: string): string => {
  const val = messageMap.get(messageID) || ''
  return val.replace('{0}', info)
}
