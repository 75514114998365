import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import momentTz from 'moment-timezone'
import * as apiConfig from 'pages/MCAZS080/apiConfig'
import * as viewConfig from 'pages/MCAZS080/viewConig'
import { MCAZS080MessageConfirmRequest } from 'types/MCAZS080/MCAZS080MessageConfirmRequest'
import { MCAZS080MessageDraftRequest } from 'types/MCAZS080/MCAZS080MessageDraftRequest'
import { MCAZS080MessageInitRequest } from 'types/MCAZS080/MCAZS080MessageInitRequest'
import { MCAZS080MessagePreviewRequest } from 'types/MCAZS080/MCAZS080MessagePreviewRequest'
import { MCAZS080MessageCsvDownloadRequest } from 'types/MCAZS080/MCAZS080MessageCsvDownloadRequest'
import { MCAZS080MessageNotAdoptedNotificationRequest } from 'types/MCAZS080/MCAZS080MessageNotAdoptedNotificationRequest'
import { getAge } from 'common/businessUtil'
import { dateTimeToString, dateToString, isEmpty, now } from 'common/generalUtil'
import { magiContants } from 'utils/contants'
momentTz.tz.setDefault('Asia/Tokyo')

const messageInitRequest: MCAZS080MessageInitRequest = {
  messageSendId: [],
  entryId: [],
  selectionManagementId: [],
  searchCriteria: '',
  action: '',
  interviewScheduleAdjustmentId: 0,
  interviewScheduleId: [],
  replyMessageSendId: 0,
  draftMessageSendId: 0,
  sysVersionNumber: 0,
  filterConditionTitle: [],
  filterConditionValue: [],
}

const notAdoptedNotificationRequest: MCAZS080MessageNotAdoptedNotificationRequest = {
  entryId: [],
  selectionManagementId: [],
  notAdoptedNotificationType: null,
  jobSeekerId: [],
  screenId: '',
  selectionManagementSysVersionNumber: null,
  progressManagementSysVersionNumber: null,
}

const screenDisplayItems: viewConfig.ScreenDisplayItems = {
  recruitmentManagementDivision: '1',
  title: viewConfig.title,
  interviewSchedule: viewConfig.interviewSchedule,
  messageInput: viewConfig.messageInput,
  sendTargetConfirmation: viewConfig.sendTargetConfirmation,
  sendTimeInput: viewConfig.sendTimeInput,
  notification: viewConfig.notification,
  recruitmentManagementFlag: viewConfig.recruitmentManagementFlag,
  seminarSchedule: viewConfig.seminarSchedule,
  seminar: viewConfig.seminar,
  signature: viewConfig.signature,
  hiddenItems: viewConfig.hiddenItems,
}

const registerValue: viewConfig.RegisterValue = viewConfig.registerValue

const initialState: {
  messageInitRequest: MCAZS080MessageInitRequest
  initResult: apiConfig.InitResult
  businessCheckResult: boolean
  screenDisplayItems: viewConfig.ScreenDisplayItems
  confirmRequest: MCAZS080MessageConfirmRequest
  draftRequest: MCAZS080MessageDraftRequest
  registerValue: viewConfig.RegisterValue
  hasCloseWindow: boolean
  hasCloseWindowByDraft: boolean
  runConfirm: boolean
  openConfirm: boolean
  open006ConfirmModal: boolean
  successfulCountMessage: number
  successfulCountEmail: number
  failedCount: number
  isInitialized: boolean
  isDraft: boolean
  alertMessageList: string[]
  previewDataList: apiConfig.PreviewResult[]
  subjectText: string
  isOnlyNonMember: boolean
  isOnlyAnonymous: boolean
  isNotAdoptedNotification: boolean
  notAdoptedNotificationRequest: MCAZS080MessageNotAdoptedNotificationRequest
  messageTemplateChanged: boolean
} = {
  messageInitRequest: messageInitRequest,
  initResult: apiConfig.initResult,
  businessCheckResult: true,
  screenDisplayItems: screenDisplayItems,
  confirmRequest: apiConfig.messageConfirmRequest,
  draftRequest: apiConfig.messageDraftRequest,
  registerValue: registerValue,
  hasCloseWindow: false,
  hasCloseWindowByDraft: false,
  runConfirm: false,
  openConfirm: false,
  open006ConfirmModal: false,
  successfulCountMessage: 0,
  successfulCountEmail: 0,
  failedCount: 0,
  isInitialized: false,
  isDraft: false,
  alertMessageList: [],
  previewDataList: [],
  subjectText: '',
  isOnlyNonMember: false,
  isOnlyAnonymous: false,
  isNotAdoptedNotification: false,
  notAdoptedNotificationRequest: notAdoptedNotificationRequest,
  messageTemplateChanged: false,
}

const messageSendSlice = createSlice({
  name: 'messageSend',
  initialState,
  reducers: {
    getInit(state, action: PayloadAction<MCAZS080MessageInitRequest>) {
        state.hasCloseWindow = true
        const initRequest = action.payload
        state.messageInitRequest = initRequest
        state.messageInitRequest.interviewScheduleAdjustmentId = Number(initRequest.interviewScheduleAdjustmentId)
        state.messageInitRequest.interviewScheduleId = initRequest.interviewScheduleId.map(item => Number(item))
        state.messageInitRequest.sysVersionNumber = Number(initRequest.sysVersionNumber)
        state.screenDisplayItems.hiddenItems.adjustmentInterviewScheduleId = initRequest.interviewScheduleId
        state.screenDisplayItems.interviewSchedule.action = initRequest.action
        state.screenDisplayItems.interviewSchedule.sysVersionNumber = initRequest.sysVersionNumber
      return state
    },
    setInit(state, action: PayloadAction<apiConfig.InitResult>) {
      state.hasCloseWindow = false
      state.initResult = action.payload
      state.screenDisplayItems.sendTargetConfirmation.sendToNonMember = action.payload.sendToNonMember
      if (action.payload.action) {
        state.messageInitRequest.interviewScheduleAdjustmentId = action.payload.interviewScheduleAdjustmentId
        state.messageInitRequest.interviewScheduleId = action.payload.interviewScheduleId
        state.screenDisplayItems.hiddenItems.adjustmentInterviewScheduleId = action.payload.interviewScheduleId
        state.screenDisplayItems.interviewSchedule.action = action.payload.action
      }
      if(state.screenDisplayItems.recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && state.notAdoptedNotificationRequest.notAdoptedNotificationType){
        state.initResult.notAdoptedNotificationType = state.notAdoptedNotificationRequest.notAdoptedNotificationType
        state.initResult.selectionManagementSysVersionNumber = state.notAdoptedNotificationRequest.selectionManagementSysVersionNumber
        state.initResult.progressManagementSysVersionNumber = state.notAdoptedNotificationRequest.progressManagementSysVersionNumber
      }
      return state
    },
    getNotAdoptedNotification(state, action: PayloadAction<MCAZS080MessageNotAdoptedNotificationRequest>) {
      state.notAdoptedNotificationRequest = action.payload
      state.messageInitRequest.entryId = action.payload.entryId
      state.messageInitRequest.selectionManagementId = action.payload.selectionManagementId
      return state
    },
    setCode(state, action: PayloadAction<number>) {
      switch (action.payload) {
        case 200:
          state.businessCheckResult = true
          break
        case 500:
          state.businessCheckResult = false
          break
      }
      return state
    },
    setRecruitmentManagementDivision(state, action: PayloadAction<string>) {
      const recruitmentManagementDivision = action.payload
      state.screenDisplayItems.recruitmentManagementDivision = recruitmentManagementDivision
      return state
    },
    setScreenDisplayItems(state) {
      const data: apiConfig.InitResult = state.initResult
      state.screenDisplayItems.title.selectedTemplateSettingId = data.draftMessageTemplateSettingId ? String(data.draftMessageTemplateSettingId) : ''
      state.screenDisplayItems.title.messageTemplate = data.messageTemplate
        ? data.messageTemplate
        : []
      state.screenDisplayItems.sendTargetConfirmation.destinationSelection =
        data.destinationDivision ? data.destinationDivision : magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
      let destinationInfoList: viewConfig.SendTarget[] = []
      let entryIdList: string[] = []
      let selectionManagementIdList: string[] = []
      let progressStatusSettingIdList: (string | null)[] = []
      let decisionDivisionList: string[] = []
      let manualRegistrationFlagList: string[] = []
      let senderMessageIdList: (number | null)[] = []
      if (data.destinationInfo) {
        data.destinationInfo.map(target => {
          const destinationInfo: viewConfig.SendTarget = viewConfig.sendTarget
          destinationInfo.entryId = target.entryId
          destinationInfo.selectionManagementId = target.selectionManagementId
          destinationInfo.progressStatusSettingId = String(target.progressStatusSettingId)
          destinationInfo.fullName = target.familyName + ' ' + target.name
          destinationInfo.mailAddress = target.mailAddress
          const age = getAge(dateToString(target.birthdate))
          destinationInfo.age = age ? age : 0
          destinationInfo.jobSeekerId = target.jobSeekerIdForDisplay
          const memberInfo = target.unsubscribedJobSeekerId ? magiContants.MEMBERS_TYPE_DEACTIVATED : target.memberType
          destinationInfo.mynaviMemberInfo = memberInfo
          destinationInfo.applicationType = target.entryType
          destinationInfo.entryType = target.entryType
          destinationInfo.viewStartTime = target.viewStartTime
          destinationInfo.viewEndTime = target.viewEndTime
          destinationInfo.applicantScreeningImmunityFlag = String(target.applicantScreeningImmunityFlag)
          destinationInfo.jobTitle = target.entryJobCategoryName
          destinationInfo.schoolName = target.schoolName
          destinationInfo.facultyName = target.facultyName
          destinationInfo.subjectName = target.subjectName
          const applicationDate: string | null = momentTz(target.entryReceptionTime).format('YYYY/MM/DD HH:mm')
          destinationInfo.applicationDate = applicationDate
            ? applicationDate
            : '-'
          destinationInfo.decisionDivision = target.decisionDivision
          destinationInfo.progress = target.progressName
          destinationInfo.progressType = target.progressType
          destinationInfo.selectionFlow = target.selectionFlowName
          destinationInfo.invisibleDestination = ''
          destinationInfo.deleteDestination = ''
          destinationInfo.entryId = target.entryId
          destinationInfo.notSend = false
          destinationInfo.selectionManagementId = target.selectionManagementId
          destinationInfo.selectionClassification = target.selectionClassification
          destinationInfo.selectionFlowSettingId = target.selectionFlowSettingId
          destinationInfo.manualRegistrationFlag = String(target.manualRegistrationFlag)
          destinationInfo.sentMessageCount = target.sentMessageCount
          destinationInfo.readableFlag = target.readableFlag
          destinationInfo.senderMessageId = target.senderMessageId

          const newDestinationInfo = JSON.parse(JSON.stringify(destinationInfo))
          destinationInfoList = [...destinationInfoList, newDestinationInfo]

          entryIdList = [...entryIdList, target.entryId]
          selectionManagementIdList = [
            ...selectionManagementIdList,
            target.selectionManagementId,
          ]
          progressStatusSettingIdList = [
            ...progressStatusSettingIdList,
            destinationInfo.progressStatusSettingId,
          ]
          decisionDivisionList = [
            ...decisionDivisionList,
            target.decisionDivision,
          ]
          manualRegistrationFlagList = [
            ...manualRegistrationFlagList,
            destinationInfo.manualRegistrationFlag,
          ]
          senderMessageIdList = [...senderMessageIdList, target.senderMessageId]
        })
      }
      state.screenDisplayItems.sendTargetConfirmation.sendTarget = destinationInfoList
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName = data.searchCriteriaName
      state.screenDisplayItems.sendTargetConfirmation.searchCriteria = data.searchCriteria
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaInfo.searchCriteriaName = data.searchCriteriaName
      state.screenDisplayItems.sendTargetConfirmation.searchCriteriaInfo.searchCriteria = data.searchCriteria

      state.screenDisplayItems.sendTargetConfirmation.mySearchCondition = data.mySearchCondition
      state.screenDisplayItems.sendTargetConfirmation.conversionMySearch = data.mySearch

      state.screenDisplayItems.sendTimeInput.transmission = data.sendTimeFlag ? data.sendTimeFlag : magiContants.SEND_TIME_FLAG_IMMEDIATE
      if (state.screenDisplayItems.sendTimeInput.transmission === magiContants.SEND_TIME_FLAG_IMMEDIATE ||
        data.sendPlanTime == null) {
        state.screenDisplayItems.sendTimeInput.sendPlanTime = null
      } else {
        const sendPlanTimeMoment = momentTz(data.sendPlanTime)
        const offsetTime = momentTz(sendPlanTimeMoment).add(sendPlanTimeMoment.utcOffset() + now().getTimezoneOffset(), 'minute')
        state.screenDisplayItems.sendTimeInput.sendPlanTime = offsetTime.toDate()
      }

      state.confirmRequest.destinationId = entryIdList
      state.confirmRequest.selectionManagementId = selectionManagementIdList
      state.confirmRequest.progressStatusSettingId = progressStatusSettingIdList
      state.confirmRequest.decisionDivision = decisionDivisionList
      state.confirmRequest.manualRegistrationFlag = manualRegistrationFlagList
      state.confirmRequest.senderMessageId = senderMessageIdList

      state.draftRequest.destinationId = entryIdList
      state.draftRequest.selectionManagementId = selectionManagementIdList
      state.draftRequest.progressStatusSettingId = progressStatusSettingIdList
      state.draftRequest.decisionDivision = decisionDivisionList
      state.draftRequest.manualRegistrationFlag = manualRegistrationFlagList
      state.draftRequest.senderMessageId = senderMessageIdList

      state.screenDisplayItems.messageInput.headingMessageInput =
        data.headingMessageInput
      state.screenDisplayItems.messageInput.headingMessageInputDescription =
        data.headingMessageInputDescription
      state.screenDisplayItems.messageInput.exampleLink = data.exampleLinkUrl
        ? data.exampleLinkUrl
        : ''
      state.screenDisplayItems.messageInput.replyAllow = data.replyPermitFlag ? data.replyPermitFlag : magiContants.REPLY_PERMIT_FLAG_DISALLOW
      if (state.screenDisplayItems.messageInput.replyAllow === magiContants.REPLY_PERMIT_FLAG_PERMIT){
        state.screenDisplayItems.messageInput.repliesNecessary = data.replyNecessaryFlag ? data.replyNecessaryFlag : magiContants.REPLY_NECESSARY_FLAG_DISALLOW
        if(state.screenDisplayItems.messageInput.repliesNecessary ===magiContants.REPLY_NECESSARY_FLAG_PERMIT && data.replyLimitTime) { 
          state.screenDisplayItems.messageInput.repliesLimit = dateToString(data.replyLimitTime)
        } else {
          state.screenDisplayItems.messageInput.repliesLimit = null
        }
      } else {
        state.screenDisplayItems.messageInput.repliesNecessary = magiContants.REPLY_NECESSARY_FLAG_DISALLOW
        state.screenDisplayItems.messageInput.repliesLimit = null
      }
      
      state.screenDisplayItems.messageInput.senderCompanyName = data.senderCompanyName
        ? data.senderCompanyName
        : ''
      state.screenDisplayItems.messageInput.senderMailAddress = data.senderMailAddress
        ? data.senderMailAddress
        : ''
      const replyFromSubject: string = data.replyFromSubject
      if (replyFromSubject) {
        state.screenDisplayItems.messageInput.subject =
          magiContants.REPLY_PREFIX_SUBJECT + replyFromSubject
      } else {
        if (isEmpty(state.screenDisplayItems.messageInput.subject)) {
          state.screenDisplayItems.messageInput.subject = data.subject
          ? data.subject
          : ''
        }
      }
      const replyFromBody: string = data.replyFromBody
      if (replyFromBody) {
        if(replyFromBody === '{@reply_body}'){
          state.screenDisplayItems.messageInput.body = replyFromBody
        }else{
          let replacedReplyFromBody = replyFromBody.replace(
            /\r?\n/g,
            '\n' + magiContants.REPLY_PREFIX_BODY
          )
          state.screenDisplayItems.messageInput.body = magiContants.REPLY_PREFIX_BODY + replacedReplyFromBody
        }
      } else {
        state.screenDisplayItems.messageInput.body = data.body ? data.body : ''
      }
      state.screenDisplayItems.messageInput.targetInput = 'subject'

      let attachmentDataList = data.attachment
      let attachmentList: viewConfig.Attachment[] = []
      if (attachmentDataList) {
        attachmentDataList.map(item => {
          let attachment: viewConfig.Attachment = {
            attachmentFileName: '',
            attachmentId: null,
            attachmentName: '',
          }
          attachment.attachmentId = item.fileManagementId
          attachment.attachmentName = item.attachmentName
          attachment.attachmentFileName = item.attachmentName
          attachmentList = [...attachmentList, attachment]
        })
      }
      if (attachmentList.length <= 0) {
        let attachment: viewConfig.Attachment = {
          attachmentFileName: '',
          attachmentId: null,
          attachmentName: '',
        }
        attachmentList = [...attachmentList, attachment]
      }
      state.screenDisplayItems.messageInput.attachment = attachmentList

      state.screenDisplayItems.messageInput.entryBox =
        data.mynaviAttachedOptionEntryBox
      let mynaviAttachedOptionEntryBoxData =
        state.screenDisplayItems.messageInput.entryBox
      let previousEntryBoxContractMediaName = ''
      if (mynaviAttachedOptionEntryBoxData) {
        mynaviAttachedOptionEntryBoxData.map(item => {
          let contractMediaName = item.contractMediaName
          if (contractMediaName === previousEntryBoxContractMediaName) {
            item.contractMediaName = ''
          }
          previousEntryBoxContractMediaName = contractMediaName
        })
      }
      const entryBoxIdData = data.checkedEntryBoxId
      let entryBoxIdList: string[] = []
      if (entryBoxIdData) {
        entryBoxIdData.map(
          entryBoxId => (entryBoxIdList = [...entryBoxIdList, `${entryBoxId}`])
        )
      }
      state.screenDisplayItems.messageInput.entryBoxId = entryBoxIdList

      state.screenDisplayItems.messageInput.seminar =
        data.mynaviAttachedOptionSeminar
      let mynaviAttachedOptionSeminarData =
        state.screenDisplayItems.messageInput.seminar
      let previousSeminarContractMediaName = ''
      if (mynaviAttachedOptionSeminarData) {
        mynaviAttachedOptionSeminarData.map(item => {
          let contractMediaName = item.contractMediaName
          if (contractMediaName === previousSeminarContractMediaName) {
            item.contractMediaName = ''
          }
          previousSeminarContractMediaName = contractMediaName
        })
      }
      const seminarIdData = data.checkedSeminarId
      let seminarIdList: string[] = []
      if (seminarIdData) {
        seminarIdData.map(
          seminarId => (seminarIdList = [...seminarIdList, `${seminarId}`])
        )
      }
      state.screenDisplayItems.messageInput.seminarId = seminarIdList

      let homePageData = data.homePage
      let homePageList: viewConfig.HomePage[] = []
      if (homePageData) {
        for (var i = 0; i < homePageData.length; i++) {
          let homePage: viewConfig.HomePage = {
            homePageTitle: '',
            homePageUrl: '',
          }
          homePage.homePageTitle = homePageData[i].homePageTitle
            ? homePageData[i].homePageTitle
            : ''
          homePage.homePageUrl = homePageData[i].homePageUrl
            ? homePageData[i].homePageUrl
            : ''
          homePageList = [...homePageList, homePage]
        }
      }
      if (homePageList.length <= 0) {
        let homePage: viewConfig.HomePage = {
          homePageTitle: '',
          homePageUrl: '',
        }
        homePageList = [...homePageList, homePage]
      }
      state.screenDisplayItems.messageInput.homePage = homePageList

      state.screenDisplayItems.messageInput.barcodeDisplay =
        data.barcodeDisplayFlag ? data.barcodeDisplayFlag : magiContants.BARCODE_DISPLAY_FLAG_NOT_SHOW

      state.screenDisplayItems.recruitmentManagementFlag =
        data.recruitmentManagementFlag
      state.screenDisplayItems.seminarSchedule = data.seminarSchedule
      state.screenDisplayItems.seminar = data.seminarScreen
      state.screenDisplayItems.signature = data.signature

      const draftMessageSendId = state.messageInitRequest.draftMessageSendId
      let notificationSelect = magiContants.NOTIFICATION_DESIRED
      let notificationMailAddress = data.companyAccountMailAdress
      if (draftMessageSendId) {
        if (!isEmpty(data.notificationMailAddress)) {
          notificationMailAddress = data.notificationMailAddress
        } else {
          notificationMailAddress = ''
          notificationSelect = magiContants.NOTIFICATION_NOT_DESIRED
        }
      }
      state.screenDisplayItems.notification.notificationSelect = notificationSelect
      state.screenDisplayItems.notification.notificationMailAddress = notificationMailAddress

      state.screenDisplayItems.hiddenItems.adjustmentInterviewScheduleAdjustmentId = data.adjustmentInterviewScheduleAdjustmentId
      state.screenDisplayItems.interviewSchedule.manipulationKubun = data.interviewScheduleAdjustmentAction && data.interviewScheduleAdjustmentAction !== '' ? data.interviewScheduleAdjustmentAction : '1'
      let interviewScheduleList: viewConfig.CandidateDate[] = []
      const interviewSchedules = state.initResult.interviewSchedule
      if (interviewScheduleList && interviewScheduleList.length <= 0) {
        for (var i = 0; i < 30; i++) {
          let interviewSchedule: viewConfig.CandidateDate = {
            interviewLocationId: '',
            interviewStartTime: '',
            interviewStaffName: '',
          }
          if (state.messageInitRequest.draftMessageSendId && interviewSchedules && interviewSchedules.length > i && (!state.initResult.action || (state.initResult.action && interviewSchedules[i].interviewScheduleDraftFlag === true))) {
            const startTime = interviewSchedules[i].interviewStartTime
            const startTimeValue = momentTz(startTime).format('YYYY/MM/DD HH:mm')
            interviewSchedule.interviewStartTime = startTimeValue ? startTimeValue : ''
            interviewSchedule.interviewLocationId = String(interviewSchedules[i].interviewLocationSettingId)
            interviewSchedule.interviewStaffName = interviewSchedules[i].interviewStaffName
          }
          interviewScheduleList = [...interviewScheduleList, interviewSchedule]
        }
      }
      state.screenDisplayItems.interviewSchedule.candidateDate = interviewScheduleList
      state.screenDisplayItems.interviewSchedule.interviewDetail = data.interviewDetail
      state.screenDisplayItems.interviewSchedule.requiredTimeId = String(data.interviewDurationId)

      let interviewDurationSelectList: viewConfig.InterviewDurationSelect[] = []
      if (data.interviewDurationSelect) {
        data.interviewDurationSelect.map(item => {
          let interviewDurationSelect: viewConfig.InterviewDurationSelect = {
            interviewDurationId: String(item.interviewDurationId),
            actualTime: String(item.actualTime),
            displayTime: item.displayTime,
          }
          interviewDurationSelectList = [
            ...interviewDurationSelectList,
            interviewDurationSelect,
          ]
        })
      }
      state.screenDisplayItems.interviewSchedule.interviewDurationSelect = interviewDurationSelectList
      let interviewLocationList: viewConfig.InterviewLocation[] = []
      if (data.interviewLocation) {
        data.interviewLocation.map(item => {
          const interviewLocation: viewConfig.InterviewLocation =
            viewConfig.interviewLocation
          interviewLocation.id = item.interviewLocationSettingId
          interviewLocation.name = item.interviewLocationName
          interviewLocation.address = item.interviewLocationAddress
          interviewLocation.mapUrl = item.mapUrl
          interviewLocation.type = String(item.interviewLocationType)
          interviewLocationList = [...interviewLocationList, JSON.parse(JSON.stringify(interviewLocation))]
        })
      }
      state.screenDisplayItems.interviewSchedule.interviewLocation = interviewLocationList
      state.confirmRequest.interviewDurationId = state.screenDisplayItems
        .interviewSchedule.interviewDurationSelect[0]
        ? state.screenDisplayItems.interviewSchedule.interviewDurationSelect[0]
            .interviewDurationId
        : '0'
      state.draftRequest.interviewDurationId = state.screenDisplayItems
        .interviewSchedule.interviewDurationSelect[0]
        ? state.screenDisplayItems.interviewSchedule.interviewDurationSelect[0]
            .interviewDurationId
        : '0'

      if (state.screenDisplayItems.hiddenItems.replyFromMessageSendId === '') {
        let replyFormerMessageId = data.replyFormerMessageId ? String(data.replyFormerMessageId) : ''
        state.screenDisplayItems.hiddenItems.replyFromMessageSendId = replyFormerMessageId
      }

      if(state.screenDisplayItems.recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && state.notAdoptedNotificationRequest.notAdoptedNotificationType){
        state.isNotAdoptedNotification = true
      }else{
        state.isInitialized = true
      }
      return state
    },
    getPreview(state, action: PayloadAction<{ formData: MCAZS080MessagePreviewRequest }>) {
      state.previewDataList = []
      return state
    },
    setPreview(state, action: PayloadAction<apiConfig.PreviewResult[]>) {
      state.previewDataList = action.payload
      return state
    },
    inputDestinationSelection(state, action: PayloadAction<{target: string, companyName: string, mailAddress: string, isInitialized: boolean}>) {
      const destinationSelection = action.payload.target
      const companyName = action.payload.companyName
      const mailAddress = action.payload.mailAddress
      const isInitialized = action.payload.isInitialized

      const recruitmentManagementDivision = state.screenDisplayItems.recruitmentManagementDivision
      const manipulationSelection = state.screenDisplayItems.interviewSchedule.manipulationSelection

      state.screenDisplayItems.sendTargetConfirmation.destinationSelection = destinationSelection
      state.screenDisplayItems.messageInput.previewSubject = ''
      state.screenDisplayItems.messageInput.previewBody = ''
      if (destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
        state.screenDisplayItems.interviewSchedule.manipulationSelection = magiContants.SELECTION_NUMBER_NOT_PRESENT
      }

      let shouldShowMessageInput: viewConfig.ShouldShowMessageInput = {
        senderCompanyName: false,
        senderMailAddress: false,
        replyAllow: false,
        repliesNecessary: false,
        repliesLimit: false,
        subject: true,
        body: true,
        attachment: false,
        entryBox: false,
        seminar: false,
        homePage: false,
        barcodeDisplay: false,
      }
      if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY)) {
        shouldShowMessageInput.senderCompanyName = true
        state.screenDisplayItems.messageInput.senderCompanyName = companyName
      } else {
        shouldShowMessageInput.senderCompanyName = false
      }
      if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED || destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
        shouldShowMessageInput.senderMailAddress = true
        state.screenDisplayItems.messageInput.senderMailAddress = mailAddress
      } else {
        shouldShowMessageInput.senderMailAddress = false
      }
      const interviewScheduleAdjustmentAction = state.screenDisplayItems.interviewSchedule.action
      if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY || destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED)) {
        shouldShowMessageInput.replyAllow = true
        shouldShowMessageInput.repliesNecessary = true
        shouldShowMessageInput.repliesLimit = true
        if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (manipulationSelection !== magiContants.SELECTION_NUMBER_NOT_PRESENT || interviewScheduleAdjustmentAction === magiContants.ACTION_FIX_INTERVIEW || interviewScheduleAdjustmentAction === magiContants.ACTION_CHANGE_POSSIBLE || interviewScheduleAdjustmentAction === magiContants.ACTION_WITHDRAW_INTERVIEW || interviewScheduleAdjustmentAction === magiContants.ACTION_CHANGE_INTERVIEW)) {
          state.screenDisplayItems.messageInput.replyAllow = magiContants.REPLY_PERMIT_FLAG_PERMIT
        }
      } else {
        shouldShowMessageInput.replyAllow = false
        shouldShowMessageInput.repliesNecessary = false
        shouldShowMessageInput.repliesLimit = false
        state.screenDisplayItems.messageInput.replyAllow = magiContants.REPLY_PERMIT_FLAG_DISALLOW
      }
      shouldShowMessageInput.subject = true
      shouldShowMessageInput.body = true
      if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
        shouldShowMessageInput.attachment = true
      } else if (!isInitialized) {
        const attachment: viewConfig.Attachment = {
          attachmentFileName: '',
          attachmentId: null,
          attachmentName: '',
        }
        state.screenDisplayItems.messageInput.attachment = [attachment]
      }
      if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1) {
        shouldShowMessageInput.entryBox = true
        shouldShowMessageInput.seminar = true
      }
      if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY)) {
        shouldShowMessageInput.homePage = true
      } else {
        const homePage: viewConfig.HomePage = {
          homePageUrl: '',
          homePageTitle: '',
        }
        state.screenDisplayItems.messageInput.homePage = [homePage]
      }
      if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
        shouldShowMessageInput.barcodeDisplay = true
      } else {
        shouldShowMessageInput.barcodeDisplay = false
        state.screenDisplayItems.messageInput.barcodeDisplay = magiContants.BARCODE_DISPLAY_FLAG_NOT_SHOW
      }
      state.screenDisplayItems.messageInput.shouldShowMessageInput = shouldShowMessageInput

      let shouldShowInterviewSchedule = false
      if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
        shouldShowInterviewSchedule = true
      }
      state.screenDisplayItems.interviewSchedule.shouldShowInterviewSchedule = shouldShowInterviewSchedule

      let shouldShowNotification = false
      if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && manipulationSelection === magiContants.SELECTION_NUMBER_NOT_PRESENT) {
        shouldShowNotification = true
      }
      state.screenDisplayItems.notification.shouldShowNotification = shouldShowNotification

      return state
    },
    inputSendToNonMember(state, action: PayloadAction<number>) {
      state.registerValue.sendToNonMember = action.payload
      state.screenDisplayItems.sendTargetConfirmation.sendToNonMember = action.payload
      return state
    },
    inputSenderCompanyName(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.senderCompanyName = replacedValue
      return state
    },
    inputSenderMailAddress(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.senderMailAddress = replacedValue
      return state
    },
    inputSubject(
      state,
      action: PayloadAction<{ target: string; value: string }>
    ) {
      let target = action.payload.target
      let value = action.payload.value
      let replacedValue = value.replace(/\n/g, '')
      state.screenDisplayItems.messageInput.targetInput = target
      state.screenDisplayItems.messageInput.subject = replacedValue
      return state
    },
    inputBody(state, action: PayloadAction<{ target: string; value: string }>) {
      let target = action.payload.target
      let value = action.payload.value
      state.screenDisplayItems.messageInput.targetInput = target
      state.screenDisplayItems.messageInput.body = value
      return state
    },
    changeTargetInput(state, action: PayloadAction<{ target: string }>) {
      let target = action.payload.target
      state.screenDisplayItems.messageInput.targetInput = target
      return state
    },
    inputAttachment(
      state,
      action: PayloadAction<{ index: number; fileName: string }>
    ) {
      const index = action.payload.index
      const fileName = action.payload.fileName
      state.screenDisplayItems.messageInput.attachment[index].attachmentFileName = fileName
      return state
    },
    deleteAttachment(state, action: PayloadAction<{ index: number }>) {
      let index: number = action.payload.index
      let attachment: viewConfig.Attachment[] =
        state.screenDisplayItems.messageInput.attachment
      attachment.splice(index, 1)
      state.screenDisplayItems.messageInput.attachment = attachment
      return state
    },
    addAttachment(state) {
      let attachment: viewConfig.Attachment[] =
        state.screenDisplayItems.messageInput.attachment
      let attachmentValue: viewConfig.Attachment = {
        attachmentFileName: '',
        attachmentId: null,
        attachmentName: '',
      }
      state.screenDisplayItems.messageInput.attachment = [
        ...attachment,
        attachmentValue,
      ]
      return state
    },
    inputHomePageTitle(
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) {
      let index = action.payload.index
      let value = action.payload.value
      state.screenDisplayItems.messageInput.homePage[
        index
      ].homePageTitle = value
      return state
    },
    inputHomePageUrl(
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) {
      let index = action.payload.index
      let value = action.payload.value
      state.screenDisplayItems.messageInput.homePage[index].homePageUrl = value
      return state
    },
    deleteHomePage(state, action: PayloadAction<{ index: number }>) {
      let index: number = action.payload.index
      let homePage: viewConfig.HomePage[] =
        state.screenDisplayItems.messageInput.homePage
      homePage.splice(index, 1)
      state.screenDisplayItems.messageInput.homePage = homePage
      return state
    },
    addHomePage(state) {
      let homePage: viewConfig.HomePage[] =
        state.screenDisplayItems.messageInput.homePage
      let homePageValue: viewConfig.HomePage = {
        homePageTitle: '',
        homePageUrl: '',
      }
      state.screenDisplayItems.messageInput.homePage = [
        ...homePage,
        homePageValue,
      ]
      return state
    },
    checkEntryBox(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let checkedEntryBoxIdList: string[] =
        state.screenDisplayItems.messageInput.entryBoxId
      const index = checkedEntryBoxIdList.findIndex(id => id === value)
      if (index === -1) {
        state.screenDisplayItems.messageInput.entryBoxId = [
          ...checkedEntryBoxIdList,
          value,
        ]
      } else {
        checkedEntryBoxIdList.splice(index, 1)
        state.screenDisplayItems.messageInput.entryBoxId = checkedEntryBoxIdList
      }
      return state
    },
    checkSeminar(state, action: PayloadAction<{ value: string }>) {
      let value = action.payload.value
      let checkedSeminarIdList: string[] =
        state.screenDisplayItems.messageInput.seminarId
      const index = checkedSeminarIdList.findIndex(id => id === value)
      if (index === -1) {
        state.screenDisplayItems.messageInput.seminarId = [
          ...checkedSeminarIdList,
          value,
        ]
      } else {
        checkedSeminarIdList.splice(index, 1)
        state.screenDisplayItems.messageInput.seminarId = checkedSeminarIdList
      }
      return state
    },
    changeReplyAllow(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.messageInput.replyAllow = value
      return state
    },
    changeReplyNecessaryFlag(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.messageInput.repliesNecessary = value
      return state
    },
    changeLimitTime(state, action: PayloadAction<{ value: string | null }>) {
      const value = action.payload.value
      state.screenDisplayItems.messageInput.repliesLimit = value
      return state
    },
    changeBarcode(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.messageInput.barcodeDisplay = value
      return state
    },
    changeTransmission(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.sendTimeInput.transmission = value
      return state
    },
    inputSendTimer(state, action: PayloadAction<{ value: Date | null }>) {
      const value = action.payload.value
      state.screenDisplayItems.sendTimeInput.sendPlanTime = value
      return state
    },
    inputNotificationMailAddress(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.notification.notificationMailAddress = value
      return state
    },
    inputNotificationSelect(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.notification.notificationSelect = value
      return state
    },
    inputInterviewDetail(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.interviewSchedule.interviewDetail = value
      return state
    },
    inputInterviewDuration(state, action: PayloadAction<{ value: string }>) {
      const value = action.payload.value
      state.screenDisplayItems.interviewSchedule.requiredTimeId = value
      state.draftRequest.interviewDurationId = value
      state.confirmRequest.interviewDurationId = value
      return state
    },
    inputInterviewDate(
      state,
      action: PayloadAction<{ index: number; value: Date | null }>
    ) {
      const index = action.payload.index
      const value = action.payload.value
      let interviewStartTime: any = ''
      if (value !== null) {
        interviewStartTime = dateTimeToString(value)
      }
      if (state.screenDisplayItems.interviewSchedule.candidateDate[index]) {
        state.screenDisplayItems.interviewSchedule.candidateDate[
          index
        ].interviewStartTime = interviewStartTime
      }
      return state
    },
    inputInterviewStaff(
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) {
      const index = action.payload.index
      const value = action.payload.value
      const candidateDate = state.screenDisplayItems.interviewSchedule.candidateDate
      if (candidateDate && candidateDate.length <= index) {
        state.screenDisplayItems.interviewSchedule.candidateDate[index] = {
          interviewStartTime: '',
          interviewLocationId: '',
          interviewStaffName: '',
        }
      }
      state.screenDisplayItems.interviewSchedule.candidateDate[
        index
      ].interviewStaffName = value
      return state
    },
    copyCandidate(
      state,
      action: PayloadAction<{ sourceIndex: number; targetIndex: number }>
    ) {
      const sourceIndex = action.payload.sourceIndex
      const targetIndex = action.payload.targetIndex
      if (
        state.screenDisplayItems.interviewSchedule.candidateDate[sourceIndex]
      ) {
        const startTime: string =
          state.screenDisplayItems.interviewSchedule.candidateDate[sourceIndex]
            .interviewStartTime
        const staffName: string =
          state.screenDisplayItems.interviewSchedule.candidateDate[sourceIndex]
            .interviewStaffName
        state.screenDisplayItems.interviewSchedule.candidateDate[
          targetIndex
        ].interviewStartTime = startTime
        state.screenDisplayItems.interviewSchedule.candidateDate[
          targetIndex
        ].interviewStaffName = staffName
      }
      return state
    },
    updateCandidate(
      state,
      action: PayloadAction<{ value: viewConfig.CandidateDate[] }>
    ) {
      const value = action.payload.value
      const candidate: viewConfig.CandidateDate[] =
        state.screenDisplayItems.interviewSchedule.candidateDate
      if (candidate) {
        candidate.map((item, index) => {
          if (value.length > index) {
            item.interviewLocationId = value[index].interviewLocationId
            item.interviewStaffName = value[index].interviewStaffName
            item.interviewStartTime = value[index].interviewStartTime
          } else {
            item.interviewLocationId = ''
            item.interviewStaffName = ''
            item.interviewStartTime = ''
          }
        })
      }
      return state
    },
    clearCandidate(state) {
      let interviewScheduleList: viewConfig.CandidateDate[] = []
      if (interviewScheduleList && interviewScheduleList.length <= 0) {
        for (var i = 0; i < 30; i++) {
          let interviewSchedule: viewConfig.CandidateDate = {
            interviewLocationId: '',
            interviewStartTime: '',
            interviewStaffName: '',
          }
          interviewScheduleList = [...interviewScheduleList, interviewSchedule]
        }
      }
      state.screenDisplayItems.interviewSchedule.candidateDate = interviewScheduleList
      state.screenDisplayItems.interviewSchedule.interviewDetail = ''
      return state
    },
    inputManipulationSelection(state, action: PayloadAction<number>) {
      const value = action.payload
      state.screenDisplayItems.interviewSchedule.manipulationSelection = value
      if (value !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
        state.screenDisplayItems.messageInput.replyAllow = magiContants.REPLY_PERMIT_FLAG_PERMIT
      }
      return state
    },
    deleteSendTarget(state, action: PayloadAction<{ id: string }>) {
      const entryId = action.payload.id
      const sendTargetList = state.screenDisplayItems.sendTargetConfirmation.sendTarget
      let newSendTargetList: viewConfig.SendTarget[] = []
      if (sendTargetList) {
        sendTargetList.map(item => {
          if (item.entryId !== entryId) {
            newSendTargetList = [...newSendTargetList, item]
          }
        })
      }
      state.screenDisplayItems.sendTargetConfirmation.sendTarget = newSendTargetList
      return state
    },
    addInterviewLocation(state, action: PayloadAction<{ interviewLocation: viewConfig.InterviewLocation }>) {
      const interviewLocation = action.payload.interviewLocation
      const interviewLocationList = state.screenDisplayItems.interviewSchedule.interviewLocation
      state.screenDisplayItems.interviewSchedule.interviewLocation = [...interviewLocationList, interviewLocation]
      return state
    },
    setDraftReqeust(state) {
      state.draftRequest.messageTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId && state.screenDisplayItems.title.selectedTemplateSettingId !== '' ? state.screenDisplayItems.title.selectedTemplateSettingId : null

      state.draftRequest.sourceDraftMessageSendId = state.messageInitRequest.draftMessageSendId ? String(state.messageInitRequest.draftMessageSendId) : ''
      state.draftRequest.destination = Number(state.screenDisplayItems.sendTargetConfirmation.destinationSelection)
      const sendTarget =
        state.screenDisplayItems.sendTargetConfirmation.sendTarget
      let entryIdList: string[] = []
      let selectionManagementIdList: string[] = []
      let progressStatusSettingIdList: (string | null)[] = []
      let decisionDivisionList: string[] = []
      let manualRegistrationFlagList: string[] = []
      let senderMessageIdList: (number | null)[] = []
      if (sendTarget) {
        sendTarget.map(item => {
          entryIdList = [...entryIdList, item.entryId]
          selectionManagementIdList = [
            ...selectionManagementIdList,
            item.selectionManagementId,
          ]
          progressStatusSettingIdList = [
            ...progressStatusSettingIdList,
            item.progressStatusSettingId && item.progressStatusSettingId !== ''
              ? item.progressStatusSettingId
              : '0',
          ]
          decisionDivisionList = [...decisionDivisionList, item.decisionDivision]
          manualRegistrationFlagList = [
            ...manualRegistrationFlagList,
            item.manualRegistrationFlag,
          ]
          senderMessageIdList = [...senderMessageIdList, item.senderMessageId
              ? item.senderMessageId
              : null,
          ]
        })
      }
      state.draftRequest.destinationId = entryIdList
      state.draftRequest.selectionManagementId = selectionManagementIdList
      state.draftRequest.progressStatusSettingId = progressStatusSettingIdList
      state.draftRequest.decisionDivision = decisionDivisionList
      state.draftRequest.manualRegistrationFlag = manualRegistrationFlagList
      state.draftRequest.senderMessageId = senderMessageIdList

      state.draftRequest.manipulationKubun = state.screenDisplayItems.interviewSchedule.manipulationKubun
      state.draftRequest.interviewScheduleAdjustmentSelection = '0'
      if (state.screenDisplayItems.interviewSchedule.manipulationSelection && state.screenDisplayItems.interviewSchedule.manipulationSelection !== magiContants.SELECTION_NUMBER_NOT_PRESENT) {
        const manipulationSelection = state.screenDisplayItems.interviewSchedule.manipulationSelection
        state.draftRequest.interviewScheduleAdjustmentSelection = String(manipulationSelection)
      }
      state.draftRequest.interviewDetail =
        state.screenDisplayItems.interviewSchedule.interviewDetail
      const candidateDateList =
        state.screenDisplayItems.interviewSchedule.candidateDate
      state.draftRequest.interviewScheduleAdjustmentId = state.initResult.interviewSchedule && state.initResult.interviewSchedule.length > 0 && state.initResult.interviewSchedule[0].interviewScheduleAdjustmentId ? String(state.initResult.interviewSchedule[0].interviewScheduleAdjustmentId) : '0'
      const manipulationSelection = state.screenDisplayItems.interviewSchedule.manipulationSelection
      state.draftRequest.interviewScheduleAdjustmentStatus = manipulationSelection !== magiContants.SELECTION_NUMBER_NOT_PRESENT
        ? manipulationSelection === magiContants.SELECTION_NUMBER_FIX || manipulationSelection === magiContants.SELECTION_NUMBER_SEND
          ? manipulationSelection === magiContants.SELECTION_NUMBER_FIX ? 'hope_fixed' : 'fixed'
          : 'offer'
        : null
      state.draftRequest.interviewScheduleId1 = null
      state.draftRequest.interviewScheduleStatus1 = ''
      state.draftRequest.interviewLocationSettingId1 = candidateDateList[0] && candidateDateList[0].interviewLocationId && !isEmpty(candidateDateList[0].interviewLocationId) ? candidateDateList[0].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId2 = candidateDateList[1] && candidateDateList[1].interviewLocationId && !isEmpty(candidateDateList[1].interviewLocationId) ? candidateDateList[1].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId3 = candidateDateList[2] && candidateDateList[2].interviewLocationId && !isEmpty(candidateDateList[2].interviewLocationId) ? candidateDateList[2].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId4 = candidateDateList[3] && candidateDateList[3].interviewLocationId && !isEmpty(candidateDateList[3].interviewLocationId) ? candidateDateList[3].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId5 = candidateDateList[4] && candidateDateList[4].interviewLocationId && !isEmpty(candidateDateList[4].interviewLocationId) ? candidateDateList[4].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId6 = candidateDateList[5] && candidateDateList[5].interviewLocationId && !isEmpty(candidateDateList[5].interviewLocationId) ? candidateDateList[5].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId7 = candidateDateList[6] && candidateDateList[6].interviewLocationId && !isEmpty(candidateDateList[6].interviewLocationId) ? candidateDateList[6].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId8 = candidateDateList[7] && candidateDateList[7].interviewLocationId && !isEmpty(candidateDateList[7].interviewLocationId) ? candidateDateList[7].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId9 = candidateDateList[8] && candidateDateList[8].interviewLocationId && !isEmpty(candidateDateList[8].interviewLocationId) ? candidateDateList[8].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId10 = candidateDateList[9] && candidateDateList[9].interviewLocationId && !isEmpty(candidateDateList[9].interviewLocationId) ? candidateDateList[9].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId11 = candidateDateList[10] && candidateDateList[10].interviewLocationId && !isEmpty(candidateDateList[10].interviewLocationId) ? candidateDateList[10].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId12 = candidateDateList[11] && candidateDateList[11].interviewLocationId && !isEmpty(candidateDateList[11].interviewLocationId) ? candidateDateList[11].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId13 = candidateDateList[12] && candidateDateList[12].interviewLocationId && !isEmpty(candidateDateList[12].interviewLocationId) ? candidateDateList[12].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId14 = candidateDateList[13] && candidateDateList[13].interviewLocationId && !isEmpty(candidateDateList[13].interviewLocationId) ? candidateDateList[13].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId15 = candidateDateList[14] && candidateDateList[14].interviewLocationId && !isEmpty(candidateDateList[14].interviewLocationId) ? candidateDateList[14].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId16 = candidateDateList[15] && candidateDateList[15].interviewLocationId && !isEmpty(candidateDateList[15].interviewLocationId) ? candidateDateList[15].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId17 = candidateDateList[16] && candidateDateList[16].interviewLocationId && !isEmpty(candidateDateList[16].interviewLocationId) ? candidateDateList[16].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId18 = candidateDateList[17] && candidateDateList[17].interviewLocationId && !isEmpty(candidateDateList[17].interviewLocationId) ? candidateDateList[17].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId19 = candidateDateList[18] && candidateDateList[18].interviewLocationId && !isEmpty(candidateDateList[18].interviewLocationId) ? candidateDateList[18].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId20 = candidateDateList[19] && candidateDateList[19].interviewLocationId && !isEmpty(candidateDateList[19].interviewLocationId) ? candidateDateList[19].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId21 = candidateDateList[20] && candidateDateList[20].interviewLocationId && !isEmpty(candidateDateList[20].interviewLocationId) ? candidateDateList[20].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId22 = candidateDateList[21] && candidateDateList[21].interviewLocationId && !isEmpty(candidateDateList[21].interviewLocationId) ? candidateDateList[21].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId23 = candidateDateList[22] && candidateDateList[22].interviewLocationId && !isEmpty(candidateDateList[22].interviewLocationId) ? candidateDateList[22].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId24 = candidateDateList[23] && candidateDateList[23].interviewLocationId && !isEmpty(candidateDateList[23].interviewLocationId) ? candidateDateList[23].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId25 = candidateDateList[24] && candidateDateList[24].interviewLocationId && !isEmpty(candidateDateList[24].interviewLocationId) ? candidateDateList[24].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId26 = candidateDateList[25] && candidateDateList[25].interviewLocationId && !isEmpty(candidateDateList[25].interviewLocationId) ? candidateDateList[25].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId27 = candidateDateList[26] && candidateDateList[26].interviewLocationId && !isEmpty(candidateDateList[26].interviewLocationId) ? candidateDateList[26].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId28 = candidateDateList[27] && candidateDateList[27].interviewLocationId && !isEmpty(candidateDateList[27].interviewLocationId) ? candidateDateList[27].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId29 = candidateDateList[28] && candidateDateList[28].interviewLocationId && !isEmpty(candidateDateList[28].interviewLocationId) ? candidateDateList[28].interviewLocationId : null
      state.draftRequest.interviewLocationSettingId30 = candidateDateList[29] && candidateDateList[29].interviewLocationId && !isEmpty(candidateDateList[29].interviewLocationId) ? candidateDateList[29].interviewLocationId : null
      state.draftRequest.interviewStaffName1 = candidateDateList[0] && candidateDateList[0].interviewStaffName && !isEmpty(candidateDateList[0].interviewStaffName) ? candidateDateList[0].interviewStaffName : ''
      state.draftRequest.interviewStaffName2 = candidateDateList[0] && candidateDateList[1].interviewStaffName && !isEmpty(candidateDateList[1].interviewStaffName) ? candidateDateList[1].interviewStaffName : ''
      state.draftRequest.interviewStaffName3 = candidateDateList[0] && candidateDateList[2].interviewStaffName && !isEmpty(candidateDateList[2].interviewStaffName) ? candidateDateList[2].interviewStaffName : ''
      state.draftRequest.interviewStaffName4 = candidateDateList[0] && candidateDateList[3].interviewStaffName && !isEmpty(candidateDateList[3].interviewStaffName) ? candidateDateList[3].interviewStaffName : ''
      state.draftRequest.interviewStaffName5 = candidateDateList[0] && candidateDateList[4].interviewStaffName && !isEmpty(candidateDateList[4].interviewStaffName) ? candidateDateList[4].interviewStaffName : ''
      state.draftRequest.interviewStaffName6 = candidateDateList[0] && candidateDateList[5].interviewStaffName && !isEmpty(candidateDateList[5].interviewStaffName) ? candidateDateList[5].interviewStaffName : ''
      state.draftRequest.interviewStaffName7 = candidateDateList[0] && candidateDateList[6].interviewStaffName && !isEmpty(candidateDateList[6].interviewStaffName) ? candidateDateList[6].interviewStaffName : ''
      state.draftRequest.interviewStaffName8 = candidateDateList[0] && candidateDateList[7].interviewStaffName && !isEmpty(candidateDateList[7].interviewStaffName) ? candidateDateList[7].interviewStaffName : ''
      state.draftRequest.interviewStaffName9 = candidateDateList[0] && candidateDateList[8].interviewStaffName && !isEmpty(candidateDateList[8].interviewStaffName) ? candidateDateList[8].interviewStaffName : ''
      state.draftRequest.interviewStaffName10 = candidateDateList[0] && candidateDateList[9].interviewStaffName && !isEmpty(candidateDateList[9].interviewStaffName) ? candidateDateList[9].interviewStaffName : ''
      state.draftRequest.interviewStaffName11 = candidateDateList[0] && candidateDateList[10].interviewStaffName && !isEmpty(candidateDateList[10].interviewStaffName) ? candidateDateList[10].interviewStaffName : ''
      state.draftRequest.interviewStaffName12 = candidateDateList[0] && candidateDateList[11].interviewStaffName && !isEmpty(candidateDateList[11].interviewStaffName) ? candidateDateList[11].interviewStaffName : ''
      state.draftRequest.interviewStaffName13 = candidateDateList[0] && candidateDateList[12].interviewStaffName && !isEmpty(candidateDateList[12].interviewStaffName) ? candidateDateList[12].interviewStaffName : ''
      state.draftRequest.interviewStaffName14 = candidateDateList[0] && candidateDateList[13].interviewStaffName && !isEmpty(candidateDateList[13].interviewStaffName) ? candidateDateList[13].interviewStaffName : ''
      state.draftRequest.interviewStaffName15 = candidateDateList[0] && candidateDateList[14].interviewStaffName && !isEmpty(candidateDateList[14].interviewStaffName) ? candidateDateList[14].interviewStaffName : ''
      state.draftRequest.interviewStaffName16 = candidateDateList[0] && candidateDateList[15].interviewStaffName && !isEmpty(candidateDateList[15].interviewStaffName) ? candidateDateList[15].interviewStaffName : ''
      state.draftRequest.interviewStaffName17 = candidateDateList[0] && candidateDateList[16].interviewStaffName && !isEmpty(candidateDateList[16].interviewStaffName) ? candidateDateList[16].interviewStaffName : ''
      state.draftRequest.interviewStaffName18 = candidateDateList[0] && candidateDateList[17].interviewStaffName && !isEmpty(candidateDateList[17].interviewStaffName) ? candidateDateList[17].interviewStaffName : ''
      state.draftRequest.interviewStaffName19 = candidateDateList[0] && candidateDateList[18].interviewStaffName && !isEmpty(candidateDateList[18].interviewStaffName) ? candidateDateList[18].interviewStaffName : ''
      state.draftRequest.interviewStaffName20 = candidateDateList[0] && candidateDateList[19].interviewStaffName && !isEmpty(candidateDateList[19].interviewStaffName) ? candidateDateList[19].interviewStaffName : ''
      state.draftRequest.interviewStaffName21 = candidateDateList[0] && candidateDateList[20].interviewStaffName && !isEmpty(candidateDateList[20].interviewStaffName) ? candidateDateList[20].interviewStaffName : ''
      state.draftRequest.interviewStaffName22 = candidateDateList[0] && candidateDateList[21].interviewStaffName && !isEmpty(candidateDateList[21].interviewStaffName) ? candidateDateList[21].interviewStaffName : ''
      state.draftRequest.interviewStaffName23 = candidateDateList[0] && candidateDateList[22].interviewStaffName && !isEmpty(candidateDateList[22].interviewStaffName) ? candidateDateList[22].interviewStaffName : ''
      state.draftRequest.interviewStaffName24 = candidateDateList[0] && candidateDateList[23].interviewStaffName && !isEmpty(candidateDateList[23].interviewStaffName) ? candidateDateList[23].interviewStaffName : ''
      state.draftRequest.interviewStaffName25 = candidateDateList[0] && candidateDateList[24].interviewStaffName && !isEmpty(candidateDateList[24].interviewStaffName) ? candidateDateList[24].interviewStaffName : ''
      state.draftRequest.interviewStaffName26 = candidateDateList[0] && candidateDateList[25].interviewStaffName && !isEmpty(candidateDateList[25].interviewStaffName) ? candidateDateList[25].interviewStaffName : ''
      state.draftRequest.interviewStaffName27 = candidateDateList[0] && candidateDateList[26].interviewStaffName && !isEmpty(candidateDateList[26].interviewStaffName) ? candidateDateList[26].interviewStaffName : ''
      state.draftRequest.interviewStaffName28 = candidateDateList[0] && candidateDateList[27].interviewStaffName && !isEmpty(candidateDateList[27].interviewStaffName) ? candidateDateList[27].interviewStaffName : ''
      state.draftRequest.interviewStaffName29 = candidateDateList[0] && candidateDateList[28].interviewStaffName && !isEmpty(candidateDateList[28].interviewStaffName) ? candidateDateList[28].interviewStaffName : ''
      state.draftRequest.interviewStaffName30 = candidateDateList[0] && candidateDateList[29].interviewStaffName && !isEmpty(candidateDateList[29].interviewStaffName) ? candidateDateList[29].interviewStaffName : ''
      state.draftRequest.interviewStartTime1 = candidateDateList[0] && candidateDateList[0].interviewStartTime && candidateDateList[0].interviewStartTime !== '' ? candidateDateList[0].interviewStartTime : null
      state.draftRequest.interviewStartTime2 = candidateDateList[1] && candidateDateList[1].interviewStartTime && candidateDateList[1].interviewStartTime !== '' ? candidateDateList[1].interviewStartTime : null
      state.draftRequest.interviewStartTime3 = candidateDateList[2] && candidateDateList[2].interviewStartTime && candidateDateList[2].interviewStartTime !== '' ? candidateDateList[2].interviewStartTime : null
      state.draftRequest.interviewStartTime4 = candidateDateList[3] && candidateDateList[3].interviewStartTime && candidateDateList[3].interviewStartTime !== '' ? candidateDateList[3].interviewStartTime : null
      state.draftRequest.interviewStartTime5 = candidateDateList[4] && candidateDateList[4].interviewStartTime && candidateDateList[4].interviewStartTime !== '' ? candidateDateList[4].interviewStartTime : null
      state.draftRequest.interviewStartTime6 = candidateDateList[5] && candidateDateList[5].interviewStartTime && candidateDateList[5].interviewStartTime !== '' ? candidateDateList[5].interviewStartTime : null
      state.draftRequest.interviewStartTime7 = candidateDateList[6] && candidateDateList[6].interviewStartTime && candidateDateList[6].interviewStartTime !== '' ? candidateDateList[6].interviewStartTime : null
      state.draftRequest.interviewStartTime8 = candidateDateList[7] && candidateDateList[7].interviewStartTime && candidateDateList[7].interviewStartTime !== '' ? candidateDateList[7].interviewStartTime : null
      state.draftRequest.interviewStartTime9 = candidateDateList[8] && candidateDateList[8].interviewStartTime && candidateDateList[8].interviewStartTime !== '' ? candidateDateList[8].interviewStartTime : null
      state.draftRequest.interviewStartTime10 = candidateDateList[9] && candidateDateList[9].interviewStartTime && candidateDateList[9].interviewStartTime !== '' ? candidateDateList[9].interviewStartTime : null
      state.draftRequest.interviewStartTime11 = candidateDateList[10] && candidateDateList[10].interviewStartTime && candidateDateList[10].interviewStartTime !== '' ? candidateDateList[10].interviewStartTime : null
      state.draftRequest.interviewStartTime12 = candidateDateList[11] && candidateDateList[11].interviewStartTime && candidateDateList[11].interviewStartTime !== '' ? candidateDateList[11].interviewStartTime : null
      state.draftRequest.interviewStartTime13 = candidateDateList[12] && candidateDateList[12].interviewStartTime && candidateDateList[12].interviewStartTime !== '' ? candidateDateList[12].interviewStartTime : null
      state.draftRequest.interviewStartTime14 = candidateDateList[13] && candidateDateList[13].interviewStartTime && candidateDateList[13].interviewStartTime !== '' ? candidateDateList[13].interviewStartTime : null
      state.draftRequest.interviewStartTime15 = candidateDateList[14] && candidateDateList[14].interviewStartTime && candidateDateList[14].interviewStartTime !== '' ? candidateDateList[14].interviewStartTime : null
      state.draftRequest.interviewStartTime16 = candidateDateList[15] && candidateDateList[15].interviewStartTime && candidateDateList[15].interviewStartTime !== '' ? candidateDateList[15].interviewStartTime : null
      state.draftRequest.interviewStartTime17 = candidateDateList[16] && candidateDateList[16].interviewStartTime && candidateDateList[16].interviewStartTime !== '' ? candidateDateList[16].interviewStartTime : null
      state.draftRequest.interviewStartTime18 = candidateDateList[17] && candidateDateList[17].interviewStartTime && candidateDateList[17].interviewStartTime !== '' ? candidateDateList[17].interviewStartTime : null
      state.draftRequest.interviewStartTime19 = candidateDateList[18] && candidateDateList[18].interviewStartTime && candidateDateList[18].interviewStartTime !== '' ? candidateDateList[18].interviewStartTime : null
      state.draftRequest.interviewStartTime20 = candidateDateList[19] && candidateDateList[19].interviewStartTime && candidateDateList[19].interviewStartTime !== '' ? candidateDateList[19].interviewStartTime : null
      state.draftRequest.interviewStartTime21 = candidateDateList[20] && candidateDateList[20].interviewStartTime && candidateDateList[20].interviewStartTime !== '' ? candidateDateList[20].interviewStartTime : null
      state.draftRequest.interviewStartTime22 = candidateDateList[21] && candidateDateList[21].interviewStartTime && candidateDateList[21].interviewStartTime !== '' ? candidateDateList[21].interviewStartTime : null
      state.draftRequest.interviewStartTime23 = candidateDateList[22] && candidateDateList[22].interviewStartTime && candidateDateList[22].interviewStartTime !== '' ? candidateDateList[22].interviewStartTime : null
      state.draftRequest.interviewStartTime24 = candidateDateList[23] && candidateDateList[23].interviewStartTime && candidateDateList[23].interviewStartTime !== '' ? candidateDateList[23].interviewStartTime : null
      state.draftRequest.interviewStartTime25 = candidateDateList[24] && candidateDateList[24].interviewStartTime && candidateDateList[24].interviewStartTime !== '' ? candidateDateList[24].interviewStartTime : null
      state.draftRequest.interviewStartTime26 = candidateDateList[25] && candidateDateList[25].interviewStartTime && candidateDateList[25].interviewStartTime !== '' ? candidateDateList[25].interviewStartTime : null
      state.draftRequest.interviewStartTime27 = candidateDateList[26] && candidateDateList[26].interviewStartTime && candidateDateList[26].interviewStartTime !== '' ? candidateDateList[26].interviewStartTime : null
      state.draftRequest.interviewStartTime28 = candidateDateList[27] && candidateDateList[27].interviewStartTime && candidateDateList[27].interviewStartTime !== '' ? candidateDateList[27].interviewStartTime : null
      state.draftRequest.interviewStartTime29 = candidateDateList[28] && candidateDateList[28].interviewStartTime && candidateDateList[28].interviewStartTime !== '' ? candidateDateList[28].interviewStartTime : null
      state.draftRequest.interviewStartTime30 = candidateDateList[29] && candidateDateList[29].interviewStartTime && candidateDateList[29].interviewStartTime !== '' ? candidateDateList[29].interviewStartTime : null

      state.draftRequest.senderCompanyName =
        state.screenDisplayItems.messageInput.senderCompanyName
      if (state.screenDisplayItems.sendTargetConfirmation.destinationSelection !== magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
        state.draftRequest.senderMailAddress = state.screenDisplayItems.messageInput.senderMailAddress
      } else {
        state.draftRequest.senderMailAddress = ''
      }
      state.draftRequest.allowReply = Number(
        state.screenDisplayItems.messageInput.replyAllow
      )
      state.draftRequest.repliesNecessary = Number(
        state.screenDisplayItems.messageInput.repliesNecessary
      )
      state.draftRequest.repliesLimit = (state.draftRequest.repliesNecessary==1 && state.screenDisplayItems.messageInput.repliesLimit) ? state.screenDisplayItems.messageInput.repliesLimit : null
      // state.draftRequest.repliesLimit = dateToString(state.screenDisplayItems.messageInput.repliesLimit)
      state.draftRequest.subject = state.screenDisplayItems.messageInput.subject
      state.draftRequest.text = state.screenDisplayItems.messageInput.body
      const attachmentData = state.screenDisplayItems.messageInput.attachment
      let attachmentIdList: Array<string|null> = []
      let attachmentNameList: string[] = []
      if (attachmentData) {
        attachmentData.map(item => {
          let attachment = item
          attachmentIdList = [...attachmentIdList, attachment.attachmentId]
          attachmentNameList = [...attachmentNameList, attachment.attachmentName]
        })
      }
      state.draftRequest.attachmentId =
        attachmentIdList.length > 0 && attachmentIdList[0] !== ''
          ? attachmentIdList
          : []
      state.draftRequest.attachmentName =
        attachmentNameList.length > 0 && attachmentNameList[0] !== ''
          ? attachmentNameList
          : []
      state.draftRequest.entryBoxId =
        state.screenDisplayItems.messageInput.entryBoxId
      state.draftRequest.seminarId =
        state.screenDisplayItems.messageInput.seminarId
      const homePage1 = state.screenDisplayItems.messageInput.homePage[0]
      if (homePage1) {
        state.draftRequest.homepageTitle1 = homePage1.homePageTitle && homePage1.homePageTitle !== '' ? homePage1.homePageTitle : null
        state.draftRequest.homepageUrl1 = homePage1.homePageUrl && homePage1.homePageUrl !== '' ? homePage1.homePageUrl : null
      }
      const homePage2 = state.screenDisplayItems.messageInput.homePage[1]
      if (homePage2) {
        state.draftRequest.homepageTitle2 = homePage2.homePageTitle && homePage2.homePageTitle !== '' ? homePage2.homePageTitle : null
        state.draftRequest.homepageUrl2 = homePage2.homePageUrl && homePage2.homePageUrl !== '' ? homePage2.homePageUrl : null
      }
      const homePage3 = state.screenDisplayItems.messageInput.homePage[2]
      if (homePage3) {
        state.draftRequest.homepageTitle3 = homePage3.homePageTitle && homePage3.homePageTitle !== '' ? homePage3.homePageTitle : null
        state.draftRequest.homepageUrl3 = homePage3.homePageUrl && homePage3.homePageUrl !== '' ? homePage3.homePageUrl : null
      }
      state.draftRequest.barcodeDisplay = Number(
        state.screenDisplayItems.messageInput.barcodeDisplay
      )

      state.draftRequest.sendTimer = Number(
        state.screenDisplayItems.sendTimeInput.transmission
      )
      state.draftRequest.sendPlanTime = dateTimeToString(state.screenDisplayItems.sendTimeInput.sendPlanTime)

      const notifyMailAddress = state.screenDisplayItems.notification.notificationMailAddress
      const notificationSelect = state.screenDisplayItems.notification.notificationSelect
      state.draftRequest.notifyMailAddress = notificationSelect === magiContants.NOTIFICATION_DESIRED && !isEmpty(notifyMailAddress) ? notifyMailAddress : ''
      state.draftRequest.sendToNonMember = state.screenDisplayItems.sendTargetConfirmation.sendToNonMember
      state.draftRequest.interviewScheduleAdjustmentIdDraft = state.messageInitRequest.interviewScheduleAdjustmentId
      state.draftRequest.interviewScheduleIdDraft = state.messageInitRequest.interviewScheduleId
      state.draftRequest.actionDraft = state.screenDisplayItems.interviewSchedule.action
      state.draftRequest.sysVersionNumber = state.screenDisplayItems.interviewSchedule.sysVersionNumber ? state.screenDisplayItems.interviewSchedule.sysVersionNumber : 0
      state.draftRequest.searchCriteriaName = state.screenDisplayItems.sendTargetConfirmation.searchCriteriaName
      state.draftRequest.searchCriteria = state.screenDisplayItems.sendTargetConfirmation.searchCriteria
      state.draftRequest.searchCriteriaJson = state.initResult.searchCriteriaJson
      state.draftRequest.mySearchCondition = state.screenDisplayItems.sendTargetConfirmation.mySearchCondition
      state.draftRequest.mySearchCriteriaNameJson = state.initResult.mySearchCriteriaNameJson
      state.draftRequest.mySearchCriteriaJson = state.initResult.mySearchCriteriaJson
      state.draftRequest.replyFormerMessageId = state.screenDisplayItems.hiddenItems.replyFromMessageSendId !== '' ? Number(state.screenDisplayItems.hiddenItems.replyFromMessageSendId) : null
      state.draftRequest.interviewScheduleIdWeb = state.initResult.interviewScheduleIdWeb
      state.draftRequest.interviewWebUrlSendFlag = state.initResult.interviewWebUrlSendFlag
    },
    setEmptyTemplateSettingId(state) {
      state.screenDisplayItems.title.selectedTemplateSettingId = ''
      return state
    },
    selectTemplate(state, action:PayloadAction<{ id: number, inputValues: any }>) {
      const messageTemplateSettingId = action.payload.id
      const inputValues = action.payload.inputValues

      const manipulationSelection = state.screenDisplayItems.interviewSchedule.manipulationSelection
      const interviewScheduleAdjustmentAction = state.screenDisplayItems.interviewSchedule.action
      const desiredScheduleList = state.initResult.jobSeekerInterviewDesiredSchedule
      const currentDestinationSelection = state.screenDisplayItems.sendTargetConfirmation.destinationSelection
      let isFixedSubject = false
      if (state.screenDisplayItems.title.messageTemplate) {
        state.messageTemplateChanged = true
        state.screenDisplayItems.title.messageTemplate.map(template => {
          if (template && template.messageTemplateSettingId === messageTemplateSettingId) {
            const sendTargetList = state.screenDisplayItems.sendTargetConfirmation.sendTarget
            if (template.destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (interviewScheduleAdjustmentAction === magiContants.ACTION_FIX_INTERVIEW || interviewScheduleAdjustmentAction === magiContants.ACTION_CHANGE_INTERVIEW || manipulationSelection === magiContants.SELECTION_NUMBER_FIX || manipulationSelection === magiContants.SELECTION_NUMBER_SEND || (currentDestinationSelection !== magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && sendTargetList && sendTargetList.length > 0 && sendTargetList[0].sentMessageCount <= 0 && interviewScheduleAdjustmentAction === '' && desiredScheduleList && desiredScheduleList.length > 0 && desiredScheduleList.some(item => moment(item.interviewPreferredDate).isAfter(moment(now()), 'date'))))) {
              isFixedSubject = true
            }
            state.screenDisplayItems.title.selectedTemplateSettingId = String(messageTemplateSettingId)

            let templateDestinationSelection = !isEmpty(template.destinationDivision) ? template.destinationDivision : magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
            let templateSendToNonMember = ( template.sendToNonMember != null ) ? template.sendToNonMember : magiContants.SEND_TO_NON_MEMBER

            // 対象者一覧にはすべての対象者は非会員であるか
            const IsOnlyNonMember = !sendTargetList.some(item => item.mynaviMemberInfo === magiContants.MEMBERS_TYPE_MEMBER)

            //  対象者一覧に会員対象者の数が一つ以上であれば、「マイナビのみ」ラジオボタンが活性で変量の値はtrueだと判断する
            const isDestinationDivisionActive = !IsOnlyNonMember;

            // テンプレートタイプの値は「３」であれば、MCBの場合だと判断する。
            const isMcb = template.templateType === magiContants.DESTINATION_DIVISION_MYCAREERBOX;
            
            if (sendTargetList && sendTargetList.length === 1) {
              // 個別送信かつ、【非会員】の場合は「e-mailのみ」以外選択不可
              if (sendTargetList[0].mynaviMemberInfo === magiContants.MEMBERS_TYPE_NON_MEMBER) {
                templateDestinationSelection = magiContants.DESTINATION_DIVISION_EMAIL_ONLY
              }
              // 個別送信かつ、【匿名】の場合は「マイナビのみ」以外選択不可
              if (sendTargetList[0].entryType === magiContants.ENTRY_TYPE_ANONYMOUS_QUESTION) {
                templateDestinationSelection = magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
              }
            }
            // 【就職】かつ、【退会済み】または【非会員】の場合は「e-mailのみ」以外選択不可
            if (state.screenDisplayItems.recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1) {
              if (sendTargetList) {
                let destinationSelectionFlag = false
                destinationSelectionFlag = sendTargetList.some(sendTarget => {
                  return sendTarget.mynaviMemberInfo !== magiContants.MEMBERS_TYPE_DEACTIVATED && sendTarget.mynaviMemberInfo !== magiContants.MEMBERS_TYPE_NON_MEMBER
                })
                if (!destinationSelectionFlag) {
                  templateDestinationSelection = magiContants.DESTINATION_DIVISION_EMAIL_ONLY
                  templateSendToNonMember = 0
                }
              }
            }

            // 「MCB」かつ、「会員」対象者が存在する場合は「マイナビのみ」以外選択不可
            if (isMcb && isDestinationDivisionActive) {
              templateDestinationSelection = magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
            }
            
            state.screenDisplayItems.sendTargetConfirmation.destinationSelection = templateDestinationSelection
            // phase2 start
            state.screenDisplayItems.sendTargetConfirmation.sendToNonMember = templateSendToNonMember
            // phase2 end

            let shouldShowMessageInput = state.screenDisplayItems.messageInput.shouldShowMessageInput
            const recruitmentManagementDivision = state.screenDisplayItems.recruitmentManagementDivision
            const destinationSelection = state.screenDisplayItems.sendTargetConfirmation.destinationSelection
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY)) {
              shouldShowMessageInput.senderCompanyName = true
            } else {
              shouldShowMessageInput.senderCompanyName = false
              state.screenDisplayItems.messageInput.senderCompanyName = ''
            }
            if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED || destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
              shouldShowMessageInput.senderMailAddress = true
            } else {
              shouldShowMessageInput.senderMailAddress = false
              if (template.destinationDivision === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
                state.screenDisplayItems.messageInput.senderMailAddress = ''
              }
            }
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY || destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED)) {
              shouldShowMessageInput.replyAllow = true
              shouldShowMessageInput.repliesNecessary = true
              shouldShowMessageInput.repliesLimit = true
            } else {
              shouldShowMessageInput.replyAllow = false
              shouldShowMessageInput.repliesNecessary = false
              shouldShowMessageInput.repliesLimit = false
              state.screenDisplayItems.messageInput.replyAllow = magiContants.REPLY_PERMIT_FLAG_DISALLOW
            }
            if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
              shouldShowMessageInput.attachment = true
            } else {
              shouldShowMessageInput.attachment = false
            }
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY)) {
              shouldShowMessageInput.homePage = true
            } else {
              shouldShowMessageInput.homePage = false
            }
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
              shouldShowMessageInput.barcodeDisplay = true
            } else {
              shouldShowMessageInput.barcodeDisplay = false
            }
            state.screenDisplayItems.messageInput.shouldShowMessageInput = shouldShowMessageInput

            let senderCompanyName = ''
            if ('' !== template.senderCompanyName) {
              senderCompanyName = template.senderCompanyName
            } 
            state.screenDisplayItems.messageInput.senderCompanyName = senderCompanyName

            let senderMailAddress = ''
            if ('' !== template.senderMailAddress) {
              senderMailAddress = template.senderMailAddress
            }
            state.screenDisplayItems.messageInput.senderMailAddress = senderMailAddress

            //  MCBの場合、「返信許可の指定」に項目値を設定しない
            if (!isMcb) {
              state.screenDisplayItems.messageInput.replyAllow = !isEmpty(template.replayPermitFlag) ? template.replayPermitFlag : magiContants.REPLY_PERMIT_FLAG_DISALLOW
              if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY && (manipulationSelection !== magiContants.SELECTION_NUMBER_NOT_PRESENT || interviewScheduleAdjustmentAction === magiContants.ACTION_FIX_INTERVIEW || interviewScheduleAdjustmentAction === magiContants.ACTION_CHANGE_POSSIBLE || interviewScheduleAdjustmentAction === magiContants.ACTION_WITHDRAW_INTERVIEW || interviewScheduleAdjustmentAction === magiContants.ACTION_CHANGE_INTERVIEW)) {
                state.screenDisplayItems.messageInput.replyAllow = magiContants.REPLY_PERMIT_FLAG_PERMIT
              }
            }
            if (!isFixedSubject || template.destinationDivision !== magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
              state.screenDisplayItems.messageInput.subject = template.subject
            } else {
              state.screenDisplayItems.messageInput.subject = magiContants.INTERVIEW_FIX_SUBJECT
              state.subjectText = template.subject
            }
            state.screenDisplayItems.messageInput.body = template.body
            //  MCBの場合、「バーコード」に項目値を設定しない
            if (!isMcb) {
              state.screenDisplayItems.messageInput.barcodeDisplay = !isEmpty(template.barcordDisplayFlag) ? template.barcordDisplayFlag : magiContants.BARCODE_DISPLAY_FLAG_NOT_SHOW
            }

            if (state.initResult.messageTemplateHomepage) {
              let homePageList: viewConfig.HomePage[] = []
              state.initResult.messageTemplateHomepage.map(templateHomePage => {
                if (templateHomePage && templateHomePage.messageTemplateSettingId === messageTemplateSettingId) {
                  let homePageData: viewConfig.HomePage = {
                    homePageTitle: '',
                    homePageUrl: '',
                  }
                  homePageData.homePageTitle = templateHomePage.homePageTitle
                  homePageData.homePageUrl = templateHomePage.homePageUrl
                  homePageList = [...homePageList, homePageData]
                }
              })
              if (homePageList.length <= 0) {
                const homePageData: viewConfig.HomePage = {
                  homePageTitle: '',
                  homePageUrl: '',
                }
                homePageList = [...homePageList, homePageData]
              }
              state.screenDisplayItems.messageInput.homePage = homePageList
            }

            if (state.initResult.messageTemplateAttachment) {
              let attachmentList: viewConfig.Attachment[] = []
              state.initResult.messageTemplateAttachment.map(templateAttachment => {
                if (templateAttachment && templateAttachment.messageTemplateSettingId === messageTemplateSettingId) {
                  let attachmentData: viewConfig.Attachment = {
                    attachmentId: null,
                    attachmentFileName: '',
                    attachmentName: '',
                  }
                  if (templateAttachment.sequence <= state.initResult.attachmentFileRegistrationMax) {
                    attachmentData.attachmentId = templateAttachment.fileManagementId
                    attachmentData.attachmentFileName = templateAttachment.attachmentName
                    attachmentData.attachmentName = templateAttachment.attachmentName
                    attachmentList = [...attachmentList, attachmentData]
                  }
                }
              })
              if (attachmentList.length <= 0) {
                const attachmentData: viewConfig.Attachment = {
                  attachmentId: null,
                  attachmentFileName: '',
                  attachmentName: '',
                }
                attachmentList = [...attachmentList, attachmentData]  
              }
              state.screenDisplayItems.messageInput.attachment = attachmentList
            }

            let entryBoxIdList: string[] = []
            if (state.initResult.messageTemplateEntrybox) {
              state.initResult.messageTemplateEntrybox.map(templateEntry => {
                if (templateEntry && templateEntry.messageTemplateSettingId === messageTemplateSettingId) {
                  const templateEntryId = templateEntry.entryBoxId
                  if (state.screenDisplayItems.messageInput.entryBox) {
                    state.screenDisplayItems.messageInput.entryBox.map(entry => {
                      if (entry && entry.entryBoxId === templateEntryId) {
                        entryBoxIdList = [...entryBoxIdList, String(entry.entryBoxId)]
                      }
                    })
                  }
                }
              })
            }
            //  MCBの場合、「マイナビ添付オプション」に項目値を設定しない
            if (!isMcb) {
              state.screenDisplayItems.messageInput.entryBoxId = entryBoxIdList
            }

            let seminarIdList: string[] = []
            if (state.initResult.messageTemplateSeminar) {
              state.initResult.messageTemplateSeminar.map(templateSeminar => {
                if (templateSeminar && templateSeminar.messageTemplateSettingId === messageTemplateSettingId) {
                  const templateSeminarId = templateSeminar.seminarId
                  if (state.screenDisplayItems.messageInput.seminar) {
                    state.screenDisplayItems.messageInput.seminar.map(seminar => {
                      if (seminar && seminar.seminarId === templateSeminarId) {
                        seminarIdList = [...seminarIdList, String(seminar.seminarId)]
                      }
                    })
                  }
                }
              })
            }
            //  MCBの場合、「返信希望アイコンの指定」、「応募者からの返信期限の指定」に項目値を設定しない
            if (!isMcb) {
              state.screenDisplayItems.messageInput.seminarId = seminarIdList
              state.screenDisplayItems.messageInput.repliesLimit = template.replyLimitTime
              state.screenDisplayItems.messageInput.repliesNecessary = template.replyNecessaryFlag
            }
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
              state.screenDisplayItems.interviewSchedule.manipulationSelection = magiContants.SELECTION_NUMBER_NOT_PRESENT
              let interviewScheduleList: viewConfig.CandidateDate[] = []
              if (interviewScheduleList && interviewScheduleList.length <= 0) {
                for (var i = 0; i < 30; i++) {
                  let interviewSchedule: viewConfig.CandidateDate = {
                    interviewLocationId: '',
                    interviewStartTime: '',
                    interviewStaffName: '',
                  }
                  interviewScheduleList = [...interviewScheduleList, interviewSchedule]
                }
              }
              state.screenDisplayItems.interviewSchedule.candidateDate = interviewScheduleList
              state.screenDisplayItems.interviewSchedule.interviewDetail = ''
            }
          } else if (-1 === messageTemplateSettingId){
            if(!(state.isOnlyNonMember || state.isOnlyAnonymous)){
              state.screenDisplayItems.sendTargetConfirmation.destinationSelection = magiContants.DESTINATION_DIVISION_MYNAVI_ONLY
            }

            state.screenDisplayItems.messageInput.headingMessageInput = ''
            state.screenDisplayItems.messageInput.headingMessageInputDescription = ''
            state.screenDisplayItems.messageInput.replyAllow = '0'
            state.screenDisplayItems.messageInput.senderCompanyName = ''
            state.screenDisplayItems.messageInput.senderMailAddress = ''
            state.screenDisplayItems.messageInput.subject = ''
            state.screenDisplayItems.messageInput.body = ''
            state.screenDisplayItems.messageInput.targetInput = 'subject'
            state.screenDisplayItems.messageInput.attachment = [{
                attachmentFileName: '',
                attachmentId: null,
                attachmentName: '',
            }]
            state.screenDisplayItems.messageInput.templateAttachmentId = []
            state.screenDisplayItems.messageInput.entryBoxId = []
            state.screenDisplayItems.messageInput.seminarId = []
            state.screenDisplayItems.messageInput.homePage = [{
              homePageTitle: '',
              homePageUrl: '',
            }]
            state.screenDisplayItems.messageInput.homePageTitle = []
            state.screenDisplayItems.messageInput.homePageUrl = []
            state.screenDisplayItems.messageInput.barcodeDisplay = '0'
            state.screenDisplayItems.messageInput.previewSubject = ''
            state.screenDisplayItems.messageInput.previewBody = ''
            state.screenDisplayItems.sendTargetConfirmation.sendToNonMember = 0

            let shouldShowMessageInput = state.screenDisplayItems.messageInput.shouldShowMessageInput
            const recruitmentManagementDivision = state.screenDisplayItems.recruitmentManagementDivision
            const destinationSelection = state.screenDisplayItems.sendTargetConfirmation.destinationSelection
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY)) {
              shouldShowMessageInput.senderCompanyName = true
            } else {
              shouldShowMessageInput.senderCompanyName = false
            }
            if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED || destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY) {
              shouldShowMessageInput.senderMailAddress = true
            } else {
              shouldShowMessageInput.senderMailAddress = false
            }
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY || destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_PREFERRED)) {
              shouldShowMessageInput.replyAllow = true
              shouldShowMessageInput.repliesNecessary = true
              shouldShowMessageInput.repliesLimit = true
            } else {
              shouldShowMessageInput.replyAllow = false
              shouldShowMessageInput.repliesNecessary = false
              shouldShowMessageInput.repliesLimit = false
            }
            if (destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
              shouldShowMessageInput.attachment = true
            } else {
              shouldShowMessageInput.attachment = false
            }
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 || (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_EMAIL_ONLY)) {
              shouldShowMessageInput.homePage = true
            } else {
              shouldShowMessageInput.homePage = false
            }
            if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 && destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
              shouldShowMessageInput.barcodeDisplay = true
            } else {
              shouldShowMessageInput.barcodeDisplay = false
            }
            state.screenDisplayItems.messageInput.shouldShowMessageInput = shouldShowMessageInput

            state.screenDisplayItems.interviewSchedule.manipulationSelection = 0
            state.screenDisplayItems.interviewSchedule.interviewDetail = ''

            state.screenDisplayItems.sendTimeInput = viewConfig.sendTimeInput
            const data: apiConfig.InitResult = state.initResult
            let notificationMailAddress = data.companyAccountMailAdress
            state.screenDisplayItems.notification = {
              notificationMailAddress: notificationMailAddress,
              notificationSelect: magiContants.NOTIFICATION_DESIRED,
              shouldShowNotification: true,
            }
            state.screenDisplayItems.messageInput.repliesLimit = ''
            state.screenDisplayItems.messageInput.repliesNecessary = ''      
          }
        })
      }
      return state
    },
    saveDraft(
      state,
      action: PayloadAction<{
        formData: MCAZS080MessageDraftRequest
        files: Array<any | null>
        attachmentFileRegistrationMax: number
        readableFlag: String
      }>
    ) {
      return state
    },
    setConfirmRequest(state) {
      const recruitmentManagementDivision = state.screenDisplayItems.recruitmentManagementDivision
      const destinationSelection = state.screenDisplayItems.sendTargetConfirmation.destinationSelection

      const shouldShowMessageInput = state.screenDisplayItems.messageInput.shouldShowMessageInput

      const confirmRequest: MCAZS080MessageConfirmRequest = {
        draftMessageSendId: null,
        messageTemplateSettingId: '0',
        destinationId: [],
        selectionManagementId: [],
        progressStatusSettingId: [],
        selectionFlowSettingId: [],
        decisionDivision: [],
        manualRegistrationFlag: [],
        destination: 0,
        senderCompanyName: '',
        senderMailAddress: '',
        allowReply: 0,
        repliesNecessary: 0,
        repliesLimit: '',
        subject: '',
        text: '',
        attachment: [],
        attachmentId: [],
        attachmentName: [],
        templateAttachmentId: [],
        templateAttachmentName: [],
        entryBoxId: [],
        seminarId: [],
        homepageTitle1: '',
        homepageTitle2: '',
        homepageTitle3: '',
        homepageUrl1: '',
        homepageUrl2: '',
        homepageUrl3: '',
        barcodeDisplay: 0,
        sendTimer: 0,
        sendPlanTime: '',
        notifyNotification: 0,
        notifyMailAddress: '',
        manipulationSelection: 0,
        interviewScheduleAdjustmentId: '0',
        interviewScheduleAdjustmentStatus: '0',
        interviewDurationId: '0',
        interviewDurationTime: 0,
        interviewDetail: '',
        interviewScheduleIdN: '',
        interviewStartTimeN: [],
        interviewLocationSettingId: [],
        interviewStaffName: [],
        interviewScheduleStatus: [],
        manipulationKubun: '',
        contractMediaId: state.initResult.contractMedia.map(item => String(item.contractMediaId)),
        title: state.screenDisplayItems.sendTimeInput.title,
        interviewSchedule: state.screenDisplayItems.sendTimeInput.interviewSchedule,
        messageInput: state.screenDisplayItems.sendTimeInput.messageInput,
        sendTargetConfirmation: {
          destinationSelection: apiConfig.sendTargetConfirmationCon.destinationSelection,
          sendTarget: state.screenDisplayItems.sendTargetConfirmation.sendTarget,
          searchCriteriaName: apiConfig.sendTargetConfirmationCon.searchCriteriaName,
          searchCriteria: apiConfig.sendTargetConfirmationCon.searchCriteria,
          searchCriteriaInfo: apiConfig.sendTargetConfirmationCon.searchCriteriaInfo,
          mySearchCondition: apiConfig.sendTargetConfirmationCon.mySearchCondition,
          mySearch: apiConfig.sendTargetConfirmationCon.conversionMySearch,
          // phase2 start
          sendToNonMember: state.screenDisplayItems.sendTargetConfirmation.sendToNonMember,
          // phase2 end
        },
        sendTimeInput: state.screenDisplayItems.sendTimeInput,
        recruitmentManagementFlag: [],
        seminarSchedule: [],
        seminar: [],
        signature: [],
        jobSeekerInterviewDesiredSchedule: state.initResult.jobSeekerInterviewDesiredSchedule,
        jobSeekerInterviewNotPossibleSchedule: state.initResult.jobSeekerInterviewNotPossibleSchedule,
        sendReplyImpossibleTime: state.initResult.sendReplyImpossibleTime,
        sendToNonMember: 0,
        interviewStartTime: '',
        interviewScheduleAdjustmentMax: [],
        searchCriteriaJson: '',
        mySearchCondition: null,
        mySearchCriteriaNameJson: [],
        mySearchCriteriaJson: [],
        selectionManagementSysVersionNumber: null,
        progressManagementSysVersionNumber: null,
        notAdoptedNotificationType: null,
        interviewScheduleIdWeb: 0,
        interviewWebUrlSendFlag: '0',
        senderMessageId: [],
      }

      const registerValue: viewConfig.RegisterValue = {
        messageType: [],
        messageTemplateSettingId: '',
        destinationId: [],
        selectionManagementId: [],
        memberType: [],
        destination: 0,
        senderCompanyName: '',
        senderMailAddress: '',
        allowReply: 0,
        subject: '',
        body: '',
        attachment: [],
        attachmentId: [],
        templateAttachmentId: [],
        templateAttachmentName: [],
        mynaviAttachedOption: '',
        homePageTitle1: null,
        homePageTitle2: null,
        homePageTitle3: null,
        homePageUrl1: null,
        homePageUrl2: null,
        homePageUrl3: null,
        barcodeDisplayFlag: 0,
        sendTimer: 0,
        sendPlanTime: null,
        notifyNotification: 0,
        notifyMailAddress: '',
        requiredTimeId: '',
        interviewContents: '',
        interviewDateN: [],
        interviewLocationIdN: [],
        chargeN: [],
        fileManagementId: [],
        fileManagementName: [],
        entryId: [],
        interviewScheduleAdjustmentId: 0,
        sysVersionNumber: '',
        messageSendId: '',
        replyFromMessageSendId: '',
        interviewScheduleAdjustmentStatus: '',
        interviewScheduleAdjustmentSelection: '',
        adjustmentInterviewScheduleAdjustmentId: '',
        interviewDurationId: null,
        interviewDetail: '',
        interviewScheduleId: [],
        interviewStartTime: [],
        interviewStaffName: [],
        interviewScheduleStatus: [],
        adjustmentInterviewDurationId: null,
        adjustmentInterviewDetail: '',
        adjustmentInterviewScheduleId: [],
        adjustmentInterviewStartTime: [],
        adjustmentInterviewStaffName: [],
        adjustmentInterviewScheduleStatus: [],
        newInterviewScheduleStatus: [],
        adjustmentInterviewLocationId: [],
        manipulationKubun: '',
        seminarId: [],
        entryBoxId: [],
        sendTimeFlag: '',
        replyPermitFlag: '',
        replyNecessaryFlag: '',
        replyLimitTime: '',
        destinationDivision: '',
        manualRegistrationFlag: [],
        decisionDivision: [],
        progressStatusSettingId: [],
        notSend: [],
        notificationMailAddress: '',
        selectionFlowSettingId: [],
        title: viewConfig.title,
        interviewSchedule: viewConfig.interviewSchedule,
        messageInput: viewConfig.messageInput,
        sendTargetConfirmation: viewConfig.sendTargetConfirmation,
        sendTimeInput: viewConfig.sendTimeInput,
        recruitmentManagementFlag: [],
        seminarSchedule: [],
        seminar: [],
        signature: [],
        successfulCountMessage: 0,
        successfulCountEmail: 0,
        failedCount: 0,
        fullName: '',
        //phase2 start
        sendToNonMember: 0,
        //phase2 end
        jobSeekerId:[],
        interviewScheduleAdjustmentMax: [],
        searchCriteriaJson: '',
        mySearchCondition: null,
        mySearchCriteriaNameJson: [],
        mySearchCriteriaJson: [],
        selectionManagementSysVersionNumber: null,
        progressManagementSysVersionNumber: null,
        notAdoptedNotificationType: null,
        interviewScheduleIdWeb: 0,
        interviewWebEnableFlag: '',
        interviewWebUrlSendFlag: '',
        sysVersionNumberWeb: '',
        senderMessageId: [],
      }

      registerValue.replyFromMessageSendId = state.screenDisplayItems.hiddenItems.replyFromMessageSendId !== '' ? state.screenDisplayItems.hiddenItems.replyFromMessageSendId : null

      confirmRequest.destination = Number(destinationSelection) 
      registerValue.destination = Number(destinationSelection)
      registerValue.sendToNonMember = state.registerValue.sendToNonMember

      if (state.initResult.messageType) {
        registerValue.messageType = state.initResult.messageType.map(
          messageType => messageType.messageType
        )
      }
      confirmRequest.messageTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId
      registerValue.messageTemplateSettingId = state.screenDisplayItems.title.selectedTemplateSettingId && state.screenDisplayItems.title.selectedTemplateSettingId !== '' ? state.screenDisplayItems.title.selectedTemplateSettingId : null

      if (state.screenDisplayItems.sendTargetConfirmation.sendTarget) {
        const destinationId: string[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.entryId
        )
        confirmRequest.destinationId = destinationId
        registerValue.destinationId = destinationId

        registerValue.entryId = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => Number(sendTarget.entryId)
        )
        const selectionManagementId: string[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.selectionManagementId
        )
        confirmRequest.selectionManagementId = selectionManagementId
        registerValue.selectionManagementId = selectionManagementId

        const memberType: string[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(item => item.mynaviMemberInfo)
        registerValue.memberType = memberType

        const selectionFlowSettingId: number[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.selectionFlowSettingId
        )
        confirmRequest.selectionFlowSettingId = selectionFlowSettingId.map(item => String(item))
        registerValue.selectionFlowSettingId = selectionFlowSettingId
      }
      const destination = state.screenDisplayItems.sendTargetConfirmation.destinationSelection
      confirmRequest.destination = Number(destination)
      registerValue.destinationDivision = destination
      
      if (state.screenDisplayItems.sendTargetConfirmation.sendTarget) {
        const manualRegistrationFlag = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.manualRegistrationFlag
        )
        confirmRequest.manualRegistrationFlag = manualRegistrationFlag
        registerValue.manualRegistrationFlag = manualRegistrationFlag
      }
      if (state.screenDisplayItems.sendTargetConfirmation.sendTarget) {
        const decisionDivision: string[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.decisionDivision
        )
        confirmRequest.decisionDivision = decisionDivision
        registerValue.decisionDivision = decisionDivision
      }
      if (state.screenDisplayItems.sendTargetConfirmation.sendTarget) {
        confirmRequest.progressStatusSettingId = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.progressStatusSettingId
        )
        registerValue.progressStatusSettingId = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => Number(sendTarget.progressStatusSettingId)
        )
      }
      if (state.screenDisplayItems.sendTargetConfirmation.sendTarget) {
        registerValue.notSend = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.notSend
        )  
      }
      if (state.screenDisplayItems.sendTargetConfirmation.sendTarget) {
        const senderMessageId: (number|null)[] = state.screenDisplayItems.sendTargetConfirmation.sendTarget.map(
          sendTarget => sendTarget.senderMessageId
        )
        confirmRequest.senderMessageId = senderMessageId
        registerValue.senderMessageId = senderMessageId
      }

      const subject = state.screenDisplayItems.messageInput.subject
      confirmRequest.subject = subject
      registerValue.subject = subject

      const body = state.screenDisplayItems.messageInput.body
      confirmRequest.text = body
      registerValue.body = body

      let senderCompanyName = ''
      if (shouldShowMessageInput.senderCompanyName) {
        senderCompanyName = state.screenDisplayItems.messageInput.senderCompanyName
      }
      confirmRequest.senderCompanyName = senderCompanyName
      registerValue.senderCompanyName = senderCompanyName

      let senderMailAddress = ''
      if (shouldShowMessageInput.senderMailAddress) {
        senderMailAddress = state.screenDisplayItems.messageInput.senderMailAddress
      }
      confirmRequest.senderMailAddress = senderMailAddress
      registerValue.senderMailAddress = senderMailAddress

      let allowReply: string = magiContants.REPLY_PERMIT_FLAG_DISALLOW
      if (shouldShowMessageInput.replyAllow) {
        allowReply = state.screenDisplayItems.messageInput.replyAllow
      }
      confirmRequest.allowReply = Number(allowReply)
      registerValue.allowReply = Number(allowReply)
      registerValue.replyPermitFlag = allowReply
      
      let repliesNecessary: string = magiContants.REPLY_NECESSARY_FLAG_DISALLOW
      if (shouldShowMessageInput.repliesNecessary) {
        repliesNecessary = state.screenDisplayItems.messageInput.repliesNecessary
      }
      confirmRequest.repliesNecessary = Number(repliesNecessary)
      registerValue.replyNecessaryFlag = repliesNecessary
      
      let repliesLimit: string|null = null
      if (confirmRequest.repliesNecessary == 1 && shouldShowMessageInput.repliesLimit) {
        repliesLimit = state.screenDisplayItems.messageInput.repliesLimit
      }
      confirmRequest.repliesLimit = repliesLimit
      registerValue.replyLimitTime = repliesLimit

      if (shouldShowMessageInput.attachment) {
        const attachmentList = state.screenDisplayItems.messageInput.attachment
        if (attachmentList) {
          let attachmentIdList: Array<string|null> = []
          let attachmentNameList: Array<string|null> = []
          let fileNameList: string[] = []
          attachmentList.map(attachment => {
            if (attachment.attachmentId && attachment.attachmentName) {
              attachmentIdList = [...attachmentIdList, attachment.attachmentId]
              attachmentNameList = [...attachmentNameList, attachment.attachmentName]
              fileNameList = [...fileNameList, attachment.attachmentName]
            }
          })
          confirmRequest.attachmentName = fileNameList
          registerValue.attachmentId = attachmentIdList
          registerValue.attachment = attachmentNameList
        }
        registerValue.templateAttachmentId = []
        registerValue.templateAttachmentName = []
        if (registerValue.messageTemplateSettingId && registerValue.messageTemplateSettingId != '') {
          if (attachmentList && attachmentList.length > 0) {
            registerValue.templateAttachmentId = attachmentList.map(item => item.attachmentId ? item.attachmentId : '')
            registerValue.templateAttachmentName = attachmentList.map(item => item.attachmentName ? item.attachmentName : '')
          }
        }
      }
      registerValue.mynaviAttachedOption = ''

      let homePageTitle1: string | null = null
      let homePageTitle2: string | null = null
      let homePageTitle3: string | null = null
      let homePageUrl1: string | null = null
      let homePageUrl2: string | null = null
      let homePageUrl3: string | null = null
      if (shouldShowMessageInput.homePage) {
        homePageTitle1 =
          state.screenDisplayItems.messageInput.homePage[0] &&
          state.screenDisplayItems.messageInput.homePage[0].homePageTitle !== ''
            ? state.screenDisplayItems.messageInput.homePage[0].homePageTitle
            : null
        homePageTitle2 =
          state.screenDisplayItems.messageInput.homePage[1] &&
          state.screenDisplayItems.messageInput.homePage[1].homePageTitle !== ''
            ? state.screenDisplayItems.messageInput.homePage[1].homePageTitle
            : null
        homePageTitle3 =
          state.screenDisplayItems.messageInput.homePage[2] &&
          state.screenDisplayItems.messageInput.homePage[2].homePageTitle !== ''
            ? state.screenDisplayItems.messageInput.homePage[2].homePageTitle
            : null
        homePageUrl1 =
          state.screenDisplayItems.messageInput.homePage[0] &&
          state.screenDisplayItems.messageInput.homePage[0].homePageUrl !== ''
            ? state.screenDisplayItems.messageInput.homePage[0].homePageUrl
            : null
        homePageUrl2 =
          state.screenDisplayItems.messageInput.homePage[1] &&
          state.screenDisplayItems.messageInput.homePage[1].homePageUrl
            ? state.screenDisplayItems.messageInput.homePage[1].homePageUrl
            : null
        homePageUrl3 =
          state.screenDisplayItems.messageInput.homePage[2] &&
          state.screenDisplayItems.messageInput.homePage[2].homePageUrl
            ? state.screenDisplayItems.messageInput.homePage[2].homePageUrl
            : null
      }
      confirmRequest.homepageTitle1 = homePageTitle1
      confirmRequest.homepageTitle2 = homePageTitle2
      confirmRequest.homepageTitle3 = homePageTitle3
      confirmRequest.homepageUrl1 = homePageUrl1
      confirmRequest.homepageUrl2 = homePageUrl2
      confirmRequest.homepageUrl3 = homePageUrl3
      registerValue.homePageTitle1 = homePageTitle1
      registerValue.homePageTitle2 = homePageTitle2
      registerValue.homePageTitle3 = homePageTitle3
      registerValue.homePageUrl1 = homePageUrl1
      registerValue.homePageUrl2 = homePageUrl2
      registerValue.homePageUrl3 = homePageUrl3
      
      let barcodeDisplayFlag: string = magiContants.BARCODE_DISPLAY_FLAG_NOT_SHOW
      if (shouldShowMessageInput.barcodeDisplay) {
        barcodeDisplayFlag = state.screenDisplayItems.messageInput.barcodeDisplay
      }
      confirmRequest.barcodeDisplay = Number(barcodeDisplayFlag)
      registerValue.barcodeDisplayFlag = Number(barcodeDisplayFlag)

      const sendTimer = Number(state.screenDisplayItems.sendTimeInput.transmission)
      confirmRequest.sendTimer = sendTimer
      registerValue.sendTimer = sendTimer
      registerValue.sendTimeFlag =
        state.screenDisplayItems.sendTimeInput.transmission

      const sendPlanTime = dateTimeToString(state.screenDisplayItems.sendTimeInput.sendPlanTime)
      confirmRequest.sendPlanTime = sendPlanTime ? sendPlanTime : ''
      registerValue.sendPlanTime = sendPlanTime

      if (recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && destinationSelection === magiContants.DESTINATION_DIVISION_MYNAVI_ONLY) {
        const notifyNotification = state.screenDisplayItems.notification.notificationSelect
        confirmRequest.notifyNotification = Number(notifyNotification)
        registerValue.notifyNotification = Number(notifyNotification)

        const notifyMailAddress = state.screenDisplayItems.notification.notificationMailAddress
        confirmRequest.notifyMailAddress = notifyMailAddress
        registerValue.notifyMailAddress = notifyMailAddress
        registerValue.notificationMailAddress = notifyMailAddress
      }

      if (shouldShowMessageInput.entryBox && shouldShowMessageInput.seminar) {
        if (state.screenDisplayItems.messageInput.entryBoxId) {
          confirmRequest.entryBoxId = state.screenDisplayItems.messageInput.entryBoxId.map(
            entryBoxId => entryBoxId
          )
          registerValue.entryBoxId = state.screenDisplayItems.messageInput.entryBoxId.map(
            entryBoxId => Number(entryBoxId)
          )
        }
        if (state.screenDisplayItems.messageInput.seminarId) {
          confirmRequest.seminarId = state.screenDisplayItems.messageInput.seminarId.map(
            seminarId => seminarId
          )
          registerValue.seminarId = state.screenDisplayItems.messageInput.seminarId.map(
            seminarId => Number(seminarId)
          )
        }
      }

      let fileManagementIdList: string[] = []
      let attachmentNameList: string[] = []
      state.screenDisplayItems.messageInput.attachment.map(item => {
        if (item.attachmentId && item.attachmentId !== '' && item.attachmentName && item.attachmentName !== '') {
          fileManagementIdList = [...fileManagementIdList, item.attachmentId]
          attachmentNameList = [...attachmentNameList, item.attachmentName]
        }
      })
      confirmRequest.attachmentName = attachmentNameList
      confirmRequest.attachmentId = fileManagementIdList
      registerValue.fileManagementId = fileManagementIdList
      registerValue.fileManagementName = attachmentNameList
      
      confirmRequest.draftMessageSendId = state.messageInitRequest.draftMessageSendId && state.messageInitRequest.draftMessageSendId !== 0 ? String(state.messageInitRequest.draftMessageSendId) : null
      registerValue.messageSendId = state.messageInitRequest.draftMessageSendId && state.messageInitRequest.draftMessageSendId !== 0 ? String(state.messageInitRequest.draftMessageSendId) : ''

      registerValue.sysVersionNumber = state.messageInitRequest.sysVersionNumber ? String(state.messageInitRequest.sysVersionNumber) : ''

      let newInterviewScheduleList = state.screenDisplayItems.interviewSchedule.candidateDate
      if (newInterviewScheduleList && newInterviewScheduleList.length > 0) {
        newInterviewScheduleList.sort((a, b) => {
          if (isEmpty(a.interviewStartTime) && isEmpty(b.interviewStartTime)) {
            return 0
          }
          if (isEmpty(a.interviewStartTime)) {
            return 1
          }
          if (isEmpty(b.interviewStartTime)) {
            return -1
          }
          if (a.interviewStartTime < b.interviewStartTime) {
            return -1
          }
          if (a.interviewStartTime > b.interviewStartTime) {
            return 1
          }
          return 0
        })
      }

      const action = state.screenDisplayItems.interviewSchedule.action
      if (((action && action !== '') && (destinationSelection && destinationSelection == magiContants.DESTINATION_DIVISION_MYNAVI_ONLY)) || (state.initResult.interviewWebUrlSendFlag == magiContants.INTERVIEW_WEB_URL_SEND_FLAG)) {

        registerValue.adjustmentInterviewScheduleId = state.screenDisplayItems.hiddenItems.adjustmentInterviewScheduleId
        registerValue.interviewSchedule = JSON.parse(JSON.stringify(state.screenDisplayItems.interviewSchedule))
        registerValue.interviewSchedule.candidateDate = newInterviewScheduleList
        registerValue.interviewScheduleAdjustmentId = state.initResult.interviewSchedule && state.initResult.interviewSchedule.length > 0 ? state.initResult.interviewSchedule[0].interviewScheduleAdjustmentId : 0
        registerValue.interviewScheduleAdjustmentStatus = state.initResult.interviewScheduleAdjustmentStatus !== '' ? state.initResult.interviewScheduleAdjustmentStatus : 'offer'
        registerValue.interviewDetail = state.initResult.interviewDetail
        registerValue.sysVersionNumber = String(state.screenDisplayItems.interviewSchedule.sysVersionNumber)

        const manipulationKubun = state.screenDisplayItems.interviewSchedule.action
        confirmRequest.manipulationKubun = manipulationKubun && manipulationKubun !== '' ? manipulationKubun : ''
        registerValue.manipulationKubun = manipulationKubun && manipulationKubun !== '' ? manipulationKubun : '1'

        const selectedDurationId = state.screenDisplayItems.interviewSchedule.requiredTimeId
        const interviewDurationId = !isEmpty(selectedDurationId) ? selectedDurationId : String(state.initResult.interviewDurationId)
        confirmRequest.interviewDurationId = interviewDurationId
        registerValue.interviewDurationId = interviewDurationId
        registerValue.requiredTimeId = interviewDurationId

        if (state.initResult.interviewSchedule) {
          const fixId = state.screenDisplayItems.hiddenItems.adjustmentInterviewScheduleId[0]
          let statusList: string[] = []
          let newStatusList: string[] = []
          let idList: number[] = []
          let startTimeList: Array<string|null> = []
          let locationIdList: number[] = []
          let staffNameList: string[] = []
          state.initResult.interviewSchedule.filter(interviewSchedule => interviewSchedule.interviewScheduleDraftFlag === false).map(item => {
            let shouldAdd = false
            let status = item.interviewScheduleStatus
            let newStatus = ''
            switch (action) {
              case magiContants.ACTION_FIX_INTERVIEW:
                if (item.interviewScheduleId === fixId) {
                  newStatus = 'fixed'
                  shouldAdd = true
                }
                break
              case magiContants.ACTION_CHANGE_INTERVIEW:
                if (item.interviewScheduleId !== fixId) {
                  if (item.interviewScheduleStatus === 'client_cancel' || item.interviewScheduleStatus === 'cancel') {
                    break
                  }
                }
                if (item.interviewScheduleId === fixId) {
                  newStatus = 'fixed'
                  shouldAdd = true
                }
                break
              case magiContants.ACTION_WITHDRAW_INTERVIEW:
                if (item.interviewScheduleId !== fixId) {
                  break
                }
                newStatus = 'client_cancel'
                shouldAdd = true
                break
              case magiContants.ACTION_READJUST_INTERVIEW:
                if (item.interviewScheduleStatus === 'client_cancel' || item.interviewScheduleStatus === 'cancel') {
                  break
                }
                newStatus = 'client_cancel'
                shouldAdd = true
                break
              case magiContants.ACTION_CHANGE_POSSIBLE:
                newStatus = 'client_cancel'
                shouldAdd = true
                break
            }
            if (shouldAdd || state.initResult.interviewWebUrlSendFlag == magiContants.INTERVIEW_WEB_URL_SEND_FLAG) {
              idList = [...idList, item.interviewScheduleId]
              startTimeList = [...startTimeList, momentTz(item.interviewStartTime).format('YYYY/MM/DD HH:mm')]
              locationIdList = [...locationIdList, item.interviewLocationSettingId]
              staffNameList = [...staffNameList, item.interviewStaffName]
              statusList = [...statusList, status]
              newStatusList = [...newStatusList, newStatus]
            }
          })
          registerValue.adjustmentInterviewScheduleId = idList
          registerValue.adjustmentInterviewDetail = state.initResult.interviewDetail
          registerValue.adjustmentInterviewDurationId = interviewDurationId
          registerValue.adjustmentInterviewStartTime = startTimeList
          registerValue.adjustmentInterviewLocationId = locationIdList
          registerValue.adjustmentInterviewStaffName = staffNameList
          registerValue.adjustmentInterviewScheduleStatus = statusList
          registerValue.newInterviewScheduleStatus = newStatusList
          // 面接場所が削除された場合、登録時点の情報を面接場所リストに追加
          let interviewLocationList = registerValue.interviewSchedule.interviewLocation
          state.initResult.interviewSchedule.forEach(item1 => {
            let pushFlag = interviewLocationList.some(item2 => {
              return item1.interviewLocationSettingId === item2.id
            })
            if (!pushFlag) {
              let interviewLocationTemp : viewConfig.InterviewLocation = viewConfig.interviewLocation
              interviewLocationTemp.id = item1.interviewLocationSettingId
              interviewLocationTemp.name = item1.interviewLocationName ? item1.interviewLocationName : ''
              interviewLocationTemp.address = item1.interviewLocationAddress ? item1.interviewLocationAddress : ''
              interviewLocationTemp.mapUrl = item1.mapUrl
              interviewLocationTemp.type = '1'
              interviewLocationTemp.deleteFlag = true
              interviewLocationList = [...interviewLocationList, JSON.parse(JSON.stringify(interviewLocationTemp))]
            }
          })
          registerValue.interviewSchedule.interviewLocation = interviewLocationList
        }

        let interviewDateList: Array<string|null> = []
        let interviewLocationIdList: Array<number|null> = []
        let interviewStaffNameList: Array<string> = []
        if (state.screenDisplayItems.interviewSchedule.action === magiContants.ACTION_FIX_INTERVIEW) {
          const interviewSchedule = state.initResult.interviewSchedule
          interviewSchedule.map(item => {
            interviewDateList = [...interviewDateList, item.interviewStartTime]
            interviewLocationIdList = [...interviewLocationIdList, item.interviewLocationSettingId]
            interviewStaffNameList = [...interviewStaffNameList, item.interviewStaffName]
          })
        }
      }

      registerValue.interviewScheduleAdjustmentSelection = '0'
      const manipulationSelection = state.screenDisplayItems.interviewSchedule.manipulationSelection
      if (((manipulationSelection && manipulationSelection !== magiContants.SELECTION_NUMBER_NOT_PRESENT) && (destinationSelection && destinationSelection == magiContants.DESTINATION_DIVISION_MYNAVI_ONLY)) || (state.initResult.interviewWebUrlSendFlag == magiContants.INTERVIEW_WEB_URL_SEND_FLAG)) {

        registerValue.interviewScheduleAdjustmentId = state.initResult.interviewSchedule && state.initResult.interviewSchedule.length > 0 ? state.initResult.interviewSchedule[0].interviewScheduleAdjustmentId : 0
        registerValue.adjustmentInterviewScheduleAdjustmentId = ''

        confirmRequest.manipulationSelection = manipulationSelection
        registerValue.interviewScheduleAdjustmentSelection = String(manipulationSelection)

        const manipulationKubun = state.screenDisplayItems.interviewSchedule.action
        confirmRequest.manipulationKubun = manipulationKubun && manipulationKubun !== '' ? manipulationKubun : ''
        registerValue.manipulationKubun = manipulationKubun && manipulationKubun !== '' ? manipulationKubun : '1'

        const selectedDurationId = state.screenDisplayItems.interviewSchedule.requiredTimeId
        const interviewDurationId = !isEmpty(selectedDurationId) ? selectedDurationId : String(state.initResult.interviewDurationId)
        confirmRequest.interviewDurationId = interviewDurationId
        registerValue.requiredTimeId = interviewDurationId
        const currentDuration = state.initResult.interviewDurationSelect.find(item => String(item.interviewDurationId) === interviewDurationId)
        confirmRequest.interviewDurationTime = currentDuration && currentDuration.actualTime ? currentDuration.actualTime : 0

        let interviewScheduleStatusList: string[] = []
        if (manipulationSelection != magiContants.SELECTION_NUMBER_PRESENT && state.initResult.interviewSchedule) {
          interviewScheduleStatusList = state.initResult.interviewSchedule.map(item => item.interviewScheduleStatus)
        }
        registerValue.interviewScheduleAdjustmentStatus = state.initResult.interviewScheduleAdjustmentStatus !== '' ? state.initResult.interviewScheduleAdjustmentStatus : 'offer'

        let newInterviewScheduleStatusList: string[] = []
        let newInterviewDateList: Array<string|null> = []
        let newInterviewLocationIdList: Array<number|null> = []
        let newInterviewStaffNameList: string[] = []
        if (newInterviewScheduleList && newInterviewScheduleList.length > 0) {
          newInterviewScheduleList.map(item => {
            const newInterviewScheduleStatus: string = item.interviewStartTime !== ''
              ? manipulationSelection === magiContants.SELECTION_NUMBER_FIX || manipulationSelection === magiContants.SELECTION_NUMBER_SEND
                ? 'fixed'
                : 'offer'
              : ''
            let newInterviewDate: string = item.interviewStartTime !== '' ? item.interviewStartTime : ''
            let newLocationId: string = item.interviewStartTime !== '' ? item.interviewLocationId : ''
            let newStaffName: string = item.interviewStartTime !== '' ? item.interviewStaffName : ''
            newInterviewScheduleStatusList = [...newInterviewScheduleStatusList, JSON.parse(JSON.stringify(newInterviewScheduleStatus))]
            newInterviewDateList = [...newInterviewDateList, JSON.parse(JSON.stringify(newInterviewDate))]
            newInterviewLocationIdList = [...newInterviewLocationIdList, JSON.parse(JSON.stringify(newLocationId))]
            newInterviewStaffNameList = [...newInterviewStaffNameList, JSON.parse(JSON.stringify(newStaffName))]
          })          
        }

        registerValue.sysVersionNumber = String(state.screenDisplayItems.interviewSchedule.sysVersionNumber)

        registerValue.interviewDetail =
          state.screenDisplayItems.interviewSchedule.interviewDetail
        registerValue.interviewDurationId = state.screenDisplayItems.interviewSchedule.requiredTimeId
        registerValue.interviewContents =
          state.screenDisplayItems.interviewSchedule.interviewDetail

        let interviewDateList: Array<string|null> = []
        let interviewLocationIdList: Array<number|null> = []
        let interviewStaffNameList: Array<string> = []
        if (newInterviewScheduleList && newInterviewScheduleList.length > 0) {
          let interviewDate = []
          interviewDate = newInterviewScheduleList.map(
            item =>
              item.interviewStartTime && item.interviewStartTime !== ''
                ? item.interviewStartTime
                : null
          )
          interviewDateList = interviewDateList.concat(interviewDate)

          registerValue.interviewStartTime = newInterviewScheduleList.map(
            item =>
              item.interviewStartTime && item.interviewStartTime !== ''
                ? item.interviewStartTime
                : null
          )

          let interviewLocationId: Array<number|null> = []
          interviewLocationId = newInterviewScheduleList.map(
            item =>
              Number(item.interviewLocationId) !== 0
                ? Number(item.interviewLocationId)
                : null
          )
          interviewLocationIdList = interviewLocationIdList.concat(interviewLocationId)

          let interviewStaffName = []
          interviewStaffName = newInterviewScheduleList.map(
            item => item.interviewStaffName
          )
          interviewStaffNameList = interviewStaffNameList.concat(interviewStaffName)
        }
        registerValue.interviewScheduleStatus = registerValue.interviewScheduleStatus.concat(newInterviewScheduleStatusList)
        confirmRequest.interviewScheduleStatus = registerValue.interviewScheduleStatus
        registerValue.interviewDateN = registerValue.interviewDateN.concat(newInterviewDateList)
        registerValue.interviewStartTime = registerValue.interviewDateN
        confirmRequest.interviewStartTimeN = registerValue.interviewDateN
        registerValue.interviewLocationIdN = registerValue.interviewLocationIdN.concat(newInterviewLocationIdList)
        confirmRequest.interviewLocationSettingId = registerValue.interviewLocationIdN.map(item => String(item))
        registerValue.interviewStaffName = registerValue.interviewStaffName.concat(newInterviewStaffNameList)
        registerValue.chargeN = registerValue.interviewStaffName
        confirmRequest.interviewStaffName = registerValue.interviewStaffName
        registerValue.interviewSchedule = JSON.parse(JSON.stringify(state.screenDisplayItems.interviewSchedule))
        // 面接場所が削除された場合、登録時点の情報を面接場所リストに追加
        if (state.initResult.interviewSchedule) {
          let interviewLocationList = registerValue.interviewSchedule.interviewLocation
          state.initResult.interviewSchedule.forEach(item1 => {
            let pushFlag = interviewLocationList.some(item2 => {
              return item1.interviewLocationSettingId === item2.id
            })
            if (!pushFlag) {
              let interviewLocationTemp : viewConfig.InterviewLocation = viewConfig.interviewLocation
              interviewLocationTemp.id = item1.interviewLocationSettingId
              interviewLocationTemp.name = item1.interviewLocationName ? item1.interviewLocationName : ''
              interviewLocationTemp.address = item1.interviewLocationAddress ? item1.interviewLocationAddress : ''
              interviewLocationTemp.mapUrl = item1.mapUrl
              interviewLocationTemp.type = '1'
              interviewLocationTemp.deleteFlag = true
              interviewLocationList = [...interviewLocationList, JSON.parse(JSON.stringify(interviewLocationTemp))]
            }
          })
          registerValue.interviewSchedule.interviewLocation = interviewLocationList
        }
        registerValue.interviewSchedule.candidateDate = newInterviewScheduleList
      }

      const title = state.screenDisplayItems.title
      confirmRequest.title = title
      registerValue.title = title

      const messageInput = state.screenDisplayItems.messageInput
      registerValue.messageInput = messageInput

      const sendTargetConfirmation = state.screenDisplayItems.sendTargetConfirmation
      confirmRequest.sendTargetConfirmation = sendTargetConfirmation
      registerValue.sendTargetConfirmation = sendTargetConfirmation

      const sendTimeInput = state.screenDisplayItems.sendTimeInput
      confirmRequest.sendTimeInput = sendTimeInput
      registerValue.sendTimeInput = sendTimeInput

      const recruitmentManagementFlag = state.screenDisplayItems.recruitmentManagementFlag
      confirmRequest.recruitmentManagementFlag = recruitmentManagementFlag
      registerValue.recruitmentManagementFlag = recruitmentManagementFlag

      const seminarSchedule = state.screenDisplayItems.seminarSchedule
      confirmRequest.seminarSchedule = seminarSchedule
      registerValue.seminarSchedule = seminarSchedule

      const seminar = state.screenDisplayItems.seminar
      confirmRequest.seminar = seminar
      registerValue.seminar = seminar

      const signature = state.screenDisplayItems.signature
      confirmRequest.signature = signature
      registerValue.signature = signature

      const interviewSchedule = state.initResult.interviewSchedule
      if (confirmRequest.manipulationKubun === magiContants.ACTION_FIX_INTERVIEW && interviewSchedule && interviewSchedule.length > 0) {
        interviewSchedule.some(interviewSchedule => {
          if (interviewSchedule.interviewScheduleId === state.screenDisplayItems.hiddenItems.adjustmentInterviewScheduleId[0]) {
            confirmRequest.interviewStartTime = interviewSchedule.interviewStartTime
            return true;
          }
        })
      }

      confirmRequest.interviewScheduleAdjustmentMax = state.initResult.interviewScheduleAdjustmentMax
      registerValue.interviewScheduleAdjustmentMax = state.initResult.interviewScheduleAdjustmentMax

      confirmRequest.searchCriteriaJson = state.initResult.searchCriteriaJson
      registerValue.searchCriteriaJson = state.initResult.searchCriteriaJson

      confirmRequest.mySearchCondition = sendTargetConfirmation.mySearchCondition
      confirmRequest.mySearchCriteriaNameJson = state.initResult.mySearchCriteriaNameJson
      confirmRequest.mySearchCriteriaJson = state.initResult.mySearchCriteriaJson
      registerValue.mySearchCondition = sendTargetConfirmation.mySearchCondition
      registerValue.mySearchCriteriaNameJson = state.initResult.mySearchCriteriaNameJson
      registerValue.mySearchCriteriaJson = state.initResult.mySearchCriteriaJson

      confirmRequest.selectionManagementSysVersionNumber = state.initResult.selectionManagementSysVersionNumber
      registerValue.selectionManagementSysVersionNumber = state.initResult.selectionManagementSysVersionNumber
      confirmRequest.progressManagementSysVersionNumber = state.initResult.progressManagementSysVersionNumber
      registerValue.progressManagementSysVersionNumber = state.initResult.progressManagementSysVersionNumber
      confirmRequest.notAdoptedNotificationType = state.initResult.notAdoptedNotificationType
      registerValue.notAdoptedNotificationType = state.initResult.notAdoptedNotificationType
      // WEB面接対応
      confirmRequest.interviewScheduleIdWeb = state.initResult.interviewScheduleIdWeb
      registerValue.interviewScheduleIdWeb = state.initResult.interviewScheduleIdWeb
      registerValue.interviewWebEnableFlag = state.initResult.interviewWebEnableFlag
      confirmRequest.interviewWebUrlSendFlag = state.initResult.interviewWebUrlSendFlag
      registerValue.interviewWebUrlSendFlag = state.initResult.interviewWebUrlSendFlag
      registerValue.sysVersionNumberWeb = state.initResult.sysVersionNumberWeb

      state.confirmRequest = confirmRequest
      state.registerValue = registerValue

      state.runConfirm = true
      return state
    },
    confirm(
      state,
      action: PayloadAction<{
        formData: MCAZS080MessageConfirmRequest
        files: Array<any | null>
        entryTypeList: string[]
        viewEndTimeList: Date[]
        forbiddenWords: string[]
        entryBoxList: viewConfig.MynaviAttachedOptionEntryBox[]
        seminarList: viewConfig.MynaviAttachedOptionSeminar[]
        attachmentFileRegistrationMax: number
        mynaviAttachedOptionMax: number
        sendToNonMemberCheck: number
        readableFlag: String
      }>
    ) {
      return state
    },
    changeConfirmState(state, action: PayloadAction<boolean>) {
      state.runConfirm = action.payload
      return state
    },
    setMessageTemplateChanged(state, action: PayloadAction<boolean>) {
      state.messageTemplateChanged = action.payload
      return state
    },
    openConfirmModal(state) {
      state.openConfirm = true
      return state
    },
    closeConfirmModal(state) {
      state.openConfirm = false
      return state
    },
    open060ConfirmModal(state) {
      state.open006ConfirmModal = true
      return state
    },
    close060ConfirmModal(state) {
      state.open006ConfirmModal = false
      return state
    },
    setCloseWindow(state, action: PayloadAction<boolean>) {
      state.hasCloseWindowByDraft = action.payload
      return state
    },
    setIsDraft(state, action: PayloadAction<boolean>) {
      state.isDraft = action.payload
      return state
    },
    setSubjectText(state, action: PayloadAction<string>) {
      state.subjectText = action.payload
      return state
    },
    setIsOnlyNonMemberReducer(state, action: PayloadAction<boolean>) {
      state.isOnlyNonMember = action.payload
      return state
    },
    setIsOnlyAnonymousReducer(state, action: PayloadAction<boolean>) {
      state.isOnlyAnonymous = action.payload
      return state
    },
    csvDownload(state, action: PayloadAction<MCAZS080MessageCsvDownloadRequest>) {
      return state
    },
    openDetailDialog(state, action: PayloadAction<viewConfig.SearchCriteriaInfo>) {
      return state
    },
  },
})

export const {
  getInit,
  setInit,
  setCode,
  setRecruitmentManagementDivision,
  setScreenDisplayItems,
  getPreview,
  setPreview,
  inputDestinationSelection,
  inputSenderCompanyName,
  inputSenderMailAddress,
  inputSubject,
  inputBody,
  changeTargetInput,
  inputAttachment,
  deleteAttachment,
  addAttachment,
  inputHomePageTitle,
  inputHomePageUrl,
  deleteHomePage,
  addHomePage,
  checkEntryBox,
  checkSeminar,
  changeReplyAllow,
  changeReplyNecessaryFlag,
  changeLimitTime,
  changeBarcode,
  changeTransmission,
  inputSendTimer,
  inputManipulationSelection,
  inputInterviewDetail,
  inputInterviewDate,
  inputInterviewDuration,
  inputInterviewStaff,
  inputNotificationMailAddress,
  inputNotificationSelect,
  copyCandidate,
  updateCandidate,
  clearCandidate,
  setEmptyTemplateSettingId,
  selectTemplate,
  deleteSendTarget,
  addInterviewLocation,
  setDraftReqeust,
  saveDraft,
  setConfirmRequest,
  confirm,
  changeConfirmState,
  openConfirmModal,
  closeConfirmModal,
  open060ConfirmModal,
  close060ConfirmModal,
  setCloseWindow,
  setIsDraft,
  setSubjectText,
  setIsOnlyNonMemberReducer,
  setIsOnlyAnonymousReducer,
  inputSendToNonMember,
  csvDownload,
  openDetailDialog,
  getNotAdoptedNotification,
  setMessageTemplateChanged,
} = messageSendSlice.actions
export default messageSendSlice.reducer
