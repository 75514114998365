/** 画面表示項目 */
export interface ScreenDisplayItems {
  messageInput: MessageInput
  sendTargetConfirmation: SendTargetConfirmation
  sendTimeInput: SendTimeInput
  signature: Signature[]
}

/** 送信対象確認エリア */
export interface SendTargetConfirmation {
  destinationSelection: string
  sendTarget: SendTarget[]
  mcbMessageDestination: McbSendTarget[]
  searchCriteriaName: string
  searchCriteria: string
}

/** 送信対象 */
export interface SendTarget {
  entryId: string
  fullName: string
  mailAddress: string
  // #MCB1.5次開発 #9168 START
  jobSeekerIdForDisplay: string
  // #MCB1.5次開発 #9168 END
  mynaviMemberInfo: string
  mcbUseStatusName: string
  schoolName: string
  facultyName: string
  subjectName: string
  applicationDate: string
  viewEndTime: Date
  // #MCB1.5次開発 #8997 START
  readableFlag: string
  // #MCB1.5次開発 #8997 END
}

/** 送信対象 */
export interface McbSendTarget {
  entryId: string
  selectionManagementId: string
  jobSeekerId: string
  mycareercd: string
  mcbMemberId: string
  mcbSubmissionRequestId: string
  jobSeekerByCompanyId: string
  submissionRequestId: string
  coordinationFormerJobSeekerIdentificationKey: string
  notSend: boolean
}

/** メッセージ入力エリア */
export interface MessageInput {
  submissionsSelectId: string
  submissionsNameForCompany: string
  submissionsNameForStudent: string
  submissionsDetails: string
  senderCompanyName: string
  senderMailAddress: string
  requestSubject: string
  requestText: string
  targetInput: string
  previewSubject: string
  previewBody: string
  sysVersionNumber: string // sysバージョン番号
}

/** 送信日時入力エリア */
export interface SendTimeInput {
  transmission: string
}

export interface Signature {
  registrationName: string
  signSettingId: string
  signature: string
}

export interface RegisterValue {
  destinationId: string[]
  submissionsSelectId: string
  submissionsNameForCompany: string
  submissionsNameForStudent: string
  submissionsDetails: string
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  sysVersionNumber: string
  messageSendId: string
  messageInput: MessageInput
  sendTargetConfirmation: SendTargetConfirmation
  sendTimeInput: SendTimeInput
  searchCriteriaJson: string
}
export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string // 検索条件名
  searchCriteria: string // 検索条件
  sysVersionNumber: number // sysバージョン番号
}

const messageInput: MessageInput = {
  submissionsSelectId: '',
  submissionsNameForCompany: '',
  submissionsNameForStudent: '',
  submissionsDetails: '',
  senderCompanyName: '',
  senderMailAddress: '',
  requestSubject: '',
  requestText: '',
  targetInput: '',
  previewSubject: '',
  previewBody: '',
  sysVersionNumber: '',
}

const sendTimeInput: SendTimeInput = {
  transmission: '0',
}

const sendTarget: SendTarget = {
  entryId: '',
  fullName: '',
  mailAddress: '',
  // #MCB1.5次開発 #9168 START
  jobSeekerIdForDisplay: '',
  // #MCB1.5次開発 #9168 END
  mynaviMemberInfo: '',
  schoolName: '',
  facultyName: '',
  subjectName: '',
  applicationDate: '',
  mcbUseStatusName: '',
  viewEndTime: new Date(),
  // #MCB1.5次開発 #8997 START
  readableFlag: '',
  // #MCB1.5次開発 #8997 END
}
const sendTargetConfirmation: SendTargetConfirmation = {
  destinationSelection: '3',
  sendTarget: [],
  mcbMessageDestination: [],
  searchCriteriaName: '',
  searchCriteria: '',
}

const signature: Signature[] = []

const registerValue: RegisterValue = {
  destinationId: [],
  submissionsSelectId:  '',
  submissionsNameForCompany:  '',
  submissionsNameForStudent:  '',
  submissionsDetails:  '',
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  sysVersionNumber: '',
  messageSendId: '',
  messageInput: messageInput,
  sendTargetConfirmation: sendTargetConfirmation,
  sendTimeInput: sendTimeInput,
  searchCriteriaJson: '',
}

export {
  messageInput,
  sendTarget,
  sendTargetConfirmation,
  sendTimeInput,
  signature,
  registerValue,
}
