import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { removeToken } from 'utils/auth'
import { replaceToOriginUrl } from 'utils/misc'
import { magiContants as contants } from 'utils/contants'
import { MCAAS010TargetYear } from 'types/MCAAS010/MCAAS010TargetYear'
import { MCAAS020NewsRequest } from 'types/MCAAS020/MCAAS020NewsRequest'
import { MCAAS010TargetService } from 'types/MCAAS010/MCAAS010TargetService'

export interface NewsInfo {
  [key: string]: string
  newsId: string,// お知らせID
  category: string,// 分類
  subject: string,// 件名
  publicStartDate: string,// 公開開始日
}

const initialState = () => ({
  companyName: '',//企業名
  fullName: '',//氏名
  previousLoginTime: '',// 前回ログイン日時
  lastLogoutTime: '',// 最終ログアウト日時
  lastLoginTime: '',// 最終ログイン日時
  newApplicantsCount: 0,//新規応募者
  unreadCVSCount: 0,//履歴書の未読数
  unreadMessagesCount: 0,//（新卒/中途）未読のメッセージ数
  waitForScheduleCount: 0,//（新卒/中途）日程確定待ちの件数
  newsCount: 0,//（新卒/中途）公開されたお知らせの件数

  recruitmentManagementDivision: '',//採用管理区分
  companyId: '',//企業ID
  companyAccountId: '',//企業アカウントID
  permissions: [] as string[],//権限
  magiApplicationStatus: '',//MAGI申込状態
  showFlag: false as Boolean,

  newsInfo: [] as NewsInfo[],
  delegateLoginAccountFlag: false as Boolean,// 代行ログインアカウントフラグ

  initFlag: false as Boolean,//初期フラグ

  // 年度対応 start
  targetYear: '', // 選択している対象年度
  targetYearErrorFlag: false as boolean, // 対象年度エラーフラグ
  targetYearDatas: [] as any[], // 対象年度プルダウンデータ
  targetYearForDisplay: '', // 表示用選択している対象年度
  promptForbiddenFlg: false, // 年度切替用離脱チェック禁止フラグ
  // 年度対応 end

  // 転職March #75621 START
  serviceMode: '', // サービスモード
  serviceModeForDisplay: '', // 表示用サービスモード
  marchResponsibleCompanyFlag: '', // March担当企業フラグ
  marchServiceFlag: '', // Marchサービスフラグ
  marchNoDisplayFlag: '0', // March選択肢非表示フラグ
  targetServiceErrorFlag: false as boolean, // サービスモードエラーフラグ
  unreadCVSCountForMarch: 0, // March用未読応募数
  modeInitialSuccessFlag: false, //　モード初期処理成功フラグ
  errorMessageForOKControl: '', // OKコントロール用エラーメッセージ
  // 転職March #9626 START
  marchModeCheckExecuteFlg: true, // March用感知チェック実行フラグ
  // 転職March #9626 END
  // 転職March #75621 END
})

const globalMenuSlice = createSlice({
  name: 'globalMenu',
  initialState: initialState(),
  reducers: {
    //初期表示時
    init(state, action: PayloadAction<{
      recruitmentManagementDivision: string
    }>) {
      return state
    },
    //初期表示後の値付け操作
    initSuccess: (state, action: PayloadAction<{
      companyName: string
      fullName: string
      previousLoginTime: string
      lastLogoutTime: string
      lastLoginTime: string
      newApplicantsCount: number
      unreadCVSCount: number
      unreadMessagesCount: number
      waitForScheduleCount: number
      newsCount: number
      delegateLoginAccountFlag: boolean
    }>) => ({
      ...state,
      ...action.payload,
    }),
    initFlag(state, action: PayloadAction<Boolean>) {
      state.showFlag =  action.payload
      return state
    },
    //ユーザ情報取得
    getUserInfo: state => state,
    //ユーザ情報取得後の値付け操作
    setUserInfo: (
      state,
      action: PayloadAction<{
        companyId: string,
        companyAccountId: string,
        recruitmentManagementDivision?: string,
        permissions: string[],
        magiApplicationStatus: string
      }>
    ) => ({
      ...state,
      ...action.payload,
    }),
    //採用管理区分設定値操作
    setRecruitmentManagementDivision(state, action: PayloadAction<string>) {
      state.recruitmentManagementDivision = action.payload
    },
    //対象の[応募者詳細（就職）]画面を表示時,「応募管理」タブ取得「新卒」
    getNewApplicantsCount(state, action: PayloadAction<{
      lastLogoutTime: string,
      previousLoginTime: string,
      lastLoginTime:string
    }>) {
      return state
    },
    //対象の[応募者詳細（就職）]画面を表示時,「応募管理」タブ取得後の値付け操作「新卒」
    setNewApplicantsCount(state, action: PayloadAction<number>) {
      state.newApplicantsCount = action.payload
    },
    //対象の[応募詳細（転職）]画面を表示時,「応募管理」タブ取得「中途」
    getUnreadCVSCount: state => state,
    //対象の[応募詳細（転職）]画面を表示時,「応募管理」タブ取得後の値付け操作「中途」
    setUnreadCVSCount(state, action: PayloadAction<number>) {
      state.unreadCVSCount = action.payload
    },
    //対象の[メッセージ詳細]画面を表示時,「メッセージ」タブ取得
    getUnreadMessagesCount(state, action: PayloadAction<{
      recruitmentManagementDivision: string
    }>) {
      return state
    },
    //対象の[メッセージ詳細]画面を表示時,「メッセージ」タブ取得後の値付け操作
    setUnreadMessagesCount(state, action: PayloadAction<number>) {
      state.unreadMessagesCount = action.payload
    },
    //対象の面接日を確定時,「面接調整」タブ取得
    getWaitForScheduleCount(state, action: PayloadAction<{
      recruitmentManagementDivision: string
    }>) {
      return state
    },
    //対象の面接日を確定時,「面接調整」タブ取得後の値付け操作
    setWaitForScheduleCount(state, action: PayloadAction<number>) {
      state.waitForScheduleCount = action.payload
    },
    //[お知らせ一覧]画面を表示時,「ベル」アイコン取得後の値付け操作
    setNewsCount(state, action: PayloadAction<number>) {
      state.newsCount = action.payload
    },
    //システムの操作を終了します
    signout(state, action: PayloadAction<{
      companyAccountId: string
    }>) {
      return state
    },
    //システムを終了したら操作します
    signoutSuccess: (state, action: PayloadAction<string>) => {
      removeToken();
      replaceToOriginUrl(action.payload)
      return initialState()
    },
    //お知らせ初期表示
    newsInit(state, action: PayloadAction<{
      //次期9月対応 #62669 start
      previousLoginTime:MCAAS020NewsRequest , openFunction:Function
      //次期9月対応 #62669 end
    }>) {
      return state
    },
    //お知らせ情報取得後の値付け操作
    setNewsInfo(state, action: PayloadAction<NewsInfo[]>) {
      state.newsInfo = action.payload
    },
    //初期フラグ
    setInitFlag(state, action: PayloadAction<Boolean>) {
      state.initFlag =  action.payload
      return state
    },
    // 年度対応 start
    // 対象年度を設定
    setTargetYear(state, action: PayloadAction<string>) {
      state.targetYear = action.payload
      return state
    },
    // 表示用対象年度を設定
    setTargetYearForDisplay(state, action: PayloadAction<string>) {
      state.targetYearForDisplay = action.payload
      return state
    },
    // 別画面で年度切替を検知チェックフラグ
    setTargetYearErrorFlag(state, action: PayloadAction<boolean>) {
      state.targetYearErrorFlag = action.payload
      return state
    },
    // 対象年度プルダウンリストデータ設定
    setTargetYearDatas(state, action: PayloadAction<any>) {
      state.targetYearDatas = action.payload
      return state
    },
    // 年度切り替え
    yearChange(state, action: PayloadAction<MCAAS010TargetYear>) {
      return state
    },
    // 初期表示時、年度情報取得
    getYearData(state) {
      return state
    },
    // 離脱チェック一時禁止用
    setPromptForbiddenFlg(state, action: PayloadAction<boolean>) {
      state.promptForbiddenFlg = action.payload
      return state
    },
    // 年度対応 end
    //次期9月対応 #62669 start 
    //初期表示後の値付け操作
    initNewsCount(state, action: PayloadAction<number>) {
      state.newsCount = action.payload
    },
    //次期9月対応 #62669 end 

    // 転職March #75621 START
    // サービスモード設定「1:March、0:March以外」
    setServiceMode(state, action: PayloadAction<string>) {
      state.serviceMode = action.payload
      return state
    },
    // 表示用サービスモード設定
    setServiceModeForDisplay(state, action: PayloadAction<string>) {
      state.serviceModeForDisplay = action.payload
      return state
    },
    // March担当企業フラグ設定
    setMarchResponsibleCompanyFlag(state, action: PayloadAction<string>) {
      state.marchResponsibleCompanyFlag = action.payload
      return state
    },
    // Marchサービスフラグ設定
    setMarchServiceFlag(state, action: PayloadAction<string>) {
      state.marchServiceFlag = action.payload
      return state
    },
    // March選択肢非表示フラグ設定
    setMarchNoDisplayFlag(state, action: PayloadAction<string>) {
      state.marchNoDisplayFlag = action.payload
      return state
    },
    // Marchモード相関初期値取得
    serviceModeInitial(state) {
      return state
    },
    // サービスモード切り替え
    serviceModeChange(state, action: PayloadAction<MCAAS010TargetService>) {
      return state
    },
    // サービスモードエラーフラグ
    setTargetServiceErrorFlag(state, action: PayloadAction<boolean>) {
      state.targetServiceErrorFlag = action.payload
      return state
    },
    // March用応募バッジ未読数設定
    setUnreadCVSCountForMarch(state, action: PayloadAction<number>) {
      state.unreadCVSCountForMarch = action.payload
      return state
    },
    // モード初期処理成功フラグ設定
    setModeInitialSuccessFlag(state, action: PayloadAction<boolean>) {
      state.modeInitialSuccessFlag = action.payload
      return state
    },
    // OKコントロール用エラーメッセージ設定
    setErrorMessageForOKControl(state, action: PayloadAction<string>) {
      state.errorMessageForOKControl = action.payload
      return state
    },
    // 転職March #9626 START
    // March用感知チェック実行フラグ設定
    setMarchModeCheckExecuteFlg(state, action: PayloadAction<boolean>) {
      state.marchModeCheckExecuteFlg = action.payload
      return state
    },
    // 転職March #9626 END
    // 転職March #75621 END
  },
})

const { actions, reducer } = globalMenuSlice

export const {
  init,
  initSuccess,
  getUserInfo,
  setUserInfo,
  setRecruitmentManagementDivision,
  getNewApplicantsCount,
  setNewApplicantsCount,
  getUnreadCVSCount,
  setUnreadCVSCount,
  getUnreadMessagesCount,
  setUnreadMessagesCount,
  getWaitForScheduleCount,
  setWaitForScheduleCount,
  setNewsCount,
  signout,
  signoutSuccess,
  newsInit,
  setNewsInfo,
  initFlag,
  setInitFlag,
  // 年度対応 start
  setTargetYear,
  setTargetYearDatas,
  setTargetYearErrorFlag,
  setTargetYearForDisplay,
  yearChange,
  getYearData,
  setPromptForbiddenFlg,
  //次期9月対応 #62669 start 
  initNewsCount,
  //次期9月対応 #62669 end 
  // 年度対応 end
  // 転職March #75621 START
  setServiceMode,
  setServiceModeForDisplay,
  setMarchResponsibleCompanyFlag,
  setMarchServiceFlag,
  setMarchNoDisplayFlag,
  serviceModeInitial,
  serviceModeChange,
  setTargetServiceErrorFlag,
  setUnreadCVSCountForMarch,
  setModeInitialSuccessFlag,
  setErrorMessageForOKControl,
  // 転職March #9626 START
  setMarchModeCheckExecuteFlg,
  // 転職March #9626 END
  // 転職March #75621 END
} = actions
export default reducer
