


export interface MCBHS180BulkOperationMaxProcessInfoDto {
  // 処理上限可否
  maxProcessType: number;

  // 処理上限値
  maxProcessCount: number;
}

export interface MCBHS180InitResultDto {
  // 企業ID
  companyId: string;
  // 'MCB一括操作処理上限情報
  maxProcessInfo: MCBHS180BulkOperationMaxProcessInfoDto;

}

export interface MCBHS180SubmissionRequestObjDto {

  // 企業別求職者ID
  jobSeekerByCompanyId: string;

  // 提出リクエストID
  submissionRequestId: string,

  //求職者ID
  jobSeekerId: string,


}

export const MCBHS180InitValue = {

  
  // 処理上限可否
  maxProcessType: 0,

  // 処理上限値
  maxProcessCount: 0,
}

export const MCBHS180InitResultValue = {
 
  companyId:'',
  // 'MCB一括操作処理上限情報
  maxProcessInfo:MCBHS180InitValue,

 

}
export interface MCBHS180MyCareerCdMCBObjDto {

  // 企業別求職者ID
  jobSeekerByCompanyId: string;

  myCareercd: string;


}
