
import { makeStyles } from '@material-ui/core/styles'
import { FieldGroup, SubTitle, Title } from 'componentsHsc'
import { DatePicker } from 'components'
import { FastField, Field } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import React, { useEffect, useState } from 'react'
import { FormControl } from '.'
import { getMessage } from 'common/messageUtil'
import ToggleAndOr from './ToggleAndOr'
import UncheckableToggle from 'pages/MCBHS040/DetailedSearch/UncheckableToggle'
import FormField from 'pages/MCBHS040/DetailedSearch/FormField'
import { magiContants } from 'utils/contants'
import { SelectOptions } from 'pages/MCBHS040/DetailedSearch/Select'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '13px',
    maxWidth: '295px',
  },
  dateRange: {
    '& input': {
      padding: '0 8px',
    },
  },
  title: {
    backgroundColor: '#e6f1ff',
    padding: 10,
    marginRight: 80,
  },
  notitle: {
    paddingLeft: 10,
    marginRight: 80,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
  },
  datePickerDiv: {
    width: '130px',
    float: 'left',
    '& .MuiIconButton-label': {
      width: '23px',
    },
    '& .btn.only-icon.icon-input_calender': {
      margin: '0 10px 0 0',
    },
  },
  wavyLineStyle: {
    float: 'left',
    width: '10px',
    margin: '13px 12px 0px 20px',
  },
  andOrStyleIE: {
    position: 'absolute',
    marginLeft: '130px',
    top: '6px',
  },
  andOrStyleOther: {
    position: 'absolute',
    marginLeft: '130px',
    marginTop: '6px',
  },
  cursor: {
    '& div': {
      cursor: 'pointer!important',
    }
  },
  fullHeight: {
    padding: theme.spacing(1, 0)
  },
  spanDisabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  }
}))

const ScoutOptionList = [
  { value: '0', label: 'AND' },
  { value: '1', label: 'OR' },
]


interface Props {
  form: FormControl
}

const SubmissionRequest = ({ form }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    deliveryStatusList,
    deliveryDetailList,
    deadlineTypeList, 
    submitStatusList,
    errorCode,
  } = useSelector((state: RootState) => state.advancedSearch.submissionRequestInfo)

  const deliveryDetailConditionSuggest = deliveryDetailList.map(i => {
    return { label: i.deliveryDetailConditionName, value: i.deliveryDetailConditionId }
  })

  const siteDisabled = () => {
    if (form.values.submitStatusObj == null) {
      return true
    } else {
      if (form.values.submitStatusObj.value == '1') {
        return false
      } else {
        return true
      }
    }
  }
  const siteForbidden = () => {
    if (form.values.deliveryStatusObj == null) {
    // MCBリプレース #8535 START
      return true
    // MCBリプレース #8535 END
    } else {
      if (form.values.deliveryStatusObj.value == '1') {
        return false
      } else {
        return true
      }
    }
  }

  const siteDeadline = () => {
    if (form.values.deadlineTypeObj == null) {
      return true
    } else {
      if (form.values.deadlineTypeObj.value == '0') {
        return false
      } else {
        return true
      }
    }
  }
  
  const [isForbidden, setForbidden] = useState(form.values.deliveryStatusObj ? false : true)
  const [isDeadline, setDeadline] = useState(form.values.deadlineTypeObj ? false : true)
  const [isDisabled, setDisabled] = useState(form.values.submitStatusObj ? false : true)
  
  return (
    <>
      <FieldGroup>
      <SubTitle variant='h3'>提出リクエスト内容</SubTitle>
        <FormField>
          <Title title='提出リクエスト状況' className={classes.title} />
          <Field
            name='deliveryStatusObj'
            optionList={deliveryStatusList}
            row
            component={UncheckableToggle}
            targetIndex={1}
            targetIndex2={1}
            target='deliveryStatusObj'
            disabledMBC={setForbidden}
          />
        </FormField>
        <FormField>
          <Title title='提出物内容' className={classes.title} />
          <div>
            <div>
              <Field
                name='deliveryDetailConditionObj'
                isMulti={true}
                defaultValue={form.values.deliveryDetailConditionObj}
                optionList={deliveryDetailConditionSuggest}
                noOptionsMessage = {errorCode == "" ? deliveryDetailList :magiContants.SUGGEST_NO_OPTIONS_MESSAGE}
                placeholder='提出物内容を選択'
                component={SelectOptions}
                className={classes.cursor}
                isClearable
              />
            </div>
            <div>
              <Field
                name='deliveryDetailConditionAndOrObj'
                component={ToggleAndOr}
                row
                optionList={ScoutOptionList}
              />
            </div>
          </div>
        </FormField>
        <FormField helperText={getMessage('MCAXS701-001')}>
          <Title title='提出リクエスト日' className={classes.title} />
          <div className={classes.dateRange}>
            <div className={classes.datePickerDiv}>
              <Field
                name='sendDateFrom'
                selectsStart
                component={DatePicker}
                placeholder='YYYY/MM/DD'
                disableFlag={siteForbidden()}
              />
            </div>
            <div className={classes.wavyLineStyle}>~</div>
            <div className={classes.datePickerDiv}>
              <Field
                name='sendDateTo'
                selectsEnd
                component={DatePicker}
                placeholder='YYYY/MM/DD'
                disableFlag={siteForbidden()}
              />
            </div>
          </div>
        </FormField>
        <FormField helperText={getMessage('MCAXS701-001')}>
          <Title title='提出締切日' className={classes.title} />
          <div>
            <div>
              <Field
                name='deadlineTypeObj'
                optionList={deadlineTypeList}
                row
                component={UncheckableToggle}
                targetIndex={2}
                targetIndex2={2}
                target='deadlineTypeObj'
                disabled={siteForbidden()}
                disabledMBC={setDeadline}
              />
            </div>
            <div className={classes.dateRange}>
              <div className={classes.datePickerDiv}>
                <Field
                  name='deadlineDateFrom'
                  selectsStart
                  component={DatePicker}
                  placeholder='YYYY/MM/DD'
                  disableFlag={siteForbidden() || siteDeadline()}
                />
              </div>
              <div className={classes.wavyLineStyle}>~</div>
              <div className={classes.datePickerDiv}>
                <Field
                  name='deadlineDateTo'
                  selectsEnd
                  component={DatePicker}
                  placeholder='YYYY/MM/DD'
                  disableFlag={siteForbidden() || siteDeadline()}
                />
              </div>
            </div>
          </div>
        </FormField>
        <FormField helperText={getMessage('MCAXS701-001')}>
          <Title title='提出状況' className={classes.title} />
          <Field
            name='submitStatusObj'
            optionList={submitStatusList}
            row
            component={UncheckableToggle}
            targetIndex={3}
            targetIndex2={3}
            target='submitStatusObj'
            disabledMBC={setDisabled}
            disabled={siteForbidden()}
          />
        </FormField>
        <FormField helperText={getMessage('MCAXS701-002')}>
          <Title title='最終提出日' className={classes.title} />
          <div className={classes.dateRange}>
            <div className={classes.datePickerDiv}>
              <Field
                name='submitDateFrom'
                selectsStart
                component={DatePicker}
                placeholder='YYYY/MM/DD'
                disableFlag={siteDisabled()}
              />
            </div>
            <div className={classes.wavyLineStyle}>~</div>
            <div className={classes.datePickerDiv}>
              <Field
                name='submitDateTo'
                selectsEnd
                component={DatePicker}
                placeholder='YYYY/MM/DD'
                disableFlag={siteDisabled()}
              />
            </div>
          </div>
        </FormField>
      </FieldGroup>
    </>
  )
}

export default SubmissionRequest
