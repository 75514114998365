import {
  mCAQS030ConfirmRequest,
  mCAQS030InitRequest,
  mCAQS030SelectRequest,
} from 'apis/MCAQS030Api'
import {
  mCAQS040InitMergingMethodConfirmation,
  mCAQS040JobSeekerDisplay,
  mCAQS040MergingExecutionRequest,
} from 'apis/MCAQS040Api'
import { getMessage, getMessageEx } from 'common/messageUtil'
import {
  getConfirmationInitData,
  getDetailData,
  getDetailInitData,
  // MCAQS030
  getDupIdentCheckHistoryTableData,
  setOpenCheckDialog,
  setSelectionName,

  // MCAQS040
  getInitDataList,
  setDelectFlag,
  setDupIdentCheckHistoryTableData,
  setInitDataList,
  setMergeParam,
  setOpenDupIdentCreate,
  setOpenMCAQS040,
  setUpdateParam,
  updateSelectedDate,
} from 'reducers/duplicateIdentificationReducer'
import { openModal, openSnackbar } from 'reducers/messageReducer'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { routeList } from 'routes/routes'
import { getDelectFlagForDuplicate } from 'selectors'
import { getGlobalMenuData } from 'selectors/authSelectors'
import { magiContants } from 'utils/contants'
import { selectInitDuplicateIdentificationResultRequest } from '../apis/MCAQS020Api'
import { setInitDuplicateIdentificationResult } from '../reducers/nameCheckListReducer'
import { MCAQS020InitDuplicateIdentificationList } from '../types/MCAQS020/MCAQS020InitDuplicateIdentificationList'

// MCAQS030

// 画面を初期表示する。
function* getDupIdentCheckHistoryTableDataSaga(
  action: ReturnType<typeof getDupIdentCheckHistoryTableData>
) {
  try {
    yield put(
      setMergeParam({
        recruitmentManagementDivision:
          action.payload.recruitmentManagementDivision,
        mergeCheckHistory: action.payload.mergeCheckHistory,
        mergeCheckHistoryDetailId: action.payload.mergeCheckHistoryDetailId,
      })
    )
    const data = yield call(mCAQS030InitRequest, action.payload)
    yield put(setDupIdentCheckHistoryTableData(data))
    const delectFlag = yield select(getDelectFlagForDuplicate)
    yield put(setDelectFlag(!delectFlag))
    yield put(setOpenDupIdentCreate(true))
    if (data.length === 0) {
      yield put(openSnackbar(magiContants.MESSAGECODE_RESULT_NULL))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// [確認する]ボタン押下時
function* getConfirmationInitDataSaga(
  action: ReturnType<typeof getConfirmationInitData>
) {
  try {
    // [残す][消す]を１つずつ選択していない場合は処理を中断し
    if (
      !action.payload.remainMergeCheckJobSeekerIdDetailId ||
      !action.payload.removeMergeCheckJobSeekerIdDetailId
    ) {
      yield put(openModal(magiContants.MESSAGECODE_MCAQS030_004))
    } else {
      // 同じ応募者を選択している場合は処理を中断し
      if (
        action.payload.remainMergeCheckJobSeekerIdDetailId ===
        action.payload.removeMergeCheckJobSeekerIdDetailId
      ) {
        yield put(openModal(magiContants.MESSAGECODE_MCAQS030_005))
      } else {
        // MCBリプレース MCAQS030 START
        yield call(mCAQS030ConfirmRequest, action.payload)
        // MCBリプレース MCAQS030 END
        yield put(setOpenDupIdentCreate(false))
        action.payload.recruitmentManagementMergeFlag = ''
        yield put(
          getInitDataList({
            ...action.payload,
          })
        )
      }
    }
  } catch (error) {
    // MCBリプレース MCAQS030 START
    const messResult:Array<string>=error.message.split("");
    const messageId = error.message.substring(0,12);
    // 25KH #74726 start
    const selectionNameAndselectionFlowSettingId = error.message.substring(12);
    let selectionName = ''
    let selectionFlowSettingId = ''
    if (selectionNameAndselectionFlowSettingId && selectionNameAndselectionFlowSettingId.split(',').length==2) {
     selectionName = selectionNameAndselectionFlowSettingId.split(',')[0]
     selectionFlowSettingId = selectionNameAndselectionFlowSettingId.split(',')[1]
    }
    // 25KH #74726 end
    if(messResult.length==12){
    // MCBリプレース MCAQS030 END
      yield put(openModal(messageId))
    }else{
      // 25KH #74726 start
      yield put(setSelectionName({messageId,selectionName,selectionFlowSettingId}))
      // 25KH #74726 end
      yield put(setOpenCheckDialog(true))
    }
  }
}

// リンク押下時
function* getDetailInitDataSaga(action: ReturnType<typeof getDetailInitData>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
  try {
    const data = yield call(mCAQS030SelectRequest, action.payload)
    localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
    const handleNewBrowser = (target: string, p: number, w: number, h: number, label: string) => {
      const params = 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + w 
        + ',height=' + h + ',left=' + p + ',top=' + p + ''
      window.open(target, label, params)
      return false
    }
    const applicantsListInfo = [
      {
        jobSeekerId: action.payload.jobSeekerId,
        entryId: action.payload.entryId,
        selectionManagementId: action.payload.selectionManagementId,
      },
    ]
    const detailRequest = {
      state: {
        displayTargetId: '0',
        applicantsListInfo: applicantsListInfo,
        listIndex: 0,
        directionId: '0',
      },
    }
    localStorage.setItem('detailRequest', JSON.stringify(detailRequest))
    handleNewBrowser(routeList.applicantDetailForEmplyment, 10, 1280, 948, '応募者詳細')
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// MCAQS040
// 画面を初期表示する。
function* getInitDataListSaga(action: ReturnType<typeof getInitDataList>) {
  try {
    const data = yield call(
      mCAQS040InitMergingMethodConfirmation,
      action.payload
    )
    yield put(setInitDataList(data))
    yield put(setOpenMCAQS040(true))
    yield put(
      setUpdateParam({
        ...action.payload,
      })
    )
    if (data.length === 0) {
      yield put(openSnackbar(magiContants.MESSAGECODE_RESULT_NULL))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// [実行する]ボタン押下時
function* updateSelectedDateSaga(
  action: ReturnType<typeof updateSelectedDate>
) {
  try {
    yield call(mCAQS040MergingExecutionRequest, action.payload)
    yield put(setOpenCheckDialog(false))
    yield put(setOpenMCAQS040(false))
    const duplicateIdentificationCheckHistoryId: MCAQS020InitDuplicateIdentificationList = {
      duplicateIdentificationCheckHistoryId: action.payload.mergeCheckHistory,
    }
    const data = yield call(
      selectInitDuplicateIdentificationResultRequest,
      duplicateIdentificationCheckHistoryId
    )
    yield put(setInitDuplicateIdentificationResult(data))
    yield put(openSnackbar(magiContants.MESSAGECODE_MCAQS040_005))
  } catch (error) {
    // 25KH #74726 start
    const messageId = error.message.substring(0,12);
    if (messageId===magiContants.MESSAGECODE_MCAQS040_007) {
      let message = getMessage(messageId) + "\n\n"+ error.message.substring(12);
      yield put(openModal(message))
    }else{
      yield put(openModal(error.message))
    }
    // 25KH #74726 end
  }
}
// リンク押下時
function* getDetailDataSaga(action: ReturnType<typeof getDetailData>) {
  const globalMenuInfo: ReturnType<typeof getGlobalMenuData> = yield select(getGlobalMenuData)
  try {
    const data = yield call(mCAQS040JobSeekerDisplay, action.payload)
    localStorage.setItem('globalMenuInfo',JSON.stringify(globalMenuInfo))
    const handleNewBrowser = (target: string, p: number, w: number, h: number, label: string) => {
      const params = 'scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=' + w 
        + ',height=' + h + ',left=' + p + ',top=' + p + ''
      window.open(target, label, params)
      return false
    }
    const applicantsListInfo = [
      {
        jobSeekerId: action.payload.jobSeekerId,
        entryId: action.payload.entryId,
        selectionManagementId: action.payload.selectionManagementId,
      },
    ]
    const detailRequest = {
      state: {
        displayTargetId: '0',
        applicantsListInfo: applicantsListInfo,
        listIndex: 0,
        directionId: '0',
      },
    }
    localStorage.setItem('detailRequest', JSON.stringify(detailRequest))
    handleNewBrowser(routeList.applicantDetailForEmplyment, 10, 1280, 948, '応募者詳細')
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export default function* selectionFlowSaga() {
  yield all([
    // MCAQS030
    // 初期表示
    takeLatest(
      getDupIdentCheckHistoryTableData,
      getDupIdentCheckHistoryTableDataSaga
    ),
    // [確認する]ボタン押下時
    takeLatest(getConfirmationInitData, getConfirmationInitDataSaga),
    // リンク押下時
    takeLatest(getDetailInitData, getDetailInitDataSaga),

    // MCAQS040
    // 初期表示
    takeLatest(getInitDataList, getInitDataListSaga),
    // [実行する]ボタン押下時
    takeLatest(updateSelectedDate, updateSelectedDateSaga),
    // リンク押下時
    takeLatest(getDetailData, getDetailDataSaga),
  ])
}
