// テンプレート情報
export interface MCALS020EntryDataIoTemPreSelectTemplateOutDto {
    [key: string]: string | number
    templateName: string // テンプレート名
    templateType: string // テンプレート種別
    templateTypeName: string // テンプレート種別名
    outputUnit: string // 出力単位
    outputUnitName: string // 出力単位名
    layoutGroup: string // レイアウトグループ
    displayOrder: number // 表示順
    defaultTemplateId: number // デフォルトテンプレートID
    entryHistoryOutputUnit: string // エントリー履歴出力単位
    descriptionPartyInterviewHistoryOutputUnit: string // 説明会・面接履歴出力単位
    interviewScheduleHistoryOutputUnit: string // 面接日程履歴出力単位
    eventEntryHistoryOutputUnit: string // イベント参加履歴出力単位
    selectionOutputUnit: string // 選考出力単位
    cancelHistoryOutputExistence: string // キャンセル履歴出力有無
    entryDataLayoutId: string // エントリーデータレイアウトID
    sysVersionNumber: number // sysバージョン番号
}

// 選択済項目情報
export interface MCALS020EntrySrhCriPreChosedItemOutDto {
    [key: string]: number | string
    entryDataIoTemplateSettingId: number // エントリーデータ入出力テンプレート設定ID
    entryDataItemId: number // エントリーデータ項目ID
    recruitmentManagementFlagSettingId: number // 採用管理フラグ設定ID
    entryBoxId: number // エントリーボックスID
    seminarId: number // セミナーID
    eventId: number // イベントID
    displayOrder: number // 表示順
    sysVersionNumber: number // sysバージョン番号
    selectItemName: string // 選択済項目名
    defaultFlag: string // デフォルトフラグ
    uniqueId: string // 一意識別
    itemType: string // 項目種別
    itemClassification: string // 項目分類
}

// 初期表示検索結果を
export interface MCALS000DragDropOutDto {
    [key: string]: any
    entrySrhCriPreChosedItemOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 選択済項目情報
    entryDataLytMstClassifyPersonInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 個人情報
    entryDataLytMstClassifySelectEvalInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 選考・評価情報
    entryDataLytMstClassifyRecMagFlgOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 採用管理フラグ情報
    entryDataLytMstClassifyActnInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // アクション情報
    entryDataLytMstClassifyActnInfoEditOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // アクション情報
    entryDataLytMstClassifyWebTsRultOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // WEBテスト結果情報
    entryDataLytMstClassifyJobOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 職歴情報
    entryDataLytMstClassifyInterDesInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 面接希望情報
    /* #62022 次期開発2022年9月 start */
    entryDataLytMstClassifySelectEvalHisOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 選考・評価（履歴）
    /* #62022 次期開発2022年9月 end */
    // MCBリプレース Lot3 start
    entryDataLytMstClassifyRequestInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 提出リクエスト情報
    entryDataLytMstClassifyEntrysheetOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // エントリーシート
    entryDataLytMstClassifyCurriculumVitaeOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 履歴書
    entryDataLytMstClassifyOriginQuestionOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // オリジナル設問
    entryDataLytMstClassifyResearchOutlineOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 研究概要書
    entryDataLytMstClassifyFileOutlineOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // ファイル
    // MCBリプレース Lot3 end
}

// レイアウトグループ
export interface MCALS020LayoutGroupOutDto {
    [key: string]: string
    value: string
    label: string
}

export interface MCALS020InitOutDto {
    [key: string]: boolean | any
    entryDataIoTemPreSelectTemplateOutDto: MCALS020EntryDataIoTemPreSelectTemplateOutDto
    entryDataLayoutIdOne: MCALS000DragDropOutDto
    entryDataLayoutIdTwo: MCALS000DragDropOutDto
    entryDataLayoutIdThree: MCALS000DragDropOutDto
    entryDataLayoutIdFour: MCALS000DragDropOutDto
    entryDataLayoutIdFive: MCALS000DragDropOutDto
    entryDataLayoutIdSix: MCALS000DragDropOutDto
    entryDataLayoutIdSeven: MCALS000DragDropOutDto
    entryDataLayoutIdEight: MCALS000DragDropOutDto
    entryDataLayoutIdNine: MCALS000DragDropOutDto
    entryDataLayoutIdFourteen: MCALS000DragDropOutDto
    entryDataLayoutIdSixteen: MCALS000DragDropOutDto
    /* #62022 次期開発2022年9月 start */
    entryDataLayoutIdEighteen: MCALS000DragDropOutDto
    /* #62022 次期開発2022年9月 end */
    // MCBリプレース Lot3 start
    entryDataLayoutIdTwenty: MCALS000DragDropOutDto
    // MCBリプレース Lot3 end
    layoutGroupOutDtos: MCALS020LayoutGroupOutDto[]
    // 年度対応 start
    targetYear: string
    targetYearList: string[]
    // 年度対応 end
    showDialog: boolean
}

const mcals020InitOutDto = {
    entryDataIoTemPreSelectTemplateOutDto: {
        templateName: '',
        templateType: '0',
        templateTypeName: '',
        outputUnit: '0',
        outputUnitName: '',
        layoutGroup: '',
        displayOrder: -1,
        defaultTemplateId: -1,
        entryHistoryOutputUnit: '',
        descriptionPartyInterviewHistoryOutputUnit: '',
        interviewScheduleHistoryOutputUnit: '',
        eventEntryHistoryOutputUnit: '',
        selectionOutputUnit: '',
        cancelHistoryOutputExistence: '',
        entryDataLayoutId: '',
        sysVersionNumber: -1
    },
    entryDataLayoutIdOne: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdTwo: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdThree: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdFour: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdFive: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdSix: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdSeven: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdEight: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdNine: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdFourteen: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdSixteen: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdEighteen: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    /* #62022 次期開発2022年9月 end */
    // MCBリプレース Lot3 start
    entryDataLayoutIdTwenty: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
    },
    // MCBリプレース Lot3 end
    layoutGroupOutDtos: [],
    // 年度対応 start
    targetYear: '',
    targetYearList: [],
    // 年度対応 end
    showDialog: false
}

export interface InitialValues {
    [key: string]: any
    layoutGroupSelect: MCALS020LayoutGroupOutDto
    entryDataIoTemplateSettingId: string
    entryDataLayoutId: string
    templateName: string
    templateType: number
    layoutType: number
    outputUnitHistory: number
    layoutGroup: string
    layoutGroupName: string
    dragDropValue: MCALS020EntrySrhCriPreChosedItemOutDto[]
    entryOutputHistory: number
    seminarInterviewOutputHistory: number
    interviewScheduleOutputHistory: number
    eventOutputHistory: number
    selectionOutputSelection: number
    seminarInterviewCancelHistory: number
    selectedItemListEntryDataItemId: string[] // 選択済項目_エントリーデータ項目ID一覧
    selectedItemListRecruitmentManagementFlagSettingId: string[] // 選択済項目_採用管理フラグ設定ID一覧
    selectedItemListEntryBoxId: string[] // 選択済項目_エントリーボックスID一覧
    selectedItemListSeminarId: string[] // 選択済項目_セミナーID一覧
    selectedItemListEventId: string[] // 選択済項目_イベントID一覧
    selectedItemListDisplayOrder: string[] // 選択済項目_表示順一覧
    selectedItemList: string[] // 選択済項目_選択済項目名一覧
    selectedItemListItemType: string[] // 項目種別
    entryOutputHistoryInput: string // エントリー履歴_出力する履歴 入力フラグ
    seminarInterviewOutputHistoryInput: string // 説明会・面接履歴_出力する履歴 入力フラグ
    interviewScheduleOutputHistoryInput: string // 面接日程履歴_出力する履歴 入力フラグ
    eventOutputHistoryInput: string // イベント参加履歴_出力する履歴 入力フラグ
    selectionOutputSelectionInput: string // 選考フロー_出力する選考フロー 入力フラグ
    seminarInterviewCancelHistoryInput: string // 説明会・面接の予約履歴-キャンセルされた履歴_出力する選考フロー 入力フラグ
    screenDivision: string
    recruitmentManagementDivision: string
    versionNumberTemPre: string // テンプレート情報バージョン番号
    versionNumberItPre: string[] // テンプレート情報（選択済項目）バージョン番号
    entryDataLayoutIdOne: MCALS000DragDropOutDto
    entryDataLayoutIdTwo: MCALS000DragDropOutDto
    entryDataLayoutIdThree: MCALS000DragDropOutDto
    entryDataLayoutIdFour: MCALS000DragDropOutDto
    entryDataLayoutIdFive: MCALS000DragDropOutDto
    entryDataLayoutIdSix: MCALS000DragDropOutDto
    entryDataLayoutIdSeven: MCALS000DragDropOutDto
    entryDataLayoutIdEight: MCALS000DragDropOutDto
    entryDataLayoutIdNine: MCALS000DragDropOutDto
    entryDataLayoutIdFourteen: MCALS000DragDropOutDto
    entryDataLayoutIdSixteen: MCALS000DragDropOutDto
    /* #62022 次期開発2022年9月 start */
    entryDataLayoutIdEighteen: MCALS000DragDropOutDto
    /* #62022 次期開発2022年9月 end */
    // MCBリプレース Lot3 start
    entryDataLayoutIdTwenty: MCALS000DragDropOutDto
    // MCBリプレース Lot3 end
    layoutGroupOutDtos: MCALS020LayoutGroupOutDto[]
    // 年度対応 start
    targetYear: string
    targetYearList: string[]
    // 年度対応 end
}

const initialValues = {
    layoutGroupSelect: {
        value: '',
        label: '',
    },
    entryDataIoTemplateSettingId: '',
    entryDataLayoutId: '',
    templateName: '',
    templateType: 0,
    layoutType: 0,
    outputUnitHistory: 1,
    layoutGroup: '',
    layoutGroupName: '',
    dragDropValue: [],
    entryOutputHistory: 0,
    seminarInterviewOutputHistory: 0,
    interviewScheduleOutputHistory: 0,
    eventOutputHistory: 0,
    selectionOutputSelection: 0,
    seminarInterviewCancelHistory: 0,
    selectedItemListEntryDataItemId: [], // 選択済項目_エントリーデータ項目ID一覧
    selectedItemListRecruitmentManagementFlagSettingId: [], // 選択済項目_採用管理フラグ設定ID一覧
    selectedItemListEntryBoxId: [], // 選択済項目_エントリーボックスID一覧
    selectedItemListSeminarId: [], // 選択済項目_セミナーID一覧
    selectedItemListEventId: [], // 選択済項目_イベントID一覧
    selectedItemListDisplayOrder: [], // 選択済項目_表示順一覧
    selectedItemList: [], // 選択済項目_選択済項目名一覧
    selectedItemListItemType: [],
    entryOutputHistoryInput: '0', // エントリー履歴_出力する履歴 入力フラグ
    seminarInterviewOutputHistoryInput: '0', // 説明会・面接履歴_出力する履歴 入力フラグ
    interviewScheduleOutputHistoryInput: '0', // 面接日程履歴_出力する履歴 入力フラグ
    eventOutputHistoryInput: '0', // イベント参加履歴_出力する履歴 入力フラグ
    selectionOutputSelectionInput: '0', // 選考フロー_出力する選考フロー 入力フラグ
    seminarInterviewCancelHistoryInput: '0', // 説明会・面接の予約履歴-キャンセルされた履歴_出力する選考フロー 入力フラグ
    screenDivision: 'insert',
    recruitmentManagementDivision: '',
    versionNumberTemPre: '',
    versionNumberItPre: [],
    entryDataLayoutIdOne: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdTwo: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdThree: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdFour: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdFive: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdSix: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdSeven: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdEight: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdNine: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdFourteen: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        /* #62022 次期開発2022年9月 end */
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdSixteen: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        /* #62022 次期開発2022年9月 start */
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    entryDataLayoutIdEighteen: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        // MCBリプレース Lot3 start
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
        // MCBリプレース Lot3 end
    },
    /* #62022 次期開発2022年9月 end */
    // MCBリプレース Lot3 start
    entryDataLayoutIdTwenty: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
        entryDataLytMstClassifySelectEvalHisOutDtos: [], // 選考・評価（履歴）情報
        entryDataLytMstClassifyRequestInfoOutDtos: [], // 提出リクエスト情報
        entryDataLytMstClassifyEntrysheetOutDtos: [], // エントリーシート
        entryDataLytMstClassifyCurriculumVitaeOutDtos: [], // 履歴書
        entryDataLytMstClassifyOriginQuestionOutDtos: [], // オリジナル設問
        entryDataLytMstClassifyResearchOutlineOutDtos: [], // 研究概要書
        entryDataLytMstClassifyFileOutlineOutDtos: [], // ファイル
    },
    // MCBリプレース Lot3 end
    layoutGroupOutDtos: [],
    // 年度対応 start
    targetYear: '',
    targetYearList: [],
    // 年度対応 end
}

export interface ShowDialog020 {
    [key: string]: boolean
    showDialog: boolean
}

const showDialog020 = {
    showDialog: false
}

const templateTypeRadioList = [
    { id: 'templateTypeDownload', label: 'ダウンロード', value: 0 },
    { id: 'templateTypeUpload', label: 'アップロード', value: 1 },
]

const templateTypeRadioListA = [
    { id: 'templateTypeDownload', label: 'ダウンロード', value: 0 },
]

const outputUnitRadioList = [
    { id: 'outputUnitApplicant', label: '応募者単位', value: 0 },
    { id: 'outputUnitAction', label: 'エントリー・選考単位', value: 1 },
    /* #62022 次期開発2022年9月 start */
    { id: 'outputUnitSelectionStep', label: '選考ステップ・合否履歴単位', value: 8 },
    /* #62022 次期開発2022年9月 end */
    // MCBリプレース Lot3 start
    { id: 'outputUnitRequest', label: '提出リクエスト単位', value: 9},
    // MCBリプレース Lot3 end
]
// MCBリプレース Lot3 start
const outputUnitRadioListB = [
    { id: 'outputUnitApplicant', label: '応募者単位', value: 0 },
    { id: 'outputUnitAction', label: 'エントリー・選考単位', value: 1 },
    { id: 'outputUnitSelectionStep', label: '選考ステップ・合否履歴単位', value: 8 },
]
// MCBリプレース Lot3 end

const outputUnitHistoryList = [
    { id: 'historyTypeEntry', label: 'エントリー履歴', value: 1 },
    { id: 'historyTypeSeminarInterview', label: '説明会・面接の予約履歴', value: 2 },
    // { id: 'historyTypeInterviewSchedule', label: '面接日程履歴', value: 3 },
    { id: 'historyTypeEvent', label: 'イベント参加履歴', value: 4 },
    // { id: 'historyTypeSelection', label: '選考フロー設定履歴', value: 5 },
]

const entryList = [
    { id: 'entryLatestOutput', label: '最新の履歴を出力', value: 0 },
    { id: 'entryAllOutput', label: '初回の履歴を出力', value: 1 },
]

const seminarInterviewList = [
    { id: 'seminarInterviewLatestOutput', label: '最新の履歴を出力', value: 0 },
    { id: 'seminarInterviewAllOutput', label: '初回の履歴を出力', value: 1 },
]

const interviewScheduleList = [
    { id: 'interviewScheduleLatestOutput', label: '最新の履歴を出力', value: 0 },
    { id: 'interviewScheduleAllOutput', label: '初回の履歴を出力', value: 1 },
]

const eventList = [
    { id: 'eventLatestOutput', label: '最新の履歴を出力', value: 0 },
    { id: 'eventAllOutput', label: '初回の履歴を出力', value: 1 },
]

const selectionList = [
    { id: 'selectionLatestOutput', label: '最新の選考フローを出力', value: 0 },
    // #57536 start
    { id: 'selectionSelectOutput', label: '出力時に選考フローを指定', value: 1 },
    // #57536 end
]

const cancelHistoryList = [
    { id: 'seminarInterviewCancelHistoryOutput', label: '出力する', value: 0 },
    { id: 'seminarInterviewCancelHistoryNoOutput', label: '出力しない', value: 1 },
]
export {
    initialValues,
    templateTypeRadioList,
    outputUnitRadioList,
    outputUnitRadioListB,
    mcals020InitOutDto,
    outputUnitHistoryList,
    selectionList,
    cancelHistoryList,
    seminarInterviewList,
    interviewScheduleList,
    eventList,
    entryList,
    showDialog020,
    templateTypeRadioListA,
}