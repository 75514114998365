import {
  transform,
  transformApplicationRoute,
  innerSortFunc,
  transformNumberVal,
} from 'componentsHsc/AdvancedSearchJobChange/Config/searchConditionConfig'
import { SearchCondition as EntryListSearchCondition } from 'pages/MCAXS010/searchConditionConfig'
import { dateToString } from 'common/generalUtil'

const managementAnd = '全てと一致'
const managementOr = 'いずれかに一致'

export interface Option {
  [key: string]: any
  label: string
  value: string
}
// MCBリプレイス #8947 START
export interface OptionNumVal {
  [key: string]: any
  label: string
  value: number
}
// MCBリプレイス #8947 END
export interface deliveryDetailConditionObj {
  deliveryDetailConditionId: string
  deliveryDetailConditionName: string
}

export interface deadlineTypeObj {
  deadlineCode: string
  deadlineName: string
}


export interface SchoolModalState {
  // school visible
  schoolGroup: boolean
  schoolDetails: boolean
  schoolDepartmentDetails: boolean
  // school selected
  schoolGroupSelected: Option[]
  schoolDetailsSelected: Option[]
  schoolDepartmentDetailsSelected: UndergraduateDepartmentSearchObj[]
  // students visible
  studentClassification: boolean
  studentDetails: boolean
  // students selected
  studentClassificationSelected: Option[]
  studentDetailsSelected: Option[]
}

export interface SearchCondition {
  // Basic
  applicantId: string | null
  name: string | null
  sexObj: Option[] | null
  phoneNumber: string | null
  mailAddress: string | null
  residenceObj: {
    current: Option[]
    vacation: Option[]
    residenceCondition: string | null
  } | null
  languageSkills: string[] | null
  eligibility: string[] | null
  memberTypeObj: Option[] | null

  // School
  graduationPeriodObj: Option[] | null
  philosophyHumanitiesObj: Option[] | null
  schoolTypeObj: Option[] | null
  schoolName: string | null
  facultyGraduateName: string | null
  departmentDepartmentName: string | null
  schoolGroupObj: Option[] | null
  schoolDetailsObj: Option[] | null
  undergraduateDepartmentObj: UndergraduateDepartmentSearchObj[] | null
  studentClassificationObj: Option[] | null
  departmentDetailsObj: Option[] | null
  countryRegionObj: Option[] | null

  // Applicant
  applicationRouteObj: Option[] | null
  firstEntryDateFrom: string | null
  firstEntryDateTo: string | null
  targetApplicationRouteObj: Option[] | null
  entryDateObj: EntryDateObj | null
  entryPresenceObj: EntryPresenceObj | null
  questionnaireResponseObj: QuestionnaireResponseObj | null
  seminarReceptionObj: SeminarReceptionObj | null
  reservationDateFrom: string | null
  reservationDateTo: string | null
  webSeminarObj: WebSeminarObj | null
  // 24KH #61555 start
  webInterviewParticipationObj: WebInterviewParticipationObj | null
  // 24KH #61555 end
  eventsObj: EventsObj | null
  eventDateFrom: string | null
  eventDateTo: string | null
  sendMessegeObj: SendMessageObj | null
  scoutObj: Option | null
  scoutPreSiteObj: Option[] | null
  scoutCharacterConditionJudgment: Option | null
  scoutProSiteObj: Option[] | null
  scoutApplicationRouteObj: Option[] | null
  welcomeObj: Option | null
  welcomeApplicationRouteObj: Option[] | null

  // RecruitmentManagement
  selectionFlowObj: Option[] | null
  selectionStepObj: Option[] | null
  judgmentUndeterminedObj: Option[] | null
  selectionFlowStepPassFailObj: SelectionFlowStepPassFailObj | null
  // managementItemCondition: string | null //TODO: managementItemObjの配下に移動するように修正が必要
  managementItemObj: ManagementItemObj | null
  tagTxtObj: Option[] | null
  // 25KH #74722 start
  //タグ条件判定FLG
  tagJudgmentFlag: string | null
  //タグ条件判定名
  tagJudgmentName: string | null
  // タグ除外
  tagExclusionTxtObj: Option[] | null
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: string | null
  // タグ除外条件判定名
  tagExclusionJudgmentName: string | null
  // 25KH #74722 end
  imagesObj: TestObj[] | null
  gABObj: TestObj[] | null
  oPQObj: TestObj[] | null
  cABObj: TestObj[] | null
  // [phase2] start
  mcbCategoryAndOrObj: Option | null
  searchTargetObj: Option | null
  mcbCategoryObj: Option[] | null
  linkDateFrom: string | null
  linkDateTo: string | null
  // [phase2] end

  //MY CareerBOX情報 SubmissionRequest
  //MCB ロット３ MY検索（応募管理）保存 start
  deliveryStatusObj: Option | null    //提出リクエスト状況
  // MCBリプレイス #8947 START
  deliveryDetailConditionObj: OptionNumVal[] | null //提出物内容
  // MCBリプレイス #8947 END
  deliveryDetailConditionAndOrObj: Option | null
  sendDateFrom: string | null  //提出リクエスト日
  sendDateTo: string | null
  deadlineTypeObj: Option | null  //提出締切日(条件)
  deadlineDateFrom: string | null  //提出締切日
  deadlineDateTo: string | null
  submitStatusObj: Option | null //提出状況(条件) 
  submitDateFrom: string | null  //最終提出日
  submitDateTo: string | null
  //MCB ロット３ MY検索（応募管理）保存 end
}

export interface TargetApplicationRouteObj {
  targetApplicationRouteFlag: number
  targetApplicationRouteId: string
  targetApplicationRouteName: string
}
export interface ScoutApplicationRouteObj {
  scoutApplicationRouteId: string
  scoutApplicationRouteName: string
}

export interface WelcomeApplicationRouteObj {
  welcomeApplicationRouteId: string
  welcomeApplicationRouteName: string
}

export interface UndergraduateDepartmentSearchObj {
  schoolTypeCode: string
  schoolTypeName: string
  collegeCode: string
  collegeName: string
  facultyCode: string
  facultyName: string
  subjectCode: string
  subjectName: string
  subjcetInfomationDagagaMasterId: string
}

export interface QuestionnaireResponseConditionObj {
  questionnaireResponseApplicationRouteId: number
  questionnaireResponseApplicationRouteName: string
  questionnaireResponsecoordinationFormerEntryBoxId: string
  questionnaireResponseEntryBoxId: number
  questionnaireResponseEntryBoxName: string
  questionnaireResponseQuestionNumber: number
  questionnaireResponseQuestionTitle: string
  questionnaireResponseQuestionAnswerConditionFlag: string
  questionnaireResponseQuestionAnswerConditionName: string
  questionnaireResponseQuestionTypeCode: string
  questionnaireResponseQuestionTypeName: string
  questionnaireResponseAnswerText: string
  questionnaireResponseAnswerCheckBoxObj: Option[]
}

export interface QuestionnaireResponseObj {
  typeCode: string
  typeName: string
  conditionList: QuestionnaireResponseConditionObj[]
}

export interface CABObj {
  cABLogicalName: string
  cABPhysicalName: string
  cABListFlag: string
  cABListName: string
  cABValueFrom: string
  cABValueTo: string
  cABTyp: string
  cABTypName: string
}

export interface OPQObj {
  oPQLogicalName: string
  oPQPhysicalName: string
  oPQValueFrom: string
  oPQValueTo: string
  oPQTyp: string
  oPQTypName: string
}

export interface GABObj {
  gABLogicalName: string
  gABPhysicalName: string
  gABListFlag: string
  gABListName: string
  gABValueFrom: string
  gABValueTo: string
  gABTyp: string
  gABTypName: string
}

export interface ImagesObj {
  imagesLogicalName: string
  imagesPhysicalName: string
  imagesValueFrom: string
  imagesValueTo: string
}

export interface TestObj {
  logicalName: string
  physicalName: string
  valueFrom: string
  valueTo: string
}

export interface ApplicationRouteObj {
  applicationRouteFlag: number
  applicationRouteId: string
  applicationRouteName: string
}

export interface SelectionFlowObj {
  selectionFlowId: string
  selectionFlowName: string
}

export interface SelectionStepObj {
  selectionStepId: string
  selectionStepName: string
}

export interface WebSeminarObj {
  webSeminarConditionJudgmentFlag: number
  webSeminarConditionJudgmentName: string
  webSeminarConditionObj: WebSeminarConditionObj[]
}

export interface WebSeminarConditionObj {
  webSeminarApplicationRouteId: string
  webSeminarApplicationRouteName: string
  webSeminarSeminarScheduleId: string
  webSeminarCoordinationFormerWebSeminarId: string
  webSeminarTitle: string
  webSeminarWatchingStatusConditionFlag: number
  webSeminarWatchingStatusConditionName: string
  webSeminarWatchingStatusConditionObj: WebSeminarWatchingStatusConditionObj[]
}

export interface WebSeminarWatchingStatusConditionObj {
  webSeminarWatchingStatusConditionFlag: number
  webSeminarWatchingStatusConditionName: string
}

export interface EntryDateObj {
  entryDateConditionJudgmentFlag: number
  entryDateConditionJudgmentName: string
  entryDateConditionObj: EntryDateConditionObj[]
}

export interface EntryDateConditionObj {
  entryDateClassificationFlag: string
  entryDateClassificationName: string
  entryDateApplicationRouteId: string
  entryDateApplicationRouteName: string
  entryDatecoordinationFormerEntryBoxId: string
  entryDateEntryBoxId: string
  entryDateEntryBoxName: string
  entryDateFrom: string
  entryDateTo: string
}

export interface EntryPresenceObj {
  entryPresenceConditionJudgmentFlag: number
  entryPresenceConditionJudgmentName: string
  entryPresenceConditionObj: EntryPresenceConditionObj[]
}

export interface EntryPresenceConditionObj {
  entryPresenceClassificationFlag: string | null
  entryPresenceClassificationName: string | null
  entryPresenceApplicationRouteId: string | null
  entryPresenceApplicationRouteName: string | null
  entryPresencecoordinationFormerEntryBoxId: string | null
  entryPresenceEntryBoxId: string | null
  entryPresenceEntryBoxName: string | null
  entryPresenceFlag: number | null
  entryPresenceName: string | null
}

export interface SeminarReceptionObj {
  seminarReceptionConditionJudgmentFlag: number
  seminarReceptionConditionJudgmentName: string
  seminarReceptionWhichOneObj: SeminarReceptionWhichOneObj | null
  seminarReceptionIndividualObj: SeminarReceptionIndividualObj | null
}
export interface SeminarReceptionWhichOneObj {
  seminarReceptionWhichOneFlag: number | null
  seminarReceptionWhichOneName: string | null
}
export interface SeminarReceptionIndividualObj {
  seminarReceptionIndividualJudgmentFlag: number
  seminarReceptionIndividualJudgmentName: string
  seminarReceptionIndividualObj2: SeminarReceptionIndividualObj2[]
}

export interface SeminarReceptionIndividualObj2 {
  seminarReceptionIndividualApplicationRouteId: string | null
  seminarReceptionIndividualApplicationRouteName: string | null
  seminarReceptionIndividualCoordinationFormerSeminarId: string | null
  seminarReceptionIndividualSeminarId: string | null
  seminarReceptionIndividualSeminarName: string | null
  seminarReceptionIndividualPublicFlag: string | null
  seminarReceptionIndividualPublicName: string | null
  seminarReceptionIndividualSeminarScheduleId: string
  seminarReceptionIndividualSeminarScheduleTitle: string | null
  seminarReceptionIndividualInvisibleFlag: string | null
  seminarReceptionIndividualInvisibleName: string | null
  seminarReceptionIndividualEventDate: string | null
  // [phase2] 開催日テキスト add start
  seminarReceptionIndividualEventDateText: string | null
  // [phase2] end
  seminarReceptionIndividualStartTime: string | null
  seminarReceptionIndividualEndTime: string | null
  seminarReceptionIndividualVenueLocationCode: string | null
  seminarReceptionIndividualVenueLocationName: string | null
  seminarReceptionIndividualFlag: number
  seminarReceptionIndividualName: string
}

// 24KH #61555 start
export interface WebInterviewParticipationObj {
  // WEB面談参加状況条件判定FLG
  webInterviewParticipationConditionJudgmentFlag: number
  // WEB面談参加状況条件判定名
  webInterviewParticipationConditionJudgmentName: string
  // WEB面談参加状況（いずれかの日程）条件
  webInterviewParticipationWhichOneObj: WebInterviewParticipationWhichOneObj | null
  // WEB面談参加状況（各受付ごと）
  webInterviewParticipationIndividualObj: WebInterviewParticipationIndividualObj | null
}
export interface WebInterviewParticipationWhichOneObj {
  // WEB面談参加状況（いずれかの日程）FLG
  webInterviewParticipationWhichOneFlag: number | null
  // WEB面談参加状況（いずれかの日程）名
  webInterviewParticipationWhichOneName: string | null
}
export interface WebInterviewParticipationIndividualObj {
  // WEB面談参加状況（各受付ごと）条件判定FLG
  webInterviewParticipationIndividualJudgmentFlag: number
  // WEB面談参加状況（各受付ごと）条件判定名
  webInterviewParticipationIndividualJudgmentName: string
  // WEB面談参加状況（各受付ごと）条件
  webInterviewParticipationIndividualObj2: WebInterviewParticipationIndividualObj2[]
}
export interface WebInterviewParticipationIndividualObj2 {
  // WEB面談参加状況（各受付ごと）FLG
  webInterviewParticipationIndividualFlag: number
  // WEB面談参加状況（各受付ごと）名
  webInterviewParticipationIndividualName: string
  // 応募経路ID
  webInterviewParticipationIndividualApplicationRouteId: string | null
  // 応募経路名
  webInterviewParticipationIndividualApplicationRouteName: string | null
  // 連携元セミナーID
  webInterviewParticipationIndividualCoordinationFormerSeminarId: string | null
  // セミナーID
  webInterviewParticipationIndividualSeminarId: string | null
  // セミナー名
  webInterviewParticipationIndividualSeminarName: string | null
  // 公開フラグ
  webInterviewParticipationIndividualPublicFlag: string | null
  // 公開フラグ名
  webInterviewParticipationIndividualPublicName: string | null
  // セミナー日程ID
  webInterviewParticipationIndividualSeminarScheduleId: string
  // セミナー日程タイトル
  webInterviewParticipationIndividualSeminarScheduleTitle: string | null
  // 非表示フラグ
  webInterviewParticipationIndividualInvisibleFlag: string | null
  // 非表示フラグ名
  webInterviewParticipationIndividualInvisibleName: string | null
  // 開催日
  webInterviewParticipationIndividualEventDate: string | null
  // 開催日テキスト
  webInterviewParticipationIndividualEventDateText: string | null
  // 開催時間
  webInterviewParticipationIndividualStartTime: string | null
  // 終了時間
  webInterviewParticipationIndividualEndTime: string | null
  // 開催地場所コード
  webInterviewParticipationIndividualVenueLocationCode: string | null
  // 開催地場所名
  webInterviewParticipationIndividualVenueLocationName: string | null
}
// 24KH #61555 end

export interface EventsObj {
  eventsConditionJudgmentFlag: number
  eventsConditionJudgmentName: string
  eventsWhichOneObj: EventsWhichOneObj | null
  eventsIndividualObj: EventsIndividualObj | null
}

export interface EventsWhichOneObj {
  eventsWhichOneFlag: number | null
  eventsWhichOneName: string | null
}
export interface EventsIndividualObj {
  eventsIndividualJudgmentFlag: number
  eventsIndividualJudgmentName: string
  eventsIndividualObj2: EventsIndividualObj2[]
}

export interface EventsIndividualObj2 {
  eventsIndivIdualApplicationRouteId: string | null
  eventsIndividualApplicationRouteName: string | null
  eventsIndivIdualEventTypeId: string | null
  eventsIndividualEventTypeName: string | null
  eventsIndividualCoordinationFormerEventId: string | null
  eventsIndividualEventSchedule: string | null
  eventsIndivIdualEventId: string
  eventsIndividualEventName: string | null
  eventsIndividualPrefecturesCode: string | null
  eventsIndividualPrefecturesName: string | null
  eventsIndividualFlag: number
  eventsIndividualName: string
  // Started By DiaoJiaHao
  eventsIndividualAreaId: string | null
  eventsIndividualAreaName: string | null
  // End By DiaoJiaHao
}

export interface SendMessageObj {
  sendMessegeConditionJudgmentFlag: number
  sendMessegeConditionJudgmentName: string
  sendMessegeConditionObj: SendMessageConditionObj[]
}

export interface SendMessageConditionObj {
  sendMessegeMessageTypeFlag: string
  sendMessegeMessageTypeName: string
  sendMessegeSubject: string
  sendMessegeSendConditionFlag: number | null
  sendMessegeSendConditionName: string
  sendMessegeSendDateFrom: string
  sendMessegeSendDateTo: string
  sendMessegeStatusObj: SendMessegeStatusObj[]
  sendMessegeTemplateConditionFlag: number | null
  sendMessegeTemplateConditionName: string
  sendMessegeTemplateObj: SendMessegeTemplateObj[]
  // phase2 start
  sendMessegeMyNaviAttachedOptionEntryBoxId: string | null
  sendMessegeMyNaviAttachedOptionSeminarId: string | null
  sendMessegeMyNaviAttachedOptionName: string
  // phase2 end
}

export interface SendMessegeStatusObj {
  sendMessegeStatusId: string
  sendMessegeStatusName: string
}

export interface MCAXS541ReturnDisplayDto {
  messegeConditionList: SendMessegeTemplateObj[]
  // phase 2 start
  entryBoxAndSeminarList: EntryBoxAndSeminarOptionObj[]
  // pahse 2 end
}

export interface SendMessegeTemplateObj {
  sendMessegeTemplateId: string
  sendMessegeTemplateName: string
  sequence: number
}

// phase 2 start
export interface EntryBoxAndSeminarOptionObj {
  entryBoxOrSeminarType: string
  mynaviAttachedOptionId: number
  mynaviAttachedOptionName: string	//マイナビ添付オプション名  
  contractMediaName: string	//契約応募経路名
}
// pahse 2 end

export interface SelectionFlowStepPassFailObj {
  searchConditionFlag: string
  searchConditionName: string
  selectionFlowObj: SelectionFlowObjDto[]
}
export interface SelectionFlowObjDto {
  selectionFlowCode: string
  selectionFlowName: string
  // 25KH #75311 start
  selectionPhaseJudgmentFlag: string
  selectionPhaseJudgmentName: string
  // 25KH #75311 end
  selectionFlowSelectAllFlag: string
  selectionFlowSelectAllName: string
  selectionStepObj: SelectionStepObjDto[]
  exceptCode: string
  exceptName: string
}
export interface SelectionStepObjDto {
  selectionStepCode: string
  selectionStepName: string
  passFailObj: PassFailObjDto[]
}
export interface PassFailObjDto {
  passFailCode: string
  passFailName: string
}

export const initSearchCondition: SearchCondition = {
  applicantId: null,
  name: null,
  sexObj: null,
  phoneNumber: null,
  mailAddress: null,
  residenceObj: null,
  languageSkills: null,
  eligibility: null,
  memberTypeObj: null,
  graduationPeriodObj: null,
  philosophyHumanitiesObj: null,
  schoolTypeObj: null,
  schoolName: null,
  facultyGraduateName: null,
  departmentDepartmentName: null,
  schoolGroupObj: null,
  schoolDetailsObj: null,
  undergraduateDepartmentObj: null,
  studentClassificationObj: null,
  departmentDetailsObj: null,
  countryRegionObj: null,
  applicationRouteObj: null,
  firstEntryDateFrom: null,
  firstEntryDateTo: null,
  targetApplicationRouteObj: null,
  // entryDateCondition: null,
  entryDateObj: null,
  // entryPresenceCondition: null,
  entryPresenceObj: null,
  // questionnaireCondition: null,
  questionnaireResponseObj: null,
  // seminarReceptionCondition: null,
  seminarReceptionObj: null,
  reservationDateFrom: null,
  reservationDateTo: null,
  // webSeminarCondition: null,
  webSeminarObj: null,
  // 24KH #61555 start
  webInterviewParticipationObj: null,
  // 24KH #61555 end
  // eventsCondition: null,
  eventsObj: null,
  eventDateFrom: null,
  eventDateTo: null,
  // sendMessegeCondition: null,
  sendMessegeObj: null,
  scoutObj: null,
  scoutPreSiteObj: null,
  scoutCharacterConditionJudgment: null,
  scoutProSiteObj: null,
  scoutApplicationRouteObj: null,
  welcomeObj: null,
  welcomeApplicationRouteObj: null,
  selectionFlowObj: null,
  selectionStepObj: null,
  judgmentUndeterminedObj: null,
  selectionFlowStepPassFailObj: null,
  // managementItemCondition: null,
  managementItemObj: null,
  tagTxtObj: null,
  // 25KH #74722 start
  // タグ条件判定FLG
  tagJudgmentFlag: null,
  // タグ条件判定名
  tagJudgmentName: null,
  // タグ除外
  tagExclusionTxtObj: null,
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: null,
  // タグ除外条件判定名
  tagExclusionJudgmentName: null,
  // 25KH #74722 end
  imagesObj: null,
  gABObj: null,
  oPQObj: null,
  cABObj: null,
  // [phase2] start
  mcbCategoryAndOrObj: null,
  searchTargetObj: null,
  mcbCategoryObj: null,
  linkDateFrom: null,
  linkDateTo: null,
  // MY CareerBOX情報
  //MCB ロット３ MY検索（応募管理）保存 start
  deliveryStatusObj: null, //提出リクエスト状況
  deliveryDetailConditionObj: null, //提出物内容
  deliveryDetailConditionAndOrObj: null,
  sendDateFrom: null, //提出リクエスト日
  sendDateTo: null,
  deadlineTypeObj: null,//提出締切日(条件)
  deadlineDateFrom: null,
  deadlineDateTo: null,
  submitStatusObj: null, //提出状況(条件)
  submitDateFrom: null,
  submitDateTo: null
  //MCB ロット３ MY検索（応募管理）保存 end
}

export interface ManagementItemObj {
  typeCode: string
  typeName: string
  conditionList: ManagementItemCondition[]
}

export interface ManagementItemCondition {
  recruitmentManagementFlagSettingId: string
  recruitmentManagementFlagName: string
  managementItemPresenceFlag: string
  managementItemPresenceName: string
  managementItemTypeCode: string
  managementItemTypeName: string
  managementItemFlagText: string
  managementItemFlagFrom: string
  managementItemFlagTo: string
  managementItemFlagCheckBoxObj: Option[]
  isDisable: boolean
  errorsMessage: string
  manageIndex: number
  manageName: string
}

export interface ManagementItemFlagCheckBoxObj {
  managementItemFlagCheckBoxId: string
  managementItemFlagCheckBoxName: string
}

export interface OrderObjArray {
  sexCodeList: { sexCode: number }[]
  memberTypeCodeList: { memberTypeCode: number }[]
  graduationPeriodCodeList: { graduationPeriodCode: number }[]
  philosophyHumanitiesCodeList: { philosophyHumanitiesCode: number }[]
  schoolTypeIdList: { schoolTypeId: number }[]
  countryRegionCodeList: { countryRegionCode: number }[]
  applicationRouteInfoList: {
    applicationRouteFlag: number
    applicationRouteId: number
  }[]
  targetApplicationRouteInfoList: {
    targetApplicationRouteFlag: number
    targetApplicationRouteId: number
  }[]
  selectionFlowIdList: { selectionFlowId: number }[]
  selectionStepList: { selectionStepId: number }[]
  judgmentUndeterminedCodeList: { judgmentUndeterminedCode: number }[]
}

export const initOrderObjArray: OrderObjArray = {
  sexCodeList: [],
  memberTypeCodeList: [],
  graduationPeriodCodeList: [],
  philosophyHumanitiesCodeList: [],
  schoolTypeIdList: [],
  countryRegionCodeList: [],
  applicationRouteInfoList: [],
  targetApplicationRouteInfoList: [],
  selectionFlowIdList: [],
  selectionStepList: [],
  judgmentUndeterminedCodeList: [],
}

export const toEntryList = (
  input: SearchCondition,
  orderObjArray?: OrderObjArray
): EntryListSearchCondition =>
({
  phoneNumber: input.phoneNumber,
  mailAddress: input.mailAddress,
  schoolName: input.schoolName,
  facultyGraduateName: input.facultyGraduateName,
  departmentDepartmentName: input.departmentDepartmentName,
  firstEntryDateFrom: input.firstEntryDateFrom,
  firstEntryDateTo: input.firstEntryDateTo,
  seminarReceptionObj: input.seminarReceptionObj
    ? {
      seminarReceptionConditionJudgmentFlag:
        input.seminarReceptionObj.seminarReceptionConditionJudgmentFlag,
      seminarReceptionConditionJudgmentName:
        input.seminarReceptionObj.seminarReceptionConditionJudgmentName,
      seminarReceptionWhichOneObj:
        input.seminarReceptionObj.seminarReceptionWhichOneObj,
      seminarReceptionIndividualObj: input.seminarReceptionObj
        .seminarReceptionIndividualObj
        ? {
          seminarReceptionIndividualJudgmentFlag:
            input.seminarReceptionObj.seminarReceptionIndividualObj
              .seminarReceptionIndividualJudgmentFlag,
          seminarReceptionIndividualJudgmentName:
            Number(input.seminarReceptionObj.seminarReceptionIndividualObj
              .seminarReceptionIndividualJudgmentFlag) === 0
              ? managementAnd
              : managementOr,
          seminarReceptionIndividualObj2:
            input.seminarReceptionObj.seminarReceptionIndividualObj
              .seminarReceptionIndividualObj2,
        }
        : null,
    }
    : null,
  reservationDateFrom: input.reservationDateFrom,
  reservationDateTo: input.reservationDateTo,
  // 24KH #61555 start
  webInterviewParticipationObj: input.webInterviewParticipationObj
    ? {
      webInterviewParticipationConditionJudgmentFlag: input.webInterviewParticipationObj.webInterviewParticipationConditionJudgmentFlag,
      webInterviewParticipationConditionJudgmentName: input.webInterviewParticipationObj.webInterviewParticipationConditionJudgmentName,
      webInterviewParticipationWhichOneObj: input.webInterviewParticipationObj.webInterviewParticipationWhichOneObj,
      webInterviewParticipationIndividualObj: input.webInterviewParticipationObj
        .webInterviewParticipationIndividualObj
        ? {
          webInterviewParticipationIndividualJudgmentFlag:
            input.webInterviewParticipationObj.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualJudgmentFlag,
          webInterviewParticipationIndividualJudgmentName:
            Number(input.webInterviewParticipationObj.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualJudgmentFlag) === 0
              ? managementAnd : managementOr,
          webInterviewParticipationIndividualObj2:
            input.webInterviewParticipationObj.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2,
        }
        : null,
    }
    : null,
  // 24KH #61555 end
  eventsObj: input.eventsObj
    ? {
      eventsConditionJudgmentFlag:
        input.eventsObj.eventsConditionJudgmentFlag,
      eventsConditionJudgmentName:
        input.eventsObj.eventsConditionJudgmentName,
      eventsWhichOneObj: input.eventsObj.eventsWhichOneObj,
      eventsIndividualObj: input.eventsObj.eventsIndividualObj
        ? {
          eventsIndividualJudgmentFlag:
            input.eventsObj.eventsIndividualObj
              .eventsIndividualJudgmentFlag,
          eventsIndividualJudgmentName:
            Number(input.eventsObj.eventsIndividualObj
              .eventsIndividualJudgmentFlag) === 0
              ? managementAnd
              : managementOr,
          eventsIndividualObj2:
            input.eventsObj.eventsIndividualObj.eventsIndividualObj2,
        }
        : null,
    }
    : null,
  eventDateFrom: input.eventDateFrom,
  eventDateTo: input.eventDateTo,
  applicantId:
    input.applicantId && input.applicantId.length > 0
      ? input.applicantId.split(/\r\n|\n/g).filter(i => i !== '')
      : null,
  name:
    input.name && input.name.length > 0
      ? input.name.split(/\r\n|\n/g).filter(i => i !== '')
      : null,
  sexObj: input.sexObj
    ? input.sexObj
      .map(i => ({ sexCode: i.value, sexName: i.label }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { sexCode: Number(a.sexCode) },
            { sexCode: Number(b.sexCode) },
            orderObjArray.sexCodeList
          )
        } else {
          return 0
        }
      })
    : null,
  residenceObj: input.residenceObj
    ? {
      residenceCurrentObj: input.residenceObj.current.map(i => ({
        residenceCurrentPrefecturesCode: i.value,
        residenceCurrentPrefecturesName: i.label,
      })),
      residenceVacationObj: input.residenceObj.vacation.map(i => ({
        residenceVacationPrefecturesCode: i.value,
        residenceVacationPrefecturesName: i.label,
      })),
      residenceConnectorFlag: input.residenceObj.residenceCondition
        ? input.residenceObj.residenceCondition.toString()
        : null,
      residenceConnectorName: input.residenceObj.residenceCondition
        ? input.residenceObj.residenceCondition === '0'
          ? 'かつ'
          : 'または'
        : null,
    }
    : null,
  languageSkills:
    input.languageSkills && input.languageSkills.length > 0
      ? input.languageSkills
      : null,
  eligibility:
    input.eligibility && input.eligibility.length > 0
      ? input.eligibility
      : null,
  memberTypeObj: input.memberTypeObj
    ? input.memberTypeObj
      .map(i => ({
        memberTypeCode: i.value,
        memberTypeName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { memberTypeCode: Number(a.memberTypeCode) },
            { memberTypeCode: Number(b.memberTypeCode) },
            orderObjArray.memberTypeCodeList
          )
        } else {
          return 0
        }
      })
    : null,
  graduationPeriodObj: input.graduationPeriodObj
    ? input.graduationPeriodObj
      .map(i => ({
        graduationPeriodCode: i.value,
        graduationPeriodName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { graduationPeriodCode: Number(a.graduationPeriodCode) },
            { graduationPeriodCode: Number(b.graduationPeriodCode) },
            orderObjArray.graduationPeriodCodeList
          )
        } else {
          return 0
        }
      })
    : null,
  philosophyHumanitiesObj: input.philosophyHumanitiesObj
    ? input.philosophyHumanitiesObj
      .map(i => ({
        philosophyHumanitiesCode: i.value,
        philosophyHumanitiesName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { philosophyHumanitiesCode: Number(a.philosophyHumanitiesCode) },
            { philosophyHumanitiesCode: Number(b.philosophyHumanitiesCode) },
            orderObjArray.philosophyHumanitiesCodeList
          )
        } else {
          return 0
        }
      })
    : null,
  schoolTypeObj: input.schoolTypeObj
    ? input.schoolTypeObj
      .map(i => ({
        schoolTypeId: i.value,
        schoolTypeName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { schoolTypeId: Number(a.schoolTypeId) },
            { schoolTypeId: Number(b.schoolTypeId) },
            orderObjArray.schoolTypeIdList
          )
        } else {
          return 0
        }
      })
    : null,
  schoolGroupObj:
    input.schoolGroupObj
      ? input.schoolGroupObj.map(i => ({
        schoolGroupId: i.value,
        schoolGroupName: i.label,
      }))
      : null,
  schoolDetailsObj:
    input.schoolDetailsObj
      ? input.schoolDetailsObj.map(i => ({
        schoolDetailsId: i.value,
        schoolDetailsName: i.label,
      }))
      : null,
  undergraduateDepartmentObj: input.undergraduateDepartmentObj
    ? input.undergraduateDepartmentObj.map(i => ({
      undergraduateDepartmentSchoolTypeId: i.schoolTypeCode,
      undergraduateDepartmentSchoolTypeName: i.schoolTypeName,
      undergraduateDepartmentUniversityId: i.collegeCode,
      undergraduateDepartmentUniversityName: i.collegeName,
      undergraduateDepartmentFacultyId: i.facultyCode,
      undergraduateDepartmentFacultyName: i.facultyName,
      undergraduateDepartmentSubjectId: i.subjectCode,
      undergraduateDepartmentSubjectName: i.subjectName,
      subjcetInfomationDagagaMasterId: i.subjcetInfomationDagagaMasterId,
    }))
    : null,
  studentClassificationObj: input.studentClassificationObj
    ? input.studentClassificationObj.map(i => ({
      studentClassificationId: i.value,
      studentClassificationName: i.label,
    }))
    : null,
  departmentDetailsObj: input.departmentDetailsObj
    ? input.departmentDetailsObj.map(i => ({
      departmentId: i.value,
      departmentName: i.label,
    }))
    : null,
  countryRegionObj: input.countryRegionObj
    ? input.countryRegionObj
      .map(i => ({
        countryRegionCode: i.value,
        countryRegionName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { countryRegionCode: Number(a.countryRegionCode) },
            { countryRegionCode: Number(b.countryRegionCode) },
            orderObjArray.countryRegionCodeList
          )
        } else {
          return 0
        }
      })
    : null,
  applicationRouteObj: input.applicationRouteObj
    ? input.applicationRouteObj
      .map(item => ({
        applicationRouteFlag: Number(item.applicationRouteFlag),
        applicationRouteId: item.value.toString(),
        applicationRouteName: item.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { applicationRouteFlag: Number(a.applicationRouteFlag), applicationRouteId: Number(a.applicationRouteId) },
            { applicationRouteFlag: Number(b.applicationRouteFlag), applicationRouteId: Number(b.applicationRouteId) },
            orderObjArray.applicationRouteInfoList
          )
        } else {
          return 0
        }
      })
    : null,
  targetApplicationRouteObj: input.firstEntryDateFrom || input.firstEntryDateTo ?
    input.targetApplicationRouteObj
      ? input.targetApplicationRouteObj
        .map(item => ({
          targetApplicationRouteFlag: Number(item.applicationRouteFlag),
          targetApplicationRouteId: item.value.toString(),
          targetApplicationRouteName: item.label,
        }))
        .sort((a, b) => {
          //詳細検索条件画面の並び順でソート
          if (orderObjArray) {
            return innerSortFunc(
              { targetApplicationRouteFlag: Number(a.targetApplicationRouteFlag), targetApplicationRouteId: Number(a.targetApplicationRouteId) },
              { targetApplicationRouteFlag: Number(b.targetApplicationRouteFlag), targetApplicationRouteId: Number(b.targetApplicationRouteId) },
              orderObjArray.targetApplicationRouteInfoList
            )
          } else {
            return 0
          }
        })
      : null
    : null,
  entryDateObj: input.entryDateObj
    ? {
      entryDateConditionJudgmentFlag:
        input.entryDateObj.entryDateConditionJudgmentFlag,
      entryDateConditionJudgmentName:
        Number(input.entryDateObj.entryDateConditionJudgmentFlag) === 0
          ? managementAnd
          : managementOr,
      entryDateConditionObj: input.entryDateObj.entryDateConditionObj,
    }
    : null,
  sendMessegeObj: input.sendMessegeObj
    ? {
      sendMessegeConditionJudgmentFlag:
        input.sendMessegeObj.sendMessegeConditionJudgmentFlag,
      sendMessegeConditionJudgmentName:
        input.sendMessegeObj.sendMessegeConditionJudgmentFlag === 0
          ? 'メッセージを何も送信していない'
          : '特定のメッセージで検索',
      sendMessegeConditionObj: input.sendMessegeObj.sendMessegeConditionObj.map(
        i =>
          i && {
            sendMessegeMessageTypeFlag: i.sendMessegeMessageTypeFlag,
            sendMessegeMessageTypeName: i.sendMessegeMessageTypeName,
            sendMessegeSendConditionFlag: i.sendMessegeSendConditionFlag,
            sendMessegeSendConditionName: i.sendMessegeSendConditionName,
            sendMessegeSendDateFrom: i.sendMessegeSendDateFrom,
            sendMessegeSendDateTo: i.sendMessegeSendDateTo,
            sendMessegeStatusObj: i.sendMessegeStatusObj ?
              i.sendMessegeStatusObj.map(s => s && {
                sendMessegeStatusId: s.sendMessegeStatusId,
                sendMessegeStatusName: s.sendMessegeStatusName,
              }) : [],
            sendMessegeTemplateConditionFlag: i.sendMessegeTemplateConditionFlag,
            sendMessegeTemplateConditionName: i.sendMessegeTemplateConditionName,
            sendMessegeTemplateObj: i.sendMessegeTemplateObj.map(
              s =>
                s && {
                  sendMessegeTemplateId: s.sendMessegeTemplateId,
                  sendMessegeTemplateName: s.sendMessegeTemplateName,
                  sequence: s.sequence
                }
            ),
            sendMessegeSubject: i.sendMessegeSubject,
            // phase2 start
            sendMessegeMyNaviAttachedOptionEntryBoxId: i.sendMessegeMyNaviAttachedOptionEntryBoxId,
            sendMessegeMyNaviAttachedOptionSeminarId: i.sendMessegeMyNaviAttachedOptionSeminarId,
            sendMessegeMyNaviAttachedOptionName: i.sendMessegeMyNaviAttachedOptionName
            // phase2 end
          }
      ),
    }
    : null,
  entryPresenceObj: input.entryPresenceObj
    ? {
      entryPresenceConditionJudgmentFlag:
        input.entryPresenceObj.entryPresenceConditionJudgmentFlag,
      entryPresenceConditionJudgmentName:
        Number(input.entryPresenceObj.entryPresenceConditionJudgmentFlag) === 0
          ? managementAnd
          : managementOr,
      entryPresenceConditionObj:
        input.entryPresenceObj.entryPresenceConditionObj,
    }
    : null,
  webSeminarObj: input.webSeminarObj
    ? {
      webSeminarConditionJudgmentFlag:
        input.webSeminarObj.webSeminarConditionJudgmentFlag,
      webSeminarConditionJudgmentName:
        input.webSeminarObj.webSeminarConditionJudgmentFlag === 0
          ? managementAnd
          : managementOr,
      webSeminarConditionObj: input.webSeminarObj.webSeminarConditionObj.map(
        i => ({
          ...i,
          webSeminarSeminarScheduleId: i.webSeminarSeminarScheduleId.toString(),
        })
      ),
    }
    : null,
  questionnaireResponseObj: input.questionnaireResponseObj
    ? {
      questionnaireResponseConditionJudgmentFlag: Number(
        input.questionnaireResponseObj.typeCode
      ),
      questionnaireResponseConditionJudgmentName:
        input.questionnaireResponseObj.typeCode === '0'
          ? managementAnd
          : managementOr,
      questionnaireResponseConditionObj: input.questionnaireResponseObj.conditionList.map(
        i => ({
          ...i,
          questionnaireResponseApplicationRouteId: i.questionnaireResponseApplicationRouteId.toString(),
          questionnaireResponseEntryBoxId: i.questionnaireResponseEntryBoxId.toString(),
          questionnaireResponseQuestionNumber: i.questionnaireResponseQuestionNumber.toString(),
          questionnaireResponseQuestionAnswerConditionFlag: Number(
            i.questionnaireResponseQuestionAnswerConditionFlag
          ),
          questionnaireResponseAnswerCheckBoxObj: i.questionnaireResponseAnswerCheckBoxObj.map(
            j => ({
              questionnaireResponseAnswerCheckBoxId: j.value,
              questionnaireResponseAnswerCheckBoxName: j.label,
            })
          ),
        })
      ),
    }
    : null,
  scoutObj: input.scoutObj
    ? {
      scoutConditionsFlag: Number(input.scoutObj.value),
      scoutConditionsName: input.scoutObj.label,
    }
    : null,
  scoutPreSiteObj: input.scoutPreSiteObj
    ? input.scoutPreSiteObj.map(item => ({
      scoutSiteCode: item.value,
      scoutSiteName: item.label,
    }))
    : null,
  scoutCharacterConditionJudgment: input.scoutCharacterConditionJudgment
    ? {
      scoutCharacteAndOrCode: input.scoutCharacterConditionJudgment.value,
      scoutCharacteAndOrName: input.scoutCharacterConditionJudgment.value
        ? input.scoutCharacterConditionJudgment.value === '0'
          ? 'かつ'
          : 'または'
        : null,
    } : null,
  scoutProSiteObj: input.scoutProSiteObj
    ? input.scoutProSiteObj.map(item => ({
      scoutSiteCode: item.value,
      scoutSiteName: item.label,
    }))
    : null,
  scoutApplicationRouteObj: input.scoutApplicationRouteObj
    ? input.scoutApplicationRouteObj.length > 0
      ? input.scoutApplicationRouteObj.map(
        i =>
          i && {
            scoutApplicationRouteId: i.value,
            scoutApplicationRouteName: i.label,
          }
      )
      : null
    : null,
  welcomeObj: input.welcomeObj
    ? {
      welcomeConditionsFlag: Number(input.welcomeObj.value),
      welcomeConditionsName: input.welcomeObj.label,
    }
    : null,
  welcomeApplicationRouteObj: input.welcomeApplicationRouteObj
    ? input.welcomeApplicationRouteObj.length > 0
      ? input.welcomeApplicationRouteObj.map(
        i =>
          i && {
            welcomeApplicationRouteId: i.value,
            welcomeApplicationRouteName: i.label,
          }
      )
      : null
    : null,
  selectionFlowObj: input.selectionFlowObj
    ? input.selectionFlowObj
      .map(i => ({
        selectionFlowId: i.value,
        selectionFlowName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { selectionFlowId: Number(a.selectionFlowId) },
            { selectionFlowId: Number(b.selectionFlowId) },
            orderObjArray.selectionFlowIdList
          )
        } else {
          return 0
        }
      })
    : null,
  selectionStepObj: input.selectionStepObj
    ? input.selectionStepObj
      .map(i => ({
        selectionStepId: i.value,
        selectionStepName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { selectionStepId: Number(a.selectionStepId) },
            { selectionStepId: Number(b.selectionStepId) },
            orderObjArray.selectionStepList
          )
        } else {
          return 0
        }
      })
    : null,
  judgmentUndeterminedObj: input.judgmentUndeterminedObj
    ? input.judgmentUndeterminedObj
      .map(i => ({
        judgmentUndeterminedCode: i.value,
        judgmentUndeterminedName: i.label,
      }))
      .sort((a, b) => {
        //詳細検索条件画面の並び順でソート
        if (orderObjArray) {
          return innerSortFunc(
            { judgmentUndeterminedCode: Number(a.judgmentUndeterminedCode) },
            { judgmentUndeterminedCode: Number(b.judgmentUndeterminedCode) },
            orderObjArray.judgmentUndeterminedCodeList
          )
        } else {
          return 0
        }
      })
    : null,
  selectionFlowStepPassFailObj: input.selectionFlowStepPassFailObj
    ? {
      searchConditionFlag: input.selectionFlowStepPassFailObj.searchConditionFlag.toString(),
      searchConditionName: '0' === input.selectionFlowStepPassFailObj.searchConditionFlag.toString()
        ? managementAnd : managementOr,
      selectionFlowObj: input.selectionFlowStepPassFailObj.selectionFlowObj ?
        input.selectionFlowStepPassFailObj.selectionFlowObj.map(i => ({
          ...i,
          selectionFlowCode: i.selectionFlowCode.toString(),
          selectionFlowName: i.selectionFlowName.toString(),
          // 25KH #75311 start
          selectionPhaseJudgmentFlag: i.selectionPhaseJudgmentFlag ? i.selectionPhaseJudgmentFlag.toString() : '0',
          selectionPhaseJudgmentName: i.selectionPhaseJudgmentName ? i.selectionPhaseJudgmentName.toString() : '現在の選考段階',
          // 25KH #75311 end
          selectionFlowSelectAllFlag: i.selectionFlowSelectAllFlag.toString(),
          selectionFlowSelectAllName: '0' === i.selectionFlowSelectAllFlag.toString()
            ? '' : '選考ステップ×合否を全て選択',
          selectionStepObj: i.selectionStepObj ?
            i.selectionStepObj.map(j => ({
              ...j,
              selectionStepCode: j.selectionStepCode.toString(),
              selectionStepName: j.selectionStepName.toString(),
              passFailObj: j.passFailObj ?
                j.passFailObj.map(k => ({
                  ...k,
                  passFailCode: k.passFailCode.toString(),
                  passFailName: k.passFailName.toString(),
                })) : [],
            })) : [],
          exceptCode: i.exceptCode.toString(),
          exceptName: '0' === i.exceptCode.toString()
            ? '' : '、を除く',
        })) : [],
    } : null,
  managementItemObj: input.managementItemObj
    ? {
      managementItemConditionJudgmentFlag: Number(
        input.managementItemObj.typeCode
      ),
      managementItemConditionJudgmentName:
        input.managementItemObj.typeCode === '0' ? managementAnd : managementOr,
      managementItemConditionObj: input.managementItemObj.conditionList.map(
        i => ({
          ...i,
          managementItemPresenceFlag: Number(i.managementItemPresenceFlag),
          managementItemPresenceName:
            (() => {
              const flag = Number(i.managementItemPresenceFlag)
              if (flag === 0) {
                return '値がある'
              } else if (flag === 1) {
                return '値がない'
              } else {
                return 'を除く'
              }
            })(),
          managementItemFlagCheckBoxObj: i.managementItemFlagCheckBoxObj.map(
            j =>
              j && {
                managementItemFlagCheckBoxId: j.value,
                managementItemFlagCheckBoxName: j.label,
              }
          ),
        })
      ),
    }
    : null,
  tagTxtObj: input.tagTxtObj
    ? input.tagTxtObj.map(i => ({
      tagSettingId: i.value,
      tagName: i.label,
    }))
    : null,
  // 25KH #74722 start
  // タグ条件判定FLG
  tagJudgmentFlag: input.tagJudgmentFlag && input.tagTxtObj != null && input.tagTxtObj.length > 0 ? input.tagJudgmentFlag : null,
  // タグ条件判定名
  tagJudgmentName: input.tagJudgmentFlag && input.tagTxtObj != null && input.tagTxtObj.length > 0 ? (input.tagJudgmentFlag === '0' ? managementAnd : managementOr) : null,
  // タグ除外
  tagExclusionTxtObj:input.tagExclusionTxtObj
    ? input.tagExclusionTxtObj.map(i => ({
    tagExclusionSettingId: i.value,
    tagExclusionName: i.label,
    }))
  :null,
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: input.tagExclusionJudgmentFlag && input.tagExclusionTxtObj != null && input.tagExclusionTxtObj.length > 0 ? input.tagExclusionJudgmentFlag : null,
  // タグ除外条件判定名
  tagExclusionJudgmentName: input.tagExclusionJudgmentFlag && input.tagExclusionTxtObj != null && input.tagExclusionTxtObj.length > 0 ? (input.tagExclusionJudgmentFlag === '0' ? managementAnd : managementOr) : null,
  // 25KH #74722 end
  imagesObj: input.imagesObj
    ? input.imagesObj
      .filter(i => i != null)
      .filter(i => i.physicalName !== '')
      .map(
        i =>
          i && {
            imagesLogicalName: i.logicalName,
            imagesPhysicalName: i.physicalName,
            imagesValueFrom:
              i.valueFrom !== ''
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueFrom))
                  : i.valueFrom
                : null,
            imagesValueTo:
              i.valueTo !== ''
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueTo))
                  : i.valueTo
                : null,
          }
      )
    : null,
  cabObj: input.cABObj
    ? input.cABObj
      .filter(i => i != null)
      .filter(i => i.physicalName !== '')
      .map(
        i =>
          i && {
            cabLogicalName: i.logicalName,
            cabPhysicalName: i.physicalName,
            cabValueFrom:
              i.physicalName.toLowerCase().includes('exam_language') ? i.valueFrom
                : i.physicalName.toLowerCase().includes('date') && i.valueFrom !== '' && i.valueFrom !== null
                  ? dateToString(new Date(i.valueFrom))
                  : i.valueFrom,
            cabValueTo:
              i.valueTo !== '' && i.valueTo !== null && !i.physicalName.includes('exam_language')
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueTo))
                  : i.valueTo
                : null,
            cabListFlag: i.physicalName.includes('exam_language') ? (i.valueFrom.includes('英語') ? '1' : (i.valueFrom.includes('日本語') ? '0' : null)) : null,
            cabListName: i.physicalName.includes('exam_language') ? i.valueFrom : null,
            cabTyp: i.physicalName.includes('exam_language') ? '0' : (i.physicalName.includes('Date') || i.physicalName.includes('date') ? '1' : '2'),
            cabTypName: i.physicalName.includes('exam_language') ? 'リスト' : (i.physicalName.includes('Date') || i.physicalName.includes('date') ? '日付' : '数値'),
          }
      )
    : null,
  opqObj: input.oPQObj
    ? input.oPQObj
      .filter(i => i != null)
      .filter(i => i.physicalName !== '')
      .map(
        i =>
          i && {
            opqLogicalName: i.logicalName,
            opqPhysicalName: i.physicalName,
            opqValueFrom:
              i.valueFrom !== '' && i.valueFrom !== null
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueFrom))
                  : i.valueFrom
                : null,
            opqValueTo:
              i.valueTo !== '' && i.valueTo !== null
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueTo))
                  : i.valueTo
                : null,
            opqTyp: i.physicalName.includes('Date') || i.physicalName.includes('date') ? '0' : '1',
            opqTypName: i.physicalName.includes('Date') || i.physicalName.includes('date') ? '日付' : '数値',
          }
      )
    : null,
  gabObj: input.gABObj
    ? input.gABObj
      .filter(i => i != null)
      .filter(i => i.physicalName !== '')
      .map(
        i =>
          i && {
            gabLogicalName: i.logicalName,
            gabPhysicalName: i.physicalName,
            gabValueFrom:
              i.physicalName.toLowerCase().includes('exam_language') ? i.valueFrom
                : i.physicalName.toLowerCase().includes('date') && i.valueFrom !== '' && i.valueFrom !== null
                  ? dateToString(new Date(i.valueFrom))
                  : i.valueFrom,
            gabValueTo:
              i.valueTo !== '' && i.valueTo !== null && !i.physicalName.includes('exam_Language')
                ? i.physicalName.includes('Date') ||
                  i.physicalName.includes('date')
                  ? dateToString(new Date(i.valueTo))
                  : i.valueTo
                : null,
            gabListFlag: i.physicalName.includes('exam_Language') ? (i.valueFrom.includes('英語') ? '1' : (i.valueFrom.includes('日本語') ? '0' : null)) : null,
            gabListName: i.physicalName.includes('exam_Language') ? i.valueFrom : null,
            gabTyp: i.physicalName.includes('exam_Language') ? '0' : (i.physicalName.includes('Date') || i.physicalName.includes('date') ? '1' : '2'),
            gabTypName: i.physicalName.includes('exam_Language') ? 'リスト' : (i.physicalName.includes('Date') || i.physicalName.includes('date') ? '日付' : '数値'),
          }
      )
    : null,
  // [phase2] start
  searchTargetObj: input.searchTargetObj
    ? {
      searchTargetCode: input.searchTargetObj.value,
      searchTargetName: input.searchTargetObj.label,
    }
    : null,
  mcbCategoryAndOrObj: input.mcbCategoryAndOrObj
    ? {
      mcbCategoryAndOrCode: input.mcbCategoryAndOrObj.value,
      mcbCategoryAndOrName: input.mcbCategoryAndOrObj.label,
    }
    : null,
  mcbCategoryObj: input.mcbCategoryObj
    ? input.mcbCategoryObj.map(i => ({
      mcbCategoryCode: i.value,
      mcbCategoryName: i.label,
    }))
    : [],
  linkDateFrom: input.linkDateFrom ? input.linkDateFrom : null,
  linkDateTo: input.linkDateTo ? input.linkDateTo : null,
  // [phase2] end
  // MCBリプレース MCAXS701 START
  //MCB ロット３ MY検索（応募管理）保存 start
  //提出リクエスト状況
  deliveryStatusObj: input.deliveryStatusObj ? {
    deliveryStatusCode: input.deliveryStatusObj.value,
    deliveryStatusName: input.deliveryStatusObj.label,
  }
    : null,
  //提出物内容
  deliveryDetailConditionObj: input.deliveryDetailConditionObj
  ? input.deliveryDetailConditionObj
    .map(i => ({ deliveryDetailConditionId: i.value, deliveryDetailConditionName: i.label })) : null,
  //提出物内容(AndOr)
  deliveryDetailConditionAndOrObj: input.deliveryDetailConditionAndOrObj
    ? {
      deliveryDetailConditionAndOrCode: input.deliveryDetailConditionAndOrObj.value,
      deliveryDetailConditionAndOrName: input.deliveryDetailConditionAndOrObj.label,
    }
    : null,
  sendDateFrom: input.sendDateFrom ? input.sendDateFrom : null,
  sendDateTo: input.sendDateTo ? input.sendDateTo : null,
  deadlineTypeObj: input.deadlineTypeObj
      ? {
          deadlineCode: input.deadlineTypeObj.value,
          deadlineName: input.deadlineTypeObj.label,
        }
      : null,
  deadlineDateFrom:
      input.deadlineTypeObj && input.deadlineTypeObj.value === '0'
        ? input.deadlineDateFrom
          ? input.deadlineDateFrom
          : null
        : null,
    deadlineDateTo:
      input.deadlineTypeObj && input.deadlineTypeObj.value === '0'
        ? input.deadlineDateTo
          ? input.deadlineDateTo
          : null
        : null,
  submitStatusObj: input.submitStatusObj
      ? {
          submitStatusCode: input.submitStatusObj.value,
          submitStatusName: input.submitStatusObj.label,
        }
      : null,
  submitDateFrom:
      input.submitStatusObj && input.submitStatusObj.value === '1'
        ? input.submitDateFrom
          ? input.submitDateFrom
          : null
        : null,
    submitDateTo:
      input.submitStatusObj && input.submitStatusObj.value === '1'
        ? input.submitDateTo
          ? input.submitDateTo
          : null
        : null,
  // MCBリプレース MCAXS701 END

} as any)

export const fromEntryList = (
  input: EntryListSearchCondition
): SearchCondition => ({
  ...input,
  applicantId: input.applicantId ? input.applicantId.join('\r\n') : null,
  name: input.name ? input.name.join('\r\n') : null,
  sexObj: input.sexObj ? input.sexObj.map(transform) : null,
  residenceObj: input.residenceObj
    ? {
      current: input.residenceObj.residenceCurrentObj
        ? input.residenceObj.residenceCurrentObj.map(transform)
        : [],
      vacation: input.residenceObj.residenceVacationObj
        ? input.residenceObj.residenceVacationObj.map(transform)
        : [],
      residenceCondition: input.residenceObj.residenceConnectorFlag
        ? input.residenceObj.residenceConnectorFlag.toString()
        : '',
    }
    : null,
  languageSkills: input.languageSkills ? input.languageSkills : [],
  eligibility: input.eligibility ? input.eligibility : [],
  memberTypeObj: input.memberTypeObj
    ? input.memberTypeObj.map(transform)
    : null,
  graduationPeriodObj: input.graduationPeriodObj
    ? input.graduationPeriodObj.map(transform)
    : null,
  philosophyHumanitiesObj: input.philosophyHumanitiesObj
    ? input.philosophyHumanitiesObj.map(transform)
    : null,
  schoolTypeObj: input.schoolTypeObj
    ? input.schoolTypeObj.map(transform)
    : null,
  facultyGraduateName: input.facultyGraduateName
    ? input.facultyGraduateName
    : null,
  departmentDepartmentName: input.departmentDepartmentName
    ? input.departmentDepartmentName
    : null,
  schoolGroupObj: input.schoolGroupObj
    ? input.schoolGroupObj.map(transform)
    : null,
  schoolDetailsObj: input.schoolDetailsObj
    ? input.schoolDetailsObj.map(transform)
    : null,
  undergraduateDepartmentObj: input.undergraduateDepartmentObj
    ? input.undergraduateDepartmentObj.map(i => ({
      schoolTypeCode: i.undergraduateDepartmentSchoolTypeId,
      schoolTypeName: i.undergraduateDepartmentSchoolTypeName,
      collegeCode: i.undergraduateDepartmentUniversityId,
      collegeName: i.undergraduateDepartmentUniversityName,
      facultyCode: i.undergraduateDepartmentFacultyId,
      facultyName: i.undergraduateDepartmentFacultyName,
      subjectCode: i.undergraduateDepartmentSubjectId,
      subjectName: i.undergraduateDepartmentSubjectName,
      subjcetInfomationDagagaMasterId: i.subjcetInfomationDagagaMasterId,
    }))
    : null,
  studentClassificationObj: input.studentClassificationObj
    ? input.studentClassificationObj.map(transform)
    : null,
  departmentDetailsObj: input.departmentDetailsObj
    ? input.departmentDetailsObj.map(transform)
    : null,
  countryRegionObj: input.countryRegionObj
    ? input.countryRegionObj.map(transform)
    : null,
  applicationRouteObj: input.applicationRouteObj
    ? input.applicationRouteObj.map(transformApplicationRoute)
    : null,
  targetApplicationRouteObj: input.targetApplicationRouteObj
    ? input.targetApplicationRouteObj.map(transformApplicationRoute)
    : null,
  entryDateObj: input.entryDateObj
    ? {
      entryDateConditionJudgmentFlag:
        input.entryDateObj.entryDateConditionJudgmentFlag,
      entryDateConditionJudgmentName:
        Number(input.entryDateObj.entryDateConditionJudgmentFlag) === 0
          ? 'AND'
          : 'OR',
      entryDateConditionObj: input.entryDateObj.entryDateConditionObj,
    }
    : null,
  entryPresenceObj: input.entryPresenceObj
    ? {
      entryPresenceConditionJudgmentFlag:
        input.entryPresenceObj.entryPresenceConditionJudgmentFlag,
      entryPresenceConditionJudgmentName:
        Number(input.entryPresenceObj.entryPresenceConditionJudgmentFlag) === 0
          ? 'AND'
          : 'OR',
      entryPresenceConditionObj:
        input.entryPresenceObj.entryPresenceConditionObj,
    }
    : null,
  questionnaireResponseObj: input.questionnaireResponseObj
    ? {
      typeCode: input.questionnaireResponseObj.questionnaireResponseConditionJudgmentFlag.toString(),
      typeName:
        Number(input.questionnaireResponseObj
          .questionnaireResponseConditionJudgmentFlag) === 0
          ? 'AND'
          : 'OR',
      conditionList: input.questionnaireResponseObj.questionnaireResponseConditionObj.map(
        i =>
        ({
          ...i,
          questionnaireResponseApplicationRouteId: Number(
            i.questionnaireResponseApplicationRouteId
          ),
          questionnaireResponseEntryBoxId: Number(
            i.questionnaireResponseEntryBoxId
          ),
          questionnaireResponseQuestionNumber: Number(
            i.questionnaireResponseQuestionNumber
          ),
          questionnaireResponseQuestionAnswerConditionFlag: i.questionnaireResponseQuestionAnswerConditionFlag.toString(),
          questionnaireResponseAnswerCheckBoxObj: i.questionnaireResponseAnswerCheckBoxObj.map(
            j => ({
              value: j.questionnaireResponseAnswerCheckBoxId,
              label: j.questionnaireResponseAnswerCheckBoxName,
            })
          ),
        } as any)
      ),
    }
    : null,
  seminarReceptionObj: input.seminarReceptionObj
    ? {
      seminarReceptionConditionJudgmentFlag:
        input.seminarReceptionObj.seminarReceptionConditionJudgmentFlag,
      seminarReceptionConditionJudgmentName:
        input.seminarReceptionObj.seminarReceptionConditionJudgmentName,
      seminarReceptionWhichOneObj:
        input.seminarReceptionObj.seminarReceptionWhichOneObj,
      seminarReceptionIndividualObj: input.seminarReceptionObj
        .seminarReceptionIndividualObj
        ? {
          seminarReceptionIndividualJudgmentFlag:
            input.seminarReceptionObj.seminarReceptionIndividualObj
              .seminarReceptionIndividualJudgmentFlag,
          seminarReceptionIndividualJudgmentName:
            Number(input.seminarReceptionObj.seminarReceptionIndividualObj.seminarReceptionIndividualJudgmentFlag) === 0
              ? 'AND'
              : 'OR',
          seminarReceptionIndividualObj2:
            input.seminarReceptionObj.seminarReceptionIndividualObj
              .seminarReceptionIndividualObj2,
        }
        : null,
    }
    : null,
  webSeminarObj: input.webSeminarObj
    ? {
      webSeminarConditionJudgmentFlag: Number(
        input.webSeminarObj.webSeminarConditionJudgmentFlag
      ),
      webSeminarConditionJudgmentName:
        Number(input.webSeminarObj.webSeminarConditionJudgmentFlag) === 0
          ? 'AND'
          : 'OR',
      webSeminarConditionObj: input.webSeminarObj.webSeminarConditionObj.map(
        i =>
        ({
          ...i,
          webSeminarSeminarScheduleId: Number(
            i.webSeminarSeminarScheduleId
          ),
          webSeminarWatchingStatusConditionFlag: Number(i.webSeminarWatchingStatusConditionFlag),
          webSeminarWatchingStatusConditionObj: i.webSeminarWatchingStatusConditionObj !== null
            ? i.webSeminarWatchingStatusConditionObj
            : [{
              webSeminarWatchingStatusConditionFlag: Number(i.webSeminarWatchingStatusConditionFlag),
              webSeminarWatchingStatusConditionName: i.webSeminarWatchingStatusConditionName
            }]
          ,
        } as any)
      ),
    }
    : null,
  // 24KH #61555 start
  webInterviewParticipationObj: input.webInterviewParticipationObj
    ? {
      webInterviewParticipationConditionJudgmentFlag: input.webInterviewParticipationObj.webInterviewParticipationConditionJudgmentFlag,
      webInterviewParticipationConditionJudgmentName: input.webInterviewParticipationObj.webInterviewParticipationConditionJudgmentName,
      webInterviewParticipationWhichOneObj: input.webInterviewParticipationObj.webInterviewParticipationWhichOneObj,
      webInterviewParticipationIndividualObj: input.webInterviewParticipationObj
        .webInterviewParticipationIndividualObj
        ? {
          webInterviewParticipationIndividualJudgmentFlag:
            input.webInterviewParticipationObj.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualJudgmentFlag,
          webInterviewParticipationIndividualJudgmentName:
            Number(input.webInterviewParticipationObj.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualJudgmentFlag) === 0
              ? 'AND' : 'OR',
          webInterviewParticipationIndividualObj2:
            input.webInterviewParticipationObj.webInterviewParticipationIndividualObj.webInterviewParticipationIndividualObj2,
        }
        : null,
    }
    : null,
  // 24KH #61555 end
  eventsObj: input.eventsObj
    ? {
      eventsConditionJudgmentFlag:
        input.eventsObj.eventsConditionJudgmentFlag,
      eventsConditionJudgmentName:
        input.eventsObj.eventsConditionJudgmentName,
      eventsWhichOneObj: input.eventsObj.eventsWhichOneObj,
      eventsIndividualObj: input.eventsObj.eventsIndividualObj
        ? {
          eventsIndividualJudgmentFlag:
            input.eventsObj.eventsIndividualObj
              .eventsIndividualJudgmentFlag,
          eventsIndividualJudgmentName:
            Number(input.eventsObj.eventsIndividualObj.eventsIndividualJudgmentFlag) === 0
              ? 'AND'
              : 'OR',
          eventsIndividualObj2:
            input.eventsObj.eventsIndividualObj.eventsIndividualObj2,
        }
        : null,
    }
    : null,
  sendMessegeObj: input.sendMessegeObj
    ? {
      sendMessegeConditionJudgmentFlag:
        Number(input.sendMessegeObj.sendMessegeConditionJudgmentFlag),
      sendMessegeConditionJudgmentName:
        input.sendMessegeObj.sendMessegeConditionJudgmentName,
      sendMessegeConditionObj: input.sendMessegeObj.sendMessegeConditionObj.map(
        i =>
          i && {
            sendMessegeMessageTypeFlag: i.sendMessegeMessageTypeFlag,
            sendMessegeMessageTypeName: i.sendMessegeMessageTypeName,
            sendMessegeSendConditionFlag: i.sendMessegeSendConditionFlag,
            sendMessegeSendConditionName: i.sendMessegeSendConditionName,
            sendMessegeSendDateFrom: i.sendMessegeSendDateFrom,
            sendMessegeSendDateTo: i.sendMessegeSendDateTo,
            sendMessegeStatusObj: i.sendMessegeStatusObj ?
              i.sendMessegeStatusObj.map(s => s && {
                sendMessegeStatusId: s.sendMessegeStatusId,
                sendMessegeStatusName: s.sendMessegeStatusName,
              }) : [],
            sendMessegeTemplateConditionFlag: i.sendMessegeTemplateConditionFlag,
            sendMessegeTemplateConditionName: i.sendMessegeTemplateConditionName,
            sendMessegeTemplateObj: i.sendMessegeTemplateObj.map(
              s =>
                s && {
                  sendMessegeTemplateId: s.sendMessegeTemplateId,
                  sendMessegeTemplateName: s.sendMessegeTemplateName,
                  sequence: s.sequence,
                }
            ),
            sendMessegeSubject: i.sendMessegeSubject,
            // phase2 start
            sendMessegeMyNaviAttachedOptionEntryBoxId: i.sendMessegeMyNaviAttachedOptionEntryBoxId,
            sendMessegeMyNaviAttachedOptionSeminarId: i.sendMessegeMyNaviAttachedOptionSeminarId,
            sendMessegeMyNaviAttachedOptionName: i.sendMessegeMyNaviAttachedOptionName
            // phase2 end
          }
      ),
    }
    : null,
  scoutObj: input.scoutObj
    ? {
      value: input.scoutObj.scoutConditionsFlag.toString(),
      label: input.scoutObj.scoutConditionsName,
    }
    : null,
  scoutPreSiteObj: input.scoutPreSiteObj
    ? input.scoutPreSiteObj.map(i => i && {
      value: i.scoutSiteCode,
      label: i.scoutSiteName,
    }) : null,
  scoutCharacterConditionJudgment: input.scoutCharacterConditionJudgment
    ? {
      value: input.scoutCharacterConditionJudgment.scoutCharacteAndOrCode,
      label: input.scoutCharacterConditionJudgment.scoutCharacteAndOrName,
    }
    : null,
  scoutProSiteObj: input.scoutProSiteObj
    ? input.scoutProSiteObj.map(i => i && {
      value: i.scoutSiteCode,
      label: i.scoutSiteName,
    }) : null,
  scoutApplicationRouteObj: input.scoutApplicationRouteObj
    ? input.scoutApplicationRouteObj.map(
      i =>
        i && {
          value: i.scoutApplicationRouteId,
          label: i.scoutApplicationRouteName,
        }
    )
    : [],
  welcomeObj: input.welcomeObj
    ? {
      value: input.welcomeObj.welcomeConditionsFlag.toString(),
      label: input.welcomeObj.welcomeConditionsName,
    }
    : null,
  welcomeApplicationRouteObj: input.welcomeApplicationRouteObj
    ? input.welcomeApplicationRouteObj.map(
      i =>
        i && {
          value: i.welcomeApplicationRouteId,
          label: i.welcomeApplicationRouteName,
        }
    )
    : [],
  selectionFlowObj: input.selectionFlowObj
    ? input.selectionFlowObj.map(transform)
    : null,
  selectionStepObj: input.selectionStepObj
    ? input.selectionStepObj.map(transform)
    : null,
  judgmentUndeterminedObj: input.judgmentUndeterminedObj
    ? input.judgmentUndeterminedObj.map(transform)
    : null,
  selectionFlowStepPassFailObj: input.selectionFlowStepPassFailObj
    ? {
      searchConditionFlag: input.selectionFlowStepPassFailObj.searchConditionFlag.toString(),
      searchConditionName: '0' === input.selectionFlowStepPassFailObj.searchConditionFlag.toString()
        ? 'AND' : 'OR',
      selectionFlowObj: input.selectionFlowStepPassFailObj.selectionFlowObj ?
        input.selectionFlowStepPassFailObj.selectionFlowObj.map(i => ({
          ...i,
          selectionFlowCode: i.selectionFlowCode.toString(),
          selectionFlowName: i.selectionFlowName.toString(),
          // 25KH #75311 start
          selectionPhaseJudgmentFlag: i.selectionPhaseJudgmentFlag ? i.selectionPhaseJudgmentFlag.toString() : '0',
          selectionPhaseJudgmentName: i.selectionPhaseJudgmentName ? i.selectionPhaseJudgmentName.toString() : '現在の選考段階',
          // 25KH #75311 end
          selectionFlowSelectAllFlag: i.selectionFlowSelectAllFlag.toString(),
          selectionFlowSelectAllName: '0' === i.selectionFlowSelectAllFlag.toString()
            ? '' : '選考ステップ×合否を全て選択',
          selectionStepObj: i.selectionStepObj ?
            i.selectionStepObj.map(j => ({
              ...j,
              selectionStepCode: j.selectionStepCode.toString(),
              selectionStepName: j.selectionStepName.toString(),
              passFailObj: j.passFailObj ?
                j.passFailObj.map(k => ({
                  ...k,
                  passFailCode: k.passFailCode.toString(),
                  passFailName: k.passFailName.toString(),
                })) : [],
            })) : [],
          exceptCode: i.exceptCode.toString(),
          exceptName: '0' === i.exceptCode.toString()
            ? '' : '、を除く',
        })) : [],
    } : null,
  managementItemObj: input.managementItemObj
    ? {
      typeCode: input.managementItemObj.managementItemConditionJudgmentFlag.toString(),
      typeName:
        Number(input.managementItemObj
          .managementItemConditionJudgmentFlag as any) === 0
          ? 'AND'
          : 'OR',
      conditionList: input.managementItemObj.managementItemConditionObj.map(
        i =>
        ({
          ...i,
          managementItemPresenceFlag: i.managementItemPresenceFlag.toString(),
          managementItemPresenceName:
            (() => {
              const flag = Number(i.managementItemPresenceFlag)
              if (flag === 0) {
                return '値がある'
              } else if (flag === 1) {
                return '値がない'
              } else {
                return 'を除く'
              }
            })(),
          managementItemFlagCheckBoxObj: i.managementItemFlagCheckBoxObj.map(
            transform
          ),
        } as any)
      ),
    }
    : null,
  tagTxtObj: input.tagTxtObj ? input.tagTxtObj.map(transform) : null,
  // 25KH #74722 start
  tagJudgmentFlag: input.tagJudgmentFlag && input.tagTxtObj != null && input.tagTxtObj.length > 0 ? input.tagJudgmentFlag : null,
  tagJudgmentName: input.tagJudgmentFlag && input.tagTxtObj != null && input.tagTxtObj.length > 0 ? (input.tagJudgmentFlag === '0' ? managementAnd : managementOr) : null,
  // タグ除外
  tagExclusionTxtObj: input.tagExclusionTxtObj ? input.tagExclusionTxtObj.map(transform) : null,
  tagExclusionJudgmentFlag: input.tagExclusionJudgmentFlag && input.tagExclusionTxtObj != null && input.tagExclusionTxtObj.length > 0 ? input.tagExclusionJudgmentFlag : null,
  tagExclusionJudgmentName: input.tagExclusionJudgmentFlag && input.tagExclusionTxtObj != null && input.tagExclusionTxtObj.length > 0 ? (input.tagExclusionJudgmentFlag === '0' ? managementAnd : managementOr) : null,
  // 25KH #74722 end
  imagesObj: input.imagesObj
    ? input.imagesObj.map(
      i =>
        i && {
          logicalName: i.imagesLogicalName,
          physicalName: i.imagesPhysicalName,
          valueFrom: i.imagesValueFrom === null ? '' : i.imagesValueFrom,
          valueTo: i.imagesValueTo === null ? '' : i.imagesValueTo,
        }
    )
    : [],
  cABObj: input.cabObj
    ? input.cabObj.map(
      i =>
        i && {
          logicalName: i.cabLogicalName,
          physicalName: i.cabPhysicalName,
          valueFrom: i.cabValueFrom === null ? '' : i.cabValueFrom,
          valueTo: i.cabValueTo === null ? '' : i.cabValueTo,
        }
    )
    : [],
  oPQObj: input.opqObj
    ? input.opqObj.map(
      i =>
        i && {
          physicalName: i.opqPhysicalName,
          logicalName: i.opqLogicalName,
          valueFrom: i.opqValueFrom === null ? '' : i.opqValueFrom,
          valueTo: i.opqValueTo === null ? '' : i.opqValueTo,
        }
    )
    : [],
  gABObj: input.gabObj
    ? input.gabObj.map(
      i =>
        i && {
          physicalName: i.gabPhysicalName,
          logicalName: i.gabLogicalName,
          valueFrom: i.gabValueFrom === null ? '' : i.gabValueFrom,
          valueTo: i.gabValueTo === null ? '' : i.gabValueTo,
        }
    )
    : [],
  // [phase2] start
  mcbCategoryAndOrObj: input.mcbCategoryAndOrObj
    ? {
      value: input.mcbCategoryAndOrObj.mcbCategoryAndOrCode,
      label: input.mcbCategoryAndOrObj.mcbCategoryAndOrName,
    }
    : null,
  searchTargetObj: input.searchTargetObj
    ? {
      value: input.searchTargetObj.searchTargetCode,
      label: input.searchTargetObj.searchTargetName,
    }
    : null,
  mcbCategoryObj: input.mcbCategoryObj ? input.mcbCategoryObj.map(transform) : [],
  linkDateFrom: input.linkDateFrom ? input.linkDateFrom : null,
  linkDateTo: input.linkDateTo ? input.linkDateTo : null,
  // [phase2] end
  
//提出リクエスト状況
  deliveryStatusObj: input.deliveryStatusObj
    ? {
      value: input.deliveryStatusObj.deliveryStatusCode,
      label: input.deliveryStatusObj.deliveryStatusName,
    }
    : null,
    
   // MCBリプレイス #8947 START
   deliveryDetailConditionObj: input.deliveryDetailConditionObj ? input.deliveryDetailConditionObj.map(transformNumberVal) : null,
   // MCBリプレイス #8947 END
//提出物内容(AndOrObj)
 deliveryDetailConditionAndOrObj: input.deliveryDetailConditionAndOrObj
  ? {
    value: input.deliveryDetailConditionAndOrObj.deliveryDetailConditionAndOrCode,
    label: input.deliveryDetailConditionAndOrObj.deliveryDetailConditionAndOrName,
    }
    : null,
  //提出リクエスト日
  sendDateFrom: input.sendDateFrom ? input.sendDateFrom : null,
  sendDateTo: input.sendDateTo ? input.sendDateTo : null,
   
    //提出締切日(条件)
  deadlineTypeObj: input.deadlineTypeObj
    ? {
      value: input.deadlineTypeObj.deadlineCode,
      label: input.deadlineTypeObj.deadlineName,
    }
    : null,
  //提出締切日
  deadlineDateFrom: input.deadlineDateFrom ? input.deadlineDateFrom : null,
  deadlineDateTo: input.deadlineDateTo ? input.deadlineDateTo : null,
  //提出状況(条件) 
  submitStatusObj: input.submitStatusObj
    ? {
      value: input.submitStatusObj.submitStatusCode,
      label: input.submitStatusObj.submitStatusName,
    }
    : null,
  //最終提出日
  submitDateFrom: input.submitDateFrom ? input.submitDateFrom : null,
  submitDateTo: input.submitDateTo ? input.submitDateTo : null,

})
