import { initRequest, redistRequest } from 'apis/MCAZS230Api'
import { isFileExist } from 'common/businessUtil'
import { isEmpty, now, stringToDateTime } from 'common/generalUtil'
import { getMessage, getMessageEx } from 'common/messageUtil'
import moment from 'moment-timezone'
import * as apiConfig from 'pages/MCAZS230/apiConfig'
import {
  getInit,
  redistMessage,
  setBusinessCheckResult,
  setCode,
  setInit,
  setIsSending,
  setRegisterResult,
} from 'reducers/mcbMessageSendConfirmReducer'
import { openModal } from 'reducers/messageReducer'
import {
  changeConfirmState,
  closeConfirmModal,
} from 'reducers/mcbMessageSendReducer'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { magiContants } from 'utils/contants'
moment.tz.setDefault('Asia/Tokyo')

export function* initSaga(action: ReturnType<typeof getInit>) {
  try {
    const initVal = action.payload.initVal
    const serverResponse = yield call(initRequest, initVal)
    yield put(setInit(serverResponse))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

export function* redistMessageSaga(action: ReturnType<typeof redistMessage>) {
  try {
    const formData = action.payload.formData
    const files: File[] = action.payload.files
    const forbiddenWords = action.payload.forbiddenWords
    const attachmentFileRegistrationMax =
      action.payload.attachmentFileRegistrationMax
    const sendReplyImpossibleTime = action.payload.sendReplyImpossibleTime

    if (files.length > 0 || formData.attachmentId.length > 0) {
      // No.10 ファイル数のチェック
      if (files.length > attachmentFileRegistrationMax) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCAZS230-010')))
        return
      }

      // No.11 ファイル存在チェック
      if (files.length > 0) {
        if (files.some(item => !item || item.size <= 0)) {
          yield put(changeConfirmState(false))
          yield put(closeConfirmModal())
          yield put(openModal(getMessage('MCAZS230-011')))
          return
        }
      }
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        if (file) {
          const isFile = yield call(isFileExist, file)
          if (!isFile) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCAZS230-011')))
            return
          }
        }
      }

      // No.8 ファイルの拡張子チェック
      if (files.length > 0) {
        const suffixCheck = [
          'ai',
          'art',
          'doc',
          'fla',
          'gif',
          'jpg',
          'jpeg',
          'lzh',
          'mpeg',
          'mpg',
          'pdf',
          'png',
          'pps',
          'ppt',
          'psd',
          'txt',
          'xls',
          'zip',
          'docx',
          'xlsx',
        ]
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const lastIndexOf = file.name.lastIndexOf('.')
            const suffix = file.name.substring(lastIndexOf + 1)
            for (var j = 0; j < suffixCheck.length; j++) {
              if (suffixCheck[j] == suffix.toLowerCase()) {
                break
              } else {
                if (suffixCheck[j] != 'xlsx') {
                  continue
                } else {
                  yield put(changeConfirmState(false))
                  yield put(closeConfirmModal())
                  yield put(openModal(getMessage('MCAZS230-008')))
                  return
                }
              }
            }
          }
        }
      }

      // No.12 同一添付ファイルチェック
      const addedfileNameList = files.map(file => file.name)
      const fullFileNameList = addedfileNameList.concat(formData.attachment)
      const fileNameList = fullFileNameList.filter(v => v)
      if (fileNameList.length > 1) {
        const beforeLength = fileNameList.length
        const newFileNameList = fileNameList.filter(
          (x, index, self) => self.indexOf(x) === index
        )
        const afterLength = newFileNameList.length
        if (beforeLength !== afterLength) {
          yield put(changeConfirmState(false))
          yield put(closeConfirmModal())
          yield put(openModal(getMessage('MCAZS230-012')))
          return
        }
      }

      // No.9 ファイルのサイズチェック
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const fileSize = file.size
            if (fileSize > magiContants.MESSAGE_ATTACHMENT_MAX_SIZE) {
              yield put(changeConfirmState(false))
              yield put(closeConfirmModal())
              yield put(openModal(getMessage('MCAZS230-009')))
              return
            }
          }
        }
      }
    }

    // No.13 不正文字チェック
    if (
      formData.subject.indexOf('●●') >= 0 ||
      formData.text.indexOf('●●') >= 0
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCAZS230-013')))
      return
    }

    // No.14 No.15 禁止語チェック
    if (forbiddenWords && forbiddenWords.length > 0) {
      let isErrorSubject = false
      let isErrorBody = false
      forbiddenWords.map(item => {
        if (formData.subject.indexOf(item) >= 0) {
          isErrorSubject = true
          return
        }
        if (formData.text.indexOf(item) >= 0) {
          isErrorBody = true
          return
        }
      })
      if (isErrorSubject) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCAZS230-014')))
        return
      }
      if (isErrorBody) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCAZS230-015')))
        return
      }
    }

    // No.16 ホームページURLチェック
    if (
      (formData.homepageTitle1 &&
        formData.homepageTitle1 !== '' &&
        (formData.homepageUrl1 === null || formData.homepageUrl1 === '')) ||
      (formData.homepageTitle2 &&
        formData.homepageTitle2 !== '' &&
        (formData.homepageUrl2 === null || formData.homepageUrl2 === '')) ||
      (formData.homepageTitle3 &&
        formData.homepageTitle3 !== '' &&
        (formData.homepageUrl3 === null || formData.homepageUrl3 === ''))
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCAZS230-016')))
      return
    }

    if (
      String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION
    ) {
      // No.18 送信予約期間チェック
      if (formData.sendPlanTime === null || formData.sendPlanTime === '') {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessage('MCAZS230-018')))
        return
      }
      if (formData.sendPlanTime) {
        const sendPlanTime = stringToDateTime(formData.sendPlanTime)
        if (sendPlanTime !== null) {
          // No.19 送信予約期間チェック
          if (sendPlanTime < now()) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCAZS230-019')))
            return
          }
          const targetDate = moment(sendPlanTime)
          const toDate = moment(now())
            .add(magiContants.RESERVATION_MAX_DATE_LENGTH, 'day')
            .set('hour', 23)
            .set('minutes', 59)
            .set('second', 59)
          // No.23 送信予約期間チェック
          if (targetDate > toDate) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCAZS230-023')))
            return
          }
          const minTime = moment(now()).add(
            magiContants.RESERVATION_MIN_MINUTES_LENGTH,
            'minutes'
          )
          // No.24 送信予約時刻チェック
          if (targetDate <= minTime) {
            yield put(changeConfirmState(false))
            yield put(closeConfirmModal())
            yield put(openModal(getMessage('MCAZS230-024')))
            return
          }
        }
      }
    }

    // No.17 My CareerBoxメンテナンス期間チェック
    if (sendReplyImpossibleTime && sendReplyImpossibleTime.length > 0) {
      let isError = false
      let errorPeriod = ''
      let sendTime = moment()
      if (
        String(formData.sendTimer) === magiContants.SEND_TIME_FLAG_RESERVATION
      ) {
        if (!isEmpty(formData.sendPlanTime)) {
          sendTime = moment(
            formData.sendPlanTime
              ? formData.sendPlanTime.replace(/\//g, '-')
              : formData.sendPlanTime
          )
        }
      }
      sendReplyImpossibleTime.map(item => {
        let startDate
        let endDate
        let startDateStr = !isEmpty(item.startDateStr)
          ? item.startDateStr.trim()
          : ''
        let endDateStr = !isEmpty(item.endDateStr) ? item.endDateStr.trim() : ''
        if (!isEmpty(item.startDateStr) && item.startDateStr.length === 12) {
          startDate = moment(
            `${startDateStr.substring(0, 4)}-${startDateStr.substring(
              4,
              6
            )}-${startDateStr.substring(6, 8)} ${startDateStr.substring(
              8,
              10
            )}:${startDateStr.substring(10, 12)}`
          )
        }
        if (!isEmpty(item.endDateStr) && item.endDateStr.length === 12) {
          endDate = moment(
            `${endDateStr.substring(0, 4)}-${endDateStr.substring(
              4,
              6
            )}-${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
          )
        }
        // #MCB1.5次開発 #8986 START
        let functionalDivision = !isEmpty(item.functionalDivisionStr)&& item.functionalDivisionStr === '1'? true : false
        if (startDate && endDate && functionalDivision) {
          if (
            (sendTime.isAfter(startDate) ||
              sendTime.diff(startDate, 'minute') === 0) &&
            (sendTime.isBefore(endDate) ||
              sendTime.diff(endDate, 'minute') === 0)
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～${endDateStr.substring(0, 4)}/${endDateStr.substring(
              4,
              6
            )}/${endDateStr.substring(6, 8)} ${endDateStr.substring(
              8,
              10
            )}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
        if (startDate && !endDate && functionalDivision) {
          if (
            sendTime.isAfter(startDate) ||
            sendTime.diff(startDate, 'minute') === 0
          ) {
            errorPeriod = `${startDateStr.substring(
              0,
              4
            )}/${startDateStr.substring(4, 6)}/${startDateStr.substring(
              6,
              8
            )} ${startDateStr.substring(8, 10)}:${startDateStr.substring(
              10,
              12
            )}～`
            isError = true
            return
          }
        }
        if (!startDate && endDate && functionalDivision) {
          // #MCB1.5次開発 #8986 END
          if (
            sendTime.isBefore(endDate) ||
            sendTime.diff(endDate, 'minute') === 0
          ) {
            errorPeriod = `～${endDateStr.substring(
              0,
              4
            )}/${endDateStr.substring(4, 6)}/${endDateStr.substring(
              6,
              8
            )} ${endDateStr.substring(8, 10)}:${endDateStr.substring(10, 12)}`
            isError = true
            return
          }
        }
      })
      if (isError) {
        yield put(changeConfirmState(false))
        yield put(closeConfirmModal())
        yield put(openModal(getMessageEx('MCAZS230-017', errorPeriod)))
        return
      }
    }

    // No.25 送信先チェック
    if (
      formData.senderMailAddress === null ||
      formData.senderMailAddress === ''
    ) {
      yield put(changeConfirmState(false))
      yield put(closeConfirmModal())
      yield put(openModal(getMessage('MCAZS230-025')))
      return
    }

    yield put(setIsSending(true))
    const serverResponse: apiConfig.MCAZS230RegisterResult = yield call(
      redistRequest,
      formData,
      files
    )
    yield put(setRegisterResult(serverResponse))
    yield put(setCode(200))
    yield put(setBusinessCheckResult(true))
    yield put(setIsSending(false))
  } catch (error) {
    yield put(changeConfirmState(false))
    yield put(closeConfirmModal())
    yield put(setIsSending(false))
    if (error.message) {
      yield put(setCode(error.code))
      yield put(openModal(error.message))
    }
  }
}

export default function* mcbMessageConfirmSaga() {
  yield all([
    takeEvery(getInit, initSaga),
    takeLatest(redistMessage, redistMessageSaga),
  ])
}
