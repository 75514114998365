import { MCAZS220MessageConfirmRequest } from 'types/MCAZS220/MCAZS220MessageConfirmRequest'
import { MCAZS220MessageDraftRequest } from 'types/MCAZS220/MCAZS220MessageDraftRequest'

export interface InitResult {
  draftMessageTemplateSettingId: number
  destinationDivision: string
  destinationInfo: MCAZS220DestinationResultDto[]
  mcbDestinationInfo: MessageUtilMcbMessagesDetailDto[]
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  attachment: MCAZS220DraftAttachmentResultDto[]
  homePage: MCAZS220DraftHomepageResultDto[]
  sendTimeFlag: string
  sendPlanTime: Date
  messageTemplate: MCAZS220MessageTemplateResultDto[]
  messageTemplateHomepage: MCAZS220MessageTemplateHomepageResultDto[]
  messageTemplateAttachment: MCAZS220TemplateAttachmentResultDto[]
  signature: MCAZS220SignResultDto[]
  companyId: number
  messageType: MCAZS220MessageResultDto[]
  forbiddenWord: string[]
  exampleLinkUrl: string
  attachmentFileRegistrationMax: number
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaJson: string
  sendReplyImpossibleTime: MCAZS220sendReplyImpossibleTimeDto[]
  companyAccountMailAdress: string
}

export interface MCAZS220MessageResultDto {
  [key: string]: any
  messageSendId: number
  messageType: string
  status: string
}

export interface MessageUtilMcbMessagesDetailDto {
  entryId: string
  selectionManagementId: string
  jobSeekerId: string
  mycareercd: string
  mcbMemberId: string
  mcbSubmissionRequestId: string
  jobSeekerByCompanyId: string
  submissionRequestId: string
  coordinationFormerJobSeekerIdentificationKey: string
  notSend: boolean
}

export interface MCAZS220DestinationResultDto {
  [key: string]: any
  entryId: string
  jobSeekerIdForDisplay: string
  familyName: string
  name: string
  birthdate: Date
  mailAddress: string
  memberType: string
  mcbUseStatus: string
  unsubscribedJobSeekerId: number
  schoolName: string
  facultyName: string
  subjectName: string
  entryReceptionTime: Date
  viewEndTime: Date
  // #MCB1.5次開発 #8997 START
  //閲覧権限
  readableFlag: string
  // #MCB1.5次開発 #8997 END
}

export interface MCAZS220DraftMessageResultDto {
  [key: string]: any
  messageId: number
  mediaFlag: number
  companyContractMediaId: number
  entryId: number
  messageType: string
  destinationDivision: string
  senderCompanyName: string
  replyPermitFlag: string
  replyNecessaryFlag: string
  replyLimitTime: Date | null
  subject: string
  body: string
  homePage1Title: string
  homePage1Url: string
  homePage2Title: string
  homePage2Url: string
  homePage3Title: string
  homePage3Url: string
  sendTimeFlag: string
  sendPlanTime: Date|null
  bulkSendFlag: string
  messageSendId: number
  replyFormerMessageId: number
  senderMailAddress: string
  messageTemplateSettingId: number
  sysVersionNumber: number
}

interface MCAZS220DraftAttachmentResultDto {
  [key: string]: any
  sequence: number
  attachmentName: string
  fileManagementId: string
}

interface MCAZS220DraftHomepageResultDto {
  [key: string]: any
  sequence: number
  homePageTitle: string
  homePageUrl: string
}

interface MCAZS220MessageTemplateResultDto {
  [key: string]: any
  messageTemplateSettingId: number
  templateName: string
  templateType: string
  destinationDivision: string
  senderCompanyName: string
  senderMailAddress: string
  replayPermitFlag: string
  subject: string
  body: string
  reservedScheduleSendFlag: string
  sequence: number
  replyNecessaryFlag: string // 要返信フラグ
  replyLimitTime: string // 返信期限
  sysVersionNumber: string // sysバージョン番号
}

interface MCAZS220MessageTemplateHomepageResultDto {
  [key: string]: any
  messageTemplateSettingId: number
  sequence: number
  homePageTitle: string
  homePageUrl: string
}

interface MCAZS220TemplateAttachmentResultDto {
  [key: string]: any
  messageTemplateSettingId: number
  sequence: number
  attachmentName: string
  fileManagementId: string
}

interface MCAZS220SignResultDto {
  [key: string]: any
  registrationName: string
  signSettingId: string
  signature: string
}

interface MCAZS220sendReplyImpossibleTimeDto {
  [key: string]: any
  rowNoStr: string
  startDateStr: string
  // #MCB1.5次開発 #8986 START
  functionalDivisionStr: string
  // #MCB1.5次開発 #8986 END
  endDateStr: string
}

const destinationResultDto: MCAZS220DestinationResultDto = {
  entryId: '',
  jobSeekerIdForDisplay: '',
  familyName: '',
  name: '',
  birthdate: new Date(),
  mailAddress: '',
  mcbUseStatus: '',
  memberType: '',
  unsubscribedJobSeekerId: 0,
  schoolName: '',
  facultyName: '',
  subjectName: '',
  entryReceptionTime: new Date(),
  viewEndTime: new Date(),
  // #MCB1.5次開発 #8997 START
  readableFlag: '',
  // #MCB1.5次開発 #8997 END
}

const draftMessageResultDto: MCAZS220DraftMessageResultDto = {
  messageId: 0,
  mediaFlag: 0,
  companyContractMediaId: 0,
  entryId: 0,
  messageType: '',
  destinationDivision: '',
  senderCompanyName: '',
  replyPermitFlag: '',
  replyNecessaryFlag: '',
  replyLimitTime: new Date(),
  subject: '',
  body: '',
  homePage1Title: '',
  homePage1Url: '',
  homePage2Title: '',
  homePage2Url: '',
  homePage3Title: '',
  homePage3Url: '',
  sendTimeFlag: '',
  sendPlanTime: new Date(),
  bulkSendFlag: '',
  messageSendId: 0,
  replyFormerMessageId: 0,
  senderMailAddress: '',
  notificationMailAddress: '',
  messageTemplateSettingId: 0,
  sysVersionNumber: 0,
}

/** 送信対象確認エリア */
export interface SendTargetConfirmation {
  [key: string]: any
  destinationSelection: number
  sendTarget: SendTarget[]
  searchedCondition: string
}

/** 送信対象 */
export interface SendTarget {
  [key: string]: any
  fullName: string
  mailAddress: string
  jobSeekerId: string
  mynaviMemberInfo: string
  mcbUseStatusName: string
  schoolName: string
  facultyName: string
  subjectName: string
  applicationDate: string
}

/** メッセージ入力エリア  */
export interface MessageInput {
  [key: string]: any
  headingMessageInput: string
  headingMessageInputDescription: string
  exampleLink: string
  replyAllow: number
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  targetInput: string
  attachment: File[]
  attachmentId: string[]
  templateAttachmentId: string[]
  homePageTitle1: string
  homePageTitle2: string
  homePageTitle3: string
  homePageUrl1: string
  homePageUrl2: string
  homePageUrl3: string
  previewSubject: string
  previewBody: string
}

export interface PreviewResult {
  entryId: number
  familyName: string
  name: string
  jobSeekerIdForDisplay: string
  submissionRequestUrl: string
}

const messageInput: MessageInput = {
  headingMessageInput: '',
  headingMessageInputDescription: '',
  exampleLink: '',
  replyAllow: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  targetInput: '',
  attachment: [],
  attachmentId: [],
  templateAttachmentId: [],
  homePageTitle1: '',
  homePageTitle2: '',
  homePageTitle3: '',
  homePageUrl1: '',
  homePageUrl2: '',
  homePageUrl3: '',
  previewSubject: '',
  previewBody: '',
}

const initResult: InitResult = {
  draftMessageTemplateSettingId: 0,
  destinationDivision: '',
  destinationInfo: [],
  mcbDestinationInfo: [],
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  attachment: [],
  homePage: [],
  sendTimeFlag: '',
  sendPlanTime: new Date(),
  messageTemplate: [],
  messageTemplateHomepage: [],
  messageTemplateAttachment: [],
  signature: [],
  companyId: 0,
  messageType: [],
  forbiddenWord: [],
  exampleLinkUrl: '',
  attachmentFileRegistrationMax: 0,
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaJson: '',
  sendReplyImpossibleTime: [],
  companyAccountMailAdress: '',
}

/** タイトルエリア */
export interface TitleCon {
  [key: string]: any
  selectedTemplateSettingId: string
  messageTemplate: MessageTemplateCon[]
}

export interface MessageTemplateCon {
  [key: string]: any
  messageTemplateSettingId: number
  templateName: string
  templateType: string
  destinationDivision: string
  senderCompanyName: string
  senderMailAddress: string
  sequence: number
  subject: string
  body: string
  reservedScheduleSendFlag: string
  sysVersionNumber: string
}

/** 送信対象確認エリア */
export interface SendTargetConfirmationCon {
  [key: string]: any
  destinationSelection: string
  sendTarget: SendTargetCon[]
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaInfo: SearchCriteriaInfo
}

export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  sysVersionNumber: number             // sysバージョン番号
}

/** 送信対象 */
export interface SendTargetCon {
  [key: string]: any
  fullName: string
  mailAddress: string
  jobSeekerId: string
  mynaviMemberInfo: string
  mcbUseStatusName: string
  schoolName: string
  facultyName: string
  subjectName: string
  applicationDate: string
}

/** メッセージ入力エリア */
export interface MessageInputCon {
  exampleLink: string
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  targetInput: string
  attachment: AttachmentCon[]
  templateAttachmentId: string[]
  homePage: HomePageCon[]
  previewSubject: string
  previewBody: string
  shouldShowMessageInput: ShouldShowMessageInput  
  sysVersionNumber: string // sysバージョン番号
}
interface ShouldShowMessageInput {
  senderCompanyName: boolean
  senderMailAddress: boolean
  subject: boolean
  body: boolean
  attachment: boolean
  homePage: boolean
}
/** 送信日時入力エリア */
export interface SendTimeInputCon {
  [key: string]: any
  transmission: string
  sendPlanTime: Date | null
}

export interface AttachmentCon {
  attachmentFileName: string
  attachmentId: string
  attachmentName: string
}

export interface HomePageCon {
  homePageTitle: string
  homePageUrl: string
}

export interface SignatureCon {
  [key: string]: any
  registrationName: string
  signSettingId: string
  signature: string
}

const titleCon: TitleCon = {
  selectedTemplateSettingId: '',
  messageTemplate: [],
}

const shouldShowMessageInput: ShouldShowMessageInput = {
  senderCompanyName: false,
  senderMailAddress: false,
  subject: false,
  body: false,
  attachment: false,
  homePage: false,
}

const messageInputCon: MessageInputCon = {
  exampleLink: '',
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  targetInput: '',
  attachment: [],
  templateAttachmentId: [],
  homePage: [],
  previewSubject: '',
  previewBody: '',
  shouldShowMessageInput: shouldShowMessageInput,
  sysVersionNumber: '',
}

const sendTimeInputCon: SendTimeInputCon = {
  transmission: '0',
  sendPlanTime: null,
}

const searchCriteriaInfo: SearchCriteriaInfo = {
  entrySearchCriteriaSettingId: '',
  searchCriteriaName: '',
  searchCriteria: '',
  sysVersionNumber: 0,
}

const sendTargetConfirmationCon: SendTargetConfirmationCon = {
  destinationSelection: '0',
  sendTarget: [],
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaInfo: searchCriteriaInfo,
}

const signatureCon: SignatureCon[] = []


const messageConfirmRequest: MCAZS220MessageConfirmRequest = {
  draftMessageSendId: null,
  messageTemplateSettingId: '0',
  mcbMessageDestination: [],
  destinationId: [],
  destination: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  text: '',
  attachment: [],
  attachmentId: [],
  attachmentName: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  homepageTitle1: '',
  homepageTitle2: '',
  homepageTitle3: '',
  homepageUrl1: '',
  homepageUrl2: '',
  homepageUrl3: '',
  sendTimer: 0,
  sendPlanTime: '',
  title: titleCon,
  messageInput: messageInputCon,
  sendTargetConfirmation: sendTargetConfirmationCon,
  sendTimeInput: sendTimeInputCon,
  signature: signatureCon,
  sendReplyImpossibleTime: [],
  searchCriteriaJson: '',
}

const messageDraftRequest: MCAZS220MessageDraftRequest = {
  messageTemplateSettingId: '0',
  mcbMessageDestination: [],
  destinationId: [],
  destination: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  text: '',
  attachment: [],
  attachmentId: [],
  attachmentName: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  homepageTitle1: null,
  homepageTitle2: null,
  homepageTitle3: null,
  homepageUrl1: null,
  homepageUrl2: null,
  homepageUrl3: null,
  sendTimer: 0,
  sendPlanTime: '',
  sourceDraftMessageSendId: '',
  sysVersionNumber: 0,
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaJson: '',
}

export {
  initResult,
  destinationResultDto,
  draftMessageResultDto,
  messageInput,
  messageConfirmRequest,
  messageDraftRequest,
  sendTargetConfirmationCon,
}
