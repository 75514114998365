import request from 'utils/request'
import {MCACS010LoginRequest} from 'types/MCACS010/MCACS010LoginRequest'
export const loginInitRequest = () =>
  request({
    url: '/MCACS010/init',
    method: 'post',
  })

export const loginMagiRequest = (params:MCACS010LoginRequest) =>
  request({
    url: '/MCACS010/loginMagi',
    method: 'post',
    data: params,
  })

export const recaptchaInitRequest = () =>
  request({
    url: '/MCACS010/siteKey',
    method: 'post',
  })

export const gtmInitRequest = () =>
  request({
    url: '/MCACS010/gtmId',
    method: 'post',
  })

// #9675 就職転職分離 start
export const checkShuTenStatus = (token: string) =>
  request({
    url: '/MCACS010/checkShuTenStatus',
    method: 'post',
    headers: { 'Authorization': token },
  })
// #9675 就職転職分離 end