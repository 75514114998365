import React, { Suspense, useEffect, useRef } from 'react'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import TopBar from './TopBar'
import ScrollReset from 'components/ScrollReset'
import { Footer } from 'components'
import { RootState } from 'reducers'
import { init, getUserInfo, setUserInfo, initSuccess, setInitFlag, getYearData, setTargetYear, setTargetYearDatas, setTargetYearForDisplay, serviceModeInitial, setServiceModeForDisplay, setServiceMode, setMarchResponsibleCompanyFlag, setMarchServiceFlag, setModeInitialSuccessFlag} from 'reducers/globalMenuReducer'
import { url, windowsOpenUrl, forceDisplayUrl } from './handleTopBarUrl'
import { routeList } from 'routes/routes'
import { magiContants } from 'utils/contants'
import { getUrl, urlMappings } from 'reducers/urlReducer'
import blueBigLogo from 'images/miws_on-blue_shinsotsu_161x44.jpg'
import redBigLogo from 'images/miws_on-red_chuuto_161x44.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    whiteSpace: 'pre-wrap'
  },
  container: {
    height: '100%',
    display: '-ms-flex',
    flex: '1 1 auto',
    minWidth: '960px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  content: {
    minHeight: 'calc(100% - 38px)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mainSection: {
    flex: '1 0 auto',
    width: '100%',
  },
  noPadding: {
    padding: '0',
  },
  fixHeader: {
    
    top: 0,
    width: '100%',
    zIndex: 99
  },
  // MCBリプレース MCBHS161 ブロッカー START
  blocker: {
    position: 'absolute',
    zIndex: 50,
    top: 0,
    left: '0px',
    width: '100%',
    height: '100%',
    opacity: 0.8,
    backgroundColor: '#808080',
  },
  // MCBリプレース MCBHS161 ブロッカー END
}))

export const scrollToTop = () => {
  const toTop = document.getElementById('toTop')
  if (toTop) {
    toTop.scrollIntoView()
  }
}
export const JudgeUrl = () => {
  let count = 0
  windowsOpenUrl.forEach(u => {
    if(window.location.href.indexOf(u) > 0){
      //メールでシェアされた応募者またはエントリーURLで応募者詳細の場合、(Windows.open以外)
      if (!((routeList.applicantDetailForEmplyment.includes(window.location.pathname)
       || routeList.entryDetail.includes(window.location.pathname)) && window.location.search)) {
        count++
      }
    }
  })
  if(count > 0){
    return true
  } else {
    return false
  }
}

const IsShowHeader = () => {

  if (routeList.applicantDetailForJobChange.includes(window.location.pathname)
    || routeList.entryDetail.includes(window.location.pathname)
    || routeList.applicantDetailForEmplyment.includes(window.location.pathname)) {
    return true
  }else {
    return false
  }
}

const DashboardLayout = ({ route }: RouteConfigComponentProps) => {
  const classes = useStyles()
  const containerRef = useRef(null)
  const dispatch = useDispatch()
  const request: any = JSON.parse(localStorage.getItem('globalMenuInfo') as string)
  const requestCheck: any = JSON.parse(localStorage.getItem('globalMenuInfoCheck') as string)
  const globalMenuInfo = useSelector((state: RootState) => state.globalMenu)
  let recruitmentManagementDivision =
    globalMenuInfo.recruitmentManagementDivision
  //ユーザ情報取得
  useEffect(() => {
    // #5583 start
    if (request && JudgeUrl()) {
      if(requestCheck && (request.companyAccountId !== requestCheck.companyAccountId || request.lastLoginTime !== requestCheck.lastLoginTime)){
        window.location.href = routeList.interruption
      } else {
        // #5583 end
        dispatch(setUserInfo({
          companyId: request.companyId,
          companyAccountId: request.companyAccountId,
          recruitmentManagementDivision: request.recruitmentManagementDivision,
          permissions: request.permissions,
          magiApplicationStatus: request.magiApplicationStatus
        }))
        dispatch(initSuccess({
          companyName: request.companyName,
          fullName: request.fullName, 
          previousLoginTime: request.previousLoginTime, 
          lastLogoutTime: request.lastLogoutTime, 
          lastLoginTime: request.lastLoginTime, 
          newApplicantsCount: request.newApplicantsCount, 
          unreadCVSCount: request.unreadCVSCount, 
          unreadMessagesCount: request.unreadMessagesCount, 
          waitForScheduleCount: request.waitForScheduleCount, 
          newsCount: request.newsCount, 
          delegateLoginAccountFlag: request.delegateLoginAccountFlag
        }))
        // 年度対応 start
        dispatch(setTargetYear(request.targetYear))
        dispatch(setTargetYearDatas(request.targetYearDatas))
        dispatch(setTargetYearForDisplay(request.targetYearForDisplay))
        // 年度対応 end
        // 転職March #75621 START
        dispatch(setServiceMode(request.serviceMode))
        dispatch(setServiceModeForDisplay(request.serviceModeForDisplay))
        dispatch(setMarchResponsibleCompanyFlag(request.marchResponsibleCompanyFlag))
        dispatch(setMarchServiceFlag(request.marchServiceFlag))
        dispatch(setModeInitialSuccessFlag(true))
        // 転職March #75621 END
      }
    } else {
      dispatch(getUserInfo())
      // 年度対応 start
      // 年度情報を取得
      dispatch(getYearData())
      // 年度対応 end
      // 転職March #75621 START
      dispatch(serviceModeInitial())
      // 転職March #75621 END
    }
    const allUrl : urlMappings = {
      urls:[//イメジス_CAB_GAB_OPQ
            magiContants.PDF_IMAGES_PATH,
            magiContants.PDF_IMAGES_PATH_ALL,
            magiContants.PDF_CAB_PATH,
            magiContants.PDF_CAB_PATH_ALL,
            magiContants.PDF_GAB_PATH,
            magiContants.PDF_GAB_PATH_ALL,
            magiContants.PDF_OPQ_PATH,
            magiContants.PDF_OPQ_PATH_ALL,
            //面接場所登録、面接場所編集
            magiContants.MCAO_MAP_SERCH_URL_OPEN,
            magiContants.MCAO_MAP_IMAGE_URL,
            //040、050ShareURL
            magiContants.MCAXS040_ShareURL,
            magiContants.MCAXS050_ShareURL,
            //loginUrl
            magiContants.LOGIN_URL,
            //GET_METHOD_URL
            magiContants.MCAXS141_GET_METHOD_URL,
            ///new-graduate url
            magiContants.NEW_GRADUATE_URL,
            //career url
            magiContants.CAREER_URL,
            //w3 url
            magiContants.W3_URL,
            //adobe url
            magiContants.ADOBE_URL,
            // MIWS Web操作マニュアル rul
            magiContants.OPER_MANUAL_RUL,
            // MIWS Web操作マニュアル rul385
            magiContants.OPER_MANUAL_RUL_385,
            // MCBリプレース MCBHS161 START
            magiContants.ORIGIN_MODAL_URL,
            magiContants.MCB_COMMON_URL,
            // MCBリプレース MCBHS161 END
            // MCBリプレース MCBLS020 START
            magiContants.MCBLS020_GET_METHOD_URL,
            // MCBリプレース MCBLS020 END
	    // 次期開発9月#51248 start
            magiContants.MCARS030_HELP_URL_1,
            magiContants.MCARS030_HELP_URL_2,
            // 次期開発9月#51248 end
            // MCBリプレース MCBHS190 START
            // MCBPDF出力マニュアルURL
            magiContants.MCB_PDF_OUTPUT_MANUAL_URL,
            // MCBリプレース MCBHS190 END
            ]
    }
    dispatch(getUrl(allUrl))
  }, [])

  useEffect(() => {
    scrollToTop() 
  }, [window.location.href])
  useEffect(() => {
    //初期
    if (globalMenuInfo.recruitmentManagementDivision !== "") {
      dispatch(setInitFlag(true))
    }

    if (globalMenuInfo.recruitmentManagementDivision !== "" && !JudgeUrl() && globalMenuInfo.initFlag) {
      dispatch(init({ recruitmentManagementDivision }))
    }
    //キャッシュの保持期間は5分間とする
    let timerId = setInterval(function() {
      if (globalMenuInfo.recruitmentManagementDivision !== '') {
        dispatch(init({ recruitmentManagementDivision }))
      }
    }, 300000)
    return () => clearInterval(timerId)
  }, [recruitmentManagementDivision])
  const handleTopBar = () => {
    let onTopBar = 0
    url.forEach(u => {
      if (window.location.href.indexOf(u) > 0) {
        onTopBar++
      }
    })
    return onTopBar
  }
  return (
    <div className={classes.root}>
      {/* MCBリプレース MCBHS161 ブロッカー START */}
      <div className={classes.blocker} style={{ display: 'none' }} id='divBlock'></div>
      {/* MCBリプレース MCBHS161 ブロッカー END */}
      {handleTopBar() > 0 ? (
        <>
        {IsShowHeader() && 
          <header className={classes.fixHeader}>
          {
            recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_1 ?
                <b>
                  <img src={blueBigLogo} />
                </b>
                : recruitmentManagementDivision === magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 ?
                <b  >
                <img src={redBigLogo} />
                </b> : <> </>
          }
          </header>
        }

          <div id="scrollContainer" className={classes.container}>
            <main
              className={`${classes.content} ${classes.noPadding}`}
              ref={containerRef}>
              <div className={classes.mainSection}>
                <Suspense fallback={<LinearProgress />}>
                  <ScrollReset ref={containerRef} />
                  {/** 年度対応 start */}
                  {/** 年度対応後就職の場合のみ、対象年度が正常に取得できるなら画面を表示します。 */}
                  {(route && 
                    ((globalMenuInfo.targetYear && globalMenuInfo.targetYearDatas.length > 0)
                     || globalMenuInfo.recruitmentManagementDivision == magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2
                     || forceDisplayUrl.includes(window.location.pathname)
                     || !Object.values(routeList).includes(window.location.pathname))) ? renderRoutes(route.routes) : null}
                  {/** 年度対応 end */}
                </Suspense>
              </div>
            </main>
            <Footer />
          </div>
        </>
      ) : (
        <>
      {window.location.href.indexOf(routeList.pdfGenerating) > 0 ||
      window.location.href.indexOf(routeList.pdfDownload) > 0 ||
      window.location.href.indexOf(routeList.creatingMcbPdf) > 0 ||
      window.location.href.indexOf(routeList.downloadMcbPdf) > 0 ||
      window.location.href.indexOf(routeList.networkError) > 0 ? (
        ''
      ) : (
        <TopBar menuInfo={globalMenuInfo} />
      )}
      <div id="scrollContainer" className={classes.container}>
        <main className={classes.content} ref={containerRef}>
          <div id='toTop' className={classes.mainSection}>
            <Suspense fallback={<LinearProgress />}>
              <ScrollReset ref={containerRef} />
              {/** 年度対応 start */}
              {/** 年度対応後就職の場合のみ、対象年度が正常に取得できるなら画面を表示します。 */}
              {(route &&
                 ((globalMenuInfo.targetYear && globalMenuInfo.targetYearDatas.length > 0)
                    // 転職March #75621 START
                    || (globalMenuInfo.recruitmentManagementDivision == magiContants.RECRUITMENT_MANAGEMENT_DIVISION_2 && globalMenuInfo.modeInitialSuccessFlag)
                    // 転職March #75621 END
                    || forceDisplayUrl.includes(window.location.pathname)
                    || !Object.values(routeList).includes(window.location.pathname))) ? renderRoutes(route.routes) : null}
              {/** 年度対応 end */}
            </Suspense>
          </div>
        </main>
        { window.location.href.indexOf(routeList.networkError) > 0 ? (
          ''
        ) : (
        <Footer />
        )}
      </div>
      </>
      )}
    </div>
  )
}

export default DashboardLayout
