import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    mcals010TemplateTypeRequest,
    mcals010TemplateIdRequest,
    macls010IdRequest,
} from 'pages/MCALS010/formConfig'
import {
    MCALS040ProcessData,
    MCALS040OtherData,
} from 'pages/MCALS040/formConfig'

import { MCALS010TemplateIdRequest } from 'types/MCALS010/MCALS010TemplateIdRequest'
import { MCALS010TemplateTypeRequest } from 'types/MCALS010/MCALS010TemplateTypeRequest'
import { MCALS010BulkOperationRequest } from 'types/MCALS010/MCALS010BulkOperationRequest'
import { MCALS010DisplayOrderRequest } from 'types/MCALS010/MCALS010DisplayOrderRequest'
import { MCALS010IdRequest } from 'types/MCALS010/MCALS010IdRequest'
import {
    mcals020InitOutDto,
    MCALS020InitOutDto,
    initialValues,
    InitialValues,
    ShowDialog020,
    showDialog020,
    // 年度対応 start
    MCALS000DragDropOutDto,
    // 年度対応 end
} from 'pages/MCALS020/formConfig'
import {
    mcals030InitOutDto,
    MCALS030InitOutDto,
    MCALS030initialValues,
    MCALS030InitialValues,
    PopUpQueryEdit,
    popUpQueryEdit,
} from 'pages/MCALS030/formConfig'
import { MCALS040ProcessRequest } from 'types/MCALS040/MCALS040ProcessRequest'
import { MCALS020CreateRequest } from 'types/MCALS020/MCALS020CreateRequest'
import { MCALS030UpdateRequest } from 'types/MCALS030/MCALS030UpdateRequest'
import { MCALS030TemplateIdRequest } from 'types/MCALS030/MCALS030TemplateIdRequest'
// 年度対応 start
import { Dispatch, SetStateAction } from 'react'
import { magiContants } from 'utils/contants'
// 年度対応 end

// テンプレート種別検索結果リスト
export interface MCALS010EntryDataIoTemPreSelOutDto {
    [key: string]: any
    companyId: string // 企業ID
    recruitmentManagementDivision: string // 採用管理区分
    defaultTemplateId: string // デフォルトテンプレートID
    templateName: string // テンプレート名
    templateType: string // テンプレート種別
    templateTypeG: string // テンプレート種別画面表示用
    invisibleFlag: string // 非表示フラグ
    invisibleFlagG: string // 非表示フラグ画面表示用
    updateTime: string // 更新日時
    updateTimeG: string // 更新日時画面表示用
    fullName: string // 氏名
    fullNameG: string // 氏名画面表示用
    copyNotPossibleFlag: string // コピー不可フラグ
    copyNotPossibleFlagG: string // コピー不可フラグ画面表示用
    displayOrder: string // 表示順
    layoutType: string // レイアウト種別
    entryDataIoTemplateSettingId: string // エントリーデータ入出力テンプレート設定ID
    // 年度対応 start
    yearDeterminingMediaFlag: string // 年度判別媒体フラグ
    // 年度対応 end
    sysVersionNumber: string // sysバージョン番号
}


// CSVテンプレート登録内容確認画面DB処理と画面用
export interface MCALS040ProcessData {
    entryDataLayoutId: string // エントリーデータレイアウトID
    entryDataIoTemplateSettingId: string // エントリーデータ入出力テンプレート設定ID
    templateName: string // テンプレート名
    // 年度対応 start
    targetYear: string // 対象年度
    // 年度対応 end
    templateType: string // テンプレート種別
    selectedItemListDisplayOrder: string[] // 選択済項目_表示順一覧
    selectedItemListEntryDataItemId: string[] // 選択済項目_エントリーデータ項目ID一覧
    selectedItemListRecruitmentManagementFlagSettingId: string[] // 選択済項目_採用管理フラグ設定ID一覧
    selectedItemListRecruitmentManagementFlagName: string[] // 選択済項目名
    selectedItemListEntryBoxId: string[] // 選択済項目_エントリーボックスID一覧
    selectedItemListSeminarId: string[] // 選択済項目_セミナーID一覧
    selectedItemListEventId: string[] // 選択済項目_イベントID一覧
    entryOutputHistory: string // エントリー履歴_出力する履歴
    seminarInterviewOutputHistory: string // 説明会・面接履歴_出力する履歴
    interviewScheduleOutputHistory: string // 面接日程履歴_出力する履歴
    eventOutputHistory: string // イベント参加履歴_出力する履歴
    selectionOutputSelection: string // 選考フロー_出力する選考フロー
    seminarInterviewCancelHistory: string // 説明会・面接の予約履歴_キャンセルされた履歴 入力フラグ
    versionNumberTemPre: string // テンプレート情報バージョン番号
    versionNumberItPre: string[] // テンプレート情報（選択済項目）バージョン番号
    recruitmentManagementDivision: string // 採用管理区分
    screenDivision: string // 遷移元画面処理区分
}

// CSVテンプレート登録内容確認画面のみ用
export interface MCALS040OtherData {
    entryOutputHistoryInput: string // エントリー履歴_出力する履歴 入力フラグ
    seminarInterviewOutputHistoryInput: string // 説明会・面接履歴_出力する履歴 入力フラグ
    interviewScheduleOutputHistoryInput: string // 面接日程履歴_出力する履歴 入力フラグ
    eventOutputHistoryInput: string // イベント参加履歴_出力する履歴 入力フラグ
    selectionOutputSelectionInput: string // 選考フロー_出力する選考フロー 入力フラグ
    seminarInterviewCancelHistoryInput: string // 説明会・面接の予約履歴_キャンセルされた履歴 入力フラグ
    layoutType: string // レイアウト種別
    layoutGroup: string // レイアウト分類
    showDialog: boolean // dialog表示
}

export interface CreatInitInfo {
    [key: string]: boolean
    showDialog: boolean
}

export interface EditInitInfo {
    [key: string]: boolean
    showDialog: boolean
}

export interface SnackbarInfo {
    [key: string]: boolean | string
    messageID: string
    title: string
}

const snackbarInfo = {
    messageID: '',
    title: '',
}

export interface CsvTemplateType {
    MCALS010IdRequest: MCALS010IdRequest
    MCALS010EntryDataIoTemPreSelOutDto: MCALS010EntryDataIoTemPreSelOutDto[]
    MCALS010TemplateTypeRequest: MCALS010TemplateTypeRequest
    selectAllNotNull: string[]
    MCALS010TemplateIdRequest: MCALS010TemplateIdRequest
    MCALS010BulkOperationRequest: MCALS010BulkOperationRequest[]
    CreatInitInfoResult: MCALS020InitOutDto
    InitialValuesResult: InitialValues
    MCALS040ProcessData: MCALS040ProcessData
    MCALS040OtherData: MCALS040OtherData
    EditInitInfoResult: MCALS030InitOutDto
    MCALS030InitialValuesResult: MCALS030InitialValues,
    PopUpQueryEditResult: PopUpQueryEdit,
    ShowDialogResult: ShowDialog020,
    SnackbarInfoResult: SnackbarInfo,
    seniFlag: string,
    // 年度対応 start
    initialValuesResultBak: InitialValues,
    entryDataIoTemplateSettingId: string,
    openConform: boolean,
    // 年度対応 end
}

//初期化
const initialState: CsvTemplateType = {
    MCALS010IdRequest: macls010IdRequest,
    MCALS010EntryDataIoTemPreSelOutDto: [], // テンプレート種別検索結果
    MCALS010TemplateTypeRequest: mcals010TemplateTypeRequest,
    selectAllNotNull: [],
    MCALS010TemplateIdRequest: mcals010TemplateIdRequest,
    MCALS010BulkOperationRequest: [],
    CreatInitInfoResult: mcals020InitOutDto,
    InitialValuesResult: initialValues,
    MCALS040ProcessData: MCALS040ProcessData,
    MCALS040OtherData: MCALS040OtherData,
    EditInitInfoResult: mcals030InitOutDto,
    MCALS030InitialValuesResult: MCALS030initialValues,
    PopUpQueryEditResult: popUpQueryEdit,
    ShowDialogResult: showDialog020,
    SnackbarInfoResult: snackbarInfo,
    seniFlag: '',
    // 年度対応 start
    initialValuesResultBak:initialValues,
    entryDataIoTemplateSettingId: '',
    openConform: false,
    // 年度対応 end
}

const csvTemplateTypeSlice = createSlice({
    name: 'csvTemplateType',
    initialState,
    reducers: {

        // 画面初期表示時用
        templateInitReducer(state, action: PayloadAction<MCALS010IdRequest>) {
            return state
        },

        // 一覧を設定
        setTemplateResultsReducer(
            state,
            action: PayloadAction<MCALS010EntryDataIoTemPreSelOutDto[]>
        ) {
            state.MCALS010EntryDataIoTemPreSelOutDto = action.payload
            state.selectAllNotNull = action.payload
                .map(i => {
                    if (i.defaultTemplateId === '' || i.defaultTemplateId === null) {
                        return i.entryDataIoTemplateSettingId
                    } else {
                        return ''
                    }
                })
                .filter((i: any) => i !== '')
            return state
        },
        // 新規登録ボタン押下、テンプレート登録画面に遷移
        templateTourokuReducer(
            state,
            action: PayloadAction<MCALS010TemplateIdRequest>
        ) {
            state.seniFlag = action.payload.seniFlag
            return state
        },
        // テンプレート種別"ラジオボタン押下し、再検索
        templateTypeSearchListReducer(
            state,
            action: PayloadAction<MCALS010TemplateTypeRequest>
        ) {
            return state
        },
        // 「テンプレート名」リンク選択時、テンプレート編集画面に遷移
        templateLinkReducer(
            state,
            action: PayloadAction<MCALS010TemplateIdRequest>
        ) {
            return state
        },
        // 「コピー」ボタン押下時、テンプレート登録画面に遷移
        templateCopyReducer(
            state,
            action: PayloadAction<MCALS010TemplateIdRequest>
        ) {
            state.seniFlag = action.payload.seniFlag
            return state
        },
        // 選択対象を表示の状態に変更する。
        templateUpdateEnableReducer(
            state,
            action: PayloadAction<{
                bulk: MCALS010BulkOperationRequest[]
                search: MCALS010TemplateTypeRequest[]
            }>
        ) {
            return state
        },
        // 選択対象を非表示の状態に変更する
        templateUpdateDisableReducer(
            state,
            action: PayloadAction<{
                bulk: MCALS010BulkOperationRequest[]
                search: MCALS010TemplateTypeRequest[]
            }>
        ) {
            return state
        },
        // 表示順変更
        templateOrderReducer(
            state,
            action: PayloadAction<{
                order: MCALS010DisplayOrderRequest[]
                search: MCALS010TemplateTypeRequest[]
            }>
        ) {
            return state
        },
        setCreatInitInfo(state, action: PayloadAction<MCALS020InitOutDto>) {
            state.InitialValuesResult.templateName = action.payload.entryDataIoTemPreSelectTemplateOutDto.templateName
            state.InitialValuesResult.templateType = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.templateType)
            state.InitialValuesResult.entryDataLayoutId = action.payload.entryDataIoTemPreSelectTemplateOutDto.entryDataLayoutId
            state.InitialValuesResult.layoutGroupSelect = {
                value: '',
                label: '',
            }
            state.InitialValuesResult.entryDataLayoutIdOne = action.payload.entryDataLayoutIdOne
            state.InitialValuesResult.entryDataLayoutIdTwo = action.payload.entryDataLayoutIdTwo
            state.InitialValuesResult.entryDataLayoutIdThree = action.payload.entryDataLayoutIdThree
            state.InitialValuesResult.entryDataLayoutIdFour = action.payload.entryDataLayoutIdFour
            state.InitialValuesResult.entryDataLayoutIdFive = action.payload.entryDataLayoutIdFive
            state.InitialValuesResult.entryDataLayoutIdSix = action.payload.entryDataLayoutIdSix
            state.InitialValuesResult.entryDataLayoutIdSeven = action.payload.entryDataLayoutIdSeven
            state.InitialValuesResult.entryDataLayoutIdEight = action.payload.entryDataLayoutIdEight
            state.InitialValuesResult.entryDataLayoutIdNine = action.payload.entryDataLayoutIdNine
            state.InitialValuesResult.entryDataLayoutIdFourteen = action.payload.entryDataLayoutIdFourteen
            state.InitialValuesResult.entryDataLayoutIdSixteen = action.payload.entryDataLayoutIdSixteen
            /* #62022 次期開発2022年9月 start */
            state.InitialValuesResult.entryDataLayoutIdEighteen = action.payload.entryDataLayoutIdEighteen
            /* #62022 次期開発2022年9月 end */
            // MCBリプレース Lot3 start
            state.InitialValuesResult.entryDataLayoutIdTwenty = action.payload.entryDataLayoutIdTwenty
            // MCBリプレース Lot3 end
            state.InitialValuesResult.layoutGroupOutDtos = action.payload.layoutGroupOutDtos
            // 年度対応 start
            state.InitialValuesResult.layoutGroup = action.payload.layoutGroup
            state.InitialValuesResult.layoutGroupName = action.payload.layoutGroupName
            state.InitialValuesResult.targetYear = action.payload.targetYear
            state.InitialValuesResult.targetYearList = action.payload.targetYearList
            if (action.payload.entryDataIoTemPreSelectTemplateOutDto.entryDataIoTemplateSettingId !== null){
                state.InitialValuesResult.entryDataIoTemplateSettingId
                  = action.payload.entryDataIoTemPreSelectTemplateOutDto.entryDataIoTemplateSettingId.toString()
            }
            // 年度対応 end
            state.InitialValuesResult.entryOutputHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.entryHistoryOutputUnit)
            state.InitialValuesResult.seminarInterviewOutputHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.descriptionPartyInterviewHistoryOutputUnit)
            state.InitialValuesResult.interviewScheduleOutputHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.interviewScheduleHistoryOutputUnit)
            state.InitialValuesResult.eventOutputHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.eventEntryHistoryOutputUnit)
            if (action.payload.entryDataIoTemPreSelectTemplateOutDto.selectionOutputUnit === "2") {
                state.InitialValuesResult.selectionOutputSelection = 1
            } else {
                state.InitialValuesResult.selectionOutputSelection = 0
            }
            state.InitialValuesResult.seminarInterviewCancelHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.cancelHistoryOutputExistence)
            if (action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "0"
                /* #62022 次期開発2022年9月 start */
                || action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "8"
                /* #62022 次期開発2022年9月 end */
                // MCBリプレース Lot3 start
                || action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "9") {
                // MCBリプレース Lot3 end
                state.InitialValuesResult.layoutType = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit)
                state.InitialValuesResult.outputUnitHistory = 1
            } else {
                if (action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "6") {
                    state.InitialValuesResult.layoutType = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit)
                } else {
                    if (action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "1" || action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "2" || action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "3" || action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "4" || action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit === "5") {
                        state.InitialValuesResult.layoutType = 1
                        state.InitialValuesResult.outputUnitHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit)
                    }
                }
            }
            state.ShowDialogResult.showDialog = action.payload.showDialog
        },
        setEditInitInfo(state, action: PayloadAction<MCALS030InitOutDto>) {
            state.EditInitInfoResult = action.payload
            state.MCALS030InitialValuesResult.entryDataIoTemplateSettingId = action.payload.entryDataIoTemPreSelectTemplateOutDto.entryDataIoTemplateSettingId
            state.MCALS030InitialValuesResult.templateName = action.payload.entryDataIoTemPreSelectTemplateOutDto.templateName
            // 年度対応 start
            state.MCALS030InitialValuesResult.yearDeterminingMediaFlag = action.payload.entryDataIoTemPreSelectTemplateOutDto.yearDeterminingMediaFlag
            // 年度対応 end
            state.MCALS030InitialValuesResult.templateType = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.templateType)
            state.MCALS030InitialValuesResult.layoutType = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.outputUnit)
            state.MCALS030InitialValuesResult.entryDataLayoutId = action.payload.entryDataIoTemPreSelectTemplateOutDto.entryDataLayoutId
            state.MCALS030InitialValuesResult.layoutGroup = action.payload.entryDataIoTemPreSelectTemplateOutDto.layoutGroup
            state.MCALS030InitialValuesResult.entryOutputHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.entryHistoryOutputUnit)
            state.MCALS030InitialValuesResult.seminarInterviewOutputHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.descriptionPartyInterviewHistoryOutputUnit)
            state.MCALS030InitialValuesResult.interviewScheduleOutputHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.interviewScheduleHistoryOutputUnit)
            state.MCALS030InitialValuesResult.eventOutputHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.eventEntryHistoryOutputUnit)
            if (action.payload.entryDataIoTemPreSelectTemplateOutDto.selectionOutputUnit === "2") {
                state.MCALS030InitialValuesResult.selectionOutputSelection = 1
            } else {
                state.MCALS030InitialValuesResult.selectionOutputSelection = 0
            }
            state.MCALS030InitialValuesResult.seminarInterviewCancelHistory = Number(action.payload.entryDataIoTemPreSelectTemplateOutDto.cancelHistoryOutputExistence)
            state.MCALS030InitialValuesResult.versionNumberTemPre = action.payload.entryDataIoTemPreSelectTemplateOutDto.sysVersionNumber.toString()
            state.MCALS030InitialValuesResult.csvTemplateEditTabOutDto = action.payload.csvTemplateEditTabOutDto
        },
        setCreatInitInfoAgain(state, action: PayloadAction<InitialValues>) {
            state.InitialValuesResult = action.payload
        },
        handleClose(state) {
            state.ShowDialogResult.showDialog = false
        },
        handleEditClose(state) {
            state.EditInitInfoResult.showEditDialog = false
        },
        // MCALS040登録する／更新する／削除するボタン押下し、DB処理
        templateDbProcessReducer(
            state,
            action: PayloadAction<MCALS040ProcessRequest>
        ) {
            return state
        },
        // MCALS040がOpen or Close
        handleDialog040(state, action: PayloadAction<boolean>) {
            state.MCALS040OtherData.showDialog = action.payload
        },
        // MCALS040からMCALS020までモーダル切替
        handleOpen020(state) {
            state.ShowDialogResult.showDialog = true
        },
        // MCALS040からMCALS030までモーダル切替
        handleOpen030(state) {
            state.EditInitInfoResult.showEditDialog = true
        },
        handlePopUpQueryEdit(state, action: PayloadAction<boolean>) {
            state.PopUpQueryEditResult.showEditDialog = action.payload
        },
        loginContentConfirmReducer(state, action: PayloadAction<MCALS020CreateRequest>) {
            return state
        },
        setMCALS040Data(state, action: PayloadAction<InitialValues>) {
            state.InitialValuesResult = action.payload
            state.MCALS040ProcessData.entryDataLayoutId = action.payload.entryDataLayoutId
            state.MCALS040ProcessData.entryDataIoTemplateSettingId = action.payload.entryDataIoTemplateSettingId
            state.MCALS040ProcessData.templateName = action.payload.templateName
            // 年度対応 start
            state.MCALS040ProcessData.targetYear = action.payload.targetYear
            // 年度対応 end
            state.MCALS040ProcessData.templateType = action.payload.templateType.toString()
            state.MCALS040ProcessData.selectedItemListDisplayOrder = action.payload.selectedItemListDisplayOrder
            state.MCALS040ProcessData.selectedItemListEntryDataItemId = action.payload.selectedItemListEntryDataItemId
            state.MCALS040ProcessData.selectedItemListRecruitmentManagementFlagSettingId = action.payload.selectedItemListRecruitmentManagementFlagSettingId
            state.MCALS040ProcessData.selectedItemListRecruitmentManagementFlagName = action.payload.selectedItemList
            state.MCALS040ProcessData.selectedItemListEntryBoxId = action.payload.selectedItemListEntryBoxId
            state.MCALS040ProcessData.selectedItemListSeminarId = action.payload.selectedItemListSeminarId
            state.MCALS040ProcessData.selectedItemListEventId = action.payload.selectedItemListEventId
            state.MCALS040ProcessData.entryOutputHistory = action.payload.entryOutputHistory.toString()
            state.MCALS040ProcessData.seminarInterviewOutputHistory = action.payload.seminarInterviewOutputHistory.toString()
            state.MCALS040ProcessData.interviewScheduleOutputHistory = action.payload.interviewScheduleOutputHistory.toString()
            state.MCALS040ProcessData.eventOutputHistory = action.payload.eventOutputHistory.toString()
            state.MCALS040ProcessData.selectionOutputSelection = action.payload.selectionOutputSelection.toString()
            state.MCALS040ProcessData.seminarInterviewCancelHistory = action.payload.seminarInterviewCancelHistory.toString()
            state.MCALS040ProcessData.recruitmentManagementDivision = action.payload.recruitmentManagementDivision
            state.MCALS040ProcessData.versionNumberTemPre = action.payload.versionNumberTemPre
            state.MCALS040ProcessData.versionNumberItPre = action.payload.versionNumberItPre
            state.MCALS040ProcessData.screenDivision = action.payload.screenDivision
            state.MCALS040OtherData.entryOutputHistoryInput = action.payload.entryOutputHistoryInput
            state.MCALS040OtherData.seminarInterviewOutputHistoryInput = action.payload.seminarInterviewOutputHistoryInput
            state.MCALS040OtherData.interviewScheduleOutputHistoryInput = action.payload.interviewScheduleOutputHistoryInput
            state.MCALS040OtherData.eventOutputHistoryInput = action.payload.eventOutputHistoryInput
            state.MCALS040OtherData.selectionOutputSelectionInput = action.payload.selectionOutputSelectionInput
            state.MCALS040OtherData.seminarInterviewCancelHistoryInput = action.payload.seminarInterviewCancelHistoryInput
            if (action.payload.layoutType === 0 
                || action.payload.layoutType === 6 
                || action.payload.layoutType === 7 
                //#62022 次期開発2022年9月 start
                || action.payload.layoutType === 8 
                //#62022 次期開発2022年9月 end
                // MCBリプレース Lot3 start
                || action.payload.layoutType === 9) {
                // MCBリプレース Lot3 end
                state.MCALS040OtherData.layoutType = action.payload.layoutType.toString()
            } else {
                state.MCALS040OtherData.layoutType = action.payload.outputUnitHistory.toString()
            }
            state.MCALS040OtherData.layoutGroup = action.payload.layoutGroup
        },
        editcontentconfirmationReducer(state, action: PayloadAction<MCALS030UpdateRequest>) {
            return state
        },
        deleteContentConfirmReducer(state, action: PayloadAction<MCALS030TemplateIdRequest>) {
            return state
        },
        setMCALS040EditData(state, action: PayloadAction<MCALS030InitialValues>) {
            state.MCALS030InitialValuesResult = action.payload
            state.MCALS040ProcessData.entryDataLayoutId = action.payload.entryDataLayoutId
            state.MCALS040ProcessData.entryDataIoTemplateSettingId = action.payload.entryDataIoTemplateSettingId
            state.MCALS040ProcessData.templateName = action.payload.templateName
            // 年度対応 start
            state.MCALS040ProcessData.targetYear = action.payload.yearDeterminingMediaFlag
            // 年度対応 end
            state.MCALS040ProcessData.templateType = action.payload.templateType.toString()
            state.MCALS040ProcessData.selectedItemListDisplayOrder = action.payload.selectedItemListDisplayOrder
            state.MCALS040ProcessData.selectedItemListEntryDataItemId = action.payload.selectedItemListEntryDataItemId
            state.MCALS040ProcessData.selectedItemListRecruitmentManagementFlagSettingId = action.payload.selectedItemListRecruitmentManagementFlagSettingId
            state.MCALS040ProcessData.selectedItemListRecruitmentManagementFlagName = action.payload.selectedItemList
            state.MCALS040ProcessData.selectedItemListEntryBoxId = action.payload.selectedItemListEntryBoxId
            state.MCALS040ProcessData.selectedItemListSeminarId = action.payload.selectedItemListSeminarId
            state.MCALS040ProcessData.selectedItemListEventId = action.payload.selectedItemListEventId
            state.MCALS040ProcessData.entryOutputHistory = action.payload.entryOutputHistory.toString()
            state.MCALS040ProcessData.seminarInterviewOutputHistory = action.payload.seminarInterviewOutputHistory.toString()
            state.MCALS040ProcessData.interviewScheduleOutputHistory = action.payload.interviewScheduleOutputHistory.toString()
            state.MCALS040ProcessData.eventOutputHistory = action.payload.eventOutputHistory.toString()
            state.MCALS040ProcessData.selectionOutputSelection = action.payload.selectionOutputSelection.toString()
            state.MCALS040ProcessData.seminarInterviewCancelHistory = action.payload.seminarInterviewCancelHistory.toString()
            state.MCALS040ProcessData.recruitmentManagementDivision = action.payload.recruitmentManagementDivision
            state.MCALS040ProcessData.versionNumberTemPre = action.payload.versionNumberTemPre
            state.MCALS040ProcessData.versionNumberItPre = action.payload.versionNumber
            state.MCALS040ProcessData.screenDivision = action.payload.screenDivision
            state.MCALS040OtherData.entryOutputHistoryInput = action.payload.entryOutputHistoryInput
            state.MCALS040OtherData.seminarInterviewOutputHistoryInput = action.payload.seminarInterviewOutputHistoryInput
            state.MCALS040OtherData.interviewScheduleOutputHistoryInput = action.payload.interviewScheduleOutputHistoryInput
            state.MCALS040OtherData.eventOutputHistoryInput = action.payload.eventOutputHistoryInput
            state.MCALS040OtherData.selectionOutputSelectionInput = action.payload.selectionOutputSelectionInput
            state.MCALS040OtherData.seminarInterviewCancelHistoryInput = action.payload.seminarInterviewCancelHistoryInput
            state.MCALS040OtherData.layoutType = action.payload.layoutType.toString()
            state.MCALS040OtherData.layoutGroup = action.payload.layoutGroup
        },
        setEditInitInfoAgain(state, action: PayloadAction<MCALS030InitialValues>) {
            state.MCALS030InitialValuesResult = action.payload
        },
        setSnackbarInfo(state, action: PayloadAction<SnackbarInfo>) {
            state.SnackbarInfoResult = action.payload
        },
        // 「フォーマットダウンロード"ボタン押下時
        templateformatDownloadReducer(
            state,
            action: PayloadAction<MCALS010TemplateIdRequest>
        ) {
            return state
        },
        // 年度対応 start
        // 「対象年度」プルダウン選択時、テンプレート登録画面を再表示
        templateYearChangeReducer(
            state,
            action: PayloadAction<MCALS010TemplateIdRequest & { formValue: any, refreshMethod: [string, Dispatch<SetStateAction<string>>] }>
        ) {
            state.seniFlag = action.payload.seniFlag
            return state
        },
        setBakupAgain(state, action: PayloadAction<InitialValues>) {
            state.initialValuesResultBak = action.payload
        },
        setTargetYearAgain(state, action: PayloadAction<string>) {
            state.InitialValuesResult.targetYear = action.payload
        },
        closeConformDialog(state) {
            state.openConform = false
        },
        setYearChangeInfo(state) {
            // 画面入力
            state.InitialValuesResult.templateName = state.initialValuesResultBak.templateName
            state.InitialValuesResult.templateType = state.initialValuesResultBak.templateType
            // MCBリプレース Lot3 start
            // 提出リクエスト単位を選択のし、2024以前の年度に切り替えの場合、出力単位は応募者単位を選択する
            if (magiContants.MCB_YEAR_2025 > state.InitialValuesResult.targetYear && state.initialValuesResultBak.layoutType == 9) {
                state.InitialValuesResult.layoutType = 0
            } else {
                state.InitialValuesResult.layoutType = state.initialValuesResultBak.layoutType
            }
            // MCBリプレース Lot3 start
            state.InitialValuesResult.layoutGroup = state.initialValuesResultBak.layoutGroup
            state.InitialValuesResult.layoutGroupName = state.initialValuesResultBak.layoutGroupName
            state.InitialValuesResult.outputUnitHistory = state.initialValuesResultBak.outputUnitHistory

            state.InitialValuesResult.entryOutputHistory = state.initialValuesResultBak.entryOutputHistory
            state.InitialValuesResult.seminarInterviewOutputHistory = state.initialValuesResultBak.seminarInterviewOutputHistory
            state.InitialValuesResult.interviewScheduleOutputHistory = state.initialValuesResultBak.interviewScheduleOutputHistory
            state.InitialValuesResult.eventOutputHistory = state.initialValuesResultBak.eventOutputHistory
            state.InitialValuesResult.selectionOutputSelection = state.initialValuesResultBak.selectionOutputSelection
            state.InitialValuesResult.seminarInterviewCancelHistory = state.initialValuesResultBak.seminarInterviewCancelHistory

            // 選択項目
            state.InitialValuesResult.entryDataLayoutIdOne.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdOne.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdTwo.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdTwo.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdThree.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdThree.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdFour.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdFour.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdFive.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdFive.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdSix.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdSix.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdSeven.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdSeven.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdEight.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdEight.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdNine.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdNine.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdFourteen.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdFourteen.entrySrhCriPreChosedItemOutDtos
            state.InitialValuesResult.entryDataLayoutIdSixteen.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdSixteen.entrySrhCriPreChosedItemOutDtos
            /* #62022 次期開発2022年9月 start */
            state.InitialValuesResult.entryDataLayoutIdEighteen.entrySrhCriPreChosedItemOutDtos
              =  state.initialValuesResultBak.entryDataLayoutIdEighteen.entrySrhCriPreChosedItemOutDtos
            /* #62022 次期開発2022年9月 end */
            // MCBリプレース Lot3 start
            state.InitialValuesResult.entryDataLayoutIdTwenty.entrySrhCriPreChosedItemOutDtos
            =  state.initialValuesResultBak.entryDataLayoutIdTwenty.entrySrhCriPreChosedItemOutDtos
            // MCBリプレース Lot3 end
            let dragDrops: MCALS000DragDropOutDto[] = [
                state.InitialValuesResult.entryDataLayoutIdOne,
                state.InitialValuesResult.entryDataLayoutIdTwo,
                state.InitialValuesResult.entryDataLayoutIdThree,
                state.InitialValuesResult.entryDataLayoutIdFour,
                state.InitialValuesResult.entryDataLayoutIdFive,
                state.InitialValuesResult.entryDataLayoutIdSix,
                state.InitialValuesResult.entryDataLayoutIdSeven,
                state.InitialValuesResult.entryDataLayoutIdEight,
                state.InitialValuesResult.entryDataLayoutIdNine,
                state.InitialValuesResult.entryDataLayoutIdFourteen,
                state.InitialValuesResult.entryDataLayoutIdSixteen,
                /* #62022 次期開発2022年9月 start */
                state.InitialValuesResult.entryDataLayoutIdEighteen,
                /* #62022 次期開発2022年9月 end */
                // MCBリプレース Lot3 start
                state.InitialValuesResult.entryDataLayoutIdTwenty,
                // MCBリプレース Lot3 start
            ]
            let index = Number(state.initialValuesResultBak.entryDataLayoutId) - 1
            if (state.initialValuesResultBak.entryDataLayoutId === '14') {
                index = 9
            } else if (state.initialValuesResultBak.entryDataLayoutId === '16') {
                index = 10
                /* #62022 次期開発2022年9月 start */
            } else if (state.initialValuesResultBak.entryDataLayoutId === '18') {
                index = 11
                /* #62022 次期開発2022年9月 end */
            // MCBリプレース Lot3 start
            } else if (state.initialValuesResultBak.entryDataLayoutId === '20') {
                index = 12
            // MCBリプレース Lot3 end
            }
            let cntOfBefor = dragDrops[index].entrySrhCriPreChosedItemOutDtos.length
            for (let j = 0; j < dragDrops.length; j++) {
                if (dragDrops[j] !== null) {
                    let selectItems = dragDrops[j].entrySrhCriPreChosedItemOutDtos
                    for (let i = 0; i < selectItems.length; i++) {
                        switch (selectItems[i].itemType) {
                            // 個人情報
                            case '0':
                                dragDrops[j].entryDataLytMstClassifyPersonInfoOutDtos
                                    = dragDrops[j].entryDataLytMstClassifyPersonInfoOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // 選考・評価情報
                            case '1':
                                dragDrops[j].entryDataLytMstClassifySelectEvalInfoOutDtos
                                    = dragDrops[j].entryDataLytMstClassifySelectEvalInfoOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // 採用管理フラグ情報
                            case '2':
                                dragDrops[j].entrySrhCriPreChosedItemOutDtos
                                = dragDrops[j].entrySrhCriPreChosedItemOutDtos.filter(
                                    item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // アクション情報
                            case '3':
                                // ダウンロード、応募者単位
                                if (j === 0) {
                                    dragDrops[j].entrySrhCriPreChosedItemOutDtos
                                    = dragDrops[j].entrySrhCriPreChosedItemOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                } else {
                                    if (dragDrops[j].entryDataLytMstClassifyActnInfoOutDtos != null) {
                                        dragDrops[j].entryDataLytMstClassifyActnInfoOutDtos
                                        = dragDrops[j].entryDataLytMstClassifyActnInfoOutDtos.filter(
                                            item => item.uniqueId !== selectItems[i].uniqueId)
                                    }
                                    if (dragDrops[j].entryDataLytMstClassifyActnInfoEditOutDtos != null) {
                                        dragDrops[j].entryDataLytMstClassifyActnInfoEditOutDtos
                                        = dragDrops[j].entryDataLytMstClassifyActnInfoEditOutDtos.filter(
                                            item => item.uniqueId !== selectItems[i].uniqueId)
                                    }
                                }
                                break
                            // WEBテスト結果情報
                            case '4':
                                dragDrops[j].entryDataLytMstClassifyWebTsRultOutDtos
                                = dragDrops[j].entryDataLytMstClassifyWebTsRultOutDtos.filter(
                                    item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // 職歴情報
                            case '5':
                                dragDrops[j].entryDataLytMstClassifyJobOutDtos
                                = dragDrops[j].entryDataLytMstClassifyJobOutDtos.filter(
                                    item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // 面接希望情報
                            case '6':
                                dragDrops[j].entryDataLytMstClassifyInterDesInfoOutDtos
                                = dragDrops[j].entryDataLytMstClassifyInterDesInfoOutDtos.filter(
                                    item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            /* #62022 次期開発2022年9月 start */
                            // 選考・評価（履歴）
                            case '7':
                                dragDrops[j].entryDataLytMstClassifySelectEvalHisOutDtos
                                    = dragDrops[j].entryDataLytMstClassifySelectEvalHisOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            /* #62022 次期開発2022年9月 end */
                            // MCBリプレース Lot3 start
                            // 提出リクエスト情報
                            case '10':
                                dragDrops[j].entryDataLytMstClassifyRequestInfoOutDtos
                                    = dragDrops[j].entryDataLytMstClassifyRequestInfoOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // エントリーシート
                            case '11':
                                dragDrops[j].entryDataLytMstClassifyEntrysheetOutDtos
                                    = dragDrops[j].entryDataLytMstClassifyEntrysheetOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // 履歴書
                            case '12':
                                dragDrops[j].entryDataLytMstClassifyCurriculumVitaeOutDtos
                                    = dragDrops[j].entryDataLytMstClassifyCurriculumVitaeOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // オリジナル設問
                            case '13':
                                dragDrops[j].entryDataLytMstClassifyOriginQuestionOutDtos
                                    = dragDrops[j].entryDataLytMstClassifyOriginQuestionOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // 研究概要書
                            case '14':
                                dragDrops[j].entryDataLytMstClassifyResearchOutlineOutDtos
                                    = dragDrops[j].entryDataLytMstClassifyResearchOutlineOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // ファイル
                            case '15':
                                dragDrops[j].entryDataLytMstClassifyFileOutlineOutDtos
                                    = dragDrops[j].entryDataLytMstClassifyFileOutlineOutDtos.filter(
                                        item => item.uniqueId !== selectItems[i].uniqueId)
                                break
                            // MCBリプレース Lot3 end
                            default:
                                break
                        }
                    }
                }
            }
            state.InitialValuesResult.entryDataLayoutIdOne = dragDrops[0]
            state.InitialValuesResult.entryDataLayoutIdTwo = dragDrops[1]
            state.InitialValuesResult.entryDataLayoutIdThree = dragDrops[2]
            state.InitialValuesResult.entryDataLayoutIdFour = dragDrops[3]
            state.InitialValuesResult.entryDataLayoutIdFive = dragDrops[4]
            state.InitialValuesResult.entryDataLayoutIdSix = dragDrops[5]
            state.InitialValuesResult.entryDataLayoutIdSeven = dragDrops[6]
            state.InitialValuesResult.entryDataLayoutIdEight = dragDrops[7]
            state.InitialValuesResult.entryDataLayoutIdNine = dragDrops[8]
            state.InitialValuesResult.entryDataLayoutIdFourteen = dragDrops[9]
            state.InitialValuesResult.entryDataLayoutIdSixteen = dragDrops[10]
            /* #62022 次期開発2022年9月 start */
            state.InitialValuesResult.entryDataLayoutIdEighteen = dragDrops[11]
            /* #62022 次期開発2022年9月 end */
            // MCBリプレース Lot3 start
            state.InitialValuesResult.entryDataLayoutIdTwenty = dragDrops[12]
            // MCBリプレース Lot3 end
            // アラートメッセージを表示する
            let cntOfAfter = dragDrops[index].entrySrhCriPreChosedItemOutDtos.length
            if (cntOfAfter != cntOfBefor) {
                state.openConform = true
            }
        },
        // 年度対応 end
    }
})

export const {
    templateInitReducer,
    setTemplateResultsReducer,
    templateTourokuReducer,
    templateTypeSearchListReducer,
    templateLinkReducer,
    templateCopyReducer,
    templateUpdateEnableReducer,
    templateUpdateDisableReducer,
    templateOrderReducer,
    setCreatInitInfo,
    setEditInitInfo,
    handleClose,
    handleEditClose,
    handleDialog040,
    handleOpen020,
    handleOpen030,
    templateDbProcessReducer,
    handlePopUpQueryEdit,
    loginContentConfirmReducer,
    setMCALS040Data,
    editcontentconfirmationReducer,
    setCreatInitInfoAgain,
    deleteContentConfirmReducer,
    setMCALS040EditData,
    setEditInitInfoAgain,
    setSnackbarInfo,
    templateformatDownloadReducer,
    // 年度対応 start
    templateYearChangeReducer,
    setYearChangeInfo,
    setTargetYearAgain,
    setBakupAgain,
    closeConformDialog,
    // 年度対応 end
} = csvTemplateTypeSlice.actions
export default csvTemplateTypeSlice.reducer
