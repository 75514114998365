// テンプレート情報
export interface MCALS020EntryDataIoTemPreSelectTemplateOutDto {
    [key: string]: string | number
    entryDataIoTemplateSettingId: string
    templateName: string // テンプレート名
    // 年度対応 start
    yearDeterminingMediaFlag:string    // 年度判別媒体フラグ
    // 年度対応 end
    templateType: string // テンプレート種別
    templateTypeName: string // テンプレート種別名
    outputUnit: string // 出力単位
    outputUnitName: string // 出力単位名
    layoutGroup: string // レイアウトグループ
    displayOrder: number // 表示順
    defaultTemplateId: number // デフォルトテンプレートID
    entryHistoryOutputUnit: string // エントリー履歴出力単位
    descriptionPartyInterviewHistoryOutputUnit: string // 説明会・面接履歴出力単位
    interviewScheduleHistoryOutputUnit: string // 面接日程履歴出力単位
    eventEntryHistoryOutputUnit: string // イベント参加履歴出力単位
    selectionOutputUnit: string // 選考出力単位
    cancelHistoryOutputExistence: string // キャンセル履歴出力有無
    entryDataLayoutId: string // エントリーデータレイアウトID
    sysVersionNumber: number // sysバージョン番号
}

// 選択済項目情報
export interface MCALS020EntrySrhCriPreChosedItemOutDto {
    [key: string]: number | string
    entryDataIoTemplateSettingId: number // エントリーデータ入出力テンプレート設定ID
    entryDataItemId: number // エントリーデータ項目ID
    recruitmentManagementFlagSettingId: number // 採用管理フラグ設定ID
    entryBoxId: number // エントリーボックスID
    seminarId: number // セミナーID
    eventId: number // イベントID
    displayOrder: number // 表示順
    sysVersionNumber: number // sysバージョン番号
    selectItemName: string // 選択済項目名
    defaultFlag: string // デフォルトフラグ
    uniqueId: string // 一意識別
    itemType: string // 項目種別
    itemClassification: string // 項目分類
}

// 初期表示検索結果を
export interface MCALS000DragDropOutDto {
    [key: string]: any
    entrySrhCriPreChosedItemOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 選択済項目情報
    entryDataLytMstClassifyPersonInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 個人情報
    entryDataLytMstClassifySelectEvalInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 選考・評価情報
    entryDataLytMstClassifyRecMagFlgOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 採用管理フラグ情報
    entryDataLytMstClassifyActnInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // アクション情報
    entryDataLytMstClassifyActnInfoEditOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // アクション情報
    entryDataLytMstClassifyWebTsRultOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // WEBテスト結果情報
    entryDataLytMstClassifyJobOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 職歴情報
    entryDataLytMstClassifyInterDesInfoOutDtos: MCALS020EntrySrhCriPreChosedItemOutDto[] // 面接希望情報
}

export interface MCALS030InitOutDto {
    [key: string]: boolean | any
    entryDataIoTemPreSelectTemplateOutDto: MCALS020EntryDataIoTemPreSelectTemplateOutDto
    csvTemplateEditTabOutDto: MCALS000DragDropOutDto
    showEditDialog: boolean
}

const mcals030InitOutDto = {
    entryDataIoTemPreSelectTemplateOutDto: {
        entryDataIoTemplateSettingId: '',
        templateName: '',
        // 年度対応 start
        yearDeterminingMediaFlag: '',
        // 年度対応 end
        templateType: '0',
        templateTypeName: '',
        outputUnit: '0',
        outputUnitName: '',
        layoutGroup: '',
        displayOrder: -1,
        defaultTemplateId: -1,
        entryHistoryOutputUnit: '',
        descriptionPartyInterviewHistoryOutputUnit: '',
        interviewScheduleHistoryOutputUnit: '',
        eventEntryHistoryOutputUnit: '',
        selectionOutputUnit: '',
        cancelHistoryOutputExistence: '',
        entryDataLayoutId: '',
        sysVersionNumber: -1
    },
    csvTemplateEditTabOutDto: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
    },
    showEditDialog: false
}

export interface MCALS030InitialValues {
    [key: string]: any
    entryDataIoTemplateSettingId: string
    entryDataLayoutId: string
    templateName: string
    // 年度対応 start
    yearDeterminingMediaFlag:string
    // 年度対応 end
    templateType: number
    layoutType: number
    outputUnitHistory: number
    layoutGroup: string
    layoutGroupName: string
    dragDropValue: MCALS020EntrySrhCriPreChosedItemOutDto[] 
    entryOutputHistory: number
    seminarInterviewOutputHistory: number
    interviewScheduleOutputHistory: number
    eventOutputHistory: number
    selectionOutputSelection: number
    selectedItemListEntryDataItemId: string[] // 選択済項目_エントリーデータ項目ID一覧
    selectedItemListRecruitmentManagementFlagSettingId: string[] // 選択済項目_採用管理フラグ設定ID一覧
    selectedItemListEntryBoxId: string[] // 選択済項目_エントリーボックスID一覧
    selectedItemListSeminarId: string[] // 選択済項目_セミナーID一覧
    selectedItemListEventId: string[] // 選択済項目_イベントID一覧
    selectedItemListDisplayOrder: string[] // 選択済項目_表示順一覧
    selectedItemList: string[] // 選択済項目_選択済項目名一覧
    selectedItemListItemType: string[] // 項目種別
    entryOutputHistoryInput: string // エントリー履歴_出力する履歴 入力フラグ
    seminarInterviewOutputHistoryInput: string // 説明会・面接履歴_出力する履歴 入力フラグ
    interviewScheduleOutputHistoryInput: string // 面接日程履歴_出力する履歴 入力フラグ
    eventOutputHistoryInput: string // イベント参加履歴_出力する履歴 入力フラグ
    selectionOutputSelectionInput: string // 選考フロー_出力する選考フロー 入力フラグ
    screenDivision: string
    recruitmentManagementDivision: string
    versionNumberTemPre: string // テンプレート情報バージョン番号
    versionNumber: string[] // テンプレート情報（選択済項目）バージョン番号
    csvTemplateEditTabOutDto: MCALS000DragDropOutDto
}

const MCALS030initialValues = {
    entryDataIoTemplateSettingId: '',
    entryDataLayoutId: '',
    templateName: '',
    // 年度対応 start
    yearDeterminingMediaFlag: '',
    // 年度対応 end
    templateType: 0,
    layoutType: 0,
    outputUnitHistory: 0,
    layoutGroup: '',
    layoutGroupName: '',
    dragDropValue: [],
    entryOutputHistory: 0,
    seminarInterviewOutputHistory: 0,
    interviewScheduleOutputHistory: 0,
    eventOutputHistory: 0,
    selectionOutputSelection: 0,
    selectedItemListEntryDataItemId: [], // 選択済項目_エントリーデータ項目ID一覧
    selectedItemListRecruitmentManagementFlagSettingId: [], // 選択済項目_採用管理フラグ設定ID一覧
    selectedItemListEntryBoxId: [], // 選択済項目_エントリーボックスID一覧
    selectedItemListSeminarId: [], // 選択済項目_セミナーID一覧
    selectedItemListEventId: [], // 選択済項目_イベントID一覧
    selectedItemListDisplayOrder: [], // 選択済項目_表示順一覧
    selectedItemList: [], // 選択済項目_選択済項目名一覧
    selectedItemListItemType: [], // 項目種別
    entryOutputHistoryInput: '', // エントリー履歴_出力する履歴 入力フラグ
    seminarInterviewOutputHistoryInput: '', // 説明会・面接履歴_出力する履歴 入力フラグ
    interviewScheduleOutputHistoryInput: '', // 面接日程履歴_出力する履歴 入力フラグ
    eventOutputHistoryInput: '', // イベント参加履歴_出力する履歴 入力フラグ
    selectionOutputSelectionInput: '', // 選考フロー_出力する選考フロー 入力フラグ
    screenDivision: 'update',
    recruitmentManagementDivision: '',
    versionNumberTemPre: '', // テンプレート情報バージョン番号
    versionNumber: [], // テンプレート情報（選択済項目）バージョン番号
    csvTemplateEditTabOutDto: {
        entrySrhCriPreChosedItemOutDtos: [], // 選択済項目情報
        entryDataLytMstClassifyPersonInfoOutDtos: [], // 個人情報
        entryDataLytMstClassifySelectEvalInfoOutDtos: [], // 選考・評価情報
        entryDataLytMstClassifyRecMagFlgOutDtos: [], // 採用管理フラグ情報
        entryDataLytMstClassifyActnInfoOutDtos: [], // アクション情報
        entryDataLytMstClassifyActnInfoEditOutDtos: [], // アクション情報
        entryDataLytMstClassifyWebTsRultOutDtos: [], // WEBテスト結果情報
        entryDataLytMstClassifyJobOutDtos: [], // 職歴情報
        entryDataLytMstClassifyInterDesInfoOutDtos: [], // 面接希望情報
    },
}

export interface PopUpQueryEdit {
    [key: string]: boolean
    showEditDialog: boolean
}

const popUpQueryEdit = {
    showEditDialog: false
}

const entryList = [
    { id: 'entryLatestOutput', label: '最新の履歴を出力', value: 0 },
    { id: 'entryAllOutput', label: '初回の履歴を出力', value: 1 },
]

const seminarInterviewList = [
    { id: 'seminarInterviewLatestOutput', label: '最新の履歴を出力', value: 0 },
    { id: 'seminarInterviewAllOutput', label: '初回の履歴を出力', value: 1 },
]

const interviewScheduleList = [
    { id: 'interviewScheduleLatestOutput', label: '最新の履歴を出力', value: 0 },
    { id: 'interviewScheduleAllOutput', label: '初回の履歴を出力', value: 1 },
]

const eventList = [
    { id: 'eventLatestOutput', label: '最新の履歴を出力', value: 0 },
    { id: 'eventAllOutput', label: '初回の履歴を出力', value: 1 },
]

const selectionList = [
    { id: 'selectionLatestOutput', label: '最新の選考フローを出力', value: 0 },
    // #57536 start
    { id: 'selectionSelectOutput', label: '出力時に選考フローを指定', value: 1 },
    // #57536 end
]

const cancelHistoryList = [
    { id: 'seminarInterviewCancelHistoryOutput', label: '出力する', value: 0 },
    { id: 'seminarInterviewCancelHistoryNoOutput', label: '出力しない', value: 1 },
]

export {
    MCALS030initialValues,
    entryList,
    seminarInterviewList,
    mcals030InitOutDto,
    interviewScheduleList,
    eventList,
    selectionList,
    cancelHistoryList,
    popUpQueryEdit,
}