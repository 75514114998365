import { all, call, put, takeLatest } from 'redux-saga/effects'
import history from 'utils/history'
import { routeList } from 'routes/routes'
import { replaceToOriginUrl } from 'utils/misc'
import {
  init,
  initSuccess,
  loginMagi,
  loginSuccess,
  setInitFlag,
  setFirst,
  initCaptcha,
  getGtmId,
  setGtmId,
  setAlerted,
} from 'reducers/companyLoginReducer'
import { openModal } from 'reducers/messageReducer'
import {
  loginInitRequest,
  loginMagiRequest,
  recaptchaInitRequest,
  gtmInitRequest,
  checkShuTenStatus
} from 'apis/MCACS010Api'
import { ALERT_FLAG } from 'utils/contants'

function* initSaga() {
  try {
    const data = yield call(loginInitRequest)
    yield put(initSuccess(data))
    yield put(setInitFlag(true))
    yield put(initCaptcha(yield call(recaptchaInitRequest)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* loginSaga(action: ReturnType<typeof loginMagi>) {
  try {
    const data = yield call(loginMagiRequest, action.payload)
    const transitionSourceFlag = data.transitionSourceFlag
    const target = window.location.href
    if (transitionSourceFlag === "0") {
      //遷移元フラグ「0：パスワード変更」
      const params = { transitionSourceFlag: data.transitionSourceFlag, companyAccountId: data.companyAccountId, magiApplicationStatus: data.magiApplicationStatus, url: target };
      const path = {
        pathname: routeList.temPassword,
        state: params,
      }
      history.push(path)
      return;
    } else if (transitionSourceFlag === "1") {
      //遷移元フラグ「1：利用規約への同意」
      // #91157 就職転職分離 start
      const params = { companyAccountId: data.companyAccountId, url: target, miwsEnvFlag: data.miwsEnvFlag };
      // #91157 就職転職分離 end
      const path = {
        pathname: routeList.checkStatute,
        state: params,
      }
      history.push(path)
      return;
    } else if (transitionSourceFlag === "2") {
      // 遷移元フラグ「2：準備中」
      history.push(routeList.readying)
      return;
    } else {
      //遷移元フラグ「3：メニュー」
      const token = data.tokenHead + ' ' + data.token
      // #9675 就職転職分離 start
      yield call(checkShuTenStatus, token)
      // #9675 就職転職分離 end
      yield put(loginSuccess({ token }))
      replaceToOriginUrl(target)
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
  yield put(setFirst(false))
}

function* gtmSaga() {
  try {
    const data = yield call(gtmInitRequest)
    yield put(setGtmId(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

function* alertSaga(action: ReturnType<typeof setAlerted>) {
    try {
      localStorage.setItem(ALERT_FLAG, action.payload)
    } catch (error) {
      yield put(openModal(error.message))
    }
  }

export default function* userSaga() {
  yield all([
    takeLatest(init, initSaga),
    takeLatest(loginMagi, loginSaga),
    takeLatest(getGtmId, gtmSaga),
    takeLatest(setAlerted, alertSaga),
  ])
}
