/** 画面表示項目 */
export interface ScreenDisplayItems {
  title: Title
  messageInput: MessageInput  
  sendTargetConfirmation: SendTargetConfirmation
  sendTimeInput: SendTimeInput
  signature: Signature[]
}

/** タイトルエリア */
export interface Title {
  selectedTemplateSettingId: string
  messageTemplate: MessageTemplate[]
}

export interface MessageTemplate {
  messageTemplateSettingId: number
  templateName: string
  templateType: string
  destinationDivision: string
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  reservedScheduleSendFlag: string
  sequence: number
  sysVersionNumber: string // sysバージョン番号
}

/** 送信対象確認エリア */
export interface SendTargetConfirmation {
  destinationSelection: string
  sendTarget: SendTarget[]
  mcbMessageDestination: McbSendTarget[]
  searchCriteriaName: string
  searchCriteria: string
  searchCriteriaInfo: SearchCriteriaInfo
  filterCondition: string
}

export interface SearchCriteriaInfo {
  entrySearchCriteriaSettingId: string // エントリー検索条件設定ID
  searchCriteriaName: string           // 検索条件名
  searchCriteria: string               // 検索条件
  sysVersionNumber: number             // sysバージョン番号
}

/** 送信対象 */
export interface SendTarget {
  entryId: string
  fullName: string
  mailAddress: string
  jobSeekerId: string
  mynaviMemberInfo: string
  schoolName: string
  facultyName: string
  subjectName: string
  applicationDate: string
  mcbUseStatusName: string
  viewEndTime: Date
  // #MCB1.5次開発 #8997 START
  readableFlag: string
  // #MCB1.5次開発 #8997 END
}

/** 送信対象 */
export interface McbSendTarget {
  entryId: string
  selectionManagementId: string
  jobSeekerId: string
  mycareercd: string
  mcbMemberId: string
  mcbSubmissionRequestId: string
  jobSeekerByCompanyId: string
  submissionRequestId: string
  coordinationFormerJobSeekerIdentificationKey: string
  notSend: boolean
}

/** メッセージ入力エリア */
export interface MessageInput {
  exampleLink: string
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  targetInput: string
  attachment: Attachment[]
  templateAttachmentId: string[]
  homePage: HomePage[]
  previewSubject: string
  previewBody: string
  shouldShowMessageInput: ShouldShowMessageInput  
  sysVersionNumber: string // sysバージョン番号
}

export interface ShouldShowMessageInput {
  senderCompanyName: boolean
  senderMailAddress: boolean
  subject: boolean
  body: boolean
  attachment: boolean
  homePage: boolean
}

/** 送信日時入力エリア */
export interface SendTimeInput {
  transmission: string
  sendPlanTime: Date | null
}

/** 非表示項目 */
export interface HiddenItems {
  
  replyFromMessageSendId: string
}

export interface Attachment {
  attachmentFileName: string
  attachmentId: string|null
  attachmentName: string
}

export interface HomePage {
  homePageTitle: string
  homePageUrl: string
}

export interface Signature {
  registrationName: string
  signSettingId: string
  signature: string
}

export interface RegisterValue {
  messageType: string[]
  messageTemplateSettingId: string|null
  mcbMessageDestination: McbSendTarget[]
  destinationId: string[]
  memberType: string[]
  destination: number
  senderCompanyName: string
  senderMailAddress: string
  subject: string
  body: string
  attachment: any[]
  attachmentId: Array<string|null>
  templateAttachmentId: string[]
  templateAttachmentName: string[]
  homePageTitle1: string|null
  homePageTitle2: string|null
  homePageTitle3: string|null
  homePageUrl1: string|null
  homePageUrl2: string|null
  homePageUrl3: string|null
  sendTimer: number
  sendPlanTime: string|null
  fileManagementId: string[]
  fileManagementName: string[]
  entryId: number[]
  sysVersionNumber: string
  messageSendId: string
  sendTimeFlag: string
  destinationDivision: string
  title: Title
  messageInput: MessageInput
  sendTargetConfirmation: SendTargetConfirmation
  sendTimeInput: SendTimeInput
  signature: Signature[]
  fullName: string
  searchCriteriaJson: string
}

const title: Title = {
  selectedTemplateSettingId: '',
  messageTemplate: [],
}

const shouldShowMessageInput: ShouldShowMessageInput = {
  senderCompanyName: false,
  senderMailAddress: false,
  subject: false,
  body: false,
  attachment: false,
  homePage: false,
}

const messageInput: MessageInput = {
  exampleLink: '',
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  targetInput: '',
  attachment: [],
  templateAttachmentId: [],
  homePage: [],
  previewSubject: '',
  previewBody: '',
  shouldShowMessageInput: shouldShowMessageInput,
  sysVersionNumber: '',
}

const sendTimeInput: SendTimeInput = {
  transmission: '0',
  sendPlanTime: null,
}

const sendTarget: SendTarget = {
  entryId: '',
  fullName: '',
  mailAddress: '',
  jobSeekerId: '',
  mynaviMemberInfo: '',
  schoolName: '',
  facultyName: '',
  subjectName: '',
  applicationDate: '',
  mcbUseStatusName: '',
  viewEndTime: new Date,
  // #MCB1.5次開発 #8997 START
  readableFlag: '',
  // #MCB1.5次開発 #8997 END
}

const mcbMessageDestination: McbSendTarget = {
  entryId: '',
  selectionManagementId: '',
  jobSeekerId: '',
  mycareercd: '',
  mcbMemberId: '',
  mcbSubmissionRequestId: '',
  jobSeekerByCompanyId: '',
  submissionRequestId: '',
  coordinationFormerJobSeekerIdentificationKey: '',
  notSend: false,
}

const searchCriteriaInfo: SearchCriteriaInfo = {
  entrySearchCriteriaSettingId: '',
  searchCriteriaName: '',
  searchCriteria: '',
  sysVersionNumber: 0,
}

const sendTargetConfirmation: SendTargetConfirmation = {
  destinationSelection: '3',
  sendTarget: [],
  mcbMessageDestination: [],
  searchCriteriaName: '',
  searchCriteria: '',
  searchCriteriaInfo: searchCriteriaInfo,
  filterCondition: '',
}

const signature: Signature[] = []

const registerValue: RegisterValue = {
  messageType: [],
  messageTemplateSettingId: '',
  mcbMessageDestination: [],
  destinationId: [],
  memberType: [],
  destination: 0,
  senderCompanyName: '',
  senderMailAddress: '',
  subject: '',
  body: '',
  attachment: [],
  attachmentId: [],
  templateAttachmentId: [],
  templateAttachmentName: [],
  homePageTitle1: null,
  homePageTitle2: null,
  homePageTitle3: null,
  homePageUrl1: null,
  homePageUrl2: null,
  homePageUrl3: null,
  sendTimer: 0,
  sendPlanTime: null,
  fileManagementId: [],
  fileManagementName: [],
  entryId: [],
  sysVersionNumber: '',
  messageSendId: '',
  sendTimeFlag: '',
  destinationDivision: '',
  title: title,
  messageInput: messageInput,
  sendTargetConfirmation: sendTargetConfirmation,
  sendTimeInput: sendTimeInput,
  signature: signature,
  fullName: '',
  searchCriteriaJson: '',
}

export {
  title,
  messageInput,
  shouldShowMessageInput,
  sendTimeInput,
  sendTarget,
  mcbMessageDestination,
  sendTargetConfirmation,
  registerValue,
  signature
}
