import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects'

import {
  templateInitReducer,
  setTemplateResultsReducer,
  templateTourokuReducer,
  templateTypeSearchListReducer,
  templateLinkReducer,
  templateCopyReducer,
  templateUpdateEnableReducer,
  templateUpdateDisableReducer,
  templateOrderReducer,
  setCreatInitInfo,
  templateDbProcessReducer,
  handleOpen020,
  handleDialog040,
  loginContentConfirmReducer,
  setEditInitInfo,
  editcontentconfirmationReducer,
  handleClose,
  handleOpen030,
  deleteContentConfirmReducer,
  handleEditClose,
  setSnackbarInfo,
  templateformatDownloadReducer,
  // 年度対応 start
  templateYearChangeReducer,
  setYearChangeInfo,
  setBakupAgain,
  setTargetYearAgain,
  // 年度対応 end
} from 'reducers/csvTemplateReducer'
import { openSnackbar, openModal } from 'reducers/messageReducer'
import {
  templateTypeSerachRequestApi,
  templateInitRequestApi,
  templateTourokuRequestApi,
  templateLinkRequestApi,
  templateCopyRequestApi,
  templateUpdateEnableApi,
  templateUpdateDisableApi,
  templateOrderApi,
  templateFormatDownloadApi,
} from 'apis/MCALS010Api'
import { MCALS020InitRequest, MCALS020RegisterRequest } from 'apis/MCALS020Api'
import {
  MCALS030InitRequest,
  MCALS030updateRequest,
  MCALS030deleteRequest,
} from 'apis/MCALS030Api'
import { magiContants } from 'utils/contants'
import { setTemplateDbProcessApi, initRequestApi } from 'apis/MCALS040Api'
import { getMessage, getDynamicMessageId } from 'common/messageUtil'
import { isEmpty } from 'common/generalUtil'

// 画面初期表示
function* templateInitSaga(action: ReturnType<typeof templateInitReducer>) {
  try {
    const data = yield call(templateInitRequestApi, action.payload)
    yield put(setTemplateResultsReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// テンプレート種別"ラジオボタン押下
function* templateTypeSearchSaga(
  action: ReturnType<typeof templateTypeSearchListReducer>
) {
  try {
    const data = yield call(templateTypeSerachRequestApi, action.payload)
    yield put(setTemplateResultsReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 新規登録ボタン押下、テンプレート登録画面に遷移
function* templateTourokuSaga(
  action: ReturnType<typeof templateTourokuReducer>
) {
  try {
    // 業務チェック
    yield call(templateTourokuRequestApi, action.payload)
    // 新規登録画面へ遷移する
    const data = yield call(MCALS020InitRequest, action.payload)
    yield put(setCreatInitInfo(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「テンプレート名」リンク選択時、テンプレート編集画面に遷移
function* templateLinkSaga(action: ReturnType<typeof templateLinkReducer>) {
  try {
    // テンプレート編集画面へ遷移する
    yield call(templateLinkRequestApi, action.payload)
    const editdata = yield call(MCALS030InitRequest, action.payload)
    yield put(setEditInitInfo(editdata))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 「コピー」ボタン押下時、テンプレート登録画面に遷移
function* templateCopySaga(action: ReturnType<typeof templateCopyReducer>) {
  try {
    // 業務チェック
    yield call(templateCopyRequestApi, action.payload)
    // 登録画面へ遷移する
    const values = yield call(MCALS020InitRequest, action.payload)
    yield put(setCreatInitInfo(values))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 選択対象を表示の状態に変更する
function* templateUpdateEnableSaga(
  action: ReturnType<typeof templateUpdateEnableReducer>
) {
  try {
    // 選択対象を表示の状態に変更
    yield call(templateUpdateEnableApi, action.payload.bulk)
    // 画面再検索
    const data = yield call(
      templateTypeSerachRequestApi,
      action.payload.search[0]
    )
    yield put(setTemplateResultsReducer(data))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCALS010_006)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 選択対象を非表示の状態に変更する
function* templateUpdateDisableSaga(
  action: ReturnType<typeof templateUpdateDisableReducer>
) {
  try {
    // 選択対象を非表示の状態に変更
    yield call(templateUpdateDisableApi, action.payload.bulk)
    // 画面再検索
    const data = yield call(
      templateTypeSerachRequestApi,
      action.payload.search[0]
    )
    yield put(setTemplateResultsReducer(data))
    yield put(openSnackbar(getMessage(magiContants.MESSAGECODE_MCALS010_005)))
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 表示順変更
function* templateOrderSaga(action: ReturnType<typeof templateOrderReducer>) {
  try {
    // 選表示順変更
    yield call(templateOrderApi, action.payload.order)
    // 画面再検索
    const data = yield call(
      templateTypeSerachRequestApi,
      action.payload.search[0]
    )
    yield put(setTemplateResultsReducer(data))
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// 登録する／更新する／削除するボタン押下
function* templateDbProcessSaga(
  action: ReturnType<typeof templateDbProcessReducer>
) {
  try {
    const data040 = yield call(setTemplateDbProcessApi, action.payload)
    // 登録する／更新する／削除するは正常終了した場合、
    // CSVテンプレート一覧画面に戻り共通方針の完了バナーメッセージを表示する。
    if (
      getDynamicMessageId(data040) !== magiContants.MESSAGECODE_MCALS040_012
    ) {
      yield put(handleDialog040(false))
      const data = yield call(templateTypeSerachRequestApi, action.payload)
      yield put(setTemplateResultsReducer(data))
      yield put(openSnackbar(getDynamicMessageId(data040)))
    } else {
      if (action.payload.screenDivision === 'insert') {
        yield put(handleDialog040(false))
        yield put(handleOpen020())
      } else {
        yield put(handleDialog040(false))
        yield put(handleOpen030())
      }
      yield put(setSnackbarInfo(data040))
    }
  } catch (error) {
    // 登録する／更新する／削除するは異常終了した場合、
    // CSVテンプレート登録（編集）画面に戻り、エラーメッセージを表示する。
    if (action.payload.screenDivision === 'insert') {
      yield put(handleDialog040(false))
      yield put(handleOpen020())
    } else {
      yield put(handleDialog040(false))
      yield put(handleOpen030())
    }
    yield put(openModal(error.message))
  }
}

// "登録内容を確認する"ボタン押下時
function* loginContentConfirmSaga(
  action: ReturnType<typeof loginContentConfirmReducer>
) {
  try {
    const data = yield call(MCALS020RegisterRequest, action.payload)
    if (data !== 'Successed') {
      yield put(setSnackbarInfo(data))
    } else {
      yield put(handleClose())
      yield call(initRequestApi)
      yield put(handleDialog040(true))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// "削除内容を確認する"ボタン押下時
function* deleteContentConfirmSaga(
  action: ReturnType<typeof deleteContentConfirmReducer>
) {
  try {
    const data = yield call(MCALS030deleteRequest, action.payload)
    if (data === 'Successed') {
      yield call(initRequestApi)
      yield put(handleDialog040(true))
      yield put(handleEditClose())
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// "更新内容を確認する"ボタン押下時
function* editcontentconfirmationSaga(
  action: ReturnType<typeof editcontentconfirmationReducer>
) {
  try {
    const editdata = yield call(MCALS030updateRequest, action.payload)
    if (editdata !== 'Successed') {
      yield put(setSnackbarInfo(editdata))
    } else {
      yield put(handleEditClose())
      yield call(initRequestApi)
      yield put(handleDialog040(true))
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
}

// "フォーマットダウンロード"ボタン押下時
function* templateformatDownloadSaga(action: ReturnType<typeof templateformatDownloadReducer>) {
  try {
    const data = yield call(templateFormatDownloadApi, action.payload);
    if (data.data.size === 0) {
      yield put(openModal("MAACOMMON-009"))
    } else {
    var blob = new Blob([data.data]);
    let name1 = action.payload.templateName;
    // 不正な文字 \/:*?"<>|
    let name2 = name1.replace(/[*?<>"|/\\:]/g, '');
    // 先頭に半角スペースの場合
    while (name2.startsWith(magiContants.BLANK) || name2.startsWith(magiContants.BLANK_ZENKAKU)) {
      name2 = name2.substring(1, name2.length);
    }
    // 空の場合は「format.csv」
    if (isEmpty(name2)) {
      name2 = name2.replace("", "format")
    }
    const fileName = name2 + '.csv';
      if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(blob, fileName)
      } else {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      }
    }
  } catch (error) {
    yield put(openModal(error.message))
  }
  
}

// 年度対応 start
// 「対象年度」プルダウン選択時、テンプレート登録画面を再表示
function* templateYearChangeSaga(action: ReturnType<typeof templateYearChangeReducer>) {
  try {
    // 登録画面を再表示
    const values = yield call(MCALS020InitRequest, action.payload)
    yield put(setBakupAgain(action.payload.formValue))
    yield put(setTargetYearAgain(action.payload.targetYear))
    yield put(setCreatInitInfo(values))
    yield put(setYearChangeInfo())
    yield action.payload.refreshMethod[1](action.payload.refreshMethod[0] == '0' ? '1' : '0')
  } catch (error) {
    yield put(openModal(error.message))
  }
}
// 年度対応 end

export default function* csvTemplateSaga() {
  yield all([
    takeLatest(templateInitReducer, templateInitSaga),
    takeEvery(templateTypeSearchListReducer, templateTypeSearchSaga),
    takeEvery(templateTourokuReducer, templateTourokuSaga),
    takeEvery(templateLinkReducer, templateLinkSaga),
    takeEvery(templateCopyReducer, templateCopySaga),
    takeEvery(templateUpdateEnableReducer, templateUpdateEnableSaga),
    takeEvery(templateUpdateDisableReducer, templateUpdateDisableSaga),
    takeEvery(templateOrderReducer, templateOrderSaga),
    takeEvery(templateDbProcessReducer, templateDbProcessSaga),
    takeEvery(loginContentConfirmReducer, loginContentConfirmSaga),
    takeEvery(editcontentconfirmationReducer, editcontentconfirmationSaga),
    takeEvery(deleteContentConfirmReducer, deleteContentConfirmSaga),
    takeEvery(templateformatDownloadReducer, templateformatDownloadSaga),
    // 年度対応 start
    takeEvery(templateYearChangeReducer, templateYearChangeSaga),
    // 年度対応 end
  ])
}
