import {
  colors,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Tab,
  Tabs as MuiTabs,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { TabPanel } from 'componentsHsc'
import { Formik, FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useInViewport } from 'ahooks'
import Applicant from './Applicant'
import Basic from './Basic'
import RecruitmentManagement from './RecruitmentManagement'
import School from './School'
import {
  initSearchCondition,
  SearchCondition as EntryListSearchCondition,
} from 'pages/MCAXS010/searchConditionConfig'
import {
  fromEntryList,
  toEntryList,
  SearchCondition,
} from './Config/searchConditionConfig'
import { RootState } from 'reducers'
import { MCAXS010SearchRequest } from 'types/MCAXS010/MCAXS010SearchRequest'
import {
  search,
  setSearchCondition,
  setDisplaySearchCriteriaTitleOpenFlag,
} from 'reducers/applicantListReducer'
import MCARS030 from 'pages/MCARS030'
import { magiContants as contants, magiContants } from '../../utils/contants'
import { getMessage } from 'common/messageUtil'
import { openModal } from 'reducers/messageReducer'
import {
  setDialogOpen,
  getSelectCriteriaList,
} from 'reducers/selectCriteriaManagementReducer'
import TabItem from './TabItem'
import SearchRequestValidation from 'validations/MCAXS351/SearchRequestValidation'
import {
  setOpen,
  setEntryReplyModalOpen,
  setManagementModalOpen,
  setMCARS030SCriteriaParam,
  setCurrentTab,
  setCurrentTabMcb,
  getMcaxs371Init,
} from 'reducers/advancedSearchReducer'
import ManagementModal from './Modal/ManagementModal'
import EntryReplyModal from './Modal/EntryReplyModal'
import OverlaySearch from 'componentsHsc/OverlaySearch'
import { TABLE_CONTAINER_ID, SEARCH_BUTTON_ID } from 'utils/contants'
import SubmissionRequest from './SubmissionRequest'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '0 auto',
  },
  tab: {
    backgroundColor: '#e6e6e6',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
      opacity: 1,
    },
  },
  textTransform: {
    textTransform: 'none',
  },
  title: {
    minWidth: '218px',
    '&::before': {
      backgroundSize: '100% auto !important',
      fontSize: '2.4rem !important',
    },
  },
  disablePointerEvents: {
    pointerEvents: 'none',
  },
  NoResult: {
    marginTop: '50px',
    marginBottom: '50px',
    textAlign: 'center',
    fontSize: '2.1rem',
  },
}))

const ExpansionPanelDetails = withStyles({
  root: {
    display: 'block',
    margin: 25,
  },
})(AccordionDetails)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: colors.blue[600],
    height: 8,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    flexGrow: 0,
  },
  expanded: {},
})(AccordionSummary)

const Tabs = withStyles({
  root: {
    justifyContent: 'center',
  },
  scroller: {
    flexGrow: 0,
  },
})(MuiTabs)

export type FormControl = FormikProps<SearchCondition>

export const isOverlayShow = ({
  open,
  tableInViewport,
  buttonInViewport
}: {
  open: boolean,
  tableInViewport: boolean | undefined,
  buttonInViewport: boolean | undefined
}) =>
  open && !tableInViewport && !buttonInViewport

const TabIndex = {
  basic: 0,
  school: 1,
  applicant: 2,
  recruitmentManagement: 3,
  submissionRequest: 4,
}

interface Props {
  initialValues?: EntryListSearchCondition
}

//ページトップへスクロール
const scrollToAnchor = () => {
  let anchorElement = document.getElementById('top')
  if (anchorElement) {
    anchorElement.scrollIntoView()
  }
}

const AdvancedSearch = ({
  initialValues = initSearchCondition,
}: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [disableTouch, setDisableTouch] = useState(false)

  const tableInViewport = useInViewport(
    () => document.querySelector(`#${TABLE_CONTAINER_ID}`)
  )

  const buttonInViewport = useInViewport(
    () => document.querySelector(`#${SEARCH_BUTTON_ID}`)
  )

  const {
    open,
    entryReplyModalOpen,
    managementModalOpen,
    MCARS030SCriteriaParam,
    orderObjArray,
    currentTab,
  } = useSelector((state: RootState) => state.advancedSearch)

  // ExpansionPanel
  const handleClick = () => {
    dispatch(setOpen(!open))
  }

  // Tabs
  const handleChange = (_: any, newValue: number) => {
    if (newValue === TabIndex.submissionRequest) {
      dispatch(setCurrentTabMcb(newValue))
    } else {
      dispatch(setCurrentTab(newValue))
    }
  }

  const searchRequest = useSelector(
    (state: RootState) => state.applicantList.searchRequest
  )

  const mySearch = useSelector(
    (state: RootState) => state.applicantList.mySearch
  )

  const { displayLimit, searchResult, sortCondition } = useSelector(
    (state: RootState) => state.applicantList
  )

  const isManagementOffice = useSelector(
    (state: RootState) => state.applicantList.searchResult.isManagementOffice
  )

  const targetYear = useSelector(
    (state: RootState) => state.globalMenu.targetYear
  )

  const multiMySearchState = useSelector(
    (state: RootState) => state.applicantList.multiMySearchState
  )

  const isSchoolTabDisplay = useSelector(
    (state: RootState) => state.advancedSearch.schoolInfo.isSchoolTabDisplay
  )

  //検索ボタン押下,My検索ボタン押下どちらがされたのかのstate保持
  const [stateClickType, setStateClickType] = useState('')

  // Formikのsubmitボタン押下時のonClick処理
  const handleSubmitButtonClick = (type: string) => {
    setStateClickType(type)
  }

  //Formikのsubmitボタン押下時のsubmit処理
  const handleSubmit = (data: any) => {
    console.log("handleSubmit:" + JSON.stringify(data))
    businessCheck(data, stateClickType)
  }

  //検索条件クリア
  const handleReset = () => {
    // dispatch(setCurrentTab(0))
    dispatch(setSearchCondition(initSearchCondition))
  }

  // 業務チェック
  const businessCheck = (data: any, type: string) => {
    if (type === 'save' && multiMySearchState.mySearchCondition !== null) {
      dispatch(openModal(getMessage(contants.MESSAGECODE_MCAXS351_005)))
      return
    }
    let errorFlag = 0
    if (data.applicantId) {
      // 応募者管理ID　桁数チェック
      let idLength = 0
      let num = 0
        ; (data.applicantId as string)
          .split(/\r\n|\n/g)
          .filter(i => i !== '')
          .forEach((data: any) => {
            if (num === 0) {
              idLength = data.length
            }
            if (idLength !== data.length) {
              errorFlag = 1
            }
            num++
          })
      // 8桁と10桁以外の場合はerrorFlagを立てる
      if (errorFlag === 0) {
        if (idLength !== 8 && idLength !== 10 && idLength !== 0) {
          errorFlag = 1
        }
      }
    }
    if (errorFlag === 1) {
      dispatch(openModal(getMessage(contants.MESSAGECODE_MCAXS351_001)))
    } else if (
      data.applicantId &&
      contants.MESSAGECODE_MCAXS351_002_MAXCOUNT <
      (data.applicantId as string).split(/\r\n|\n/g).filter(i => i !== '')
        .length
    ) {
      // 応募者管理ID 件数チェック
      dispatch(openModal(getMessage(contants.MESSAGECODE_MCAXS351_002)))
    } else if (
      data.name &&
      contants.MESSAGECODE_MCAXS351_003_MAXCOUNT <
      (data.name as string).split(/\r\n|\n/g).filter(i => i !== '').length
    ) {
      // 氏名 件数チェック
      dispatch(openModal(getMessage(contants.MESSAGECODE_MCAXS351_003)))
    } else if (isManagementOffice && !data.entryPresenceObj && !data.entryDateObj && !data.seminarReceptionObj && !data.eventsObj) {
      // 必須項目チェック
      dispatch(openModal(getMessage(contants.MESSAGECODE_MCAXS351_004)))
    } else if (
      targetYear >= contants.MCB_YEAR_2025 &&
      (null === data.deliveryStatusObj ||
        data.deliveryStatusObj.value === '1') &&
      data.deadlineTypeObj &&
      data.deadlineTypeObj.value === '0' &&
      data.deadlineDateFrom === null &&
      data.deadlineDateTo === null
    ) {
      // 提出締切日 必須項目チェック
      dispatch(openModal(getMessage(contants.MESSAGECODE_MCAXS351_006)))
    } else if (type === 'search') {
      const newSearchRequest: MCAXS010SearchRequest = Object.assign(
        {},
        searchRequest
      )
      newSearchRequest.sourceFunctionId = 'MCAXS351'

      delete data.sendMessageCondition
      let newData = {
        ...data,
        name: data.name ? (data.name as string).replace(/[ 　\t]/g, '') : data.name,
      }

      newSearchRequest.searchCondition = JSON.stringify(toEntryList(newData, orderObjArray))
      newSearchRequest.searchCount = 1
      newSearchRequest.displayLimit = displayLimit.value !== '' ? Number(displayLimit.value) : null
      newSearchRequest.sortKey = sortCondition.order

      dispatch(
        search({
          request: newSearchRequest,
          isInitFilter: false,
          multiMySearch:
            multiMySearchState.mySearchCondition !== null
              ? multiMySearchState
              : undefined,
          onSearch: () => {
            dispatch(setOpen(false))
            dispatch(setDisplaySearchCriteriaTitleOpenFlag(false))
            scrollToAnchor()
          },
        })
      )
      dispatch(setSearchCondition(toEntryList(data, orderObjArray)))
    } else if (type === 'save') {
      // MY検索に保存

      delete data.sendMessageCondition

      const mcaxs010SearchCondtion = toEntryList(data, orderObjArray)
      dispatch(setSearchCondition(mcaxs010SearchCondtion))
      dispatch(setMCARS030SCriteriaParam(mcaxs010SearchCondtion))
      dispatch(getSelectCriteriaList())
      dispatch(setDialogOpen(true))
    }
  }

  useEffect(() => {
    // 学校情報タブ押下時 && 学校情報タブ情報未取得時
    if(currentTab === TabIndex.school && !isSchoolTabDisplay){
      dispatch(getMcaxs371Init())
    }
  },[currentTab, isSchoolTabDisplay])

  return (
    <>
      <Paper className={classes.root}>
        <Accordion expanded={open}
          TransitionProps={{
            onEnter: () => {
              setDisableTouch(true)
            },
            onEntered: () => {
              setDisableTouch(false)
            },
            onExit: () => {
              setDisableTouch(true)
            },
            onExited: () => {
              setDisableTouch(false)
            },
            timeout: 500,
          }}
          className={`${disableTouch ? classes.disablePointerEvents : ''}`}>
          <ExpansionPanelSummary>
            <Typography
              className={`btn high on-icon icon-search detailed-search ${classes.title}`}
              onClick={handleClick}>
              詳細な検索条件を{open === true ? '閉じる' : '指定する'}
            </Typography>
          </ExpansionPanelSummary>
          <Formik
            onSubmit={handleSubmit}
            onReset={handleReset}
            initialValues={fromEntryList(initialValues)}
            enableReinitialize
            validationSchema={SearchRequestValidation}
            // 相関チェック
            validate={values => {
              const errors = {
                firstEntryDateTo: '',
                reservationDateTo: '',
                eventDateTo: '',
                linkDateTo: '',
                sendDateTo: '',
                deadlineDateTo: '',
                submitDateTo: '',
                errorflag: '',
              }
              if (
                values.firstEntryDateFrom &&
                values.firstEntryDateTo &&
                values.firstEntryDateFrom > values.firstEntryDateTo
              ) {
                errors.firstEntryDateTo = '終了日が開始日よりも前です'
                errors.errorflag = '1'
              }
              if (
                values.reservationDateFrom &&
                values.reservationDateTo &&
                values.reservationDateFrom > values.reservationDateTo
              ) {
                errors.reservationDateTo = '終了日が開始日よりも前です'
                errors.errorflag = '1'
              }
              if (
                values.eventDateFrom &&
                values.eventDateTo &&
                values.eventDateFrom > values.eventDateTo
              ) {
                errors.eventDateTo = '終了日が開始日よりも前です'
                errors.errorflag = '1'
              }
              // [phase2] start
              if (
                values.linkDateFrom &&
                values.linkDateTo &&
                values.linkDateFrom > values.linkDateTo
              ) {
                errors.linkDateTo = '終了日が開始日よりも前です'
                errors.errorflag = '1'
              }
              // [phase2] end
              if (
                values.sendDateFrom &&
                values.sendDateTo &&
                values.sendDateFrom > values.sendDateTo
              ) {
                errors.sendDateTo = getMessage(magiContants.MESSAGECODE_MCAXS701_004)
                errors.errorflag = '1'
              }
              if (
                values.deadlineDateFrom &&
                values.deadlineDateTo &&
                values.deadlineDateFrom > values.deadlineDateTo
              ) {
                errors.deadlineDateTo = getMessage(magiContants.MESSAGECODE_MCAXS701_005)
                errors.errorflag = '1'
              }
              if (
                values.submitDateFrom &&
                values.submitDateTo &&
                values.submitDateFrom > values.submitDateTo
              ) {
                errors.submitDateTo = getMessage(magiContants.MESSAGECODE_MCAXS701_006)
                errors.errorflag = '1'
              }
              if (errors.errorflag === '1') {
                return errors
              }
            }}
            render={formik => {
              return (
                <ExpansionPanelDetails>
                  <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'>
                    <Tab
                      label='基本情報'
                      className={classes.tab}
                      value={TabIndex.basic}
                    />
                    <Tab
                      label='学校情報'
                      className={classes.tab}
                      value={TabIndex.school}
                    />
                    <Tab
                      label='応募情報'
                      className={classes.tab}
                      value={TabIndex.applicant}
                    />
                    <Tab
                      label='採用管理情報'
                      className={classes.tab}
                      value={TabIndex.recruitmentManagement}
                    />
                    {targetYear >= contants.MCB_YEAR_2025 && (
                      <Tab
                        label='My CareerBox情報'
                        className={`${classes.tab} ${classes.textTransform}`}
                        value={TabIndex.submissionRequest}
                      />
                    )}
                  </Tabs>
                  <TabItem
                    onSubmitButtonClick={handleSubmitButtonClick}
                    enableDisplayLimit
                    displayLimit={displayLimit}
                    displayLimitOption={searchResult.displayLimitOptionInfo}>
                    <TabPanel value={currentTab} index={TabIndex.basic}>
                      <Basic form={formik} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={TabIndex.school}>
                      {isSchoolTabDisplay ? (
                        <School form={formik} />
                      ) : (
                        <div className={classes.NoResult}>
                          {magiContants.MESSAGEINFO_LOADING}
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel value={currentTab} index={TabIndex.applicant}>
                      <Applicant form={formik} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={TabIndex.recruitmentManagement}>
                      <RecruitmentManagement form={formik} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={TabIndex.submissionRequest}>
                      <SubmissionRequest form={formik} />
                    </TabPanel>
                    <OverlaySearch
                      show={isOverlayShow({
                        open,
                        tableInViewport,
                        buttonInViewport
                      })}
                      onClick={handleSubmitButtonClick}
                      enableDisplayLimit
                      displayLimit={displayLimit}
                      displayLimitOption={searchResult.displayLimitOptionInfo}
                    />
                  </TabItem>
                </ExpansionPanelDetails>
              )
            }}
          />
        </Accordion>
      </Paper>

      <MCARS030
        escsId={mySearch.entrySearchCriteriaSettingId}
        scName={mySearch.searchCriteriaName}
        sCriteria={MCARS030SCriteriaParam}
      />

      <ManagementModal
        open={managementModalOpen}
        onClose={() => {
          dispatch(setManagementModalOpen(false))
        }}
        form={fromEntryList(initialValues)}
      />

      <EntryReplyModal
        open={entryReplyModalOpen}
        onClose={() => {
          dispatch(setEntryReplyModalOpen(false))
        }}
        form={fromEntryList(initialValues)}
      />
    </>
  )
}

export default AdvancedSearch
