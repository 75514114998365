import { SexObj, MemberTypeObj } from 'pages/MCAXS020/searchConditionConfig'

export interface Option {
  label: string
  value: string
}

export interface deliveryDetailConditionObj {
  deliveryDetailConditionId: string
  deliveryDetailConditionName: string
}

export interface deadlineTypeObj {
  deadlineCode: string
  deadlineName: string
}

export interface deliveryStatusObj {
  deliveryStatusCode: string
  deliveryStatusName: string
}

export interface submitStatusObj {
  submitStatusCode: string
  submitStatusName: string
}

export interface SearchCondition {
  // Basic
  applicantId: string[] | null
  name: string[] | null
  sexObj: SexObj[] | null
  phoneNumber: string | null
  mailAddress: string | null
  residenceObj: ResidenceConditions | null
  languageSkills: string[] | null
  eligibility: string[] | null
  memberTypeObj: MemberTypeObj[] | null

  // School
  graduationPeriodObj: GraduationPeriodObj[] | null
  philosophyHumanitiesObj: PhilosophyHumanitiesObj[] | null
  schoolTypeObj: SchoolTypeObj[] | null
  schoolName: string | null
  facultyGraduateName: string | null
  departmentDepartmentName: string | null
  schoolGroupObj: SchoolGroupObj[] | null
  schoolDetailsObj: SchoolDetailsObj[] | null
  undergraduateDepartmentObj: UndergraduateDepartmentObj[] | null
  studentClassificationObj: StudentClassificationObj[] | null
  departmentDetailsObj: DepartmentDetailsObj[] | null
  countryRegionObj: CountryRegionObj[] | null

  // Applicant
  applicationRouteObj: ApplicationRouteObj[] | null
  firstEntryDateFrom: string | null
  firstEntryDateTo: string | null
  targetApplicationRouteObj: TargetApplicationRouteObj[] | null
  entryDateObj: EntryDateObj | null
  entryPresenceObj: EntryPresenceObj | null
  questionnaireResponseObj: QuestionnaireResponseObj | null
  seminarReceptionObj: SeminarReceptionObj | null
  reservationDateFrom: string | null
  reservationDateTo: string | null
  webSeminarObj: WebSeminarObj | null
  // 24KH #61555 start
  webInterviewParticipationObj: WebInterviewParticipationObj | null
  // 24KH #61555 end
  eventsObj: EventsObj | null
  eventDateFrom: string | null
  eventDateTo: string | null
  sendMessegeObj: SendMessegeObj | null
  scoutApplicationRouteObj: ScoutApplicationRouteObj[] | null
  scoutObj: ScoutObj | null
  scoutPreSiteObj: ScoutSiteObj[] | null
  scoutCharacterConditionJudgment: ScoutCharacteAndOrObj | null
  scoutProSiteObj: ScoutSiteObj[] | null
  welcomeApplicationRouteObj: WelcomeApplicationRouteObj[] | null
  welcomeObj: WelcomeObj | null

  // RecruitmentManagement
  selectionFlowObj: SelectionFlowObj[] | null
  selectionStepObj: SelectionStepObj[] | null
  judgmentUndeterminedObj: JudgmentUndeterminedObj[] | null
  selectionFlowStepPassFailObj: SelectionFlowStepPassFailObj | null
  managementItemObj: ManagementItemObj | null
  tagTxtObj: TagTxtObj[] | null
  // TO DO
  // 25KH #74722 start
  //タグ条件判定FLG
  tagJudgmentFlag: string | null
  //タグ条件判定名
  tagJudgmentName: string | null
  // タグ除外
  tagExclusionTxtObj: TagExclusionTxtObj[] | null
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: string | null
  // タグ除外条件判定名
  tagExclusionJudgmentName: string | null
  // 25KH #74722 end
  // TO DO
  imagesObj: ImagesObj[] | null
  gabObj: GABObj[] | null
  opqObj: OPQObj[] | null
  cabObj: CABObj[] | null
  // [phase2] start  
  searchTargetObj: SearchTargetObj | null
  mcbCategoryAndOrObj: McbCategoryAndOrObj | null
  mcbCategoryObj: McbCategoryObj[]
  linkDateFrom: string | null
  linkDateTo: string | null
  // [phase2] end

  //MY CareerBOX情報 SubmissionRequest
  deliveryStatusObj: deliveryStatusObj | null    //提出リクエスト状況
  deliveryDetailConditionObj: deliveryDetailConditionObj[] | null //提出物内容
  deliveryDetailConditionAndOrObj: deliveryDetailConditionAndOrObj | null
  deadlineTypeObj: deadlineTypeObj | null //提出締切日(条件)
  submitStatusObj: submitStatusObj | null//提出状況(条件) 
  sendDateFrom: string | null  //提出リクエスト日
  sendDateTo: string | null
  deadlineDateFrom: string | null  //提出締切日
  deadlineDateTo: string | null
  submitDateFrom: string | null  //最終提出日
  submitDateTo: string | null

}

export interface ScoutSiteObj {
  scoutSiteCode: string
  scoutSiteName: string
}

// [phase2] start 
export interface SearchTargetObj {
  searchTargetCode: string
  searchTargetName: string
}

export interface McbCategoryAndOrObj {
  mcbCategoryAndOrCode: string
  mcbCategoryAndOrName: string
}

export interface deliveryDetailConditionAndOrObj {
  deliveryDetailConditionAndOrCode: string
  deliveryDetailConditionAndOrName: string
}

export interface McbCategoryObj {
  mcbCategoryCode: string
  mcbCategoryName: string
}
const categoryAndOrObj: McbCategoryAndOrObj = {
  mcbCategoryAndOrCode: '0',
  mcbCategoryAndOrName: 'AND'
}

const deliveryDetailConditionAndOrObj: deliveryDetailConditionAndOrObj = {
  deliveryDetailConditionAndOrCode: '0',
  deliveryDetailConditionAndOrName: 'AND'
}
// [phase2] end

export interface CABObj {
  cabLogicalName: string
  cabPhysicalName: string
  cabListFlag: string
  cabListName: string
  cabValueFrom: string
  cabValueTo: string
  cabTyp: string
  cabTypName: string
}

export interface OPQObj {
  opqLogicalName: string
  opqPhysicalName: string
  opqValueFrom: string
  opqValueTo: string
  opqTyp: string
  opqTypName: string
}

export interface GABObj {
  gabLogicalName: string
  gabPhysicalName: string
  gabListFlag: string
  gabListName: string
  gabValueFrom: string
  gabValueTo: string
  gabTyp: string
  gabTypName: string
}

export interface ImagesObj {
  imagesLogicalName: string
  imagesPhysicalName: string
  imagesValueFrom: string
  imagesValueTo: string
}

export interface ManagementItemFlagCheckBoxObj {
  managementItemFlagCheckBoxId: string
  managementItemFlagCheckBoxName: string
}

export interface ManagementItemConditionObj {
  recruitmentManagementFlagSettingId: string
  recruitmentManagementFlagName: string
  managementItemPresenceFlag: string
  managementItemPresenceName: string
  managementItemTypeCode: string
  managementItemTypeName: string
  managementItemFlagText: string
  managementItemFlagFrom: string
  managementItemFlagTo: string
  managementItemFlagCheckBoxObj: ManagementItemFlagCheckBoxObj[]
}

export interface ManagementItemObj {
  managementItemConditionJudgmentFlag: string
  managementItemConditionJudgmentName: string
  managementItemConditionObj: ManagementItemConditionObj[]
}

export interface SendMessegeConditionObj {
  sendMessegeMessageTypeFlag: string
  sendMessegeMessageTypeName: string
  sendMessegeSubject: string
  sendMessegeSendConditionFlag: number
  sendMessegeSendConditionName: string
  sendMessegeSendDateFrom: string
  sendMessegeSendDateTo: string
  sendMessegeStatusObj: SendMessegeStatusObj[]
  sendMessegeTemplateConditionFlag: number
  sendMessegeTemplateConditionName: string
  sendMessegeTemplateObj: SendMessegeTemplateObj[]
  // phase2 start
  sendMessegeMyNaviAttachedOptionEntryBoxId: string
  sendMessegeMyNaviAttachedOptionSeminarId: string
  sendMessegeMyNaviAttachedOptionName: string
  // phase2 end
}

export interface SendMessegeStatusObj {
  sendMessegeStatusId: string
  sendMessegeStatusName: string
}

export interface SendMessegeTemplateObj {
  sendMessegeTemplateId: string
  sendMessegeTemplateName: string
  sequence: number
}

export interface SendMessegeObj {
  sendMessegeConditionJudgmentFlag: number
  sendMessegeConditionJudgmentName: string
  sendMessegeConditionObj: SendMessegeConditionObj[]
}

export interface EventsConditionObj {
  eventsIndivIdualApplicationRouteId: string
  eventsIndividualApplicationRouteName: string
  eventsIndivIdualEventTypeId: string
  eventsIndividualEventTypeName: string
  eventsIndividualCoordinationFormerEventId: string
  eventsIndividualEventSchedule: string
  eventsIndivIdualEventId: string
  eventsIndividualEventName: string
  eventsIndividualPrefecturesCode: string
  eventsIndividualPrefecturesName: string
  eventsIndividualFlag: number
  eventsIndividualName: string
  // Started By DiaoJiaHao
  eventsIndividualAreaId: string
  eventsIndividualAreaName: string
  // End By DiaoJiaHao
}

export interface EventsObj {
  eventsConditionJudgmentFlag: number
  eventsConditionJudgmentName: string
  eventsWhichOneFlag: number
  eventsWhichOneName: string
  eventsIndividualJudgmentFlag: number
  eventsIndividualJudgmentName: string
  eventsConditionObj: EventsConditionObj[]
}

export interface WebSeminarConditionObj {
  webSeminarApplicationRouteId: string
  webSeminarApplicationRouteName: string
  webSeminarSeminarScheduleId: string
  webSeminarCoordinationFormerWebSeminarId: string
  webSeminarTitle: string
  webSeminarWatchingStatusConditionFlag: number
  webSeminarWatchingStatusConditionName: string
  webSeminarWatchingStatusConditionObj: WebSeminarWatchingStatusConditionObj[]
}

export interface WebSeminarWatchingStatusConditionObj {
  webSeminarWatchingStatusConditionFlag: number
  webSeminarWatchingStatusConditionName: string
}

export interface WebSeminarObj {
  webSeminarConditionJudgmentFlag: number
  webSeminarConditionJudgmentName: string
  webSeminarConditionObj: WebSeminarConditionObj[]
}

// 24KH #61555 start
export interface WebInterviewParticipationObj {
  // WEB面談参加状況条件判定FLG
  webInterviewParticipationConditionJudgmentFlag: number
  // WEB面談参加状況条件判定名
  webInterviewParticipationConditionJudgmentName: string
  // WEB面談参加状況（いずれかの日程）条件
  webInterviewParticipationWhichOneObj: WebInterviewParticipationWhichOneObj | null
  // WEB面談参加状況（各受付ごと）
  webInterviewParticipationIndividualObj: WebInterviewParticipationIndividualObj | null
}
export interface WebInterviewParticipationWhichOneObj {
  // WEB面談参加状況（いずれかの日程）FLG
  webInterviewParticipationWhichOneFlag: number | null
  // WEB面談参加状況（いずれかの日程）名
  webInterviewParticipationWhichOneName: string | null
}
export interface WebInterviewParticipationIndividualObj {
  // WEB面談参加状況（各受付ごと）条件判定FLG
  webInterviewParticipationIndividualJudgmentFlag: number
  // WEB面談参加状況（各受付ごと）条件判定名
  webInterviewParticipationIndividualJudgmentName: string
  // WEB面談参加状況（各受付ごと）条件
  webInterviewParticipationIndividualObj2: WebInterviewParticipationIndividualObj2[]
}
export interface WebInterviewParticipationIndividualObj2 {
  // WEB面談参加状況（各受付ごと）FLG
  webInterviewParticipationIndividualFlag: number
  // WEB面談参加状況（各受付ごと）名
  webInterviewParticipationIndividualName: string
  // 応募経路ID
  webInterviewParticipationIndividualApplicationRouteId: string | null
  // 応募経路名
  webInterviewParticipationIndividualApplicationRouteName: string | null
  // 連携元セミナーID
  webInterviewParticipationIndividualCoordinationFormerSeminarId: string | null
  // セミナーID
  webInterviewParticipationIndividualSeminarId: string | null
  // セミナー名
  webInterviewParticipationIndividualSeminarName: string | null
  // 公開フラグ
  webInterviewParticipationIndividualPublicFlag: string | null
  // 公開フラグ名
  webInterviewParticipationIndividualPublicName: string | null
  // セミナー日程ID
  webInterviewParticipationIndividualSeminarScheduleId: string
  // セミナー日程タイトル
  webInterviewParticipationIndividualSeminarScheduleTitle: string | null
  // 非表示フラグ
  webInterviewParticipationIndividualInvisibleFlag: string | null
  // 非表示フラグ名
  webInterviewParticipationIndividualInvisibleName: string | null
  // 開催日
  webInterviewParticipationIndividualEventDate: string | null
  // 開催日テキスト
  webInterviewParticipationIndividualEventDateText: string | null
  // 開催時間
  webInterviewParticipationIndividualStartTime: string | null
  // 終了時間
  webInterviewParticipationIndividualEndTime: string | null
  // 開催地場所コード
  webInterviewParticipationIndividualVenueLocationCode: string | null
  // 開催地場所名
  webInterviewParticipationIndividualVenueLocationName: string | null
}
// 24KH #61555 end

export interface SeminarReceptionConditionObj {
  seminarReceptionIndividualApplicationRouteId: string
  seminarReceptionIndividualApplicationRouteName: string
  seminarReceptionIndividualCoordinationFormerSeminarId: string
  seminarReceptionIndividualSeminarId: string
  seminarReceptionIndividualSeminarName: string
  seminarReceptionIndividualPublicFlag: string
  seminarReceptionIndividualPublicName: string
  seminarReceptionIndividualSeminarScheduleId: string
  seminarReceptionIndividualSeminarScheduleTitle: string
  seminarReceptionIndividualInvisibleFlag: string
  seminarReceptionIndividualInvisibleName: string
  seminarReceptionIndividualEventDate: string
  // [phase2] 開催日テキスト add start
  seminarReceptionIndividualEventDateText: string
  // [phase2] end
  seminarReceptionIndividualStartTime: string
  seminarReceptionIndividualEndTime: string
  seminarReceptionIndividualVenueLocationCode: string
  seminarReceptionIndividualVenueLocationName: string
  seminarReceptionIndividualFlag: number
  seminarReceptionIndividualName: string
}

export interface SeminarReceptionObj {
  seminarReceptionConditionJudgmentFlag: number
  seminarReceptionConditionJudgmentName: string
  seminarReceptionWhichOneFlag: number
  seminarReceptionWhichOneName: string
  seminarReceptionIndividualJudgmentFlag: number
  seminarReceptionIndividualJudgmentName: string
  seminarReceptionConditionObj: SeminarReceptionConditionObj[]
}

export interface QuestionnaireResponseAnswerCheckBoxObj {
  questionnaireResponseAnswerCheckBoxId: string
  questionnaireResponseAnswerCheckBoxName: string
}

export interface QuestionnaireResponseConditionObj {
  questionnaireResponseApplicationRouteId: string
  questionnaireResponseApplicationRouteName: string
  questionnaireResponseEntryBoxId: string
  questionnaireResponseEntryBoxName: string
  questionnaireResponseQuestionNumber: string
  questionnaireResponseQuestionTitle: string
  questionnaireResponseQuestionAnswerConditionFlag: number
  questionnaireResponseQuestionAnswerConditionName: string
  questionnaireResponseQuestionTypeCode: string
  questionnaireResponseQuestionTypeName: string
  questionnaireResponseAnswerText: string
  questionnaireResponseAnswerCheckBoxObj: QuestionnaireResponseAnswerCheckBoxObj[]
}

export interface QuestionnaireResponseObj {
  questionnaireResponseConditionJudgmentFlag: number
  questionnaireResponseConditionJudgmentName: string
  questionnaireResponseConditionObj: QuestionnaireResponseConditionObj[]
}

export interface EntryPresenceConditionObj {
  entryPresenceClassificationFlag: string | null
  entryPresenceClassificationName: string | null
  entryPresenceApplicationRouteId: string | null
  entryPresenceApplicationRouteName: string | null
  entryPresencecoordinationFormerEntryBoxId: string | null
  entryPresenceEntryBoxId: string | null
  entryPresenceEntryBoxName: string | null
  entryPresenceFlag: number | null
  entryPresenceName: string | null
}

export interface EntryPresenceObj {
  entryPresenceConditionJudgmentFlag: number
  entryPresenceConditionJudgmentName: string
  entryPresenceConditionObj: EntryPresenceConditionObj[]
}

export interface EntryDateConditionObj {
  entryDateClassificationFlag: string
  entryDateClassificationName: string
  entryDateApplicationRouteId: string
  entryDateApplicationRouteName: string
  entryDatecoordinationFormerEntryBoxId: string
  entryDateEntryBoxId: string
  entryDateEntryBoxName: string
  entryDateFrom: string
  entryDateTo: string
}

export interface EntryDateObj {
  entryDateConditionJudgmentFlag: number
  entryDateConditionJudgmentName: string
  entryDateConditionObj: EntryDateConditionObj[]
}

export interface WelcomeObj {
  welcomeConditionsFlag: number
  welcomeConditionsName: string
}

export interface WelcomeApplicationRouteObj {
  welcomeApplicationRouteId: string
  welcomeApplicationRouteName: string
}

export interface ScoutObj {
  scoutConditionsFlag: number
  scoutConditionsName: string
}

export interface ScoutCharacteAndOrObj {
  scoutCharacteAndOrCode: string
  scoutCharacteAndOrName: string
}

export interface ScoutApplicationRouteObj {
  scoutApplicationRouteId: string
  scoutApplicationRouteName: string
}

export interface TagTxtObj {
  tagSettingId: string
  tagName: string
}

// TO DO
// 25KH #74722 start
// タグ除外
export interface TagExclusionTxtObj {
  tagExclusionSettingId: string
  tagExclusionName: string
}
// 25KH #74722 end
// TO DO

export interface CountryRegionObj {
  countryRegionCode: string
  countryRegionName: string
}

export interface DepartmentDetailsObj {
  departmentId: string
  departmentName: string
}

export interface StudentClassificationObj {
  studentClassificationId: string
  studentClassificationName: string
}

export interface UndergraduateDepartmentObj {
  undergraduateDepartmentSchoolTypeId: string
  undergraduateDepartmentSchoolTypeName: string
  undergraduateDepartmentUniversityId: string
  undergraduateDepartmentUniversityName: string
  undergraduateDepartmentFacultyId: string
  undergraduateDepartmentFacultyName: string
  undergraduateDepartmentSubjectId: string
  undergraduateDepartmentSubjectName: string
  subjcetInfomationDagagaMasterId: string
}

export interface SchoolDetailsObj {
  schoolDetailsId: string
  schoolDetailsName: string
}

export interface SchoolGroupObj {
  schoolGroupId: string
  schoolGroupName: string
}

export interface SchoolTypeObj {
  schoolTypeId: string
  schoolTypeName: string
}

export interface PhilosophyHumanitiesObj {
  philosophyHumanitiesCode: string
  philosophyHumanitiesName: string
}

export interface GraduationPeriodObj {
  graduationPeriodCode: string
  graduationPeriodName: string
}

export interface ResidenceCurrent {
  residenceCurrentPrefecturesCode: string
  residenceCurrentPrefecturesName: string
}

export interface ResidenceVacation {
  residenceVacationPrefecturesCode: string
  residenceVacationPrefecturesName: string
}

export interface ResidenceConditions {
  residenceCurrentObj: ResidenceCurrent[]
  residenceVacationObj: ResidenceVacation[]
  residenceConnectorFlag: number
  residenceConnectorName: string
}

export interface ApplicationRouteObj {
  applicationRouteFlag: number
  applicationRouteId: string
  applicationRouteName: string
}

export interface TargetApplicationRouteObj {
  targetApplicationRouteFlag: number
  targetApplicationRouteId: string
  targetApplicationRouteName: string
}

export interface SelectionFlowObj {
  selectionFlowId: string
  selectionFlowName: string
}

export interface SelectionStepObj {
  selectionStepId: string
  selectionStepName: string
}

export interface JudgmentUndeterminedObj {
  judgmentUndeterminedCode: string
  judgmentUndeterminedName: string
}

export interface SeminarReceptionObj {
  seminarReceptionConditionJudgmentFlag: number
  seminarReceptionConditionJudgmentName: string
  seminarReceptionWhichOneObj: SeminarReceptionWhichOneObj
  seminarReceptionIndividualObj: SeminarReceptionIndividualObj
}

export interface SeminarReceptionWhichOneObj {
  seminarReceptionWhichOneFlag: number | null
  seminarReceptionWhichOneName: string | null
}

export interface SeminarReceptionIndividualObj {
  seminarReceptionIndividualJudgmentFlag: number
  seminarReceptionIndividualJudgmentName: string
  seminarReceptionIndividualObj2: SeminarReceptionIndividualObj2[]
}

export interface SeminarReceptionIndividualObj2 {
  seminarReceptionIndividualApplicationRouteId: string | null
  seminarReceptionIndividualApplicationRouteName: string | null
  seminarReceptionIndividualCoordinationFormerSeminarId: string | null
  seminarReceptionIndividualSeminarId: string | null
  seminarReceptionIndividualSeminarName: string | null
  seminarReceptionIndividualPublicFlag: string | null
  seminarReceptionIndividualPublicName: string | null
  seminarReceptionIndividualSeminarScheduleId: string
  seminarReceptionIndividualSeminarScheduleTitle: string | null
  seminarReceptionIndividualInvisibleFlag: string | null
  seminarReceptionIndividualInvisibleName: string | null
  seminarReceptionIndividualEventDate: string | null
  // [phase2] 開催日テキスト add start
  seminarReceptionIndividualEventDateText: string | null
  // [phase2] end
  seminarReceptionIndividualStartTime: string | null
  seminarReceptionIndividualEndTime: string | null
  seminarReceptionIndividualVenueLocationCode: string | null
  seminarReceptionIndividualVenueLocationName: string | null
  seminarReceptionIndividualFlag: number
  seminarReceptionIndividualName: string
}

export interface EventsObj {
  eventsConditionJudgmentFlag: number
  eventsConditionJudgmentName: string
  eventsWhichOneObj: EventsWhichOneObj
  eventsIndividualObj: EventsIndividualObj
}

export interface EventsWhichOneObj {
  eventsWhichOneFlag: number | null
  eventsWhichOneName: string | null
}

export interface EventsIndividualObj {
  eventsIndividualJudgmentFlag: number
  eventsIndividualJudgmentName: string
  eventsIndividualObj2: EventsIndividualObj2[]
}

export interface EventsIndividualObj2 {
  eventsIndivIdualApplicationRouteId: string | null
  eventsIndividualApplicationRouteName: string | null
  eventsIndivIdualEventTypeId: string | null
  eventsIndividualEventTypeName: string | null
  eventsIndividualCoordinationFormerEventId: string | null
  eventsIndividualEventSchedule: string | null
  eventsIndivIdualEventId: string
  eventsIndividualEventName: string | null
  eventsIndividualPrefecturesCode: string | null
  eventsIndividualPrefecturesName: string | null
  eventsIndividualFlag: number
  eventsIndividualName: string
  // Started By DiaoJiaHao
  eventsIndividualAreaId: string | null
  eventsIndividualAreaName: string | null
  // End By DiaoJiaHao
}

export interface SelectionFlowStepPassFailObj {
  searchConditionFlag: string
  searchConditionName: string
  selectionFlowObj: SelectionFlowObjDto[]
}
export interface SelectionFlowObjDto {
  selectionFlowCode: string
  selectionFlowName: string
  // 25KH #75311 start
  selectionPhaseJudgmentFlag: string
  selectionPhaseJudgmentName: string
  // 25KH #75311 end
  selectionFlowSelectAllFlag: string
  selectionFlowSelectAllName: string
  selectionStepObj: SelectionStepObjDto[]
  exceptCode: string
  exceptName: string
}
export interface SelectionStepObjDto {
  selectionStepCode: string
  selectionStepName: string
  passFailObj: PassFailObjDto[]
}
export interface PassFailObjDto {
  passFailCode: string
  passFailName: string
}

export const initSearchCondition: any = {
  applicantId: null,
  name: null,
  sexObj: null,
  phoneNumber: null,
  mailAddress: null,
  residenceObj: null,
  languageSkills: null,
  eligibility: null,
  memberTypeObj: null,
  graduationPeriodObj: null,
  philosophyHumanitiesObj: null,
  schoolTypeObj: null,
  schoolName: null,
  facultyGraduateName: null,
  departmentDepartmentName: null,
  schoolGroupObj: null,
  schoolDetailsObj: null,
  undergraduateDepartmentObj: null,
  studentClassificationObj: null,
  departmentDetailsObj: null,
  countryRegionObj: null,
  applicationRouteObj: null,
  firstEntryDateFrom: null,
  firstEntryDateTo: null,
  targetApplicationRouteObj: null,
  entryDateObj: null,
  entryPresenceObj: null,
  questionnaireResponseObj: null,
  seminarReceptionObj: null,
  reservationDateFrom: null,
  reservationDateTo: null,
  webSeminarObj: null,
  webInterviewParticipationObj: null,
  eventsObj: null,
  eventDateFrom: null,
  eventDateTo: null,
  sendMessegeObj: null,
  scoutApplicationRouteObj: null,
  scoutObj: null,
  scoutCharacterConditionJudgment: {
    scoutCharacteAndOrCode: '0',
    scoutCharacteAndOrName: 'AND'
  },
  welcomeApplicationRouteObj: null,
  welcomeObj: null,
  selectionFlowObj: null,
  selectionStepObj: null,
  judgmentUndeterminedObj: null,
  selectionFlowStepPassFailObj: null,
  managementItemObj: null,
  tagTxtObj: null,
  // TO DO
  // 25KH #74722 start
  // タグ条件判定FLG
  tagJudgmentFlag: null,
  // タグ条件判定名
  tagJudgmentName: null,
  // タグ除外
  tagExclusionTxtObj: null,
  // タグ除外条件判定FLG
  tagExclusionJudgmentFlag: null,
  // タグ除外条件判定名
  tagExclusionJudgmentName: null,
  // 25KH #74722 end
  // TO DO
  imagesObj: null,
  gabObj: null,
  opqObj: null,
  cabObj: null,
  // [phase2] start  
  searchTargetObj: null,
  mcbCategoryAndOrObj: categoryAndOrObj,
  mcbCategoryObj: null,
  linkDateFrom: null,
  linkDateTo: null,
  // [phase2] end  
  // MCBリプレース MCAXS701 START
  deliveryStatusObj: null,    //提出リクエスト状況
  deliveryDetailConditionObj: [], //提出物内容
  deliveryDetailConditionAndOrObj: deliveryDetailConditionAndOrObj,
  submitStatusObj: null,//提出状況(条件) 
  sendDateFrom: null,  //提出リクエスト日
  sendDateTo: null,
  deadlineDateFrom: null,  //提出締切日
  deadlineDateTo: null,
  submitDateFrom: null,  //最終提出日
  submitDateTo: null,
  // MCBリプレース MCAXS701 END

}
